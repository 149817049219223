import React, { PureComponent } from 'react';
import {I18n} from "react-i18next";
import Loading from "../Loading";

export default class EZPayRow extends PureComponent {
    onTextChange = e => {
        this.props.onEnterAmount(e);
    };

    onKeyDown = e => {
        const DOT_KEY_CODE = 190;
        const COMMA_KEY_CODE = 188;
        if (e.keyCode === DOT_KEY_CODE || e.keyCode === COMMA_KEY_CODE) {
            e.preventDefault();
        }
    }

    render() {
        const {
            isUnderline,
            label,
            value,
            textClass,
            isVerticalLine,
            marginClass,
            isInput,
            isLoading,
            requestAmount
        } = this.props;
        const verticalLineClass = isVerticalLine ? 'border-right' : ''
        return (<I18n ns="translations">
            {
                t => (
                    <div>
                        <div className={`row ${marginClass}`}>
                            <div className={`col-lg-7 col-md-12 ${verticalLineClass}`}>
                                <span className={`text-black ${textClass}`}>{label}</span>
                            </div>
                                <div className='col-lg-5 col-md-12 text-right'>
                                    {!isInput ?
                                        isLoading ?
                                            value ?
                                            <span className={`text-black ${textClass}`}>{value}</span>
                                            : (<Loading parent1Class="text-right"/>)
                                        : <span className={`text-black ${textClass}`}>{value}</span>
                                    :<input
                                        value={!!requestAmount ? requestAmount : ""}
                                        type='number'
                                        className="transfer-to-card-input text-right"
                                        placeholder={t('type_here')}
                                        onChange={this.onTextChange}
                                        onKeyDown={this.onKeyDown}
                                    />
                                    }
                                </div>
                        </div>
                        {isUnderline && <hr className='transfer-to-card-line'/>}
                    </div>
                )}</I18n>
        )
    }
}