import React, { Component } from 'react'
import {I18n} from "react-i18next";
import './confirmAddress.css'
import {
    getAmountBalance
} from '../../05-utils/commonUtils';
import { constants } from '../../05-utils/commonData'

class ConfirmAddress extends Component {
    render() {
        const {
            titleSelected,
            typeCode,
            type,
            walletSelected,
            numberOfPackage,
            totalAmount,
            numberOfShipping
        } = this.props;
        return (
            <I18n ns="translations">
                {
                    t => (
                        <div className='wrapper-confirm-address'>
                                <table>
                                    <tbody>
                                    {
                                        typeCode === constants.TYPE_CODE.REGISTER_CODE ?
                                            <tr className='info-row'>
                                                <td className='info-title'>{t('type_code')}:</td>
                                                <td className='info-content'>{titleSelected.name}</td>
                                            </tr>
                                            :
                                            <tr className='info-row'>
                                                <td className='info-title'>{type === constants.TYPE_REPEAT_ORDER_CODE.RETAIL ? t('number_or_package') : t('number_of_shipping')}:</td>
                                                <td className='info-content'>{type === constants.TYPE_REPEAT_ORDER_CODE.RETAIL ? numberOfPackage : numberOfShipping}</td>
                                            </tr>
                                    }
                                    <tr className='info-row'>
                                        <td className='info-title'>{t('address')}:</td>
                                        <td className='info-content'>{walletSelected}</td>
                                    </tr>
                                    {
                                        typeCode === constants.TYPE_CODE.REGISTER_CODE ?
                                            <tr className='info-row'>
                                                <td className='info-title'>{t('amount')}:</td>
                                                <td className='info-content'>{titleSelected.coin_amount}</td>
                                            </tr>
                                            :
                                            <tr className='info-row'>
                                                <td className='info-title'>{t('amount')}:</td>
                                                <td className='info-content'>{`${getAmountBalance(totalAmount, 3)} AGT`}</td>
                                            </tr>
                                    }
                                    </tbody>
                                </table>
                        </div>
                    )
                }
            </I18n>
        );
    }
}

export default ConfirmAddress;