import { createSelector } from 'reselect'
import { errors } from '../05-utils/commonData';

const getMaintenanceMode = (state, props) => state.system.maintenanceMode;

const getErrorAuth = (state, props) => state.auth.error;
const getErrorUser = (state, props) => state.user.error;
const getErrorAsset = (state, props) => state.asset.error;
const getErrorTransaction = (state, props) => state.transaction.error;
const getErrorWallet = (state, props) => state.wallet.error;

const getMaintanceModeFromError = createSelector(
    [getErrorAuth, getErrorUser, getErrorAsset, getErrorTransaction, getErrorWallet],
    (authErr, userErr, assetErr, transactionErr, walletErr) => {
        let errorsList = [authErr, userErr, assetErr, transactionErr, walletErr];
        return errorsList.find(err => err && err.errorCode === errors.SERVER_IS_MAINTENANCE.errorCode);
    }
);

const isMaintenanceMode = createSelector(
    [getMaintenanceMode, getMaintanceModeFromError], 
    (maintenanceMode, getMaintenanceModeFromError) => {
        return maintenanceMode || getMaintenanceModeFromError;
    }
);

export {
    getMaintanceModeFromError,
    isMaintenanceMode
}