import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { I18n } from 'react-i18next';
import {
	deleteBackUpFile,
	resetDeleteBackUpFileStatus,
} from '../../../../../01-actions/adminDB';
import SubmitButton from '../../../../../03-components/ModalSubmitButton';

class ConfirmDeleteFile extends Component {
	deleteFile = () => {
		const { actions, backupFileName } = this.props;
		actions.deleteBackUpFile({ backupFileName });
	};

	render() {
		const {
			isOpen,
			toggle,
			backUpFileDeleting,
		} = this.props;
		return (
			<I18n ns="translations">
				{
					t => (
						<Modal isOpen={isOpen} toggle={toggle} className="modal-lg modal-delete-contact">
							<ModalHeader toggle={toggle}>{t('warning')}</ModalHeader>
							<ModalBody>
								<div className="text-center">
									<i className="fa fa-exclamation-triangle text-warning"/>
									<p className="delete-contact-intro">{t('delete_back_up_file_confirm')}</p>
								</div>
							</ModalBody>
							<ModalFooter>
								<SubmitButton
									className="btn btn-light"
									onClick={this.props.toggle}
									text={t('cancel')}
								/>
								<SubmitButton
									className="btn btn-danger"
									onClick={this.deleteFile}
									isSubmitting={backUpFileDeleting}
									text={t('delete')}
								/>
							</ModalFooter>
						</Modal>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		backUpFileDeleting: store.adminDB.backUpFileDeleting,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				deleteBackUpFile,
				resetDeleteBackUpFileStatus,
			},
			dispatch
		)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ConfirmDeleteFile);
