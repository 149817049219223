import I18n from 'i18next';

const validate = values => {
	const errors = {};
	if (!values.resetCode) {
		errors.resetCode = I18n.t('RESET_CODE_REQUIRED');
	}
	if (!values.password) {
		errors.password = I18n.t('PASSWORD_REQUIRED');
	}
	return errors;
};

export default validate;