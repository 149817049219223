import I18n from 'i18next';

const validate = values => {
	const errors = {};
	if (!values.title || values.title.trim() === "") {
		errors.title = I18n.t('TITLE_REQUIRED');
	}
    return errors;
};

export default validate;