import React, {Component} from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { I18n } from 'react-i18next';
import validate from './validate';
import './resetOPPassword.css';
import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';

class ResetOPPassword extends Component {
	state = {
		newPassword: '',
		confirmPassword: '',
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps.passwordChanged) {
			this.refreshInputAndToggle();
		}
	}

	renderField = ({ input, label, type, fieldValue, meta: { touched, error } }) => (
		<div className="form-group">
			<label>{label}</label>
			<div className="input-group">
				<input
					{...input}
					type={type}
					className="form-control transfer-input"
					value={fieldValue}
					onChange={e => this.setState({ [input.name]: e.target.value.trim() }, () => {
						this.props.onChangeField(input.name, this.state[input.name]);
					})}
				/>
				{touched && error && <p className="text-danger">{`* ${error}`}</p>}
			</div>
		</div>
	);
	
	render() {

		const {
			newPassword,
			confirmPassword
        } = this.state;

		return (
			<I18n ns="translations">
				{
					t => (
						<div  className="margin-top-1rem">
							<Field
								name="newPassword"
								type="password"
								component={this.renderField}
								label={t('new_op_password')}
								fieldValue={newPassword}
							/>
							<Field
								name="confirmPassword"
								type="password"
								component={this.renderField}
								label={t('confirm_op_password')}
								fieldValue={confirmPassword}
							/>
						</div>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {

	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{

			},
			dispatch
		)
	};
};

ResetOPPassword.defaultProps = {
	onChangeField: () => {}
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(reduxForm({
	form: 'resetOPPassword',
	validate,
})(ResetOPPassword));
