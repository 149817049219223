import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import EventRegister, { EVENT_CLOSE_EZPAY_TOP_UP } from '../../../../../05-utils/eventRegister';
import './eZpayTopUp.css';

export default class ModalTemplate extends Component {
    refreshInputAndToggle = () => {
        EventRegister.emit(EVENT_CLOSE_EZPAY_TOP_UP);
        this.props.refreshInputAndToggle();
    };

    render() {
        const {
            isOpen,
            titleText,
            children,
        } = this.props;
        return (
            <Modal isOpen={isOpen} className="modal-lg" contentClassName="transfer-to-card-border">
                <div className='transfer-to-card'>
                    <div className='transfer-to-card-title text-black'>
                        <span className='text-size-18'>{titleText}</span>
                        <button type="button" onClick={this.refreshInputAndToggle} className='close' aria-label='Close'>
                            <span aria-hidden="true">{"×"}</span>
                        </button>
                    </div>
                    {children}
                </div>
            </Modal>
        );
    }
}
