import React, {Component} from 'react';
import { reduxForm } from 'redux-form';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { I18n } from 'react-i18next';
import './confirmResetOPPass.css';

class ConfirmResetOPPass extends Component {

    render() {
        return (
            <I18n ns="translations">
                {
                    t => (
                        <div  className="margin-top-1rem">
                            <div className="change-password-attention">
                                <p>{t('attention_')}</p>
                                <p>{t('reset_op_password_attention')}</p>
                            </div>
                        </div>
                    )
                }
            </I18n>
        );
    }
}

const mapStateToProps = store => {
    return {
        userWallets: store.wallet.userWallets
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions : bindActionCreators(
            {
            },
            dispatch
        )
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(reduxForm({
    form: 'confirmResetOPPass',
})(ConfirmResetOPPass));
