import { ACTIONS } from '../01-actions/auth';
import { getUserFromLocalStorage } from '../05-utils/commonUtils';
import { UTIL_ACTIONS } from '../01-actions/utils';

const initialState = {
	status: null,
	token: null,
	getAuthStatusDone: false,
	loggingIn: false,
	isOpenTFAModal: false,
	error: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.LOGIN_PROGRESS:
		case ACTIONS.LOGIN_WITH_CODE_PROGRESS:
			return {
				...state,
				status: action.type,
				loggingIn: true,
				isOpenTFAModal: false,
			};
		case ACTIONS.LOGIN_SUCCESS:
		case ACTIONS.LOGIN_WITH_CODE_SUCCESS:
			localStorage.setItem("USER_ACCESS_TOKEN", action.data.token);
			localStorage.setItem("LOGIN_METHOD", action.data.loginMethod);
			return {
				...state,
				status: action.type,
				token: action.data.token,
				loggingIn: false
			};
		case ACTIONS.LOGIN_FAILED:
		case ACTIONS.LOGIN_WITH_CODE_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				loggingIn: false,
			};
		case ACTIONS.LOGOUT:
			localStorage.removeItem("USER_ACCESS_TOKEN");
			localStorage.removeItem("LOGIN_METHOD");
			return {
				...state,
				status: action.type,
				token: null
			};
		case ACTIONS.GET_AUTH_STATUS:
			return {
				...state,
				status: action.type,
				token: getUserFromLocalStorage(),
				getAuthStatusDone: true,
			};
		case ACTIONS.LOGIN_TFA_REQUIRE:
			return {
				...state,
				status: action.type,
				isOpenTFAModal: true,
				loggingIn: false,
			};
		case UTIL_ACTIONS.RESET_ERROR:
			return {
				...state,
				error: null,
			};
		default:
			return state;
	}
}