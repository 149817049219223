import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'
import auth from './auth';
import wallet from './wallet';
import asset from './asset';
import transaction from './transaction';
import email from './email';
import user from './user';
import addressBook from './addressBook';
import adminWallet from './adminWallet';
import adminUser from './adminUser';
import adminDB from './adminDB';
import adminNews from './adminNews';
import socket from './socket';
import system from './system';
import topUp from './topUp';
import productCode from './productCode';
import repeatOrderCode from './repeatOrderCode';

export default combineReducers({
	form: formReducer,
	auth,
	wallet,
	asset,
	transaction,
	email,
	user,
	addressBook,
	adminWallet,
	adminUser,
	adminDB,
	adminNews,
	socket,
	system,
	topUp,
	productCode,
	repeatOrderCode
});
