import React, { PureComponent } from 'react';

export default class Tab extends PureComponent {
    isActiveClass = () => {
        const { currentTab, tabName } = this.props;
        return currentTab === tabName ? 'active' : '';
    }

    render() {
        const { tabName, text, onTabClick } = this.props;

        return (
            <li className="nav-item history-tab">
                <button
                    className={`btn btn-link nav-link cursor-pointer ${this.isActiveClass()}`}
                    data-toggle="tab"
                    role="tab"
                    onClick={e => onTabClick(e, tabName)}
                >
                    {text}
                </button>
            </li>
        );
    }
}