import React, { PureComponent } from 'react';

export default class ChangeLanguageBar extends PureComponent {
    state = {
        isOpenChooseLanguage: false,
        currentFlag: `/images/flags/${localStorage.getItem('i18nextLng')}.png`,
    }

    handleOutsideClick = e => {
        if ( this.nodeChooseLanguage && !this.nodeChooseLanguage.contains(e.target)) {
            this.setState({ isOpenChooseLanguage: false});
        }
    };

    toggleChooseLanguage = () => this.setState({ isOpenChooseLanguage: !this.state.isOpenChooseLanguage });

    changeLanguage = (lang, i18n) => {
        i18n.changeLanguage(lang);
        this.setState({
            currentFlag: `/images/flags/${lang}.png`,
            isOpenChooseLanguage: false,
        })
    };

    render() {
        const { i18n } = this.props;
        const { isOpenChooseLanguage, currentFlag } = this.state;

        return (
            <div className="col-lg-12 col-12 p-0 fixed-top d-flex flex-row justify-content-end mt-1 mr-10">
                <div className=" d-flex align-items-right">
                    <ul className="navbar-nav flag-lang navbar-nav-right">
                        <li className="nav-item dropdown">
                            <button
                                className={`btn btn-link nav-link dropdown-toggle cursor-pointer ${isOpenChooseLanguage ? 'show' : ''}`}
                                id="UserDropdown"
                                data-toggle="dropdown"
                                aria-expanded={isOpenChooseLanguage}
                                onClick={this.toggleChooseLanguage}
                            >
                                <img className="flag-language" src={currentFlag} alt=""/>
                            </button>
                            <div
                                className={`dropdown-menu dropdown-menu-right navbar-dropdown padding-unset text-center ${isOpenChooseLanguage ? 'show' : ''}`}
                                aria-labelledby="UserDropdown"
                                ref={node => this.nodeChooseLanguage = node}
                            >
                                <button className="btn btn-link dropdown-item"
                                    onClick={() => this.changeLanguage('en', i18n)}>
                                    <img className="flag-language" src="/images/flags/en.png" alt=""/>
                                </button>
                                {/*<button className="btn btn-link dropdown-item"*/}
                                {/*    onClick={() => this.changeLanguage('ja', i18n)}>*/}
                                {/*    <img className="flag-language" src="/images/flags/ja.png" alt=""/>*/}
                                {/*</button>*/}
                                <button className="btn btn-link dropdown-item"
                                    onClick={() => this.changeLanguage('ch', i18n)}>
                                    <img className="flag-language" src="/images/flags/ch.png" alt=""/>
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}