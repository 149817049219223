import { callApi } from "../05-utils/commonUtils";
import { updateStatus } from "./utils";

const ACTIONS = {
	GET_LIST_USER_INFO_PROGRESS: "GET_LIST_USER_INFO_PROGRESS",
	GET_LIST_USER_INFO_SUCCESS: "GET_LIST_USER_INFO_SUCCESS",
	GET_LIST_USER_INFO_FAILED: "GET_LIST_USER_INFO_FAILED",
	GET_USER_PROFILE_PROGRESS: "GET_USER_PROFILE_PROGRESS",
	GET_USER_PROFILE_SUCCESS: "GET_USER_PROFILE_SUCCESS",
	GET_USER_PROFILE_FAILED: "GET_USER_PROFILE_FAILED",
	CHANGE_STATUS_USER_PROGRESS: "CHANGE_STATUS_USER_PROGRESS",
	CHANGE_STATUS_USER_SUCCESS: "CHANGE_STATUS_USER_SUCCESS",
	CHANGE_STATUS_USER_FAILED: "CHANGE_STATUS_USER_FAILED",
	ADMIN_RESET_USER_PROGRESS: "ADMIN_RESET_USER_PROGRESS",
	ADMIN_RESET_USER_SUCCESS: "ADMIN_RESET_USER_SUCCESS",
	ADMIN_RESET_USER_FAILED: "ADMIN_RESET_USER_FAILED",
	RESET_ADMIN_RESET_USER_STATUS: "RESET_USER_RESET_STATUS",
    EDIT_USER_PROGRESS: "EDIT_USER_PROGRESS",
    EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
    EDIT_USER_FAILED: "EDIT_USER_FAILED",
    RESET_TFA_USER_PROGRESS: "RESET_TFA_USER_PROGRESS",
    RESET_TFA_USER_SUCCESS: "RESET_TFA_USER_SUCCESS",
    RESET_TFA_USER_FAILED: "RESET_TFA_USER_FAILED",
    CHANGE_TFA_STATUS: "CHANGE_TFA_STATUS",
    EDIT_EMAIL_PROGRESS: "EDIT_EMAIL_PROGRESS",
	EDIT_EMAIL_SUCCESS: "EDIT_EMAIL_SUCCESS",
	EDIT_EMAIL_FAILED: "EDIT_EMAIL_FAILED",
	RESET_EDIT_EMAIL_STATUS: "RESET_EDIT_EMAIL_STATUS",
	RESET_ERROR_ADMIN_USER_API_MESSAGE: "RESET_ERROR_ADMIN_USER_API_MESSAGE",
};

const getListUserInfo = payload => {
	const data = {
		name: "get_list_users_info",
		params: {
			searchParam: payload.searchParam,
			numPage: payload.numPage,
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.GET_LIST_USER_INFO_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success) {
					const users = response.result.map((user, index) => {
						return {...user, index: index + 1};
					});
					dispatch(updateStatus(ACTIONS.GET_LIST_USER_INFO_SUCCESS, { users, totalUser: response.totalUser }));
				}
				else {
					dispatch(updateStatus(ACTIONS.GET_LIST_USER_INFO_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("getListUserInfo", err);
				dispatch(updateStatus(ACTIONS.GET_LIST_USER_INFO_FAILED, {error: err}));
			});
	};
};

const getUserProfile = payload => {
	const data = {
		name: "get_user_info_by_arm_id",
		params: {
			armId: payload.armId,
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.GET_USER_PROFILE_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success) {
					dispatch(updateStatus(ACTIONS.GET_USER_PROFILE_SUCCESS, { userProfile: response.result }));
				}
				else {
					dispatch(updateStatus(ACTIONS.GET_USER_PROFILE_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("getUserProfile", err);
				dispatch(updateStatus(ACTIONS.GET_USER_PROFILE_FAILED, {error: err}));
			});
	};
};

const changeStatusUser = payload => {
	const data = {
		name: "admin_change_status_user",
		params: {
			_id: payload.userID,
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.CHANGE_STATUS_USER_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success) {
					dispatch(updateStatus(ACTIONS.CHANGE_STATUS_USER_SUCCESS, { accountStatus: response.result.accountStatus }));
				}
				else {
					dispatch(updateStatus(ACTIONS.CHANGE_STATUS_USER_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("changeStatusUser", err);
				dispatch(updateStatus(ACTIONS.CHANGE_STATUS_USER_FAILED, {error: err}));
			});
	};
};

const editUserData = payload => {
    const data = {
        name: "edit_user_info",
        params: payload
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.EDIT_USER_PROGRESS));
        callApi('rpc', data)
            .then(response => {
                if (response.success) {
                    dispatch(updateStatus(ACTIONS.EDIT_USER_SUCCESS, { userProfile: response.result, userInfo: payload }));
                }
                else {
                    dispatch(updateStatus(ACTIONS.EDIT_USER_FAILED, { error: response.error }));
                }
            })
            .catch(err => {
                console.log("editUserData", err);
                dispatch(updateStatus(ACTIONS.EDIT_USER_FAILED, {error: err}));
            });
    };
};

const editEmailData = payload => {
    const data = {
        name: "edit_user_email",
        params: payload
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.EDIT_EMAIL_PROGRESS));
        callApi('rpc', data)
            .then(response => {
                if (response.success) {
                    dispatch(updateStatus(ACTIONS.EDIT_EMAIL_SUCCESS, { email: payload.email }));
                }
                else {
                    dispatch(updateStatus(ACTIONS.EDIT_EMAIL_FAILED, { error: response.error }));
                }
            })
            .catch(err => {
                console.log("editUserData", err);
                dispatch(updateStatus(ACTIONS.EDIT_EMAIL_FAILED, {error: err}));
            });
    };
};

const resetTfa = payload => {
    const data = {
        name: "admin_request_reset_ga",
        params: {
            armId: payload.armId,
        }
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.RESET_TFA_USER_PROGRESS));
        callApi('rpc', data)
            .then(response => {
                if (response.success) {
                    dispatch(updateStatus(ACTIONS.RESET_TFA_USER_SUCCESS, { resetTfaStatus: response.result, status: true }));
                }
                else {
                    dispatch(updateStatus(ACTIONS.RESET_TFA_USER_FAILED, { error: response.error }));
                }
            })
            .catch(err => {
                console.log("resetTfa", err);
                dispatch(updateStatus(ACTIONS.RESET_TFA_USER_FAILED, {error: err}));
            });
    };
};

const adminResetUser = payload => {
	const data = {
		name: "resend_email_user_info",
		params: {
			listUser: payload.listUser,
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.ADMIN_RESET_USER_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success) {
					dispatch(updateStatus(ACTIONS.ADMIN_RESET_USER_SUCCESS, { accountStatus: response.result }));
				}
				else {
					dispatch(updateStatus(ACTIONS.ADMIN_RESET_USER_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("adminResetUser", err);
				dispatch(updateStatus(ACTIONS.ADMIN_RESET_USER_FAILED, {error: err}));
			});
	};
};

const resetAdminResetUserStatus = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_ADMIN_RESET_USER_STATUS, {}));

const changeTfaStatus = () => dispatch => dispatch(updateStatus(ACTIONS.CHANGE_TFA_STATUS, {status: false}));

const changeEditEmailStatus = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_EDIT_EMAIL_STATUS, {}));

const resetErrorAdminUserApiMessage = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_ERROR_ADMIN_USER_API_MESSAGE, {}));

export {
	ACTIONS,
	getListUserInfo,
    resetTfa,
	getUserProfile,
	changeStatusUser,
	adminResetUser,
	resetAdminResetUserStatus,
    editUserData,
    changeTfaStatus,
    editEmailData,
	changeEditEmailStatus,
	resetErrorAdminUserApiMessage,
};