import React, { Component } from 'react';
import { I18n } from 'react-i18next'
import './eZpayTopUp.css';
import EZPayRow from "../../../../../03-components/EZPayRow";
import Loading from "../../../../../03-components/Loading";
import ModalTemplate from './modalTemplate';

export default class EZPayFetchingAmount extends Component {
    refreshInputAndToggle = () => {
        this.props.toggle();
    };

    render() {
        const {
            isOpen,
            sgpBalance,
            isFetchingSGPBalance,
            onPressBack
        } = this.props;
        return (
            <I18n ns="translations">
                {
                    t => (
                        <ModalTemplate
                            isOpen={isOpen}
                            titleText={t('transfer_to_card')}
                            refreshInputAndToggle={this.refreshInputAndToggle}
                        >
                            <div className='transfer-to-card-body'>
                                {isFetchingSGPBalance ? (<Loading parent1Class="text-center padding-15px"/>)
                                    : sgpBalance ?
                                        <div className='transfer-to-card-image-bg-step1'>
                                            <div className='transfer-to-card-amount-wrapper'>
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <EZPayRow label={t('available_agt_amount')}
                                                                    value={sgpBalance.balanceAGT && sgpBalance.balanceAGT.avaiable_balance}
                                                                    isUnderline={true}
                                                                    isLoading={true}
                                                                    textClass='text-size-18'/>
                                                        <EZPayRow label={t('available_tct_amount')}
                                                                    value={sgpBalance.balanceTCT && sgpBalance.balanceTCT.avaiable_balance}
                                                                    isUnderline={true}
                                                                    isLoading={true}
                                                                    textClass='text-size-18'/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='text-center margin-top-1rem margin-bottom-half-rem'>
                                                <button
                                                    className="btn btn-primary btn-fw select-application-id-button"
                                                    onClick={onPressBack}
                                                >
                                                    <span>{t('back')}</span>
                                                </button>
                                                <button className="btn btn-primary btn-fw select-application-id-button"
                                                        onClick={this.props.onSubmit}>
                                                    <span>{t('transfer_to_card')}</span>
                                                </button>
                                            </div>
                                        </div>
                                        : <div className='attention-allow-topup'>
                                            <span
                                                className='text-center text-size-18'>{t('you_are_not_enough_condition_for_top_up')}</span>
                                            <div className='text-center margin-top-1rem margin-bottom-half-rem'>
                                                <button
                                                    className="btn btn-primary btn-fw select-application-id-button"
                                                    onClick={onPressBack}
                                                >
                                                    <span>{t('back')}</span>
                                                </button>
                                                <button className="btn btn-primary btn-fw select-application-id-button"
                                                        onClick={this.props.onClose}>
                                                    <span>{t('close')}</span>
                                                </button>
                                            </div>
                                        </div>
                                }
                            </div>
                        </ModalTemplate>
                    )
                }
            </I18n>
        );
    }
}
