import { callApi } from "../05-utils/commonUtils";
import { updateStatus } from "./utils";

const ACTIONS = {
	GET_USER_INFO_PROGRESS: "GET_USER_INFO_PROGRESS",
	GET_USER_INFO_SUCCESS: "GET_USER_INFO_SUCCESS",
	GET_USER_INFO_FAILED: "GET_USER_INFO_FAILED",
	FORGOT_PASSWORD_PROGRESS: "FORGOT_PASSWORD_PROGRESS",
	FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
	FORGOT_PASSWORD_FAILED: "FORGOT_PASSWORD_FAILED",
	CHANGE_PASSWORD_PROGRESS: "CHANGE_PASSWORD_PROGRESS",
	CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
	CHANGE_PASSWORD_FAILED: "CHANGE_PASSWORD_FAILED",
	RESET_CHANGE_PASSWORD_STATUS: "RESET_CHANGE_PASSWORD_STATUS",
	CHANGE_TFA_LOGIN_REQUIRE_PROGRESS: "CHANGE_TFA_LOGIN_REQUIRE_PROGRESS",
	CHANGE_TFA_LOGIN_REQUIRE_SUCCESS: "CHANGE_TFA_LOGIN_REQUIRE_SUCCESS",
	CHANGE_TFA_LOGIN_REQUIRE_FAILED: "CHANGE_TFA_LOGIN_REQUIRE_FAILED",
	GET_LOGIN_HISTORY_PROGRESS: "GET_LOGIN_HISTORY_PROGRESS",
	GET_LOGIN_HISTORY_SUCCESS: "GET_LOGIN_HISTORY_SUCCESS",
	GET_LOGIN_HISTORY_FAILED: "GET_LOGIN_HISTORY_FAILED",
	RESET_GA_PROGRESS: "RESET_GA_PROGRESS",
	RESET_GA_SUCCESS: "RESET_GA_SUCCESS",
	RESET_GA_FAILED: "RESET_GA_FAILED",
	SET_GA_PROGRESS: "SET_GA_PROGRESS",
	SET_GA_SUCCESS: "SET_GA_SUCCESS",
	SET_GA_FAILED: "SET_GA_FAILED",
	VERIFY_GA_PROGRESS: "VERIFY_GA_PROGRESS",
	VERIFY_GA_SUCCESS: "VERIFY_GA_SUCCESS",
	VERIFY_GA_FAILED: "VERIFY_GA_FAILED",
	PAGING_LOGIN_HISTORY: "PAGING_LOGIN_HISTORY",
	RESET_CHANGE_TFA_LOGIN_REQUIRE_STATUS: "RESET_CHANGE_TFA_LOGIN_REQUIRE_STATUS",
	RESET_GA_RESET_STATUS: "RESET_GA_RESET_STATUS",
	RESET_GA_VERIFY_STATUS: "RESET_GA_VERIFY_STATUS",
	RESET_PASSWORD_PROGRESS:"RESET_PASSWORD_PROGRESS",
	RESET_PASSWORD_SUCCESS:"RESET_PASSWORD_SUCCESS",
	RESET_PASSWORD_FAILED:"RESET_PASSWORD_FAILED",
    CREATE_USER_PROGRESS: "CREATE_USER_PROGRESS",
    CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
	CREATE_USER_FAILED: "CREATE_USER_FAILED",
	RESET_CREATE_USER_STATUS: "RESET_CREATE_USER_STATUS",
	RESET_OP_PASSWORD_PROGRESS:"RESET_OP_PASSWORD_PROGRESS",
	RESET_OP_PASSWORD_SUCCESS:"RESET_OP_PASSWORD_SUCCESS",
	RESET_OP_PASSWORD_FAILED:"RESET_OP_PASSWORD_FAILED",
	CHANGE_OP_PASSWORD_PROGRESS: "CHANGE_OP_PASSWORD_PROGRESS",
	CHANGE_OP_PASSWORD_SUCCESS: "CHANGE_OP_PASSWORD_SUCCESS",
	CHANGE_OP_PASSWORD_FAILED: "CHANGE_OP_PASSWORD_FAILED",
	CHANGE_OP_PASSWORD_STATUS: "CHANGE_OP_PASSWORD_STATUS",
	SETTING_OP_PASSWORD_PROGRESS: "SETTING_OP_PASSWORD_PROGRESS",
	SETTING_OP_PASSWORD_SUCCESS: "SETTING_OP_PASSWORD_SUCCESS",
	SETTING_OP_PASSWORD_FAILED: "SETTING_OP_PASSWORD_FAILED",
	SETTING_OP_PASSWORD_STATUS: "SETTING_OP_PASSWORD_STATUS",
	RESET_OP_PASSWORD_STATUS: "RESET_OP_PASSWORD_STATUS"
};


const forgotPassword = payload => {
	const data = {
		name: "forgot_password",
		params: {
			email: payload.email,
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.FORGOT_PASSWORD_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.FORGOT_PASSWORD_SUCCESS, {}));
				else {
					dispatch(updateStatus(ACTIONS.FORGOT_PASSWORD_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("forgotPassword", err);
				dispatch(updateStatus(ACTIONS.FORGOT_PASSWORD_FAILED, {error: err}));
			});
	};
};

const createUser = payload => {
    const data = {
        name: "sign_up_for_robin_free_member",
        params: {
            email: payload.email,
        }
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.CREATE_USER_PROGRESS));
        callApi('rpc', data)
            .then(response => {
                if (response.success)
                    dispatch(updateStatus(ACTIONS.CREATE_USER_SUCCESS, response.result));
                else {
                    dispatch(updateStatus(ACTIONS.CREATE_USER_FAILED, { error: response.error }));
                }
            })
            .catch(err => {
                console.log("createUser", err);
                dispatch(updateStatus(ACTIONS.CREATE_USER_FAILED, {error: err}));
            });
    };
};

const resetPassword = payload => {
	const data = {
		name: "reset_password",
		params: payload
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.RESET_PASSWORD_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.RESET_PASSWORD_SUCCESS, {}));
				else {
					dispatch(updateStatus(ACTIONS.RESET_PASSWORD_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("resetPassword", err);
				dispatch(updateStatus(ACTIONS.RESET_PASSWORD_FAILED, {error: err}));
			});
	};
};

const changePassword = payload => {
	const data = {
		name: "change_password",
		params: {
			oldPassword: payload.oldPassword.trim(),
			newPassword: payload.newPassword.trim(),
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.CHANGE_PASSWORD_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.CHANGE_PASSWORD_SUCCESS, {}));
				else {
					dispatch(updateStatus(ACTIONS.CHANGE_PASSWORD_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("changePassword", err);
				dispatch(updateStatus(ACTIONS.CHANGE_PASSWORD_FAILED, {error: err}));
			});
	};
};

const getUserInfo = (token) => {
	const data = {
		name: "get_user_info",
		params: {}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.GET_USER_INFO_PROGRESS));
		callApi('rpc', data, token)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.GET_USER_INFO_SUCCESS, { userInfo: response.result }));
				else {
					dispatch(updateStatus(ACTIONS.GET_USER_INFO_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("getUserInfo", err);
				dispatch(updateStatus(ACTIONS.GET_USER_INFO_FAILED, {error: err}));
			});
	};
};

const getLoginHistory = () => {
	const data = {
		name: "get_login_history",
		params: {}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.GET_LOGIN_HISTORY_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.GET_LOGIN_HISTORY_SUCCESS, { loginHistory: response.result }));
				else {
					dispatch(updateStatus(ACTIONS.GET_LOGIN_HISTORY_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("getLoginHistory", err);
				dispatch(updateStatus(ACTIONS.GET_LOGIN_HISTORY_FAILED, {error: err}));
			});
	};
};

const setGa = payload => {
	const data = {
		name: "set_ga_secret",
		params: {
			password: payload.password,
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.SET_GA_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.SET_GA_SUCCESS, { qrData: response.result.qrData, gaSecret: response.result.gaSecret }));
				else {
					dispatch(updateStatus(ACTIONS.SET_GA_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("setGa", err);
				dispatch(updateStatus(ACTIONS.SET_GA_FAILED, {error: err}));
			});
	};
};

const resetGa = payload => {
	const data = {
		name: "request_reset_ga",
		params: {
			resetCode: payload.resetCode,
			password: payload.password,
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.RESET_GA_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.RESET_GA_SUCCESS, {}));
				else {
					dispatch(updateStatus(ACTIONS.RESET_GA_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("resetGa", err);
				dispatch(updateStatus(ACTIONS.RESET_GA_FAILED, {error: err}));
			});
	};
};

const verifyGa = payload => {
	const data = {
		name: "verify_ga",
		params: {
			code: payload.code,
			password: payload.password,
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.VERIFY_GA_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.VERIFY_GA_SUCCESS, {}));
				else {
					dispatch(updateStatus(ACTIONS.VERIFY_GA_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("verifyGa", err);
				dispatch(updateStatus(ACTIONS.VERIFY_GA_FAILED, {error: err}));
			});
	};
};

const pagingLoginHistory = (loginHistory, page = 0, limit = 10) => {
	const displayLoginHistory = loginHistory && loginHistory.slice(page * limit, (page + 1) * limit);
	return dispatch => dispatch(updateStatus(ACTIONS.PAGING_LOGIN_HISTORY, { displayLoginHistory, limit }));
};

const changeTFALoginRequire = payload => {
	const data = {
		name: "change_login_tfa_require",
		params: {
			loginTFARequire: payload.loginTFARequire,
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.CHANGE_TFA_LOGIN_REQUIRE_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.CHANGE_TFA_LOGIN_REQUIRE_SUCCESS, {}));
				else {
					dispatch(updateStatus(ACTIONS.CHANGE_TFA_LOGIN_REQUIRE_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("changeTFALoginRequire", err);
				dispatch(updateStatus(ACTIONS.CHANGE_TFA_LOGIN_REQUIRE_FAILED, {error: err}));
			});
	};
};

const resetChangeTFALoginRequireStatus = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_CHANGE_TFA_LOGIN_REQUIRE_STATUS, {}));

const resetChangePasswordStatus = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_CHANGE_PASSWORD_STATUS, {}));

const resetGaResetStatus = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_GA_RESET_STATUS, {}));

const resetGaVerifyStatus = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_GA_VERIFY_STATUS, {}));

const resetCreateUserStatus = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_CREATE_USER_STATUS, {}));

const resetOPPassword = payload => {
	const data = {
		name: "reset_op_password",
		params: {
			newPassword: payload.newPassword.trim(),
			wallets: payload.wallets
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.RESET_OP_PASSWORD_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.RESET_OP_PASSWORD_SUCCESS, {}));
				else {
					dispatch(updateStatus(ACTIONS.RESET_OP_PASSWORD_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("resetOPPassword", err);
				dispatch(updateStatus(ACTIONS.RESET_OP_PASSWORD_FAILED, {error: err}));
			});
	};
};

const changeOPPassword = payload => {
	const data = {
		name: "change_op_password",
		params: {
			oldPassword: payload.oldPassword.trim(),
			newPassword: payload.newPassword.trim(),
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.CHANGE_OP_PASSWORD_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.CHANGE_OP_PASSWORD_SUCCESS, {}));
				else {
					dispatch(updateStatus(ACTIONS.CHANGE_OP_PASSWORD_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("changeOPPassword", err);
				dispatch(updateStatus(ACTIONS.CHANGE_OP_PASSWORD_FAILED, {error: err}));
			});
	};
};

const changeOPPasswordStatus = () => dispatch => dispatch(updateStatus(ACTIONS.CHANGE_OP_PASSWORD_STATUS, {}));

const resetOPPasswordStatus = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_OP_PASSWORD_STATUS, {}));

const settingOPPassword = payload => {
	const data = {
		name: "setting_op_password",
		params: {
			opPassword: payload.opPassword.trim()
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.SETTING_OP_PASSWORD_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.SETTING_OP_PASSWORD_SUCCESS, {}));
				else {
					dispatch(updateStatus(ACTIONS.SETTING_OP_PASSWORD_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("changeOPPassword", err);
				dispatch(updateStatus(ACTIONS.SETTING_OP_PASSWORD_FAILED, {error: err}));
			});
	};
};

const settingOPPasswordStatus = () => dispatch => dispatch(updateStatus(ACTIONS.SETTING_OP_PASSWORD_STATUS, {}));

export {
	ACTIONS,
    forgotPassword,
	changePassword,
	getUserInfo,
	changeTFALoginRequire,
	resetChangeTFALoginRequireStatus,
	resetChangePasswordStatus,
	getLoginHistory,
	pagingLoginHistory,
	resetGa,
	resetGaResetStatus,
	setGa,
	verifyGa,
	resetGaVerifyStatus,
	resetPassword,
	createUser,
	resetCreateUserStatus,
	resetOPPassword,
	changeOPPassword,
	changeOPPasswordStatus,
	resetOPPasswordStatus,
	settingOPPassword,
	settingOPPasswordStatus
};