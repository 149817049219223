import { ACTIONS } from '../01-actions/adminDB';
import { AUTH_ACTIONS } from '../01-actions';
import { UTIL_ACTIONS } from '../01-actions/utils';

const initialState = {
	status: null,
	backUpFiles: null,
	displayBackUpFiles: null,
	limit: null,
	dbBackingUp: false,
	dbBackedUp: false,
	backUpFileDeleting: false,
	backUpFileDeleted: false,
	databaseRestoring: false,
	databaseRestored: false,
	error: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.GET_ALL_BACK_UP_FILE_PROGRESS:
			return {
				...state,
				status: action.type,
			};
		case ACTIONS.GET_ALL_BACK_UP_FILE_SUCCESS:
			return {
				...state,
				status: action.type,
				backUpFiles: action.data.backUpFiles,
			};
		case ACTIONS.GET_ALL_BACK_UP_FILE_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
			};
		case ACTIONS.PAGING_BACK_UP_FILE:
			return {
				...state,
				status: action.type,
				displayBackUpFiles: action.data.displayBackUpFiles,
				limit: action.data.limit,
			};
		case ACTIONS.BACKUP_MANUALLY_PROGRESS:
			return {
				...state,
				status: action.type,
				dbBackingUp: true,
			};
		case ACTIONS.BACKUP_MANUALLY_SUCCESS:
			return {
				...state,
				status: action.type,
				dbBackingUp: false,
				dbBackedUp: true,
			};
		case ACTIONS.BACKUP_MANUALLY_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				dbBackingUp: false,
			};
		case ACTIONS.RESET_DB_BACK_UP_STATUS:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				dbBackedUp: false,
			};
		case ACTIONS.DELETE_BACK_UP_FILE_PROGRESS:
			return {
				...state,
				status: action.type,
				backUpFileDeleting: true,
			};
		case ACTIONS.DELETE_BACK_UP_FILE_SUCCESS:
			return {
				...state,
				status: action.type,
				backUpFileDeleting: false,
				backUpFileDeleted: true,
			};
		case ACTIONS.DELETE_BACK_UP_FILE_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				backUpFileDeleting: false,
			};
		case ACTIONS.RESET_DELETE_BACK_UP_FILE_STATUS:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				backUpFileDeleted: false,
			};
		case ACTIONS.RESTORE_DATABASE_PROGRESS:
			return {
				...state,
				status: action.type,
				databaseRestoring: true,
			};
		case ACTIONS.RESTORE_DATABASE_SUCCESS:
			return {
				...state,
				status: action.type,
				databaseRestoring: false,
				databaseRestored: true,
			};
		case ACTIONS.RESTORE_DATABASE_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				databaseRestoring: false,
			};
		case ACTIONS.RESET_RESTORE_DATABASE_STATUS:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				databaseRestored: false,
			};
		case AUTH_ACTIONS.LOGOUT:
			return initialState;
		case UTIL_ACTIONS.RESET_ERROR:
			return {
				...state,
				error: null,
			};
		default:
			return state;
	}
}