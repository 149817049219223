import React, {Component} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { I18n } from 'react-i18next';

import validate from './validate';
import SubmitButton from '../../03-components/ModalSubmitButton/index';
import { constants } from '../../05-utils/commonData';

class OPPassModal extends Component {
	state = {
		opPass: '',
	};

	renderField = ({ input, label, type, fieldValue, meta: { touched, error } }) => (
		<div className="form-group">
			<label>{label}</label>
			<div className="input-group">
				<input
					{...input}
					type={type}
					className="form-control transfer-input"
					value={fieldValue}
					onChange={e => this.setState({ [input.name]: e.target.value })}
				/>
				{touched && error && <p className="text-danger">{`* ${error}`}</p>}
			</div>
		</div>
	);

	onSubmitOPPass = () => {
		this.props.onSubmit(this.state.opPass);
		this.refreshInputAndToggle();
	}

	refreshInputAndToggle = () => {
		this.setState({ opPass: '' });
		this.props.toggle();
	};

	render() {
		const {
			isOpen,
			invalid,
		} = this.props;
		const { opPass } = this.state;

		return (
			<I18n ns="translations">
				{
					t => (
						<Modal isOpen={isOpen} className="modal-lg">
							<ModalHeader toggle={this.refreshInputAndToggle}>{t('op_pass_verification')}</ModalHeader>
							<ModalBody>
								<form className="margin-top-1rem">
									<Field
										name="opPass"
										type="password"
										component={this.renderField}
										fieldValue={opPass}
										label={t('op_pass')}
									/>
								</form>
							</ModalBody>
							<ModalFooter>
								<SubmitButton
									className="btn btn-primary"
									onClick={this.onSubmitOPPass}
									disabled={!opPass || opPass.length < constants.MIN_USER_PASSWORD_LENGTH}
									text={t('submit')}
								/>
							</ModalFooter>
						</Modal>

					)
				}
			</I18n>
		);
	}
}

export default reduxForm({
	form: 'opPassModal',
	validate,
})(OPPassModal);
