import React, { Component } from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import { logout } from '../../01-actions/auth';
import { getUserInfo, settingOPPasswordStatus } from '../../01-actions/user';
import { getAuthStatus } from '../../01-actions/auth';
import { getTimestampBackground, getProductCodeStatus, getRepeatOrderCodeStatus } from '../../01-actions/system';
import { checkDisableCodeForUser } from '../../05-utils/commonUtils';
import { connectSocket } from '../../01-actions/socket';
import MyWallet from './MyWallet/myWallet';
import Setting from './Setting/settings';
// import Contact from './Contact/contact';
import AddressBook from './AddressBook/addressBook';
import History from './History/history';
import ProductKeyStore from './ProductKeyStore/productKeyStore';
import './index.css';
import { isSuperAdmin , getUserFromLocalStorage} from "../../05-utils/commonUtils";
import {bindActionCreators} from "redux";
import { I18n } from 'react-i18next';
import Header from '../../03-components/Header';
import Footer from '../../03-components/Footer';
import { isMaintenanceMode } from '../../07-selectors';
import MenuItem from '../../03-components/MenuItem';
import SettingOPPassword from "./SettingOPPasswordModal/settingOPPasswordModal";
import {pushNotify} from "../../03-components/Notification";
import i18n from "i18next";
import EventRegister, {EVENT_CREATED_PRODUCT_CODE_BY_USDT} from "../../05-utils/eventRegister";
import NotifyProductCodeCreated from "./ProductKeyStore/NotifyModal/notifyProductCodeCreatedModal";
import { getEnv } from '../../env';
import { constants } from '../../05-utils/commonData';
import { TAB_KEY } from './ProductKeyStore/productKeyStore';

class WalletPage extends Component {
	state = {
		isOpenMenu: false,
		isOpenSettingOPPass: false,
		isOpenNotifyProductCodeCreated: false,
		newProductCodeCreated: {},
		type: null
	};

	componentWillMount(){
		let token = getUserFromLocalStorage();
		if (!token) {
			// reset store
			this.props.actions.logout();
			this.props.history.push("/login");
		}
	}

	componentDidMount() {
		const { actions, userInfo } = this.props;
		const { getUserInfo, getAuthStatus, getProductCodeStatus, getRepeatOrderCodeStatus } = actions;
		getAuthStatus();
		if (!userInfo) {
			getUserInfo();
		}
		getProductCodeStatus();
		getRepeatOrderCodeStatus();
		this.listenerProductCodeCreated = EventRegister.on(EVENT_CREATED_PRODUCT_CODE_BY_USDT, (data) => {
			this.setState({
				isOpenNotifyProductCodeCreated: true,
				newProductCodeCreated: data.code,
				type: data.type
			});
		});
	}

	componentWillUnmount() {
		EventRegister.off(this.listenerProductCodeCreated);
	}

	componentWillReceiveProps(nextProps) {
		const { userInfo, opPasswordSettingDone, token } = nextProps;
		const { getUserInfo } = nextProps.actions;
		if(!this.props.userInfo && userInfo){
			this.props.actions.connectSocket(userInfo._id);
		}
		if(!token){
			this.props.history.push("/login");
		}
		if (!opPasswordSettingDone && this.props.userInfo !== userInfo && userInfo && !userInfo.isSetOPPass) {
			this.setState({
				isOpenSettingOPPass: true
			})
		}
		if (opPasswordSettingDone) {
			pushNotify('success', i18n.t('SETTING_OP_PASSWORD_SUCCESSFULLY'));
			getUserInfo();
		}
	}

	isNavActive = key => {
		const { href } = window.location;
		if (key === '/wallet')
			return href.endsWith(key) ? 'active' : '';
		return href.includes(key) ? 'active' : '';
	};

	logout = () => {
		const { logout, getTimestampBackground } = this.props.actions;
		logout();
		getTimestampBackground();
	};

	toggleMenu = () => {
		this.setState({ isOpenMenu: !this.state.isOpenMenu });
	};
	toggleSettingOPPassword = () => {
		this.setState({ isOpenSettingOPPass: !this.state.isOpenSettingOPPass })
	};
	toggleNotifyProductCodeCreated = () => {
		this.setState({ isOpenNotifyProductCodeCreated: !this.state.isOpenNotifyProductCodeCreated })
	};
	closeMenu = () => {
		this.setState({ isOpenMenu: false });
	};
	onViewProductCode = () => {
		const urlSuffix = this.state.type === constants.TYPE_CODE.REPEAT_ORDER_CODE ? constants.TYPE_CODE.REPEAT_ORDER_CODE : '';
		this.props.history.push(`/wallet/product-key-store#${urlSuffix}`);
		this.toggleNotifyProductCodeCreated();
	}
	render() {
		const {
			isOpenMenu,
			isOpenNotifyProductCodeCreated,
			isOpenSettingOPPass,
			newProductCodeCreated,
			type
		} = this.state;
		const {
			userInfo,
			socket,
			socketID,
			notifies,
			actions,
			productCodeEnabled,
			repeatOrderCodeEnabled
		} = this.props;
		const loginMethod = localStorage.getItem('LOGIN_METHOD');

		return (
			<I18n ns="translations">
				{
					(t, {i18n}) => (
						<div className="container-scroller">
							<Header
								userInfo={userInfo}
								logout={this.logout}
								toggleMenu={this.toggleMenu}
								socket={socket}
								notifies={notifies}
								actions={actions}
								socketID={socketID}
								loginMethod={loginMethod}
							/>
							<div className="container-fluid page-body-wrapper">
								<nav className={`sidebar sidebar-offcanvas ${isOpenMenu ? 'active' : null}`} id="sidebar">
									{
										userInfo && isSuperAdmin(userInfo) ?
											(<div className="text-center admin-profile-wrapper">
												{/*<div className="margin-bottom-15px">*/}
													{/*<i className="fa fa-user-tie text-white"/>*/}
												{/*</div>*/}
												<NavLink to="/admin" className="btn btn-primary" role="button">
													<i className="fa fa-cogs"/>
													<span>{t('admin_mode')}</span>
												</NavLink>
											</div>)
											:
											null
									}
									<ul className="nav">
										<MenuItem
											activeClass={this.isNavActive('/wallet')}
											linkTo='/wallet'
											srcImage='/images/icon/icons8-wallet-filled-50.png'
											iconClass='icon-style icon-color'
											text={t('my_wallet')}
											closeMenu={this.closeMenu}
										/>
										<MenuItem
											activeClass={this.isNavActive('/wallet/setting')}
											linkTo='/wallet/setting'
											srcImage='/images/icon/icons8-slider-60.png'
											iconClass='icon-style icon-color'
											text={t('setting')}
											closeMenu={this.closeMenu}
										/>
										<MenuItem
											activeClass={this.isNavActive('/wallet/address-book')}
											linkTo='/wallet/address-book'
											srcImage='/images/icon/icons8-addressbook-128.png'
											iconClass='icon-style icon-color'
											text={t('address_book')}
											closeMenu={this.closeMenu}
										/>
										<MenuItem
											activeClass={this.isNavActive('/wallet/history')}
											linkTo='/wallet/history'
											srcImage='/images/icon/icons8-time-machine-64.png'
											iconClass='icon-style icon-color'
											text={t('history')}
											closeMenu={this.closeMenu}
										/>
										{ userInfo && checkDisableCodeForUser({userInfo, productCodeEnabled, repeatOrderCodeEnabled}) &&
											<MenuItem
												activeClass={this.isNavActive('/wallet/product-key-store')}
												linkTo='/wallet/product-key-store'
												srcImage='/images/icon/key_product.svg'
												iconClass='icon-style'
												text={t('product_key_store')}
												closeMenu={this.closeMenu}
											/>
										}
										{/*<MenuItem*/}
										{/*	activeClass={this.isNavActive('/wallet/contact')}*/}
										{/*	linkTo='/wallet/contact'*/}
										{/*	iconClass='fa-file-signature icon-color'*/}
										{/*	text={t('contact')}*/}
										{/*	closeMenu={this.closeMenu}*/}
										{/*/>*/}
										<li className="nav-item nav-logout">
											<button className="btn btn-link nav-link" onClick={this.logout}>
												<i className="menu-icon fa fa-sign-out-alt icon-color" />
												<span className="menu-title">{t('logout')}</span>
											</button>
										</li>
									</ul>
								</nav>
								<div className="main-panel">
									<div className="content-wrapper">
										{/*{*/}
										{/*	userInfo && !userInfo.verifyGA ?*/}
										{/*		(<div className="alert alert-danger">*/}
										{/*			<strong>{t('tfa_disabled_intro')}</strong>*/}
										{/*			<NavLink to="/wallet/setting#security">{` ${t('go_to_settings')}`}</NavLink>*/}
										{/*		</div>)*/}
										{/*		:*/}
										{/*		null*/}
										{/*}*/}
										<Switch>
											<Route exact path="/wallet" component={MyWallet}/>
											<Route path="/wallet/setting" component={Setting}/>
											{/*<Route path="/wallet/contact" component={Contact}/>*/}
											<Route path="/wallet/history" component={History}/>
											<Route path="/wallet/address-book" component={AddressBook}/>
											{userInfo && checkDisableCodeForUser({userInfo, productCodeEnabled, repeatOrderCodeEnabled}) && <Route path="/wallet/product-key-store" component={ProductKeyStore}/>}
											<Redirect to="/error/404"/>
										</Switch>
										<Footer/>
									</div>
								</div>
							</div>
							<SettingOPPassword
								isOpen={isOpenSettingOPPass}
								toggle={this.toggleSettingOPPassword}
							/>
							<NotifyProductCodeCreated
								isOpen={isOpenNotifyProductCodeCreated}
								toggle={this.toggleNotifyProductCodeCreated}
								newProductCodeCreated={newProductCodeCreated}
								onClose={this.toggleNotifyProductCodeCreated}
								type={type}
								onView={this.onViewProductCode}
							/>
						</div>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		userInfo: store.user.userInfo,
		status: store.user.status,
		token: store.auth.token,
		getAuthStatusDone: store.auth.getAuthStatusDone,
		socket: store.socket.socket,
		socketID: store.socket.socketID,
		notifies: store.socket.notifies,
		isMaintenanceMode: isMaintenanceMode(store),
		opPasswordSettingDone: store.user.opPasswordSettingDone,
		productCodeEnabled: store.system.productCodeEnabled,
		repeatOrderCodeEnabled: store.system.repeatOrderCodeEnabled,
	}
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				logout,
				getUserInfo,
				getAuthStatus,
				connectSocket,
				getTimestampBackground,
				settingOPPasswordStatus,
				getProductCodeStatus,
				getRepeatOrderCodeStatus
			},
			dispatch
		)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletPage);