import React, {Component} from 'react';
import General from './General/general';
import Security from './Security/security';
import { I18n } from 'react-i18next';
import './setting.css';

export default class Setting extends Component {
	state = {
		currentTab: 'general'
	};
	
	componentDidMount() {
		if (window.location.href.endsWith('#security'))
			this.setState({ currentTab: 'security' });
	}
	
	onTabClick = (e, tabName) => {
		e.preventDefault();
		this.setState({ currentTab: tabName });
	};

	render() {
		const { currentTab } = this.state;

		return (
			<I18n ns="translations">
				{
					t => (
						<div className="row">
							<div className="col-lg-12 grid-margin">
								<div className="card">
									<ul className="nav nav-tabs tab-solid tab-solid-danger" role="tablist">
										<li className="nav-item">
											<button
												className={`btn btn-link nav-link cursor-pointer ${currentTab === 'general' ? 'active' : ''}`}
												data-toggle="tab"
												role="tab"
												onClick={e => this.onTabClick(e, 'general')}
											>
												{t('general')}
											</button>
										</li>
										<li className="nav-item">
											<button
												className={`btn btn-link nav-link cursor-pointer ${currentTab === 'security' ? 'active' : ''}`}
												data-toggle="tab"
												role="tab"
												onClick={e => this.onTabClick(e, 'security')}
											>
												{t('security')}
											</button>
										</li>
									</ul>
									{
										currentTab === 'general' ? <General/> : <Security/>
									}
								</div>
							</div>
						</div>
					)
				}
			</I18n>
		);
	}
}