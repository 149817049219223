import { callApi } from "../05-utils/commonUtils";
import { updateStatus } from "./utils";
import { constants } from "../05-utils/commonData";

const ACTIONS = {
	GET_ALL_USER_WALLET_PROGRESS: "GET_ALL_USER_WALLET_PROGRESS",
	GET_ALL_USER_WALLET_SUCCESS: "GET_ALL_USER_WALLET_SUCCESS",
	GET_ALL_USER_WALLET_FAILED: "GET_ALL_USER_WALLET_FAILED",
	CHOOSE_WALLET: "CHOOSE_WALLET",
	SUBMIT_TFA_CODE: "SUBMIT_TFA_CODE",
	INIT_KEYPAIR_PROGRESS: "INIT_KEYPAIR_PROGRESS",
	INIT_KEYPAIR_SUCCESS: "INIT_KEYPAIR_SUCCESS",
	INIT_KEYPAIR_FAILED: "INIT_KEYPAIR_FAILED",
	SAVE_WALLET_PROGRESS: "SAVE_WALLET_PROGRESS",
	SAVE_WALLET_SUCCESS: "SAVE_WALLET_SUCCESS",
	SAVE_WALLET_FAILED: "SAVE_WALLET_FAILED",
	CREATE_WALLET_PROGRESS: "CREATE_WALLET_PROGRESS",
	CREATE_WALLET_SUCCESS: "CREATE_WALLET_SUCCESS",
	CREATE_WALLET_FAILED: "CREATE_WALLET_FAILED",
	UNBLOCK_WALLET_PROGRESS: "UNBLOCK_WALLET_PROGRESS",
	UNBLOCK_WALLET_SUCCESS: "UNBLOCK_WALLET_SUCCESS",
	UNBLOCK_WALLET_FAILED: "UNBLOCK_WALLET_FAILED",
	RESET_SAVE_WALLET_STATUS: "RESET_SAVE_WALLET_STATUS",
	RESET_CREATE_WALLET_STATUS: "RESET_CREATE_WALLET_STATUS",
	RESET_UNBLOCK_WALLET_STATUS: "RESET_UNBLOCK_WALLET_STATUS",
	RESET_TFA_CODE: "RESET_TFA_CODE",
	WALLET_SECRET_KEY_REQUEST_PROGRESS: "WALLET_SECRET_KEY_REQUEST_PROGRESS",
	WALLET_SECRET_KEY_REQUEST_SUCCESS: "WALLET_SECRET_KEY_REQUEST_SUCCESS",
	WALLET_SECRET_KEY_REQUEST_FAILED: "WALLET_SECRET_KEY_REQUEST_FAILED",
	REFRESH_WALLET_KEY: "REFRESH_WALLET_KEY",
    RENAME_WALLET_PROGRESS: "SAVE_WALLET_PROGRESS",
    RENAME_WALLET_SUCCESS: "RENAME_WALLET_SUCCESS",
    RENAME_WALLET_FAILED: "RENAME_WALLET_FAILED",
    RESET_STATUS_RENAME: "RESET_STATUS_RENAME",
	GET_AND_GROUP_USER_WALLET_PROGRESS: "GET_AND_GROUP_USER_WALLET_PROGRESS",
	GET_AND_GROUP_USER_WALLET_SUCCESS: "GET_AND_GROUP_USER_WALLET_SUCCESS",
	GET_AND_GROUP_USER_WALLET_FAILED: "GET_AND_GROUP_USER_WALLET_FAILED",
};

const getAllUserWallet = payload => {
	const data = {
		name: "get_all_user_wallets",
		params: {
			with_assets: true,
			with_qrcode: true,
			with_active: payload && payload.with_active,
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.GET_ALL_USER_WALLET_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.GET_ALL_USER_WALLET_SUCCESS, { userWallets: response.result }));
				else {
					dispatch(updateStatus(ACTIONS.GET_ALL_USER_WALLET_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("getAllUserWallet", err);
				dispatch(updateStatus(ACTIONS.GET_ALL_USER_WALLET_FAILED, {error: err}));
			});
	};
};

const getAndGroupUserWallet = () => {
	const data = {
		name: "get_all_user_wallets",
		params: {
			with_assets: true,
			with_qrcode: true,
			with_advance: true,
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.GET_AND_GROUP_USER_WALLET_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success) {
					const { result } = response;
					const { STANDARD_WALLET } = constants;
					const standardWallet = result.filter(wallet => !wallet.type || wallet.type === STANDARD_WALLET);
					dispatch(updateStatus(ACTIONS.GET_AND_GROUP_USER_WALLET_SUCCESS, { standardWallet }));
				}
				else {
					dispatch(updateStatus(ACTIONS.GET_AND_GROUP_USER_WALLET_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("getAndGroupUserWallet", err);
				dispatch(updateStatus(ACTIONS.GET_AND_GROUP_USER_WALLET_FAILED, {error: err}));
			});
	};
};

const initKeyPair = () => {
	const data = {
		name: "get_keypair",
		params: {}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.INIT_KEYPAIR_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.INIT_KEYPAIR_SUCCESS, { keyPair: response.result }));
				else {
					dispatch(updateStatus(ACTIONS.INIT_KEYPAIR_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("initKeyPair", err);
				dispatch(updateStatus(ACTIONS.INIT_KEYPAIR_FAILED, {error: err}));
			});
	};
};

const saveWallet = payload => {
	const data = {
		name: "save_wallet",
		params: {
			opPass: payload.opPass,
			name: payload.name,
			secretKey: payload.secretKey,
			code: payload.code
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.SAVE_WALLET_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.SAVE_WALLET_SUCCESS, { keyPair: response.result }));
				else {
					dispatch(updateStatus(ACTIONS.SAVE_WALLET_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("saveWallet", err);
				dispatch(updateStatus(ACTIONS.SAVE_WALLET_FAILED, { error: err }));
			});
	};
};

const renameWallet = payload => {
    const data = {
        name: "rename_wallet",
        params: {
            newName: payload.newName,
            walletID: payload.walletId,
        }
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.RENAME_WALLET_PROGRESS));
        callApi('rpc', data)
            .then(response => {
                if (response.success)
                    dispatch(updateStatus(ACTIONS.RENAME_WALLET_SUCCESS, payload));
                else {
                    dispatch(updateStatus(ACTIONS.RENAME_WALLET_FAILED, { error: response.error }));
                }
            })
            .catch(err => {
                console.log("renameWallet", err);
                dispatch(updateStatus(ACTIONS.RENAME_WALLET_FAILED, { error: err }));
            });
    };
};


const createWallet = payload => {
	const data = {
		name: "create_wallet",
		params: {
			secretKey: payload.secretKey,
			funderAddress: payload.funderAddress,
			name: payload.name,
			startingBalance: payload.startingBalance,
			code: payload.code,
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.CREATE_WALLET_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.CREATE_WALLET_SUCCESS, {}));
				else {
					dispatch(updateStatus(ACTIONS.CREATE_WALLET_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("createWallet", err);
				dispatch(updateStatus(ACTIONS.CREATE_WALLET_FAILED, { error: err }));
			});
	};
};

const unblockWallet = payload => {
	const data = {
		name: "unblock_wallet",
		params: {
			privateKey: payload.privateKey,
			opPass: payload.opPass,
			walletID: payload.walletID,
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.UNBLOCK_WALLET_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.UNBLOCK_WALLET_SUCCESS, {}));
				else {
					dispatch(updateStatus(ACTIONS.UNBLOCK_WALLET_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("unblockWallet", err);
				dispatch(updateStatus(ACTIONS.UNBLOCK_WALLET_FAILED, { error: err }));
			});
	};
};

const displaySecretKey = payload => {
	const data = {
		name: "get_all_user_wallet_pk",
		params: {
			password: payload.password,
			code: payload.code,
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.WALLET_SECRET_KEY_REQUEST_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.WALLET_SECRET_KEY_REQUEST_SUCCESS, { walletKeys: response.result }));
				else {
					dispatch(updateStatus(ACTIONS.WALLET_SECRET_KEY_REQUEST_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("displaySecretKey", err);
				dispatch(updateStatus(ACTIONS.WALLET_SECRET_KEY_REQUEST_FAILED, { error: err }));
			});
	};
};

const chooseWallet = wallet => dispatch => dispatch(updateStatus(ACTIONS.CHOOSE_WALLET, { wallet }));

const submitTFACode = code => dispatch => dispatch(updateStatus(ACTIONS.SUBMIT_TFA_CODE, { code }));

const resetSaveWalletStatus = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_SAVE_WALLET_STATUS, {}));

const resetCreateWalletStatus = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_CREATE_WALLET_STATUS, {}));

const resetUnblockWalletStatus = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_UNBLOCK_WALLET_STATUS, {}));

const resetTfaCode = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_TFA_CODE, {}));

const refreshWalletKeys = () => dispatch => dispatch(updateStatus(ACTIONS.REFRESH_WALLET_KEY, {}));

const changeStatusRenameWallet = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_STATUS_RENAME, {}));

export {
	ACTIONS,
	getAllUserWallet,
	chooseWallet,
	submitTFACode,
	initKeyPair,
	saveWallet,
	resetSaveWalletStatus,
	createWallet,
	resetCreateWalletStatus,
	resetTfaCode,
	unblockWallet,
	resetUnblockWalletStatus,
	displaySecretKey,
	refreshWalletKeys,
    renameWallet,
    changeStatusRenameWallet,
	getAndGroupUserWallet,
};