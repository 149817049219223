import React, {Component} from 'react';
import { I18n } from 'react-i18next';
import Transaction from './Transaction/transaction';
import TransactionFail from './TransactionFail/transactionsFail';
import './history.css';
import Tab from '../../../03-components/Tab';
import EzpayTransfer from "./EZPayTransfer/ezpayTransfer";
import { connect } from 'react-redux';
import {bindActionCreators} from "redux";
import {getTopUpStatus} from "../../../01-actions/system";

class History extends Component {
	state = {
		currentTab: 'transaction'
	};

	componentDidMount() {
		const { actions } = this.props;
		actions.getTopUpStatus();
	}

	onTabClick = (e, tabName) => {
		e.preventDefault();
		this.setState({ currentTab: tabName });
	};

	render() {
		const { currentTab } = this.state;
		let contentJSX;
		switch(currentTab) {
			case 'transaction-fail':
				contentJSX = <TransactionFail/>;
				break;
			case 'ezpay-transfer':
				contentJSX = <EzpayTransfer/>;
				break;
			default:
				contentJSX = <Transaction/>;
		}

		return (
			<I18n ns="translations">
				{
					t => (
						<div className="row">
							<div className="col-lg-12 grid-margin">
								<div className="card">
									<ul className="nav nav-tabs tab-solid tab-solid-danger" role="tablist">
										<Tab
											tabName="transaction"
											currentTab={currentTab}
											onTabClick={this.onTabClick}
											text={t('transaction')}
										/>
										{
											this.props.topUpEnabled && (
												<Tab
													tabName="ezpay-transfer"
													currentTab={currentTab}
													onTabClick={this.onTabClick}
													text={t('ezpay_transfer')}
												/>
											)
										}
										<Tab
											tabName="transaction-fail"
											currentTab={currentTab}
											onTabClick={this.onTabClick}
											text={t('transaction_fail')}
										/>
									</ul>
									{
										contentJSX
									}
								</div>
							</div>
						</div>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		topUpEnabled: store.system.topUpEnabled,
	}
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				getTopUpStatus
			},
			dispatch
		)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(History);