import React, { Component } from 'react';
import { I18n } from 'react-i18next'
import './eZpayTopUp.css';
import ModalTemplate from './modalTemplate';

export default class EZPayTopUpSuccess extends Component {
    refreshInputAndToggle = () => {
        this.props.toggle();
    };

    render() {
        const {
            isOpen,
        } = this.props;
        return (
            <I18n ns="translations">
                {
                    t => (
                        <ModalTemplate
                            isOpen={isOpen}
                            titleText={t('transfer_to_card')}
                            refreshInputAndToggle={this.refreshInputAndToggle}
                        >
                            <div className='transfer-to-card-body'>
                                <div className="wrapper-top-up-success">
                                    <p>{t('EZPAY_TOP_UP_SUCCESS_1')}</p>
                                </div>
                            </div>
                        </ModalTemplate>
                    )
                }
            </I18n>
        );
    }
}
