import queryString from 'querystring';
import moment from 'moment';
import {getEnv} from "../env";
import { errors } from "./commonData";
import I18n from "i18next";
import { constants } from "./commonData";
import QueryString from 'query-string';
import _ from "lodash";
import BigNumber from "bignumber.js";

const METHOD_GET = 'get';
const METHOD_POST = 'post';
const ezpayTransferStatus = {
	PENDING: 0,
	APPROVED: 1,
	REJECT: 2,
	SUCCEED: 3
};
const parseUrlQuery = (query) => {
	if (query.indexOf('?') >= 0) query = query.substr(query.indexOf('?') + 1);
	return queryString.parse(query);
};

const getUserFromLocalStorage = () => {
	return localStorage.getItem("USER_ACCESS_TOKEN");
};

const formatDateTime = dateTime => moment(dateTime).format('MM/DD HH:mm');

const formatDateTimeFull = dateTime => moment(dateTime).format('YYYY/MM/DD HH:mm:ss Z');

const formatOnlyDate = dateTime => moment(dateTime).format('YYYY/MM/DD');

const getIconTransaction = (transaction, sourceAcccount) => {
	if (transaction.type === 'payment') {
		return transaction.source_account === sourceAcccount ?
			"fa fa-chevron-circle-right text-success"
			:
			"fa fa-chevron-circle-left text-primary";
	}
	else if (transaction.type === 'change_trust') {
		return transaction.limit === 0 ?
			"fa fa-thumbs-down text-dark"
			:
			"fa fa-thumbs-up text-info";
	}
	else if (transaction.type === 'create_account') {
		return "fa fa-plus-circle text-warning";
	}
	return "fa fa-question-circle text-primary";
};
const bitwiseAnd = function (a, b) {
    return Number(a) & Number(b);
};

const getTransactionDescription = transaction => {
	if (transaction.type === 'payment') {
		return `${transaction.amount} ${transaction.asset_code || 'RIA'}`;
	}
	else if (transaction.type === 'change_trust') {
		return transaction.asset_code;
	}
	else if (transaction.type === 'create_account') {
		return `${transaction.starting_balance} RIA`;
	}
	return "...";
};

const getErrorMessage = error => {
	let index = Object.values(errors).findIndex(err => error.errorCode === err.errorCode);
	if (index === -1)
		index = 2; // System error
	return I18n.t(Object.keys(errors)[index]);
};

const oauthPopup = function (options) {
	const left = (window.screen.width - options.width) / 2;
	const top = (window.screen.height - options.height) / 4;

	options.windowName = options.windowName || 'ConnectWithOAuth';
	options.windowOptions = options.windowOptions || `width=${options.width},height=${options.height},top=${top},left=${left},scrollbars=no,resizable=no`;
	options.callback = options.callback || function () { window.location.reload() };

	const _oauthWindow = window.open(options.path, options.windowName, options.windowOptions);
	const _oauthInterval = window.setInterval(function () {
		let code;
		try {
			code = QueryString.parse(_oauthWindow.location.search).code;
		} catch (err) {}

		if(code){
			window.clearInterval(_oauthInterval);
			_oauthWindow.close();
			options.callback(code);
		}
	}, 1000);
}

const getByteLengthString = str => {
	let s = str.length;
	for (let i=str.length-1; i>=0; i--) {
		const code = str.charCodeAt(i);
		if (code > 0x7f && code <= 0x7ff) s++;
		else if (code > 0x7ff && code <= 0xffff) s+=2;
		if (code >= 0xDC00 && code <= 0xDFFF) i--; //trail surrogate
	}
	return s;
};

const isSuperAdmin = userInfo => userInfo.userClass.includes(constants.SUPER_ADMIN);

const getUserName = user => {
	const { firstname, middlename, lastname } = user;
	const fullName = `${firstname ? firstname : ''} ${middlename ? middlename : ''} ${lastname ? lastname : ''}`;
	return fullName ? (fullName.length > 50 ? `${fullName.substring(0,50)}...` : fullName) : '';
};

const getAmountBalance = (balance, decimalPlace) => {
	if (decimalPlace === 0)
		return new RegExp(`[0-9]*`).exec(balance)[0];
	return new RegExp(`[0-9]*[.]?[0-9]{0,${decimalPlace}}`).exec(balance)[0];
};

const callApi = (endpoint, data, token) => {
	const config = {
		method: METHOD_POST,
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			...data,
			token: token || getUserFromLocalStorage(),
		}),
	};
	return fetch(getEnv('REACT_APP_API_SERVER') + '/v2/' + endpoint, config)
		.then(response => response.json())
		.catch(err => {
			return { errorMessage: err }
		});
};

const formatData = (data, total, limit) => {
    const totalRecord = total;
    const totalPage = Math.ceil(totalRecord / limit);
    return data && data.map((tran) => ({
        _id: tran._id,
        status: tran.status,
        walletID: tran.walletID._id,
        name: tran.walletID.name,
        address: tran.walletID.address,
        createdOn: tran.createdAt,
        user: {
            name: tran.signedBy.length > 0 ? tran.signedBy[0].signer.name : "",
            address: tran.signedBy.length > 0 ? tran.signedBy[0].signer.address : "",
        },
        signedAmount: tran.signedBy.length,
        totalSigners: tran.walletID.signers.length,
        weightRequire: tran.weightRequire,
        type: tran.type,
        weightSigned: _.sumBy(tran.signedBy, s => s.weight),
        signedBy: tran.signedBy,
        totalPage
    }));
}

const getSignaturesData = (tran) => {
    return _.map(tran && tran.walletID && tran.walletID.signers, s => {
        const {totalSignedWeight, weightRequire} = tran;
        const percent = (s.weight + totalSignedWeight <= weightRequire ? s.weight : weightRequire - totalSignedWeight) / weightRequire * 100;
        return {
            name: s.name,
            address: s.public_key,
            isMasterKey: s.public_key === tran.walletID.address,
            weight: s.weight,
            isOwned: s.isOwned,
            isSigned: _.some(tran.signedBy, s2 => s2.signer.address === s.public_key),
            percent,
        };
    });
}

const ceilWithDecimal = (number, decimal) => {
	return Math.ceil(number * Math.pow(10, decimal)) / Math.pow(10, decimal);
}

const isNaturalNumber = number => {
	const numberParsed = parseInt(number, 10);
	return numberParsed >= 0 && numberParsed.toString() === number;
}

const isChineseUser = userCode => userCode && userCode.startsWith('GT');

const isRetailUser = userCode => userCode && userCode.startsWith('CGN');

const checkDisableCodeForUser = ({userInfo, productCodeEnabled, repeatOrderCodeEnabled}) => {
	if (isRetailUser(userInfo && userInfo.userCode)) {
		return repeatOrderCodeEnabled;
	}
	else if ((userInfo && !isChineseUser(userInfo && userInfo.userCode)) || getEnv("ENABLE_PRODUCT_CODE_FOR_CHINA"))
		return repeatOrderCodeEnabled || productCodeEnabled;
	else return false;
};

const calculateTopUpHistoryItem = (itemTopUpHistory) => {
	const {ezpFee, wcgFee, requestAmount, otcRate, otcFee, amountNeedOTC} = itemTopUpHistory;
	let amountTCT = new BigNumber(requestAmount);
	let totalOTCFee;
	let amountAGTNeed;
	const topUpFee = new BigNumber(1)
		.minus(wcgFee)
		.times(new BigNumber(1).minus(ezpFee));
	// total TCT need for topup 'amount' input
	let totalTransferTCT = amountTCT.dividedBy(topUpFee.toString() || 1);
	if (amountNeedOTC) {
		amountAGTNeed = new BigNumber(amountNeedOTC).multipliedBy(otcRate).toString();
		totalOTCFee = new BigNumber(amountNeedOTC).multipliedBy(otcRate).multipliedBy(otcFee).toString();
	}
	BigNumber.config({
		ROUNDING_MODE: BigNumber.ROUND_UP,
		DECIMAL_PLACES: 3
	});
	totalOTCFee = amountNeedOTC ? new BigNumber(totalOTCFee, 10).toString() : 0;
	amountAGTNeed = amountNeedOTC ? new BigNumber(amountAGTNeed, 10).toString() : 0;
	totalTransferTCT = new BigNumber(totalTransferTCT, 10).toString();
	// total fee topup (WCGFee and EZPFee)
	const transferFeeTopUp = new BigNumber(totalTransferTCT.toString()).minus(amountTCT).toString();
	return {
		...itemTopUpHistory,
		requestAmount,
		amountAGTNeed: Number(amountAGTNeed),
		totalOTCFee: Number(totalOTCFee),
		totalTransferTCT: Number(totalTransferTCT),
		transferFeeTopUp: Number(transferFeeTopUp)
	};
};

export {
	callApi,
	parseUrlQuery,
    formatData,
	getUserFromLocalStorage,
    getSignaturesData,
	formatDateTime,
	formatDateTimeFull,
	getIconTransaction,
	getTransactionDescription,
	getErrorMessage,
	getByteLengthString,
	isSuperAdmin,
	getUserName,
	oauthPopup,
	getAmountBalance,
	bitwiseAnd,
	METHOD_GET,
	METHOD_POST,
	ceilWithDecimal,
	isNaturalNumber,
	ezpayTransferStatus,
	isChineseUser,
	calculateTopUpHistoryItem,
	formatOnlyDate,
	isRetailUser,
	checkDisableCodeForUser
};