import React, {Component} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { I18n } from 'react-i18next';

import { getAllContact } from "../../../../../01-actions/addressBook";
import { resetTfaCode } from "../../../../../01-actions/wallet";
import { resetTransferStatus, transferMoney } from "../../../../../01-actions/transaction";
import validate from './validate';
import TFAModal from '../../../../Wallet/TfaCode/tfaCode';
import SubmitButton from '../../../../../03-components/ModalSubmitButton';
import OPPassModal from '../../../../../03-components/OPPassModal/OPPassModal';
import './transferMoney.css';

class TransferMoney extends Component {
	state = {
		isOpenTFA: false,
		isOpenOPPass: false,
		destinationEntered: '',
		amount: '',
		memo: '',
		isEnterDestination: true,
		destinationSelected: '',
		opPass: '',
	};

	componentDidMount() {
		this.props.actions.getAllContact();
	}
	
	componentWillReceiveProps(nextProps) {
		if (nextProps.moneyTransferred) {
			this.refreshInputAndToggle();
			nextProps.actions.resetTransferStatus();
		}
	}
	
	getValidAmount = amount => {
		const regex = new RegExp(`[0-9]*[.]?[0-9]{0,${this.props.balance.decimalPlace}}`);
		const result = regex.exec(amount);
		return result ? result[0] : null;
	};
	
	renderField = ({ input, label, type, fieldValue, disabled, hideLabel, meta: { touched, error } }) => (
		<div className="form-group">
			{
				hideLabel ? null : <label>{label}</label>
			}
			<div className="input-group">
				<input
					{...input}
					type={type}
					className="form-control transfer-input"
					disabled={disabled}
					value={fieldValue}
					onChange={e => {
						if (input.name === 'amount') {
							const amount = this.getValidAmount(e.target.value);
							this.setState({ amount });
						}
						else {
							this.setState({[input.name]: e.target.value})
						}
					}}
				/>
				{touched && error && <p className="text-danger">{`* ${error}`}</p>}
			</div>
		</div>
	);

	renderSelect = ({ input, disabled, type, fieldValue, addressBook, name, t, meta: { touched, error } }) => (
		<div className="form-group">
			<select
				{...input}
				type={type}
				className="form-control"
				disabled={disabled}
				value={fieldValue}
				onChange={this.onDestinationSelected}
			>
				<option value="">{t('select_the_destination')}</option>
				{
					addressBook && addressBook.map((book, index) => (
						<option key={index} value={JSON.stringify(book)}>
							{book.name}
						</option>
					))
				}
			</select>
			{touched && error && <p className="text-danger">{`* ${error}`}</p>}
		</div>
	);

	toggleTFAModal = () => this.setState({ isOpenTFA: !this.state.isOpenTFA });

	toggleOPPass = () => this.setState({ isOpenOPPass: !this.state.isOpenOPPass });

	onTransferMoney = tfaCode => {
		this.setState({ isOpenTFA: false, isOpenOPPass: false });

		const { walletAddress, balance, actions } = this.props;
		const { destinationEntered, destinationSelected, amount, memo, isEnterDestination, opPass } = this.state;
		const destination = isEnterDestination ? destinationEntered.trim() : JSON.parse(destinationSelected).address;

		actions.transferMoney({
			sourceAddress: walletAddress,
			amount,
			asset: balance.asset_code || 'RIA',
			destination,
			memo,
			code: tfaCode,
			opPass
		});
	};

	changeDestinationSelectWay = key => {
		this.setState({ isEnterDestination: key === 'enter', destinationSelected: '', destinationEntered: '' });
	};

	onDestinationSelected = e => {
		const { value } = e.target;
		this.setState({
			destinationSelected: value,
			destinationEntered: value === "" ? value : JSON.parse(value).address,
		});
	};

	refreshInputAndToggle = () => {
		this.setState({
			destinationEntered: '',
			amount: '',
			memo: '',
			isEnterDestination: true,
			destinationSelected: '',
		});
		this.props.toggle();
	};

	onReceiveOPPass = opPass => {
		const { tfaEnabled } = this.props.userInfo;
		this.setState({ opPass }, () => {
			if (tfaEnabled) {
				this.setState({ isOpenTFA: true });
			}
			else {
				this.onTransferMoney();
			}
		});
	};

	render() {
		const {
			isOpen,
			walletName,
			walletAddress,
			moneyTransferring,
			invalid,
			addressBook,
		} = this.props;
		const {
			isOpenTFA,
			isOpenOPPass,
			isEnterDestination,
			destinationSelected,
			destinationEntered,
			amount,
			memo,
		} = this.state;
		let { asset_code, balance } = this.props.balance;
		asset_code = asset_code || 'RIA';
		
		return (
			<I18n ns="translations">
				{
					t => (
					    <React.Fragment>
                            <TFAModal
                                isOpen={isOpenTFA}
                                toggle={this.toggleTFAModal}
                                onSubmit={this.onTransferMoney}
                            />
                            <OPPassModal
                                isOpen={isOpenOPPass}
                                toggle={this.toggleOPPass}
                                onSubmit={this.onReceiveOPPass}
                            />
						    <Modal isOpen={isOpen} className="modal-lg">
                                <ModalHeader toggle={this.refreshInputAndToggle}>{t('transfer_money')}</ModalHeader>
                                <ModalBody>
                                    <div><b>{t('wallet')}</b></div>
                                    <div>{walletName}</div>
                                    <div className="transfer-source-address">{walletAddress}</div>
                                    <div className="margin-top-1rem"><b>{t('balance')}</b></div>
                                    <div>
                                        <b>{`${balance} ${asset_code}`}</b>
                                        <span>{t('available')}</span>
                                    </div>
                                    <form className="margin-top-1rem">
                                        <div className="radio form-radio">
                                            <label className="form-check-label">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    checked={isEnterDestination}
                                                    onChange={() => this.changeDestinationSelectWay('enter')}
                                                />
                                                {t('enter_the_destination')}
                                                <i className="input-helper"/>
                                            </label>
                                        </div>
                                        <Field
                                            name="destinationEntered"
                                            type="text"
                                            component={this.renderField}
                                            disabled={!isEnterDestination}
                                            fieldValue={destinationEntered}
                                            hideLabel
                                        />
                                        <div className="radio form-radio">
                                            <label className="form-check-label">
                                                <input
                                                    type="radio"
                                                    className="form-check-input"
                                                    checked={!isEnterDestination}
                                                    onChange={() => this.changeDestinationSelectWay('select')}
                                                />
                                                {t('select_the_destination_from_address_book')}
                                                <i className="input-helper"/>
                                            </label>
                                        </div>
                                        <div className="form-group">
                                            <Field
                                                t={t}
                                                name="destinationSelected"
                                                type="select"
                                                disabled={isEnterDestination}
                                                fieldValue={destinationSelected}
                                                addressBook={addressBook}
                                                component={this.renderSelect}
                                            />
                                        </div>
                                        <Field
                                            name="amount"
                                            type="number"
                                            component={this.renderField}
                                            label={t('amount')}
                                            fieldValue={amount}
                                        />
                                        <Field
                                            name="memo"
                                            type="text"
                                            component={this.renderField}
                                            label={t('memo')}
                                            fieldValue={memo}
                                        />
                                    </form>
                                </ModalBody>
                                <ModalFooter>
                                    <SubmitButton
                                        className="btn btn-primary"
                                        onClick={this.toggleOPPass}
                                        isSubmitting={moneyTransferring}
                                        disabled={invalid || (!isEnterDestination && destinationSelected === '') || (isEnterDestination && !destinationEntered)}
                                        text={t('send_')}
                                    />
                                </ModalFooter>
						</Modal>
                        </React.Fragment>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		tfaCode: store.wallet.tfaCode,
		userInfo: store.user.userInfo,
		moneyTransferred: store.transaction.moneyTransferred,
		moneyTransferring: store.transaction.moneyTransferring,
		addressBook: store.addressBook.contacts,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				resetTfaCode,
				getAllContact,
				resetTransferStatus,
				transferMoney,
			},
			dispatch
		)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(reduxForm({
	form: 'transferMoney',
	validate,
})(TransferMoney));
