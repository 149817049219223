import React, {Component} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { I18n } from 'react-i18next';

import { changePassword } from "../../../../../01-actions/user";
import validate from './validate';
import SubmitButton from '../../../../../03-components/ModalSubmitButton/index';
import './changePasswordModal.css';

class ChangePassword extends Component {
	state = {
		isOpenTFA: false,
		password: '',
		newPassword: '',
		confirmPassword: '',
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps.passwordChanged) {
			this.refreshInputAndToggle();
		}
	}

	renderField = ({ input, label, type, fieldValue, meta: { touched, error } }) => (
		<div className="form-group">
			<label>{label}</label>
			<div className="input-group">
				<input
					{...input}
					type={type}
					className="form-control transfer-input"
					value={fieldValue}
					onChange={e => this.setState({ [input.name]: e.target.value.trim() })}
				/>
				{touched && error && <p className="text-danger">{`* ${error}`}</p>}
			</div>
		</div>
	);

	changePassword = () => {
		const { password, newPassword } = this.state;
		this.props.actions.changePassword({ oldPassword: password, newPassword });
	};

	refreshInputAndToggle = () => {
		this.setState({ password: '', newPassword: '', confirmPassword: '' });
		this.props.toggle();
	};
	
	render() {
		const {
			isOpen,
			invalid,
			passwordChanging,
		} = this.props;

		const {
		    password,
            newPassword,
            confirmPassword
        } = this.state;

		return (
			<I18n ns="translations">
				{
					t => (
						
						<Modal isOpen={isOpen} className="modal-lg">
							<ModalHeader toggle={this.refreshInputAndToggle}>{t('change_password')}</ModalHeader>
							<ModalBody>
								<form className="margin-top-1rem">
									<Field
										name="password"
										type="password"
										component={this.renderField}
										label={t('password')}
										fieldValue={password}
									/>
									<Field
										name="newPassword"
										type="password"
										component={this.renderField}
										label={t('new_password')}
										fieldValue={newPassword}
									/>
									<Field
										name="confirmPassword"
										type="password"
										component={this.renderField}
										label={t('confirm_password')}
										fieldValue={confirmPassword}
									/>
								</form>
							</ModalBody>
							<ModalFooter>
								<SubmitButton
									className="btn btn-primary"
									onClick={this.changePassword}
									isSubmitting={passwordChanging}
									disabled={invalid || !password || !newPassword || !confirmPassword}
									text={t('change_')}
								/>
							</ModalFooter>
						</Modal>

					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		passwordChanged: store.user.passwordChanged,
		passwordChanging: store.user.passwordChanging,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				changePassword,
			},
			dispatch
		)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(reduxForm({
	form: 'changePassword',
	validate,
})(ChangePassword));
