import React, {Component} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { I18n } from 'react-i18next';

import SubmitButton from '../../../../../03-components/ModalSubmitButton';
import validate from "../../../../../03-components/OPPassModal/validate";

class RejectProductCodeModal extends Component {
    state = {
        userCode: '',
    };

    renderField = ({ input, label, type, fieldValue, meta: { touched, error } }) => (
        <div className="form-group">
            <label>{label}</label>
            <div className="input-group">
                <input
                    {...input}
                    type={type}
                    className="form-control transfer-input"
                    value={fieldValue}
                    onChange={e => this.setState({ [input.name]: e.target.value })}
                />
                {touched && error && <p className="text-danger">{`* ${error}`}</p>}
            </div>
        </div>
    );

    onSubmitUserCode = () => {
        this.props.onSubmit(this.state.userCode);
        this.refreshInputAndToggle();
    };

    refreshInputAndToggle = () => {
        this.setState({ userCode: '' });
        this.props.toggle();
    };

    render() {
        const {
            isOpen
        } = this.props;
        const { userCode } = this.state;

        return (
            <I18n ns="translations">
                {
                    t => (
                        <Modal isOpen={isOpen} className="modal-lg">
                            <ModalHeader toggle={this.refreshInputAndToggle}>{t('confirm_reject_product_code')}</ModalHeader>
                            <ModalBody>
                                <form className="margin-top-1rem">
                                    <Field
                                        name="userCode"
                                        type="text"
                                        component={this.renderField}
                                        fieldValue={userCode}
                                        label={t('user_code')}
                                    />
                                </form>
                            </ModalBody>
                            <ModalFooter>
                                <SubmitButton
                                    className="btn btn-primary"
                                    onClick={this.onSubmitUserCode}
                                    disabled={ !userCode}
                                    text={t('submit')}
                                />
                            </ModalFooter>
                        </Modal>

                    )
                }
            </I18n>
        );
    }
}

export default  reduxForm({
    form: 'opPassModal',
    validate,
})(RejectProductCodeModal);
