import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { I18n } from 'react-i18next';
import { pushNotify } from "../../../03-components/Notification";
import i18n from 'i18next';
import {
	unblockWallet,
	resetUnblockWalletStatus,
} from '../../../01-actions/wallet'
import validate from './validate';
import SubmitButton from '../../../03-components/ModalSubmitButton';

class UnblockWallet extends Component {
	state = {
		opPass: '',
		privateKey: '',
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps.walletUnblocked) {
            pushNotify('success', i18n.t('WALLET_UNBLOCKED_SUCCESSFULLY'));
			this.refreshInputAndToggle();
			nextProps.actions.resetUnblockWalletStatus();
		}
	}

	renderField = ({ input, label, type, value, meta: { touched, error } }) => (
		<div className="form-group">
			<label>{label}</label>
			<div className="input-group">
				<input
					{...input}
					type={type}
					className="form-control create-wallet-input"
					value={value}
					onChange={e => this.setState({ [input.name]: e.target.value })}
				/>
				{touched && error && <p className="text-danger">{`* ${error}`}</p>}
			</div>
		</div>
	);

	unblockWallet = () => {
		const { opPass, privateKey } = this.state;
		this.props.actions.unblockWallet({
			opPass: opPass.trim(),
			privateKey: privateKey.trim(),
			walletID: this.props.walletID,
		});
	};

	refreshInputAndToggle = () => {
		this.setState({ opPass: '', privateKey: '' });
		this.props.toggle();
	};
	
	render() {
		const {
			isOpen,
			walletUnblocking,
			invalid,
		} = this.props;
		return (
			<I18n ns="translations">
				{
					t => (
						<Modal isOpen={isOpen} className="modal-lg">
							<ModalHeader toggle={this.refreshInputAndToggle}>{t('unblock_wallet')}</ModalHeader>
							<ModalBody>
								<form className="margin-top-1rem">
									<Field
										name="privateKey"
										type="text"
										component={this.renderField}
										label={t('private_key')}
									/>
									<Field
										name="opPass"
										type="password"
										component={this.renderField}
										label={t('op_password')}
									/>
								</form>
							</ModalBody>
							<ModalFooter>
								<SubmitButton
									className="btn btn-primary"
									onClick={this.unblockWallet}
									isSubmitting={walletUnblocking}
									disabled={invalid}
									text={t('unblock')}
								/>
							</ModalFooter>
						</Modal>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		walletUnblocking: store.wallet.walletUnblocking,
		walletUnblocked: store.wallet.walletUnblocked,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				unblockWallet,
				resetUnblockWalletStatus,
			},
			dispatch
		)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(reduxForm({
	form: 'unblockWallet',
	validate,
})(UnblockWallet));
