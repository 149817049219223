import React, { PureComponent } from 'react';

export default class Loading extends PureComponent {
    render() {
        const {
            loadingClass,
            parent1Class,
            parent2Class,
            isTableRow,
            colSpan,
        } = this.props;

        if (isTableRow)
            return (
                <tr className="text-center">
                    <td colSpan={colSpan || 1}>
                        <i className="fa fa-spin fa-spinner"/>
                    </td>
                </tr>
            );
        if (parent2Class)
            return (
                <div className={parent2Class}>
                    <div className={parent1Class || ''}>
                        <i className={`fa fa-spin fa-spinner ${loadingClass || ''}`}/>
                    </div>
                </div>
            );
        if (parent1Class)
            return (
                <div className={parent1Class}>
                    <i className={`fa fa-spin fa-spinner ${loadingClass || ''}`}/>
                </div>
            );
        return (
            <i className={`fa fa-spin fa-spinner ${loadingClass || ''}`}/>
        );
    }
}