export default {
	"logout": "退出",
	"login": "登录",
	"login_": "登录",
	"forgot_password": "忘记密码者",
	"password": "密码",
	"warning": "注意",
	"delete_address_book_confirm": "您确定要删除该地址码？",
	"cancel": "取消",
	"delete": "删除",
	"address_book": "地址本",
	"name": "电子钱包名称",
	"address": "地址",
	"actions": "Actions",
	"add_address_book": "添加地址",
	"add": "添加",
	"edit_address_book": "Edit address book",
	"save": "保存",
	"transaction_history": "Transaction history",
	"transaction": "交易",
	"wallet_selection": "选择电子钱包",
	"destination_wallet": "接收方电子钱包地址",
	"send_": "发送",
	"create_wallet": "新电子钱包",
	"attention_": "！！！注意！！！",
	"create_wallet_attention": "(密钥在修改密码或修复时非常重要。密钥仅在创建电子钱包时显示1次，请用书写或拍照方式保存妥善。（如果泄露于它人或被盗密钥您的电子钱包会损失严重，请注意）",
	"wallet_address": "电子钱包地址",
	"wallet_secret_key": "秘密キー密钥",
	"copy": "复制",
	"wallet_name_optional": "电子钱包名称（任意）",
	"transfer_money": "Transfer Money",
	"wallet": "电子钱包",
	"balance": "余额",
	"available": " 有效",
	"enter_the_destination": "Enter the destination",
	"select_the_destination_from_address_book": "Select the destination from address book",
	"select_the_destination": "Select the destination",
	"amount": "金额",
	"memo": "消息",
	"select_token": "Select token",
	"search_token": "Search token",
	"search_asset_by_name": "Search asset by name...",
	"OK": "OK",
	"unblock_wallet": "解锁电子钱包",
	"private_key": "密钥",
	"unblock": "解锁",
	"dont_have_wallet_intro": "You don't have any wallet for now. Create your first wallet for free!",
	"copied": "已被复制!",
	"wallet_not_active_intro": "此电子钱包未被激活。您需要从另一个已激活的电子钱包发送RIA币发送到此电子钱包。激活化电子钱包必须要20RIA才能激活化，激活后均可使用电子钱包。",
	"wallet_blocked_intro": "此电子钱包被封锁。请使用密码和密钥进行解锁。",
	"trust_coin": "货币TRUST",
	"change_password": "更改密码",
	"change_password_attention": "密码一旦发生更改所有的电子钱包将自动被封锁。如要封解锁电子钱包，必须持有各电子钱包的密钥。请先确认是否持有各电子钱包的密钥后再进行更改密码。",
	"new_password": "新密码",
	"confirm_password": "确认新密码",
	"change_": "更改",
	"email_and_password": "Email & Password",
	"email": "电子邮箱地址",
	"change_password_": " 更改密码",
	"tfa_verification": "设置双重身份验证",
	"tfa_verification_intro": "设置双重身份验证能够提高更高的安全化。",
	"status": "现状",
	"enabled_": " 有效",
	"disabled_": " 有效",
	"why_should_i_keep_tfa_enabled": "为什么需要设置双重身份验证呢？",
	"preferred_tfa_method": "以下的方法进行双重身份验证",
	"google_authenticator": "Google（谷歌）验证",
	"verified_": " 验证完毕",
	"not_verified_": " 未验证",
	"tfa_required_when_login": "登录时会被要求输入双重身份验证",
	"reset_": " 再设置",
	"account_verification": "账户验证",
	"verify_account_intro": "为了Triple Walle的所有服务能够使用，请您进行认证。",
	"email_": " 电子邮箱地址: ",
	"recent_login_history": "Recent login history",
	"date": "Date",
	"ip_address": "IP address",
	"geo_location": "Geo-location",
	"general": "General",
	"security": "安全",
	"two_factor_authentication": "双重身份验证",
	"enter_tfa_intro": "请在以下文字框里输入Google（谷歌）应用生成的6位数验证码。",
	"confirm": "确认",
	"my_wallet": "我的电子钱包",
	"setting": "设置",
	"history": "记录",
	"contact": "联系我",
	"two_factor_authentication_reset": "Two Factor Authentication reset",
	"two_factor_authentication_setup": "Two Factor Authentication setup",
	"reset_ga_intro": "重置Google（谷歌）双重身份验证。请输入重置双重身份验证的代码。重置后，重置时会接收到新的重置代码。",
	"ga_reset_code": "Google（谷歌）重置代码",
	"setup": "设置",
	"tfa_disabled_intro": "您还未进行双重身份验证，为了更强化安全性，请您设置开通双重身份验证。",
	"set_ga_intro": "You are about to change Two Factor Authentication settings. To verify it's you, please provide your password in the text box below.",
	"ga_secret": "Google（谷歌）重置代码",
	"verify_ga_intro": "这是Google（谷歌）验证系统的重置代码。如终端的故障，丢失，根据手机种类的变更等，必须要用到此重置代码。由于该代码仅显示一次，请您务必用书写方式或拍照方式，或离线的方式，进行妥善保管。",
	"verify": "验证",
	"scan_qr_code_intro": "Please use Google Authenticator app to scan this QR Code.",
	"enter_verify_ga_intro": "请在以下文字框里输入Google（谷歌）应用生成的6位数验证码。",
	"user_management": "User Management",
	"reset_user": "Reset User",
	"first_wallet_store": "First Wallet Store",
	"database_backup": "Database backup",
	"news": "News",
	"mail_box": "Mailbox",
	"first_name": "First Name",
	"middle_name": "Middle Name",
	"last_name": "Last Name",
	"ga_verified": "GA Verified",
	"admin_mode": "Admin mode",
	"user_mode": "User mode",
	"control": "Control",
	"important": "IMPORTANT",
	"reset_user_confirm": "Please notice, this action will RESET the password of users selected, then send an email contains the login details.",
	"summary": "Summary",
	"search_user": "Search user...",
	"unblock_this_wallet": "Unblock this wallet",
	"user_info": "User info",
	"lock_user": "Lock user",
	"unlock_user": "Unlock user",
	"unblock_all_wallet": "Unblock all wallets",
	"all_wallets": "All wallets",
	"reset_user_and_resend_email": "Reset user and resend email",
	"reset_user_intro": "This function will reset password and resend user login details (email, User Code, new password and first wallet info)",
	"reset_and_send_email": "Reset & Send email",
	"manual_backup": "Manual backup",
	"restore_database": "Restore database",
	"select_a_back_up_file": "Select a back up file",
	"restore_now": "Restore now",
	"run_backup_now": "Run backup now",
	"backup_now": "Back up now",
	"next_auto_backup_in": "Next auto back up in ",
	"file_name": "File name",
	"size": "Size",
	"restore": "Restore",
	"restore_database_intro": "Are your sure? This action can not undo!",
	"delete_back_up_file_confirm": "You are deleting the database backup file! Are you sure?",
	"display_secret_key": "显示密钥",
	"select_a_wallet": "请选择电子钱包",
	"secret_key": "秘密キー密钥",
	"please_enter_your_password_to_continue": "请输入登录密码",
	"display": "显示密钥",
	"exchange": "交换",
	"go_to_settings": "Go to settings",
	"create_account": "创建电子钱包",
	"payment": "支付",
	"change_trust": "更改TRUST状态",
	"profile": "Profile",
	"user_not_have_wallet": "This user does not have any wallet.",
	"wallet_name": "Wallet name",
	"wallet_balances": "Wallet balances",
	"asset": "Asset",
	"total": "Total",
	"wallets": "wallets",
	"created_at": "Created at",
	"title": "Title",
	"content": "Content",
	"edit": "Edit",
	"create_news": "Create news",
	"create": "Create",
	"delete_news_confirm": "You are deleting the news! Are you sure?",
	"login_by_robin_account": "Login by Robin account",
	"edit_user": "Edit user",
	"edit_user_info": "Edit user info",
	"firstname": "First Name",
	"middlename": "Middle Name (optional)",
	"lastname": "Last Name",
	"reset_tfa": "设置双重身份证",
	"charge_trust": "更改TRUST状态",
	"trust_asset": "Trust asset",
	"issue_wallet": "Issue wallet",
	"time": "Time",
	"funder_wallet": "Funder wallet",
	"new_wallet": "New wallet",
	"sender_wallet": "Sender wallet",
	"receiver_wallet": "Receiver wallet",
	"register": "REGISTER",
	"id_member_free": "FREE成员的ID",
	"address_email_create_robin_sns": "ROBIN SNS注册用电子邮箱地址",
	"transaction_status": "Transaction status",
	"rename_wallet": "Rename wallet",
	"please_enter_keyword": "Please enter keyword...",
	"edit_email_of_user": "Edit email of user",
	"high_permission_intro": "该权限包含了以下事项：SIGNER的添加，更新，删除",
	"med_permission_intro": "该权限包含以下事项：货币 代币 的发送，代币的创建，TRUSTED和解除",
	"low_permission_intro": "该权限包含了以下事项：从其它用户申请TRUSTED申请的允许",
	"signatures": "Signatures",
	"weight": "Weight",
	"master_key": "Master key",
	"signer": "Signer",
	"payment_and_trust": "Payment and trust",
	"allow_trust_permision": "Allow trust permision",
	"enter_private_keys": "请输入密钥",
	"please_enter_the_secret_keys": "Please enter the secret keys",
	"generate": "Generate",
	"save_keypairs_intro": "以下的三个 key pair务必保存好。此key pair 仅显示一次。无法再次显示，也无法再次发行。如果保管不妥善，一旦发生丢失，被盗，会损失或电子钱包永久被冻结。",
	"keypairs": "keypairs",
	"make_sure_you_backed_up_keypairs": "请务必知悉key pai的密钥一旦发生丢失，电子钱包内的货币或代币无法转移，永远被冻结。务必理解。",
	"download_csv": "DOWNLOAD CSV",
	"setup_now": "马上设置！",
	"setup_wallet": "电子钱包设置",
	"previous": "返回",
	"next": "下一",
	"edit_email": "Edit email",
	"please_provide_your_email_address": "请输入电子邮箱地址。我们将发送一封含有重置密码的秘密代码的邮件给您。",
	"send_code_to_user": "Send code to user",
	"i_already_have_code": "已接收到代码",
	"code": "重置密码",
	"reset_password": "重置密码",
	"success": "SUCCESS",
	"failure": "FAILURE",
	"transaction_detail_status": "Transaction detail status",
	"transaction_fee": "Transaction fee",
	"number_of_operation": "Number of operations",
	"admin_permission": "管理者权限",
	"admin_detail_permisstion": "该权限包含了以下事项：SIGNER的添加，更新，删除",
	"payment_permisstion": "者権限结算，TRUSTED，发行",
	"payment_detail_permisstion": "该权限包含以下事项：货币 代币 的发送，代币的创建，TRUSTED和解除",
	"trust_permission": "TRUSTED允许权限",
	"trust_detail_permission": "该权限包含了以下事项：从其它用户申请TRUSTED申请的允许",
	"Submit": "Submit",
	"signer_address": "Signer address",
	"all": "All",
	"view": "View",
	"inactive": "inactive",
	"blocked": "blocked",
	"signed": "signed",
	"Signed": "Signed",
	"sign_submit": "Sign submit",
	"signature": "Signature",
	"require_permission": "Require permission",
	"total_signed_weight": "Total signed weight",
	"created_by": "Created by",
	"weight_required": "Weight required",
	"set_options": "setOptions",
	"is_sign": "Is signed",
	"change_setting": "Change setting",
	"confirm_signer": "Confirm signer",
	"signer_transaction": "You will sign this transaction by",
	"no_data_to_show": "No data to show",
	"source_wallet": "Source wallet",
	"master_weight": "Master weight",
	"allower_trust": "Allower trust",
	"payment_trust": "Payment trust",
	"admin_": "Admin",
	"weight_": "Weight",
	"property_": "Property",
	"action_": "Action",
	"asset_": "Asset",
	"Issuer": "Issuer",
	"current_wallet": "Current Wallet",
	"update_thresholds":"Update Thresholds",
	"add_signer": "Add Signer",
	"maintenance_mode": "Maintenance mode",
	"maintenance_mode_app": "Maintenance mode application",
	"maintenance_text_1": ":( Oops!",
	"maintenance_text_2": "Temporarily down for maintenance.",
	"maintenance_text_3": "Sorry for the inconvenience but we’re performing some maintenance at the moment. We’ll be back online shortly!",
	"maintenance_text_4": "Triple Wallet",
	"view_all": "View all",
	"register_intro": "Please register with FREE member ID.",
	"login_intro": "Please log in with your e-mail address.",
	"redirect_to_register_intro": "Register with FREE member ID",
	"all_backups": "All backups",
	"transaction_fail": "Transaction Failure",
	"operations": "operations",
	"transaction_id": "Transaction ID",
	"change_background":"Change background",
	"required_size":"Required size: 100Kb ~ 1Mb",
	"update":"Update",
	"file_not_valid": "File is not valid (currently ",
	"card_charge_button": "Send",
	"triple_id":"Triple ID",
	"gbt_balance":"GBT Balance",
	"top_up_amount":"Top Up Amount",
	"amount_can_charge": "Amount can charge",
	"unlimited": "Unlimited",
	"charge_gbt_quantity": "GBT total quantity",
	"charge_desired_amount": "Reflected quantity",
	"choose_wallet": "Choose wallet",
	"generate_keypairs": "Generate keypairs",
	"finish": "Finish",
	"upload_csv": "Upload CSV file",
	"import_asset": "Import asset",
	"search_asset": "Search asset",
	"import_": "Import",
	"notification_import_csv": "Success: %s/%s have been imported!",
	"notification": "Notification",
	"asset_management": "Asset management",
	"remove_": "Remove",
	"are_you_sure_remove_this_asset?": "Are you sure remove this asset?",
	"confirm_": "Confirm",
	"detail_asset": "Detail asset",
	"user_email": "User email",
	"capital_": "Capital",
	"decimalPlace_": "Decimal Place",
	"ratio_": "Ration",
	"fee_": "Fee",
	"ok": "OK",
	"not_allowed": "Not allowed",
	"no_asset_": "No asset",
	"asset_name_not_allow_special_character": "Asset name is not allow special character",
	"op_pass": "OP Pass",
	"op_pass_verification": "OP Pass verification",
	"select_system_to_transfer": "选择卡",
	"ezpay_select_application_id_intro": "请选择已注册完毕的卡",
	"application_with_card_number": "注册卡号",
	"back": "返回",
	"select_an_application_id": "注册卡号",
	"enter_your_sgp_password": "请输入SGP交易所密码",
	"enter_authentate_code_to_proceed": "输入SGP交易所设置的双重身份验证码",
	"enable_top_up": "Enable top up",
	"request_code": "Request code",
	"title_name": "Title name",
	"cancel_request_code_confirm": "You are cancelling this request code, all of USDT amount you paid for this request will be lost. This action can not be undo. You still want to continue?",
	"cancel_request": "Cancel request",
	"repeat_order": "Repeat order",
	"12_months_repeat_order": "12 months repeat order",
	"number_of_package": "Number of package",
	"total_amount": "Total amount",
	"enter_number_of_package": "Enter number of package",
	"purchase_a_repeat_order_code": "Purchase a repeat order code",
	"delivery_at_the_same_time":"Delivery at the same time for 12 months %s %s(Free shipping)",
	"monthly_delivery_for_12_months":"Monthly delivery for 12 months %s %s (%s + %s x %s shipping fee)",
	"choose_the_way_to_ship": "Choose the way to ship",

	"NETWORK_ERROR": "网络无法连接",
	"VALIDATION_ERROR": "检测错误！",
	"EMAIL_EXISTED_ERROR": "此电子邮箱地址已被使用",
	"SYSTEM_ERROR": "Sorry, something went wrong there. Try again.",
	"LOGIN_INCORRECT_ERROR": "您输入的电子邮箱地址或密码不正确",
	"ACCOUNT_LOCKED": "为了您的账户安全，暂时被封锁。请您联系管理员",
	"PHONE_NUMBER_ERROR": "您输入的电话号码不正确",
	"VERIFICATION_ERROR": "您输入的代码不正确",
	"ADDRESS_CONFLIT_ERROR": "该电子钱包地址不存在",
	"NO_WALLET_ERROR": "您还未选择发送货币或代币的电子钱包",
	"INPUT_ERROR": "您输入的数量不正确。",
	"NOT_FOUND_ERROR": "找不到资源",
	"USER_STATE_ERROR": "用户处于无效状态",
	"TOKEN_INVALID_ERROR": "用户的代币无效",
	"VERIFICATION_REQUIRED_ERROR": "使用该功能，必须进行设置双重身份验证。",
	"WRONG_PASSWORD_ERROR": "密码不正确",
	"ISSUER_EXISTED_ERROR": "该发行者已存在",
	"WALLET_UNDERFUNDED": "余额不足",
	"INVALID_INPUT_ERROR": "文字被输入了无效的编码",
	"MALFORMED_REQUEST_ERROR": "请求无效",
	"NOT_ISSUER_ERROR": "该用户不是发行者",
	"NO_ACTION_ERROR": "您输入的名字搜索不到",
	"EXECUTION_HALTED_ERROR": "执行因错误而停止",
	"AMOUNT_REQUIRE_NUMBER": "数量必须是数字",
	"NOT_FOUND_WALLET": "搜索不到电子钱包",
	"WALLET_INACTIVE": "该电子钱包无法使用。",
	"WALLET_BLOCK": "电子钱包被封锁",
	"NOT_FOUND_DESTINATION_ACCOUNT": "对方的账户不存在",
	"TRANSACTION_ERROR": "取消错误",
	"NOT_POSSESSED_ERROR": "您要求的resource不存在",
	"EMAIL_NOT_VERIFIED_ERROR": "用户还未被验证",
	"OP_LOW_RESERVE": "运营-滴储备",
	"TFA_REQUIRE_ENABLED": "必须双重身份验证",
	"QUERY_DB_ERR": "数据库查询错误",
	"ASSET_EXISTED_ERROR": "此货币，代币名称已被使用。",
	"RESOURCE_NOT_FOUND": "搜索不到resource",
	"ASSET_NOT_FOUND": "搜索不到不到货币，代币",
	"HOLDING_WALLET_ERROR": "创建代币的电子钱包无法直接发行代币的",
	"TX_INSUFFICIENT_BALANCE": "您的电子钱包持有的资金不足",
	"REQUIRE_SUPER_ADMIN_ROLE": "管理者必须要ROLE ",
	"PRICE_AND_CURRENCY_NOT_VALID": "价格和货币无法使用",
	"CURRENCY_EXIST": "该货币不存在",
	"EMAIL_NOT_EXISTED_ERROR": "此电子邮箱地址的用户不存在。",
	"DUPLICATED_ICO_REQUEST": "各ICOパケットに対して1つのリクエストしか持てません每个ICO包只允许一个要求",
	"ICO_RESERVATION_NOT_FOUND": "リクエストされたICO予約が見つかりません搜索不到您预约请求的ICO",
	"ICO_PLAN_EMPTY_ERROR": "空のICO計画を購入することはできません无法购买空的ICO计划",
	"ICO_NOT_READY_ERROR": "ICOはまだ利用できませ要求されたICO予約が見つかりませんICO无法使用，请求的ICO不存在",
	"REQUIRE_SET_PHONE_NUMBER": "電話番号を設定してください请设置电话号码",
	"YOU_ARE_NOT_ALLOWED": "您无法执行此操作",
	"QUERY_S3_ERROR": "Amazon　S3にアクセスする時、エラーが発生しました访问Amazon S3时候，发生了错误",
	"WRONG_PRIVATE_KEY_ERROR": "您输入的个人密钥不正确",
	"ASSET_EXIST": "您输入的代币名称已被使用",
	"DONT_OWN_WALLET": "您还未持有此电子钱包",
	"INCORRECT_PASSWORD": "密码不正确",
	"INCORRECT_PASSWORD_LENGTH": "Password's length must more than 6 characters and less than 200 characters",
	"CODE_EXPIRED": "确认代码已过期",
	"CAN_NOT_UPDATE_PACKET": "ICO正在操作中，无法编辑PAXKET",
	"WRONG_ASSET_TYPE": "提供したコイン・トークンでオファーを作成できません",
	"WRONG_PARAMS_VALUE": "媒介変数が無効です",
	"ASSET_NOT_TRUSTED": "您要购买货币或代币前必须先TRUSTED货币或代币。",
	"NOT_MERCHANT_ERROR": "ユーザーはマーチャントではありません",
	"MERCHANT_EXIST": "ユーザーは既にマーチャントでした",
	"NOT_FOUND_MERCHANT_STORE": "マーチャントストアを見つかりません",
	"REQUEST_COOLDOWN": "请稍后再操作",
	"WALLET_EXISTED": "该电子钱包不存在",
	"ICO_IS_RUNNING": "ICOが実行されています。 この操作を実行する前に無効にしてください。",
	"ICO_PACKET_CONTAIN_RESERVATION": "予約を含むパケットを削除できません",
	"NOT_WEBSOCKET_CONNECTION": "このリクエストはウェブソケット接続ではありません",
	"ISSUER_NOT_APPROVE": "发行者还未被验证",
	"MERCHANT_NOT_APPROVE": "マーチャントが承認されません",
	"SOURCE_WALLET_ISSUED": "您选择的电子钱包为发行货币或代币用电子钱包无法作为被吸收的电子钱包合并",
	"SOURCE_WALLET_USED": "コイン・トークンを吸収するウォレットはマーチャントストアで使用されています",
	"MAXIMUM_AMOUNT_EXCEEDED": "入力したICOの数量が許可されていません",
	"WRONG_CODE": "验证代码不正确",
	"ASSET_ALREADY_TRUSTED": "您选择的货币 代币 已TRUSTED",
	"WALLET_STATE_ERROR": "该电子钱包未被认可",
	"DIRTY_ERROR": "已使用的资源无法删除",
	"ISSUING_PROCESS_REQUIRED": "この操作は発行手順を通じて実行する必要があります该操作必须按照发行的顺序进行操作",
	"ISSUING_WALLET_NOT_ALLOWED": "发行的电子钱包无法进行实际操作",
	"INVALID_SECKET_KEY": "密钥不正确",
	"HOLDING_FEE_WALLET": "持有手续费的电子钱包",
	"ERROR_OCCURRED": "发生错误",
	"CANT_DELETE_BACKUP_FILE": "备用的文件无法删除",
	"BACKUP_ERROR": "备用错误！",
	"LOGIN_AGAIN": "请重新登录",
	"ERROR_WHEN_RESTORE": "电子钱包导入错误！",
	"CANT_GET_CURRENCY": "无法取得货币",
	"UPDATE_SUCCESS": "已更新",
	"CANT_UPDATE_ICO_CONTROL": "ICO制御を更新できません",
	"CANT_ADD_NEW_CURRENCY": "无法添加货币",
	"INPUT_ALL_INFORMATION": "请输入所有的信息",
	"NEED_MORE_ARMCOIN_IN_WALLET_TRUST_NEW_ASSET": "如要TRUSTED货币或代币，电子钱包里必须要有足够的RIA币",
	"CANT_CREATE_OFFER": "オファーを作成できません",
	"ESTABLISH_REALTIME_CONNオファーを作成できませんECTION": "建立实时连接，请重新加载页面再重试。",
	"CONNECTION_ATTEMPT_FAILED": "连接失败",
	"CONNECTION_TO_SERVER_INTERRUPTED": "サーバーへの接続が中断されました服务器的连接被中断。",
	"SELL_BUY_SAME_ASSETS": "请不要重复的买卖同一个货币或代币",
	"SESSION_EXPIRED": "会议已结束，请再重新登录",
	"CANT_GET_TRUST_ASSET_REQUESTS": "货币，代币无法获得TRUSTED的请求",
	"ALLOW_TRUST_ERROR": "TRUSTED错误",
	"CONFIRM_REMOVE_CONTACT": "请问您要删除收信者的地址码？",
	"CONTACT_EXIST": "地址被添加到地址簿",
	"ISSUING_WALLET_CANT_HOLDING_FEE_WALLET": "无法同时选择，代币电子钱包和储存手续费的的电子钱包",
	"CREATE_ASSET_WITHOUT_LOGO": "可以先不设置图片先申请代币，申请代币后再添加图片。",
	"CANT_ISSUE_MONEY_TO_ISSUING_WALLET": "创建货币或代币的电子钱包无法进行发行货币或代币。",
	"ISSUING_WALLET_OR_HOLDING_WALLET_NOT_ENOUGH_ARM": "其它发行的电子钱包或保留的电子钱包没有足够的RIA来执行此操作。",
	"NEED_MORE_MONEY_HOLDING_WALLET_TRUST_NEW_ASSET": "为了TRUSTED新的货币或代币的，储存的电子钱包里必须有足够的RIA币。",
	"NEW_STORE_CREATED": "已创建了新用户的店。",
	"CANT_UPLOAD_STORE_LOGO": "请上传店铺的LOGO",
	"CANT_MERGE_WALLET": "电子钱包无法合并",
	"MERGE_WALLET_SUCCESS": "电子钱包合并完成",
	"RENEW_ERROR": "更新错误",
	"SAVE_ERROR": "保存错误",
	"FUNDING_WALLET_NOT_ENOUGH_ARM": "进行操作发送货币或代币的电子钱包里没有足够的RIA币。",
	"RESTORE_ERROR": "电子钱包导入错误",
	"GENERATE_ERROR": "发生错误",
	"CANT_SEND_MONEY": "无法发送货币 代币",
	"WALLET_UNBLOCK": "电子钱包已被解锁",
	"NATIVE_ASSET_TRUST_BY_DEFAULT": "ネイティブコイン・トークンはデフォルトでトラストされています",
	"ACTIVE_WALLET_SUCCESS": "电子钱包已经可以使用",
	"CANT_RENAME_WALLET": "电子钱包名称无法修改",
	"ADDRESS_OR_TRANSACTION_INCORRECT": "电子钱包的地址不正确",
	"CANT_FETCH_ICO_REALTIME_STATUS": "ICOリアルタイムステータスを取得できません",
	"NEW_MAIL_SENT": "新邮件已发出",
	"CANT_REQUEST_NEW_VERIFICATION_MAIL": "新邮件已被发出",
	"WALLET_NOT_TRUST_ASSET": "电子钱包对该代币未进行TRUSTED",
	"ASSET_NOT_BELONG_TO_ADMIN_WALLET": "This asset is not belong to admin wallet",
	"REQUIRE_SUB_SYSTEM_ADMIN": "Require sub system admin",
	"CREATE_ACCOUNT_MALFORMED": "Invalid destination",
	"CREATE_ACCOUNT_UNDERFUNDED": "Not enough funds in source account",
	"CREATE_ACCOUNT_LOW_RESERVE": "Would create an account below the min reserve",
	"CREATE_ACCOUNT_ALREADY_EXIST": "此电子钱包名称已被使用",
	"PAYMENT_MALFORMED": "Payment bad input",
	"PAYMENT_UNDERFUNDED": "Not enough funds in source account",
	"PAYMENT_SRC_NO_TRUST": "No trust line on source account",
	"PAYMENT_SRC_NOT_AUTHORIZED": "Source not authorized to transfer",
	"PAYMENT_NO_DESTINATION": "Destination account does not exist",
	"PAYMENT_NO_TRUST": "收件者的电子钱包未对该代币进行TRUSTED",
	"PAYMENT_NOT_AUTHORIZED": "Destination not authorized to hold asset",
	"PAYMENT_LINE_FULL": "Destination would go above their limit",
	"PAYMENT_NO_ISSUER": "Missing issuer on asset",
	"PATH_PAYMENT_MALFORMED": "Path payment bad input",
	"PATH_PAYMENT_UNDERFUNDED": "Not enough funds in source account",
	"PATH_PAYMENT_SRC_NO_TRUST": "No trust line on source account",
	"PATH_PAYMENT_SRC_NOT_AUTHORIZED": "Source not authorized to transfer",
	"PATH_PAYMENT_NO_DESTINATION": "Destination account does not exist",
	"PATH_PAYMENT_NO_TRUST": "Destination missing a trust line for asset",
	"PATH_PAYMENT_NOT_AUTHORIZED": "Destination not authorized to hold asset",
	"PATH_PAYMENT_LINE_FULL": "Destination would go above their limit",
	"PATH_PAYMENT_NO_ISSUER": "Missing issuer on one asset",
	"PATH_PAYMENT_TOO_FEW_OFFERS": "Not enough offers to satisfy path",
	"PATH_PAYMENT_OFFER_CROSS_SELF": "Would cross one of its own offers",
	"PATH_PAYMENT_OVER_SENDMAX": "Could not satisfy sendmax",
	"MANAGE_OFFER_MALFORMED": "Generated offer would be invalid",
	"MANAGE_OFFER_SELL_NO_TRUST": "No trust line for what we're selling",
	"MANAGE_OFFER_BUY_NO_TRUST": "No trust line for what we're buying",
	"MANAGE_OFFER_SELL_NOT_AUTHORIZED": "Not authorized to sell",
	"MANAGE_OFFER_BUY_NOT_AUTHORIZED": "Not authorized to buy",
	"MANAGE_OFFER_LINE_FULL": "Can't receive more of what it's buying",
	"MANAGE_OFFER_UNDERFUNDED": "Doesn't hold what it's trying to sell",
	"MANAGE_OFFER_CROSS_SELF": "Doesn't hold what it's trying to sell",
	"MANAGE_OFFER_SELL_NO_ISSUER": "No issuer for what we're selling",
	"MANAGE_OFFER_BUY_NO_ISSUER": "No issuer for what we're buying",
	"MANAGE_OFFER_NOT_FOUND": "OfferID does not match an existing offer",
	"MANAGE_OFFER_LOW_RESERVE": "Not enough funds to create a new Offer",
	"SET_OPTIONS_SUCCESS": "Success",
	"SET_OPTIONS_LOW_RESERVE": "Not enough funds to add a signer",
	"SET_OPTIONS_TOO_MANY_SIGNERS": "Max number of signers already reached",
	"SET_OPTIONS_BAD_FLAGS": "Invalid combination of clear/set flags",
	"SET_OPTIONS_INVALID_INFLATION": "Inflation account does not exist",
	"SET_OPTIONS_CANT_CHANGE": "Can no longer change this option",
	"SET_OPTIONS_UNKNOWN_FLAG": "Can't set an unknown flag",
	"SET_OPTIONS_THRESHOLD_OUT_OF_RANGE": "Bad value for weight/threshold",
	"SET_OPTIONS_BAD_SIGNER": "Signer cannot be master key",
	"SET_OPTIONS_INVALID_HOME_DOMAIN": "Malformed home domain",
	"CHANGE_TRUST_SUCCESS": "Success",
	"CHANGE_TRUST_MALFORMED": "Bad input",
	"CHANGE_TRUST_NO_ISSUER": "Could not find issuer",
	"CHANGE_TRUST_INVALID_LIMIT": "Cannot drop limit below balance",
	"CHANGE_TRUST_LOW_RESERVE": "Not enough funds to create a new trust line,",
	"CHANGE_TRUST_SELF_NOT_ALLOWED": "Trusting self is not allowed",
	"ALLOW_TRUST_SUCCESS": "Success",
	"ALLOW_TRUST_MALFORMED": "Asset is not ASSET_TYPE_ALPHA NUM",
	"ALLOW_TRUST_NO_TRUST_LINE": "Trustor does not have a trustline",
	"ALLOW_TRUST_TRUST_NOT_REQUIRED": "Source account does not require trust",
	"ALLOW_TRUST_CANT_REVOKE": "Source account can't revoke trust,",
	"ALLOW_TRUST_SELF_NOT_ALLOWED": "Trusting self is not allowed",
	"ACCOUNT_MERGE_SUCCESS": "Success",
	"ACCOUNT_MERGE_MALFORMED": "Can't merge onto itself",
	"ACCOUNT_MERGE_NO_ACCOUNT": "Destination does not exist",
	"ACCOUNT_MERGE_IMMUTABLE_SET": "Source account has AUTH_IMMUTABLE set",
	"ACCOUNT_MERGE_HAS_SUB_ENTRIES": "Account has trust lines/offers",
	"INFLATION_SUCCESS": "Success",
	"INFLATION_NOT_TIME": "Failure",
	"MANAGE_DATA_SUCCESS": "Success",
	"MANAGE_DATA_NOT_SUPPORTED_YET": "The network hasn't moved to this protocol change yet",
	"MANAGE_DATA_NAME_NOT_FOUND": "Trying to remove a Data Entry that isn't there",
	"MANAGE_DATA_LOW_RESERVE": "Not enough funds to create a new Data Entry",
	"MANAGE_DATA_INVALID_NAME": "Name not a valid string",
	"OP_INNER": "Inner object result is valid",
	"OP_BAD_AUTH": "Too few valid signatures / wrong network",
	"OP_NO_ACCOUNT": "Source account was not found",
	"TX_SUCCESS": "Success",
	"TX_FAILED": "Failed",
	"TX_TOO_EARLY": "Ledger closeTime before minTime",
	"TX_TOO_LATE": "Ledger closeTime after maxTime",
	"TX_MISSING_OPERATION": "No operation was specified",
	"TX_BAD_SEQ": "Sequence number does not match source account",
	"TX_BAD_AUTH": "Too few valid signatures / wrong network",
	"TX_NO_ACCOUNT": "Source account not found",
	"TX_INSUFFICIENT_FEE": "Fee is too small",
	"TX_BAD_AUTH_EXTRA": "Unused signatures attached to transaction",
	"TX_INTERNAL_ERROR": "发生不明的错误",
	"NOT_ENOUGH_AMOUNT": "数量不足",
	"DUPPLICATE_SIGNNER_ADDRESS": "此SIGNER已被设置",
	"AUTHORIZATION_NONE": "权限：无",
	"AUTHORIZATION_REQUIRED": "TRUSTED允许/拒绝 权限：有",
	"SENDING_TO_INACTIVE_WALLET": "此电子钱包未激活，未了激活此电子钱包，必须从别的电子钱包发送最低20RIA币到此钱包。",
	"CANNOT_SEND_TOKEN_TO_ITSELF": "同一个个电子钱包地址无法给同个电子钱包地址发送货币或代币",
	"ACCOUNT_ALREADY_EXIST": "此账户已存在",
	"OP_UNDERFUNDED": "数量不足",
	"OP_SIGNER_CURRENT": "请不要更改所有权的重量",
	"THE_USER_HAS_NOT_BEEN_VERIFIED_IDENTITY_CARD": "该用户的身份证明还未被审核",
	"NOT_FOUND_USER": "搜索不到该用户",
	"INVALID_WALLET_ADDRESS": "该电子钱包地址不正确",
	"TX_UNSAFE_SETTING": "SIGNER的权限值的合计是 管理权限值 必须大于结算权限值和TRUSTED允许权限值。又或者管理权限值和结算权限值和TRUSTED允许权限值为平等。结算权限值和TRUSTED允许权限值 必须小于管理权限值。",
	"WEIGHT_REQUIRED_INTEGER": "重量必须是整数的。",
	"SIGNER_REQUIRED": "共享电子钱包的设置是，必须是MASTERKEY者和一名SIGNER以上。",
	"SIGNER_WALLET_INVALID": "电子钱包搜索不到或是无效或每激活，已被封锁。",
	"SERVER_IS_MAINTENANCE": "Server is maintenance!",
	"NOT_ENOUGH_PERMISSION": "You have not enough permission.",
	"PASSPORT_NUMBER_NOT_FOUND": "Passport number not found",
	"AMOUNT_TOP_UP_EXCEED": "Amount top up exceed specifed amount",

	"PASSWORD_REQUIRED": "您输入的密码不正确",
	"ADDRESS_REQUIRED": "请输入电子钱包地址",
	"NAME_REQUIRED": "请输入电子钱包名称",
	"NAME_INVALID": "请输入有效的电子钱包名称",
	"ADDRESS_INVALID": "请输入有效的电子钱包地址",
	"STARTING_BALANCE_REQUIRED": "请输入有效的电子初期资金",
	"DESTINATION_REQUIRED": "请输入接收方电子钱包地址",
	"DESTINATION_INVALID": "请输入接收方电子钱包地址",
	"AMOUNT_REQUIRED": "请输入金额",
	"AMOUNT_INVALID": "请输入有效的金额",
	"MEMO_TOO_LONG": "Memo is to long",
	"PRIVATE_KEY_REQUIRED": "请输入密钥",
	"PRIVATE_KEY_INVALID": "请输入有效的密钥",
	"NEW_PASSWORD_REQUIRED": "请输入新密码",
	"CONFIRM_PASSWORD_REQUIRED": "请输入确认新密码",
	"NEW_PASSWORD_MIN_LENGTH": "New password at least 6 characters",
	"PASSWORD_NOT_MATCH": "Password and confirm password does not match",
	"TFA_CODE_REQUIRED": "请在以下文字框里输入Google（谷歌）应用生成的6位数验证码。",
	"TFA_CODE_MIN_LENGTH": "请输入6位数双重身份验证码",
	"RESET_CODE_REQUIRED": "Reset code is required",
	"CODE_REQUIRED": "TFA Code is required",
	"EMAIL_REQUIRED": "请输入电子邮箱地址",
	"EMAIL_INVALID": "请输入有效的电子邮箱地址",
	"FIRST_NAME_REQUIRED":"First Name is required",
	"LAST_NAME_REQUIRED":"Last Name is required",
	"NAME_MUST_LESS_THAN":"Name must less than %s characters",
	"WEIGHT_REQUIRED": "Weight is required",
	"WEIGHT_INVALID": "Weight is invalid",
	"AMOUNT_EXCEEDS_GBT_AMOUNT_AVAILABLE": "Amount exceeds GBT amount available",
	"AMOUNT_MUST_MORE_THAN_MIN_GBT_AMOUNT": "Amount must more than Min GBT amount",
	"AMOUNT_MUST_LESS_THAN_MAX_GBT_AMOUNT": "Amount must less than Max GBT amount",
	"AMOUNT_MUST_BE_A_NATURAL_NUMBER": "Amount must be a natural number",
	"SEARCH_ASSET_FIELD_IS_REQUIRE": "You must fill in search asset field.",
	"OP_PASS_REQUIRED": "OP Pass is required",
	"OP_PASS_MIN_LENGTH": "OP Pass at least 6 characters",
	"INCORRECT_OP_PASSWORD": "OP Password is not correct",
	"INCORRECT_OP_PASSWORD_LENGTH": "OP Password's length must more than 6 characters and less than 200 characters",
	"CANNOT_ACTIVE_MERGED_WALLET": "Cannot active a merged wallet",
	"USER_IS_NOT_ENOUGH_CONDITION_TOP_UP": "User is not enough condition for top up!",
	"CANNOT_GET_SGP_BALANCE_INFO": "Can't get sgp balance info!",
	"CANNOT_GET_OTC_CURRENCY_INFO": "Can't get OTC currency info!",
	"CANNOT_GET_INFO_FOR_TOP_UP": "Can't get info for top up!",
	"CANNOT_LOGIN_TO_SGP_ERROR": "Can't login to SGP!",
	"CANNOT_OTC_EXCHANGE_IN_SGP_ERROR": "Can't OTC Exchange in SGP!",
	"CANNOT_TOP_UP_ERROR": "Can't top up in SGP!",
	"WRONG_SGP_CODE": "The SGP code provided is not correct!",
	"WRONG_SGP_PASSWORD": "The SGP password is not correct!",
	"SGP_ACCOUNT_DISABLED": "The SGP Account is disabled!",
	"SGP_ACCOUNT_BLOCKED": "The SGP Account is blocked!",
	"NOT_LOGGED_IN_SGP": "Please login to SGP!",
	"SGP_ERR_AMOUNT_OTC_LESS_THAN_MINIMUM_AMOUNT": "Amount need OTC must be greater than minimum amount!",
	"SGP_ERR_NOT_ENOUGH_AMOUNT_FOR_OTC_EXCHANGE": "Source account balance not enough for OTC!",
	"SGP_ERR_AMOUNT_TOP_UP_LESS_THAN_MINIMUM_AMOUNT": "Amount top up must higher than 100!",
	"SGP_ERR_SOMETHING_WRONG": "Something is wrong, plesae check information and do it again!",
	"SGP_ERR_NOT_REQUIRE_TFA": "Require active twofactor in SGP Exchange!",
	"AMOUNT_IS_INVALID": "Amount is invalid' ",
	"MAX_REQUEST_CODE_OF_USER_ERROR": "You has been locked by security because request use product code incorrectly many times in a row . Please wait for cooldown.!" ,
	"REPEAT_ORDER_CODE_HAS_BEEN_USED": "Repeat order code has been used!",
	"REPEAT_ORDER_CODE_HAS_BEEN_REJECTED": "Repeat order code has been rejected!",
	"REPEAT_ORDER_CODE_IS_NOT_EXISTED": "Repeat order code isn\'t existed!",
	"REPEAT_ORDER_CODE_IS_DISABLED": "Repeat order code feature disabled!",
	"EXCEEDS_THE_MAXIMUM_PACKAGES_ALLOW": "Exceeds the maximum packages allow !",
	"REPEAT_ORDER_CODE_IS_NOT_MATCH": "Repeat order code isn\'t match!",
	"SGP_ERR_AMOUNT_TOP_UP_GREATER_THAN_MAXIMUM_AMOUNT": 'Amount need OTC must be less than maximum amount!',
	"ADDRESS_ADDED_SUCCESSFULLY": "Address added successfully",
	"ADDRESS_EDITED_SUCCESSFULLY": "Address book edited successfully",
	"ADDRESS_DELETED_SUCCESSFULLY": "Address deleted successfully",
	"TRANSFER_MONEY_SUCCESSFULLY": "转账",
	"TRUST_ASSET_SUCCESSFULLY": "代币已被TRUST",
	"WALLET_SAVED_SUCCESSFULLY": "Wallet saved successfully",
	"WALLET_CREATED_SUCCESSFULLY": "电子钱包已创建！",
	"WALLET_UNBLOCKED_SUCCESSFULLY": "电子钱包已解封",
	"TFA_LOGIN_REQUIRE_CHANGE_SUCCESSFULLY": "设置已更改",
	"PASSWORD_CHANGE_SUCCESSFULLY": "密码已更改。",
	"GA_RESET_SUCCESSFULLY": "GA reset successfully",
	"GA_VERIFIED_SUCCESSFULLY": "Google（谷歌）双重身份验证已确认。",
	"LOCK_USER_SUCCESSFULLY": "Lock user successfully",
	"UNLOCK_USER_SUCCESSFULLY": "Unlock user successfully",
	"UNBLOCK_USER_WALLET_SUCCESSFULLY": "Unblock user wallet successfully",
	"USER_RESET_SUCCESSFULLY": "User reset successfully",
	"DATABASE_BACKED_UP_SUCCESSFULLY": "Database backed up successfully",
	"BACK_UP_FILE_DELETED_SUCCESSFULLY": "Back up file deleted successfully",
	"DATABASE_RESTORED_SUCCESSFULLY": "Database restored successfully",
	"NEWS_CREATED_SUCCESSFULLY": "News created successfully",
	"NEWS_EDITED_SUCCESSFULLY": "News edited successfully",
	"NEWS_DELETED_SUCCESSFULLY": "News deleted successfully",
	"RENAME_WALLET_SUCCESSFULLY": "Rename wallet successfully",
	"RESET_TFA_SUCCESSFULLY": "设置已更改",
	"EDIT_EMAIL_SUCCESSFULLY": "Edit email successfully",
	"SENT_CODE_SUCCESSFULLY" : "Sent code successfully",
	"RESET_PASSWORD_SUCCESSFULLY": "密码已重置",
	"USER_CREATED_SUCCESSFULLY": "User created successfully",
	"CHANGE_SETTING_SUCCESSFULLY": "Change setting successfully",
	"TRANSACTION_SUBMIT_FAILED": "Transaction submit failed",
	"INVALID_EMAIL": "Your email address is invalid. Please enter a valid address.",
	"CANT_ADD_YOUR_OTHER_WALLET_AS_SIGNER": "Cannot add your other wallet as a signer",
	"SIGN_SUCCESSFULLY": "Sign transaction successfully",
	"CHANGE_BACKGROUND_SUCCESSFULLY": "Change background successfully",
	"op_password": "OP Password",
	"change_op_password_": "Change OP password",
	"reset_op_password_": "Reset OP password",
	"private_key_input_": "Private key input",
	"OP_PASSWORD_REQUIRED": "OP Password is required",
	"NEW_OP_PASSWORD_REQUIRED": "New OP password is required",
	"CONFIRM_OP_PASSWORD_REQUIRED": "Confirm OP password is required",
	"NEW_OP_PASSWORD_MIN_LENGTH": "New OP password at least 6 characters",
	"OP_PASSWORD_NOT_MATCH": "OP Password and confirm OP password does not match",
	"OP_PASSWORD_CHANGE_SUCCESSFULLY": "OP Pasword changed successfully",
	"RESET_OP_PASSWORD_SUCCESSFULLY": "Reset OP Pasword successfully",
	"reset_op_password_attention": "Reset OP password need at least one correct private key. Other wallets will be block if you entered an incorrect private key. Do you want continue? ",
	"main_wallet_private_key_attention": "Please choose your main wallet address and enter it's private key to reset OP password now!",
	"unblock_wallet_attention": "Please enter correct private key with its wallet address in order to unblock your wallets.",
	"confirm_unblock_wallet_": "Confirm unblock wallet",
	"wallet_login": "WALLET Login",
	"OP_PASS_INVALID": "OP Password is invalid",
	"OP_PASS_IS_REQUIRED":  "OP Password is required",
	"INCORRECT_ALL_PRIVATE_KEY": "User need to enter at least one private key",
	"UNBLOCK_WALLET_SUCCESS": "Unblock wallet success",
	"EZPAY_TOP_UP_SUCCESS_1": "您的请求发送成功。",
	"CANCEL_REQUEST_CODE_SUCCESSFULLY": "Cancel request code successfully",

	"close": "关闭",
	"select_wallet": "Select wallet",
	"skip": "Skip",
	"reset": "Reset",
	"attention": "Attention",
	"done": "Done",
	"CANNOT_SEND_TO_MERGED_WALLET": 'Cannot send to merged wallet!',
	"OP_PASS_MUST_CONTAIN_UPPERCASE_CHARACTER": 'The OP Password must contain at least an uppercase character',
	"OP_PASS_MUST_CONTAIN_LOWERCASE_CHARACTER": 'The OP Password must contain at least an lowercase character',
	"OP_PASS_MUST_CONTAIN_DIGITS": 'The OP Password must contain at least a digits',
	"OP_PASS_MUST_CONTAIN_SPECIAL_CHARACTER": 'OP Password must  contain at least a special characters',
	"OP_PASS_EXIST": 'You set OP Password already',
	"OP_PASS_DIFFERENT": 'Password and OP Password must different',
	'setting_op_password_': "Setting OP Password",
	"set_up": "Set up",
	"setting_op_password_intro": "OP pass to increase the security of the system. OP pass will be used to authenticate transactions. From now, every transaction will need OP pass to be executed. Please setting OP pass below.",
	"send_wallet_info": "Send user wallet info",
	"ADMIN_SEND_USER_WALLET_INFORMATION_SUCCESS": "Send email for user wallet information successfully!",
	"confirm_send_user_wallet_information": "Do you want to send email to user with their wallet information ?",
	"OP_PASS_ONLY_CONTAIN_LATIN_CHARACTER": "The OP Password only contains Latin characters!",
	"transfer_to_card": "发送至卡",
	"request_amount": "已发送个数量",
	"current_available_transfer": "Current available transfer (TCT)",
	"need_otc_to_tct": "AGT个数",
	"otc_rate": "OTC比率",
	"otc_fee": "OTC手续费",
	"total_otc_fee": "OTC手续费合计",
	"total_transfer": "发送合计个数",
	"transfer_fee": "发送手续费",
	"available_agt_amount": "AGT个数",
	"available_tct": "TCT个数",
	"available_tct_amount": "TCT个数",
	"in_order": "保留中的枚数",
	"ezpay_note": "注意：请按照以下的顺序进行操作。",
	"note_1": "1/在SGP系统中使用OTC将AGT自动转换为TCT",
	"note_2": "2/将TCT 法送至Ezpay卡。",
	"type_here": "已发送个数量...",
	"you_are_not_enough_condition_for_top_up": "数量不足，无法转送到卡。",
	"request": "确定",
	"ezpay_transfer": "Ezpay Transfer",
	"pending": "Pending",
	"approved": "Approved",
	"reject": "Reject",
	"success_": "Success",
	"otc_amount": "OTC Amount",
	"fee_otc": "Fee OTC",
	"total_topup_amount": "Total Topup Amount",
	"top_up_fee": "Topup Fee",
	"not_allow_otc": "You can't OTC now, so you can only top up using available TCT amount!",
	"OTHER_REQUEST_IS_PROCESSING": "Other request is processing, please try again later!",
	"TOP_UP_IS_DISABLED": "Top Up function is disabled now!",
	"EMAIL_OR_USERCODE_INVALID": "Email or usercode is invalid!",
	"email_or_usercode": "Email or Usercode",
	"usercode": "User code",
	"list_card_is_empty": "List cards from %s is empty!",
	"CANNOT_SEND_DATA_TO_TRIPLE_SYSTEM_ERROR": "Can't send data to TripleSystem!",
	"MIDDLE_SYSTEM_USER_IS_NOT_FOUND": "User is not found in Middle System!",
	"MIDDLE_SYSTEM_CARD_IS_NOT_FOUND": "Card is not found in Middle System!",
	"SGP_ERR_ONLY_LOGIN_ONE_BROWSER": "Your SGP account is being logged in other browser, please try again later!",
	"international": "International",
	"chinese": "Chinese",
	"new": "NEW",
	"used": "USED",
	"rejected": "REJECTED",
	"product_code": "Product Key",
	"user_code": "UserCode",
	"region": "Region",
	"product_code_management": "Product Key Management",
	"search_code": "Search code...",
	"available_agt": "Available AGT :",
	"purchased_agt": "Purchased AGT :",
	"rejected_agt": "Rejected AGT :",
	"confirm_reject_product_code": "Confirm reject product key",
	"enable_product_code": "Enable product key",
	"submit": "Submit",
	"CANNOT_GET_LIST_TITLE_OF_TRIPLE_SYSTEM_ERROR":  "Can't get list titles of Triple System!",
	"PRODUCT_CODE_HAS_BEEN_USED":  "Product key has been used!",
	"PRODUCT_CODE_IS_NOT_MATCH": "Product key isn't match!",
	"PRODUCT_CODE_HAS_BEEN_REJECTED": "Product key has been rejected!",
	"AMOUNT_IS_NOT_MATCH": "Amount isn't match!",
	"TITLE_NOT_EXIST": "Title isn't exist!",
	"PRODUCT_CODE_IS_NOT_EXISTED": "Product key isn't existed!",
	"WRONG_DATA_WHEN_REJECT_PRODUCT_CODE": "Wrong data when reject this product key!",
	"REJECT_PRODUCT_CODE_SUCCESSFULLY": "Reject product key successfully!",
	"CREATE_PRODUCT_CODE_SUCCESSFULLY": "Create product key successfully!",
	"already_used": "Already used",
	"purchased_key": "Purchased Key",
	"create_new_code": "Create new code",
	"choose_type_code": "Choose type code",
	"purchase_a_product_key": "Purchase a product key",
	"wallet_amount": "Wallet amount : ",
	"type_code": "Type code",
	"COPIED": "Copied",
	"no_have_wallet_valid": "Don't have valid wallets!",
	"time_of_statistics": "Time of statistics :",
	"request_product_code_info": "Request create product key information",
	"select_asset_for_create_product_code": "Choose asset do you want pay for create product key:",
	"notify": "Notify",
	"notify_created_product_code": "You have created successfully product key with the information below:",
	"remaining_time_code": "%sd %sh:%sm:%ss",
	"remaining_time": "Remaining time",
	"CREATE_REQUEST_PRODUCT_CODE_SUCCESSFULLY": "Create request product code successfully!",
	"PRODUCT_CODE_IS_DISABLED": "Product code feature disabled!",
	"TRIPLE_PAYMENT_ERROR": "Triple Payment error!",
	"REQUEST_PRODUCT_CODE_IS_NOT_EXISTED": "Request product code isn't existed!",
	"INVALID_CALLBACK_MAC_AUTHORIZATION": "Invalid Callback Mac Authorization!",
	"REQUEST_PRODUCT_CODE_STATUS_ERROR": "Request Product code status is wrong!",
	"MAX_REQUEST_CODE_OF_USER": "Only a maximum of 5 requests create product code are allowed in the new status" ,
	"CANNOT_CANCEL_THIS_REQUEST_CODE": "Can't cancel this request!",
	"used_by": "Used By",
	"repeat_order_code": "Repeat order code",
	"register_code": "Register code",
	"type": "Type",
	"unit_price": "Unit price",
	"fee": "Fee",
	"number_of_shipping": "Number of shipping",
	"repeat_order_code_management": "Repeat order code management",
	"max_amount_package_error": "You can only buy up to %s packages!",
	"min_amount_package_error": "Number of package is invalid!",
	"number_or_package": "Number of packages",
	"enable_repeat_order_code": "Enable repeat order code",
	"enter_number_of_packages": "Enter number of packages",
	"CREATE_REPEAT_ORDER_CODE_SUCCESSFULLY": "Create repeat order code successfully!",
	"CREATE_REQUEST_REPEAT_ORDER_CODE_SUCCESSFULLY": "Create request repeat order code successfully!"
}
