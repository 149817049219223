import { ACTIONS } from '../01-actions/user';
import { AUTH_ACTIONS } from '../01-actions';
import { UTIL_ACTIONS } from '../01-actions/utils';


const initialState = {
	status: null,
	forgotPasswordSent: false,
	passwordChanged: false,
	passwordChanging: false,
	gaReset: false,
	gaResetting: false,
	gaSet: false,
	gaSetting: false,
	gaVerified: false,
	gaVerifying: false,
	resetGARequested: false,
	qrData: null,
	gaSecret: null,
	userInfo: null,
	loginHistory: null,
	displayLoginHistory: null,
	limit: 10,
	isFetching: false,
	error: null,
	resetPassword: false,
	registering: false,
	registered: false,
	opPasswordChanged: false,
	opPasswordChanging: false,
	resetOPPassDone: false,
	resetOPPassContinue: false,
	opPasswordSettingDone: false,
	opPasswordSettingContinue: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.CREATE_USER_SUCCESS:
			localStorage.setItem("USER_ACCESS_TOKEN", action.data.access_token);
			return {
				...state,
				status: action.type,
				token: action.data.access_token,
				registering: false,
				registered: true,
			};
		case ACTIONS.FORGOT_PASSWORD_PROGRESS:
			return {
				...state,
				status: action.type,
				forgotPasswordSent: false,
			};
		case ACTIONS.SET_GA_SECRET_PROGRESS:
		case ACTIONS.GET_LOGIN_HISTORY_PROGRESS:
		case ACTIONS.RESET_PASSWORD_PROGRESS:
			return {
				...state,
				status: action.type
			};
 		case ACTIONS.CREATE_USER_PROGRESS:
			return {
				...state,
				status: action.type,
				registering: true
			};			
		case ACTIONS.CHANGE_PASSWORD_PROGRESS:
			return {
				...state,
				status: action.type,
				passwordChanging: true,
			};
		case ACTIONS.RESET_OP_PASSWORD_PROGRESS:
			return {
				...state,
				status: action.type,
				resetOPPassContinue: true,
			};
		case ACTIONS.CHANGE_OP_PASSWORD_PROGRESS:
			return {
				...state,
				status: action.type,
				opPasswordChanging: true,
			};
		case ACTIONS.SETTING_OP_PASSWORD_PROGRESS:
			return {
				...state,
				status: action.type,
				opPasswordSettingContinue: true,
			};
		case ACTIONS.RESET_GA_PROGRESS:
			return {
				...state,
				status: action.type,
				gaResetting: true,
			};
		case ACTIONS.SET_GA_PROGRESS:
			return {
				...state,
				status: action.type,
				gaSetting: true,
			};
		case ACTIONS.VERIFY_GA_PROGRESS:
			return {
				...state,
				status: action.type,
				gaVerifying: true,
			};
		case ACTIONS.FORGOT_PASSWORD_SUCCESS:
			return {
				...state,
				status: action.type,
				forgotPasswordSent: true,
			};
		case ACTIONS.CHANGE_PASSWORD_SUCCESS:
			return {
				...state,
				status: action.type,
				passwordChanged: true,
				passwordChanging: false,
			};
		case ACTIONS.CHANGE_OP_PASSWORD_SUCCESS:
			return {
				...state,
				status: action.type,
				opPasswordChanged: true,
				opPasswordChanging: false,
			};
		case ACTIONS.SETTING_OP_PASSWORD_SUCCESS:
			return {
				...state,
				status: action.type,
				opPasswordSettingDone: true,
				opPasswordSettingContinue: false,
			};
		case ACTIONS.RESET_OP_PASSWORD_SUCCESS:
			return {
				...state,
				status: action.type,
				resetOPPassDone: true,
				resetOPPassContinue: false,
			};
		case ACTIONS.RESET_GA_SUCCESS:
			return {
				...state,
				status: action.type,
				gaReset: true,
				gaResetting: false,
			};
		case ACTIONS.SET_GA_SUCCESS:
			return {
				...state,
				status: action.type,
				gaSet: true,
				gaSetting: false,
				qrData: action.data.qrData,
				gaSecret: action.data.gaSecret,
			};
		case ACTIONS.VERIFY_GA_SUCCESS:
			return {
				...state,
				status: action.type,
				gaVerified: true,
				gaVerifying: false,
			};
		case ACTIONS.SET_GA_SECRET_SUCCESS:
			return {
				...state,
				status: action.type,
				qrData: action.data.qrData,
				gaSecret: action.data.gaSecret,
			};
		case ACTIONS.GET_LOGIN_HISTORY_SUCCESS:
			return {
				...state,
				status: action.type,
				loginHistory: action.data.loginHistory,
			};
		case ACTIONS.FORGOT_PASSWORD_FAILED:
			return {
				...state,
				status: action.type,
				forgotPasswordSent: false,
				error: action.data.error,
			};
		case ACTIONS.SET_GA_SECRET_FAILED:
		case ACTIONS.GET_LOGIN_HISTORY_FAILED:
        case ACTIONS.RESET_PASSWORD_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
			};
  		case ACTIONS.CREATE_USER_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				registering: false
			};			
		case ACTIONS.CHANGE_PASSWORD_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				passwordChanging: false,
			};
		case ACTIONS.RESET_OP_PASSWORD_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				resetOPPassContinue: false,
			};
		case ACTIONS.CHANGE_OP_PASSWORD_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				opPasswordChanging: false,
			};
		case ACTIONS.SETTING_OP_PASSWORD_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				opPasswordSettingContinue: false,
			};
		case ACTIONS.RESET_GA_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				gaResetting: false,
			};
		case ACTIONS.SET_GA_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				gaSetting: false,
			};
		case ACTIONS.VERIFY_GA_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				gaVerifying: false,
			};
		case ACTIONS.GET_USER_INFO_PROGRESS:
			return {
				...state,
				status: action.type,
				isFetching: true,
			};
		case ACTIONS.GET_USER_INFO_SUCCESS:
			return {
				...state,
				status: action.type,
				userInfo: action.data.userInfo,
				isFetching: false,
			};
		case ACTIONS.GET_USER_INFO_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				isFetching: false,
			};
		case ACTIONS.CHANGE_TFA_LOGIN_REQUIRE_PROGRESS:
			return {
				...state,
				status: action.type,
			};
		case ACTIONS.CHANGE_TFA_LOGIN_REQUIRE_SUCCESS:
			return {
				...state,
				status: action.type,
				TFARequireLoginChanged: true,
			};
		case ACTIONS.RESET_PASSWORD_SUCCESS:
			return {
				...state,
				status: action.type,
				resetPassword: true,
			};
		case ACTIONS.CHANGE_TFA_LOGIN_REQUIRE_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
			};
		case ACTIONS.RESET_CHANGE_TFA_LOGIN_REQUIRE_STATUS:
			return {
				...state,
				status: action.type,
				TFARequireLoginChanged: false,
			};
		case ACTIONS.RESET_CHANGE_PASSWORD_STATUS:
			return {
				...state,
				status: action.type,
				passwordChanged: false,
			};
		case ACTIONS.CHANGE_OP_PASSWORD_STATUS:
			return {
				...state,
				status: action.type,
				opPasswordChanged: false,
			};
		case ACTIONS.SETTING_OP_PASSWORD_STATUS:
			return {
				...state,
				status: action.type,
				opPasswordSettingDone: false,
			};
		case ACTIONS.RESET_OP_PASSWORD_STATUS:
			return {
				...state,
				status: action.type,
				resetOPPassDone: false,
			};
		case ACTIONS.RESET_GA_RESET_STATUS:
			return {
				...state,
				status: action.type,
				gaReset: false,
			};
		case ACTIONS.RESET_GA_VERIFY_STATUS:
			return {
				...state,
				status: action.type,
				gaVerified: false,
			};
		case ACTIONS.PAGING_LOGIN_HISTORY:
			return {
				...state,
				status: action.type,
				displayLoginHistory: action.data.displayLoginHistory,
				limit: action.data.limit,
			};
		case ACTIONS.RESET_CREATE_USER_STATUS:
			return {
				...state,
				status: action.type,
				registered: false,
			};
		case AUTH_ACTIONS.LOGOUT:
			return initialState;
		case UTIL_ACTIONS.RESET_ERROR:
			return {
				...state,
				error: null,
			};
		default:
			return state;
	}
}