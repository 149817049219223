import React, {Component} from 'react';
import {I18n} from 'react-i18next';
import {connect} from 'react-redux';
import axios from 'axios';
import {bindActionCreators} from "redux";
import {
    changeMaintenanceModeStatus,
    getMaintenanceModeStatus,
    getUrlUploadBackground,
    getMaintenanceAppStatus,
    changeMaintenanceAppStatus,
    getTopUpStatus,
    changeTopUpStatus,
    getProductCodeStatus,
    changeProductCodeStatus,
    getRepeatOrderCodeStatus,
    changeRepeatOrderCodeStatus
} from '../../../01-actions/system';
import SystemSetting from '../../../03-components/SystemSetting';
import {pushNotify} from "../../../03-components/Notification";
import i18n from "i18next";

class Summary extends Component {
    state = {
        maintenanceMode: false,
        maintenanceApp: false,
        topUpEnabled: false,
        background: null,
        url: '',
        productCodeEnabled: false,
        repeatOrderCodeEnabled: false,
    };

    componentDidMount() {
        const {
            getMaintenanceModeStatus,
            getMaintenanceAppStatus,
            getTopUpStatus,
            getProductCodeStatus,
            getRepeatOrderCodeStatus,
        } = this.props.actions;
        getMaintenanceModeStatus();
        getMaintenanceAppStatus();
        getTopUpStatus();
        getProductCodeStatus();
        getRepeatOrderCodeStatus()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.maintenanceMode !== this.state.maintenanceMode) {
            this.setState({maintenanceMode: nextProps.maintenanceMode});
        }
        if (nextProps.maintenanceApp !== this.state.maintenanceApp) {
            this.setState({maintenanceApp: nextProps.maintenanceApp});
        }
        if (nextProps.topUpEnabled !== this.state.topUpEnabled) {
            this.setState({topUpEnabled: nextProps.topUpEnabled});
        }
        if (nextProps.productCodeEnabled !== this.state.productCodeEnabled) {
            this.setState({productCodeEnabled: nextProps.productCodeEnabled});
        }
        if (nextProps.repeatOrderCodeEnabled !== this.state.repeatOrderCodeEnabled) {
            this.setState({repeatOrderCodeEnabled: nextProps.repeatOrderCodeEnabled});
        }
        if (nextProps.url && nextProps.url !== this.props.url) {
            this.uploadImage(nextProps.url);
            this.setState({background: null})
        }
    }

    uploadImage(url) {
        const {background} = this.state;
        const options = {
            headers: {
                "Content-Type": 'image/*',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, PATCH, OPTIONS",
            }
        };
        axios.put(url, background, options).then(function (result) {
            if (result.status === 200) pushNotify('success', i18n.t('CHANGE_BACKGROUND_SUCCESSFULLY'));
        }).catch(function (err) {
            console.log(err);
        });

    };

    handleChange(name) {
        return (e) => {
            if (e.target.files) {
                this.setState({[name]: e.target.files && e.target.files.length ? e.target.files[0] : null});
            }

        }
    }

    onMaintenanceModeChange = maintenanceMode => {
        this.props.actions.changeMaintenanceModeStatus();
        this.setState({maintenanceMode});
    };

    onMaintenanceAppChange = maintenanceApp => {
        this.props.actions.changeMaintenanceAppStatus();
        this.setState({maintenanceApp});
    };

    onTopUpStatusChange = topUpEnabled => {
        this.props.actions.changeTopUpStatus();
        this.setState({topUpEnabled});
    };


    onProductCodeStatusChange = productCodeEnabled => {
        this.props.actions.changeProductCodeStatus();
        this.setState({productCodeEnabled});
    };

    onRepeatOrderCodeStatusChange = repeatOrderCodeEnabled => {
        this.props.actions.changeRepeatOrderCodeStatus();
        this.setState({repeatOrderCodeEnabled});
    };

    validateUploadFileSize = background => {
        return background.size > 1024 * 1024 ? 1 : (background.size < 100 * 1024 ? -1 : 0);
    };
    uploadBackground = () => {
        this.props.actions.getUrlUploadBackground()

    };

    render() {
        const {
            maintenanceMode,
            maintenanceApp,
            topUpEnabled,
            productCodeEnabled,
            repeatOrderCodeEnabled
        } = this.state;
        const {
            isFetchingMaintenanceModeStatus,
            isChangingMaintenanceModeStatus,
            isFetchingMaintenanceAppStatus,
            isChangingMaintenanceAppStatus,
            isFetchingTopUpStatus,
            isChangingTopUpStatus,
            isChangingProductCodeStatus,
            isFetchingProductCodeStatus,
            isFetchingRepeatOrderCodeStatus,
            isChangingRepeatOrderCodeStatus,
        } = this.props;

        return (
            <I18n ns="translations">
                {
                    (t, {i18n}) => (
                        <div className="row">
                            <div className="col-lg-12 grid-margin">
                                <div className="card">
                                    <h4 className="card-title">{t('summary')}</h4>
                                    <div className="table-responsive">
                                        <table className="table table-hover table-bordered">
                                            <tbody>
                                            <SystemSetting
                                                text={t('maintenance_mode')}
                                                checked={maintenanceMode}
                                                onChange={this.onMaintenanceModeChange}
                                                isFetching={isFetchingMaintenanceModeStatus}
                                                isChanging={isChangingMaintenanceModeStatus}
                                            />
                                            <SystemSetting
                                                text={t('maintenance_mode_app')}
                                                checked={maintenanceApp}
                                                onChange={this.onMaintenanceAppChange}
                                                isFetching={isFetchingMaintenanceAppStatus}
                                                isChanging={isChangingMaintenanceAppStatus}
                                            />
                                            <SystemSetting
                                                text={t('enable_top_up')}
                                                checked={topUpEnabled}
                                                onChange={this.onTopUpStatusChange}
                                                isFetching={isFetchingTopUpStatus}
                                                isChanging={isChangingTopUpStatus}
                                            />
                                            <SystemSetting
                                                text={t('enable_product_code')}
                                                checked={productCodeEnabled}
                                                onChange={this.onProductCodeStatusChange}
                                                isFetching={isFetchingProductCodeStatus}
                                                isChanging={isChangingProductCodeStatus}
                                            />
                                            {/* <SystemSetting
                                                text={t('enable_repeat_order_code')}
                                                checked={repeatOrderCodeEnabled}
                                                onChange={this.onRepeatOrderCodeStatusChange}
                                                isFetching={isFetchingRepeatOrderCodeStatus}
                                                isChanging={isChangingRepeatOrderCodeStatus}
                                            /> */}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card mt-4">
                                    <h4 className="card-title">{t('change_background')}</h4>
                                    <div>
                                        <div>
                                            <input
                                                accept="image/*"
                                                multiple={false}
                                                required={true}
                                                placeholder="Please upload image"
                                                onChange={this.handleChange("background")}
                                                className="upload-image"
                                                type="file"
                                                name="fileToUpload"
                                                ref="fileInput"
                                                id="fileToUpload"/>
                                        </div>
                                        <div>
                                            {t('required_size')}
                                        </div>
                                        {this.state.background && this.validateUploadFileSize(this.state.background) !== 0 &&
                                        <div className="text-danger">
                                            {t('file_not_valid')}{Math.round(this.state.background.size / 1024)} Kb)
                                        </div>
                                        }
                                        <button className="btn btn-success margin-top-15px"
                                                onClick={this.uploadBackground}
                                                disabled={
                                                    !this.state.background ||
                                                    this.validateUploadFileSize(this.state.background) !== 0
                                                }>
                                            <span>
                                                <i className="fas fa-upload"/>
                                                <span>{t('update')}</span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </I18n>
        );
    }
}

const mapStateToProps = store => {
    return {
        url: store.system.url,
        maintenanceMode: store.system.maintenanceMode,
        maintenanceApp: store.system.maintenanceApp,
        isFetchingMaintenanceModeStatus: store.system.isFetchingMaintenanceModeStatus,
        isChangingMaintenanceModeStatus: store.system.isChangingMaintenanceModeStatus,
        isFetchingMaintenanceAppStatus: store.system.isFetchingMaintenanceAppStatus,
        isChangingMaintenanceAppStatus: store.system.isChangingMaintenanceAppStatus,
        topUpEnabled: store.system.topUpEnabled,
        isFetchingTopUpStatus: store.system.isFetchingTopUpStatus,
        isChangingTopUpStatus: store.system.isChangingTopUpStatus,
        productCodeEnabled: store.system.productCodeEnabled,
        isChangingProductCodeStatus: store.system.isChangingProductCodeStatus,
        isFetchingProductCodeStatus: store.system.isFetchingProductCodeStatus,
        repeatOrderCodeEnabled: store.system.repeatOrderCodeEnabled,
        isChangingRepeatOrderCodeStatus: store.system.isChangingRepeatOrderCodeStatus,
        isFetchingRepeatOrderCodeStatus: store.system.isFetchingRepeatOrderCodeStatus,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(
            {
                changeMaintenanceModeStatus,
                getMaintenanceModeStatus,
                getUrlUploadBackground,
                getMaintenanceAppStatus,
                changeMaintenanceAppStatus,
                getTopUpStatus,
                changeTopUpStatus,
                getProductCodeStatus,
                changeProductCodeStatus,
                getRepeatOrderCodeStatus,
                changeRepeatOrderCodeStatus
            },
            dispatch
        )
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);