import React, { Component } from 'react';
import { I18n } from 'react-i18next';
import i18n, { t } from 'i18next';
import { ACTIONS, getListProductCodesInfo,rejectProductCode, getSummaryProductCode } from "../../../../01-actions/productCode";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {formatDateTimeFull, formatOnlyDate} from "../../../../05-utils/commonUtils";
import Paginate from 'react-paginate';
import Loading from '../../../../03-components/Loading';
import {constants} from "../../../../05-utils/commonData";
import {Button} from "reactstrap";
import RejectProductCodeModal from "./Modal/rejectProductCode";
import {pushNotify} from "../../../../03-components/Notification";
import '../../index.css'

class RegisterCodeManagement extends Component {
    state = {
        searchParam: '',
        productCodeSelected: '',
        isOpenRejectCodeModal: false,
        region: -1
    };

    componentDidMount() {
        this.props.actions.getListProductCodesInfo({});
        this.props.actions.getSummaryProductCode();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.status !== ACTIONS.ADMIN_REJECT_PRODUCT_CODE_SUCCESS && nextProps.status === ACTIONS.ADMIN_REJECT_PRODUCT_CODE_SUCCESS) {
            pushNotify('success', i18n.t('REJECT_PRODUCT_CODE_SUCCESSFULLY'));
            nextProps.actions.getListProductCodesInfo({});
            this.setState({
                isOpenRejectCodeModal: false
            });
        }
    }
    onKeySearchPress = e => {
        if (e.key === 'Enter')
            this.props.actions.getListProductCodesInfo({ searchParam: this.state.searchParam, region: this.state.region });
    };

    onSearchChange = e => {
        this.setState({ searchParam: e.target.value.trim() }, () => {
            if (this.state.searchParam === '')
                this.props.actions.getListProductCodesInfo({});
        });

    };

    onPageChange = data => this.props.actions.getListProductCodesInfo({
        searchParam: this.state.searchParam,
        numPage: data.selected,
    });


    toggleRejectCode = () => this.setState({ isOpenRejectCodeModal: !this.state.isOpenRejectCodeModal });

    onRegionSelected = e => {
        this.props.actions.getListProductCodesInfo({ searchParam: this.state.searchParam, region: Number(e.target.value) });
        this.setState({region: Number(e.target.value)})
    };

    onRejectProductCode = userCode => {
        this.props.actions.rejectProductCode({
            productCode: this.state.productCodeSelected,
            userCode
        })
    };

    renderStatus = (status) => {
        switch (status) {
            case constants.PRODUCT_CODE_STATUS.NEW:
                return <span className="badge badge-primary">{t('new')}</span>;
            case constants.PRODUCT_CODE_STATUS.USED:
                return <span className="badge badge-success">{t('used')}</span>;
            case constants.PRODUCT_CODE_STATUS.REJECTED:
                return <span className="badge badge-danger">{t('rejected')}</span>;
            default:
                return <span className="badge badge-primary">{status}</span>
        }
    };

    renderRegion = (region) => {
        switch (region) {
            case constants.REGION.CHINESE:
                return <label className='color-danger'>{t('chinese')}</label>;
            case constants.REGION.NON_CHINESE:
                return <label className='color-success'>{t('international')}</label>;
            default:
                return <label className='color-success'>{region}</label>
        }
    };

    renderStatistical = () => {
        const { isFetchingSummary, summary} = this.props;

        return (
            <React.Fragment>
            {
                isFetchingSummary ? <Loading parent1Class="text-center"/>
                :
                    <React.Fragment>
                        <div className='row statistical-title justify-content-end'>
                            <div className='col-md-3 col-xs-6 word-break'>{t('time_of_statistics')}</div>
                            <div className='col-md-2 col-xs-6 word-break text-primary'>{formatOnlyDate(summary.time)}</div>
                        </div>
                        <div className='row statistical-title justify-content-end'>
                            <div className='col-md-3 col-xs-6 word-break'>{t('available_agt')}</div>
                            <div className='col-md-2 col-xs-6 word-break text-success'>{summary.totalAvailable}</div>
                        </div>
                        <div className='row statistical-title justify-content-end'>
                            <div className='col-md-3 col-xs-6 word-break'>{t('purchased_agt')}</div>
                            <div className='col-md-2 col-xs-6 word-break text-success'>{summary.totalPurchased}</div>
                        </div>
                        <div className='row statistical-title justify-content-end'>
                            <div className='col-md-3 col-xs-6 word-break'>{t('rejected_agt')}</div>
                            <div className='col-md-2 col-xs-6 word-break text-danger'>{summary.totalRejected}</div>
                        </div>
                    </React.Fragment>
            }
            </React.Fragment>
        )
    };

    render() {
        const { totalCodes, listCodes, isFetching } = this.props;
        const { isOpenRejectCodeModal } = this.state;
        return (
            <I18n ns="translations">
                {
                    (t, {i18n}) => (
                        <div className="row">
                            <div className="col-lg-12 grid-margin">
                                <div className="card">
                                    <h4 className="card-title">{t('product_code_management')}</h4>
                                    {this.renderStatistical()}
                                    <div className='row'>
                                        <div className="col-3 input-group margin-bottom-15px margin-top-15px">
                                            <select
                                                className="form-control cp-gbt-select"
                                                value={this.state.region}
                                                onChange={this.onRegionSelected}
                                            >
                                                <option key={0} value={null}>{t('ALL')}</option>
                                                <option key={1} value={constants.REGION.CHINESE}>{t('CHINA')}</option>
                                                <option key={2} value={constants.REGION.NON_CHINESE}>{t('INTERNATIONAL')}</option>

                                            </select>
                                        </div>
                                        <div className="col-9 input-group margin-bottom-15px margin-top-15px">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={t('search_code')}
                                                onKeyPress={this.onKeySearchPress}
                                                onChange={this.onSearchChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-hover">
                                            <thead>
                                            <tr>
                                                <th>
                                                    #
                                                </th>
                                                <th>
                                                    {t('product_code')}
                                                </th>
                                                <th>
                                                    {t('user_code')}
                                                </th>
                                                <th>
                                                    {t('region')}
                                                </th>
                                                <th>
                                                    {t('amount')}
                                                </th>
                                                <th>
                                                    {t('status')}
                                                </th>
                                                <th>
                                                    {t('used_by')}
                                                </th>
                                                <th>
                                                    {t('created_at')}
                                                </th>
                                                <th>
                                                    {t('actions')}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                listCodes ?
                                                    listCodes.map((code, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                {index + 1}
                                                            </td>
                                                            <td>
                                                                {code.code}
                                                            </td>
                                                            <td>
                                                                {code.userCode}
                                                            </td>
                                                            <td className="text-primary">
                                                                {this.renderRegion(code.region)}
                                                            </td>
                                                            <td >
                                                                {code.amount}
                                                            </td>
                                                            <td className="text-primary">
                                                                {this.renderStatus(code.status)}
                                                            </td>
                                                            <td className="text-primary">
                                                                {code.usedBy}
                                                            </td>
                                                            <td>
                                                                {formatDateTimeFull(code && code.createdAt)}
                                                            </td>
                                                            <td>
                                                            {
                                                               code.status === constants.PRODUCT_CODE_STATUS.NEW &&
                                                               <Button
                                                                   color="danger"
                                                                   onClick={() => {
                                                                       this.setState({productCodeSelected: code.code, isOpenRejectCodeModal : true});
                                                                   }}
                                                               >{t('reject')}</Button>
                                                            }
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    null
                                            }
                                            </tbody>
                                        </table>
                                    <RejectProductCodeModal
                                        isOpen={isOpenRejectCodeModal}
                                        toggle={this.toggleRejectCode}
                                        onSubmit={this.onRejectProductCode}
                                    />
                                    </div>
                                    {
                                        listCodes &&
                                        (<div className="paginate-wrapper text-center margin-top-15px">
                                            <Paginate
                                                pageCount={totalCodes / 50}
                                                pageRangeDisplayed={3}
                                                marginPagesDisplayed={3}
                                                previousLabel="<"
                                                nextLabel=">"
                                                breakLabel="..."
                                                onPageChange={this.onPageChange}
                                                disabledClassName="paginate-disabled"
                                            />
                                        </div>)
                                    }
                                    {
                                        isFetching && (<Loading parent1Class="text-center"/>)
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
            </I18n>
        );
    }
}

const mapStateToProps = store => {
    return {
        status: store.productCode.status,
        listCodes: store.productCode.listCodes,
        totalCodes: store.productCode.totalCodes,
        isFetching: store.productCode.isFetching,
        isFetchingSummary: store.productCode.isFetchingSummary,
        summary: store.productCode.summary,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions : bindActionCreators(
            {
                getListProductCodesInfo,
                rejectProductCode,
                getSummaryProductCode
            },
            dispatch
        )
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterCodeManagement);