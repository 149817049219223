import { callApi } from "../05-utils/commonUtils";
import { updateStatus } from "./utils";

const ACTIONS = {
	GET_ALL_FIRST_WALLET_NON_USER_PROGRESS: "GET_ALL_FIRST_WALLET_NON_USER_PROGRESS",
	GET_ALL_FIRST_WALLET_NON_USER_SUCCESS: "GET_ALL_FIRST_WALLET_NON_USER_SUCCESS",
	GET_ALL_FIRST_WALLET_NON_USER_FAILED: "GET_ALL_FIRST_WALLET_NON_USER_FAILED",
	ADMIN_GET_ALL_USER_WALLET_PROGRESS: "ADMIN_GET_ALL_USER_WALLET_PROGRESS",
	ADMIN_GET_ALL_USER_WALLET_SUCCESS: "ADMIN_GET_ALL_USER_WALLET_SUCCESS",
	ADMIN_GET_ALL_USER_WALLET_FAILED: "ADMIN_GET_ALL_USER_WALLET_FAILED",
	CREATE_MULTI_FIRST_WALLET_PROGRESS: "CREATE_MULTI_FIRST_WALLET_PROGRESS",
	CREATE_MULTI_FIRST_WALLET_SUCCESS: "CREATE_MULTI_FIRST_WALLET_SUCCESS",
	CREATE_MULTI_FIRST_WALLET_FAILED: "CREATE_MULTI_FIRST_WALLET_FAILED",
	PAGING_FIRST_WALLET: "PAGING_FIRST_WALLET",
	ADMIN_UNBLOCK_USER_WALLET_PROGRESS: "ADMIN_UNBLOCK_USER_WALLET_PROGRESS",
	ADMIN_UNBLOCK_USER_WALLET_SUCCESS: "ADMIN_UNBLOCK_USER_WALLET_SUCCESS",
	ADMIN_UNBLOCK_USER_WALLET_FAILED: "ADMIN_UNBLOCK_USER_WALLET_FAILED",
	RESET_UNBLOCK_USER_WALLET_STATUS: "RESET_UNBLOCK_USER_WALLET_STATUS",
	RESET_ERROR_ADMIN_WALLET_API_MESSAGE: "RESET_ERROR_ADMIN_WALLET_API_MESSAGE",
	RESET_CREATE_MULTI_FIRST_WALLET_STATUS: "RESET_CREATE_MULTI_FIRST_WALLET_STATUS",
	ADMIN_SEND_USER_WALLET_INFORMATION_PROGRESS: "ADMIN_SEND_USER_WALLET_INFORMATION_PROGRESS",
	ADMIN_SEND_USER_WALLET_INFORMATION_SUCCESS: "ADMIN_SEND_USER_WALLET_INFORMATION_SUCCESS",
	ADMIN_SEND_USER_WALLET_INFORMATION_FAILED: "ADMIN_SEND_USER_WALLET_INFORMATION_FAILED",
};

const getAllFirstWalletNonUser = () => {
	const data = {
		name: "get_all_first_wallet_non_user",
		params: {}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.GET_ALL_FIRST_WALLET_NON_USER_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success) {
					const firstWallets = response.result.map((wallet, index) => {
						return {...wallet, index: index + 1};
					});
					dispatch(updateStatus(ACTIONS.GET_ALL_FIRST_WALLET_NON_USER_SUCCESS, { firstWallets }));
				}
				else {
					dispatch(updateStatus(ACTIONS.GET_ALL_FIRST_WALLET_NON_USER_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("getAllFirstWalletNonUser", err);
				dispatch(updateStatus(ACTIONS.GET_ALL_FIRST_WALLET_NON_USER_FAILED, {error: err}));
			});
	};
};

const adminGetAllUserWallet = payload => {
	const data = {
		name: "get_wallets_info_by_arm_id",
		params: {
			armId: payload.armId
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.ADMIN_GET_ALL_USER_WALLET_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success) {
					dispatch(updateStatus(ACTIONS.ADMIN_GET_ALL_USER_WALLET_SUCCESS, { userWallets: response.result }));
				}
				else {
					dispatch(updateStatus(ACTIONS.ADMIN_GET_ALL_USER_WALLET_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("adminGetAllUserWallet", err);
				dispatch(updateStatus(ACTIONS.ADMIN_GET_ALL_USER_WALLET_FAILED, {error: err}));
			});
	};
};

const adminUnblockUserWallet = payload => {
	const data = {
		name: "admin_unblock_wallet",
		params: {
			listWalletAddress: payload.listWalletAddress
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.ADMIN_UNBLOCK_USER_WALLET_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success) {
					dispatch(updateStatus(ACTIONS.ADMIN_UNBLOCK_USER_WALLET_SUCCESS, {}));
				}
				else {
					dispatch(updateStatus(ACTIONS.ADMIN_UNBLOCK_USER_WALLET_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("adminUnblockUserWallet", err);
				dispatch(updateStatus(ACTIONS.ADMIN_UNBLOCK_USER_WALLET_FAILED, {error: err}));
			});
	};
};

const createMultiFirstWallet = payload => {
	const data = {
		name: "create_first_wallet_non_user",
		params: {
			numberOfWallet: payload.numberOfWallet,
			code: payload.code,
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.CREATE_MULTI_FIRST_WALLET_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success) {
					dispatch(updateStatus(ACTIONS.CREATE_MULTI_FIRST_WALLET_SUCCESS, {}));
				}
				else {
					dispatch(updateStatus(ACTIONS.CREATE_MULTI_FIRST_WALLET_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("createMultiFirstWallet", err);
				dispatch(updateStatus(ACTIONS.CREATE_MULTI_FIRST_WALLET_FAILED, {error: err}));
			});
	};
};

const pagingFirstWallet = (firstWallets, page = 0, limit = 50) => {
	const displayFirstWallets = firstWallets.slice(page * limit, (page + 1) * limit);
	return dispatch => dispatch(updateStatus(ACTIONS.PAGING_FIRST_WALLET, { displayFirstWallets, limit }));
};

const resetUnblockUserWalletStatus = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_UNBLOCK_USER_WALLET_STATUS, {}));

const resetErrorAdminWalletApiMessage = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_ERROR_ADMIN_WALLET_API_MESSAGE, {}));

const resetCreateMultiFirstWalletStatus = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_CREATE_MULTI_FIRST_WALLET_STATUS, {}));

const adminSendUserWalletInformation = payload => {
	const data = {
		name: "admin_send_secret_key_to_user",
		params: {
			userID: payload.userID
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.ADMIN_SEND_USER_WALLET_INFORMATION_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success) {
					dispatch(updateStatus(ACTIONS.ADMIN_SEND_USER_WALLET_INFORMATION_SUCCESS, {}));
				}
				else {
					dispatch(updateStatus(ACTIONS.ADMIN_SEND_USER_WALLET_INFORMATION_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("adminSendUserWalletInformation", err);
				dispatch(updateStatus(ACTIONS.ADMIN_SEND_USER_WALLET_INFORMATION_FAILED, {error: err}));
			});
	};
};
export {
	ACTIONS,
	getAllFirstWalletNonUser,
	createMultiFirstWallet,
	resetCreateMultiFirstWalletStatus,
	pagingFirstWallet,
	adminGetAllUserWallet,
	adminUnblockUserWallet,
	resetUnblockUserWalletStatus,
	resetErrorAdminWalletApiMessage,
	adminSendUserWalletInformation
};