import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import { I18n } from 'react-i18next'
import './eZpayTopUp.css';
import SubmitButton from "../../../../../03-components/ModalSubmitButton";

export default class EZPayEnterSgpTfa extends Component {
    state = {
        tfaCode: '',
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.isSubmitting && !nextProps.isSubmitting) {
            this.setState({ tfaCode: '' });
        }
    }

    refreshInputAndToggle = () => {
        this.setState({ tfaCode: '' });
        this.props.toggle();
    };

    onTextChange = e => this.setState({ tfaCode: e.target.value });

    onSubmit = () => {
        const { tfaCode } = this.state;
        this.props.onSubmit({ tfaCode });
    };

    render() {
        const { isOpen, isSubmitting } = this.props;
        const { tfaCode } = this.state;
        return (
            <I18n ns="translations">
                {
                    t => (
                        <Modal isOpen={isOpen} contentClassName="transfer-to-card-border">
                            <div className='transfer-to-card'>
                                <div className='transfer-to-card-title text-black'>
                                    <span className='text-size-18'>{t('transfer_to_card')}</span>
                                    <button type="button" onClick={this.refreshInputAndToggle} className='close' aria-label='Close'>
                                        <span aria-hidden="true">{"×"}</span>
                                    </button>
                                </div>
                                <div className='sgp-authorize-body'>
                                    <div className="select-application-id-wrapper">
                                        <p className="text-center enter-sgp-password-intro">{t('enter_authentate_code_to_proceed')}</p>
                                        <div className="form-group">
                                            <div className="input-group">
                                                <input
                                                    name="tfa-code"
                                                    type="number"
                                                    className="form-control sgp-tfa-input"
                                                    value={tfaCode}
                                                    onChange={this.onTextChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='text-center'>
                                            <SubmitButton
                                                className="btn btn-primary btn-fw select-application-id-button"
                                                onClick={this.onSubmit}
                                                isSubmitting={isSubmitting}
                                                disabled={!tfaCode}
                                                text={t('confirm')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    )
                }
            </I18n>
        );
    }
}
