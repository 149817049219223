import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { I18n } from 'react-i18next';
import Paginate from 'react-paginate';
import { Collapse } from 'reactstrap';
import {
	getTransactionsFailHistory,
	resetTransactionFailedPage,
	pagingTransactionFailHistory,
} from "../../../../01-actions/transaction";
import { getAndGroupUserWallet } from "../../../../01-actions/wallet";
import {
	formatDateTimeFull,
} from "../../../../05-utils/commonUtils";
import TransactionDetail from '../../../../03-components/TransactionDetail/TransactionFromDB';
import Loading from '../../../../03-components/Loading';

class TransactionFail extends Component {
	state = {
		addressSelected: '',
		loadedPage: 1,
		displayTransactionsFail: [],
        addresses: []
	};

	componentDidMount() {
		this.props.actions.getAndGroupUserWallet();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.standardWallet !== this.props.standardWallet && nextProps.standardWallet && nextProps.standardWallet.length > 0 && nextProps.standardWallet[0]) {
            const addresses = nextProps.standardWallet;
		    this.setState({ addressSelected: addresses[0].address , addresses}, () => {
				nextProps.actions.getTransactionsFailHistory({ address: this.state.addressSelected });
			});
		}
		if (nextProps.displayTransactionsFail && nextProps.displayTransactionsFail !== this.props.displayTransactionsFail) {
			this.setState({displayTransactionsFail: nextProps.displayTransactionsFail});
		}
	}

	componentWillUnmount() {
		this.props.actions.resetTransactionFailedPage();
	}

	onWalletSelected = e => {
		this.setState({ addressSelected: e.target.value }, () => {
			this.props.actions.resetTransactionFailedPage();
			this.props.actions.getTransactionsFailHistory({ address: this.state.addressSelected });
		});
	};

	onPageChange = data => {
		const { actions, transactionsFail } = this.props;
		if (this.state.loadedPage < data.selected + 1) {
			actions.getTransactionsFailHistory({ address: this.state.addressSelected, numPage: data.selected + 1 });
			this.setState({ loadedPage: data.selected + 1 });
		}
		else {
			actions.pagingTransactionFailHistory(transactionsFail, data.selected);
		}
	};

	render() {
		const {
			isFetchingWallet,
			isFetchingHistory,
			transactionsFailPageCount,
		} = this.props;

		const {addresses} = this.state;
		const { displayTransactionsFail } = this.state;

		return (
			<I18n ns="translations">
				{
					t => (
						<div className="col-lg-12">
							<h4 className="card-title">{t('transaction_history')}</h4>
							<div className="form-group">
								{
									isFetchingWallet ?
										(<Loading parent1Class="text-center" />)
										:
										addresses && addresses.length !== 0 && (
											<select
												className="form-control cp-gbt-select"
												value={this.state.addressSelected}
												onChange={this.onWalletSelected}
											>
												{
													isFetchingWallet ? null : addresses.map((wallet, index) => (
														<option key={index} value={wallet.address}>{wallet.name}</option>
													))
												}
											</select>
										)
								}
							</div>
							<div className="table-responsive">
								<table className="table table-hover">
									<tbody>
										{

											isFetchingWallet ?
												null
												:
												isFetchingHistory ?
													(<Loading isTableRow />)
													:
													Array.isArray(displayTransactionsFail) && displayTransactionsFail.length > 0 ?
														displayTransactionsFail.map((tran, index) => (
															<React.Fragment key={index}>
																<tr className="hover-item-tb" onClick={() => this.setState({
																	displayTransactionsFail: [
																		...displayTransactionsFail.slice(0, index),
																		Object.assign({}, displayTransactionsFail[index], { isOpen: displayTransactionsFail[index] && displayTransactionsFail[index].isOpen ? false : true }),
																		...displayTransactionsFail.slice(index + 1)
																	]
																})}>
																	<td>
																		{formatDateTimeFull(tran.createdOn)}
																	</td>
																	<td>
																		<b>{`${tran.operations.length} ${t('operations')}`}</b>
																	</td>
																	<td>
																		{tran._memo && tran._memo._value && (<i className="text-success fas fa-comment mr-2" />)}
																		{tran._memo && tran._memo._value}
																	</td>
																	<td className="history-transaction-icon">
																		<div  >
																			<i
																				className={`fa fa-chevron-${displayTransactionsFail[index] && displayTransactionsFail[index].isOpen ? "up" : "down"}`}
																			/>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td colSpan={6}>
																		<Collapse isOpen={displayTransactionsFail[index] && displayTransactionsFail[index].isOpen}>
																			
																				{
																					Array.isArray(tran.operations) && tran.operations.map((operation, index) => (
																						<div className="transaction-detail align-items-center row" key={index}>
																							<TransactionDetail
																								t={t}
																								operate={operation}
																								createdOn={tran.createdOn}
																								sourceAddress={tran.walletAddress}
																							/>
																						</div>
																					))
																				}
																		</Collapse>
																	</td>
																</tr>
															</React.Fragment>
														))
														:
														(<tr className="text-center">
															<td>
																<div>{t('no_data_to_show')}</div>
															</td>
														</tr>)
										}
									</tbody>
								</table>
								{
									displayTransactionsFail && transactionsFailPageCount > 1 ?
										(<div className="paginate-wrapper text-center">
											<Paginate
												pageCount={transactionsFailPageCount}
												pageRangeDisplayed={3}
												marginPagesDisplayed={3}
												previousLabel="<"
												nextLabel=">"
												breakLabel="..."
												onPageChange={this.onPageChange}
											/>
										</div>)
										:
										null
								}
							</div>
						</div>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		isFetchingWallet: store.wallet.isFetching,
		isFetchingHistory: store.transaction.isFetching,
		transactionsFail: store.transaction.transactionsFail,
		displayTransactionsFail: store.transaction.displayTransactionsFail,
		transactionsFailPageCount: store.transaction.transactionsFailPageCount,
		transactionsFailLimit: store.transaction.transactionsFailLimit,
        standardWallet: store.wallet.standardWallet,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions: bindActionCreators(
			{
				getTransactionsFailHistory,
				resetTransactionFailedPage,
				pagingTransactionFailHistory,
                getAndGroupUserWallet,
			},
			dispatch
		)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TransactionFail);