import React, {Component} from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { I18n } from 'react-i18next';
import i18n from 'i18next';

import './general.css';
import {
	getUserInfo,
	resetChangePasswordStatus,
	changeOPPasswordStatus,
	resetOPPassword,
	resetOPPasswordStatus
} from "../../../../01-actions/user";
import {
	resetUnblockWalletStatus
} from "../../../../01-actions/wallet";
import {
	getAllUserWallet
} from "../../../../01-actions/wallet";
import { pushNotify } from '../../../../03-components/Notification';
import ChangePasswordModal from './ChangePasswordModal/changePasswordModal';
import ChangeOPPasswordModal from './ChangeOPPasswordModal/changeOPPasswordModal';
import ResetOPPasswordStepByStep from './ResetOPPassStepByStepModal/resetOPPasswordStepByStep';
import Loading from '../../../../03-components/Loading';
import { getEnv } from '../../../../env';
class General extends Component {
	state = {
		isOpenChangePassword: false,
		isOpenChangeOPPassword: false,
		isOpenResetOPPassword: false,
	};

	componentDidMount() {
		if (!this.props.userInfo)
			this.props.actions.getUserInfo();
		if (!this.props.userWallets)
			this.props.actions.getAllUserWallet();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.passwordChanged) {
			pushNotify('success', i18n.t('PASSWORD_CHANGE_SUCCESSFULLY'));
			this.setState({ isOpenChangePassword: false });
			nextProps.actions.resetChangePasswordStatus();
			nextProps.actions.getUserInfo();
		}
		if (nextProps.opPasswordChanged) {
			pushNotify('success', i18n.t('OP_PASSWORD_CHANGE_SUCCESSFULLY'));
			this.setState({ isOpenChangeOPPassword: false });
			nextProps.actions.changeOPPasswordStatus();
			nextProps.actions.getUserInfo();
		}
		if (nextProps.resetOPPassDone) {
			pushNotify('success', i18n.t('RESET_OP_PASSWORD_SUCCESSFULLY'));
			nextProps.actions.resetOPPasswordStatus();
		}
		if (nextProps.walletUnblocked) {
			pushNotify('success', i18n.t('UNBLOCK_WALLET_SUCCESS'));
			nextProps.actions.resetUnblockWalletStatus();
			nextProps.actions.getUserInfo();
		}
	}

	toggleChangePassword = () => {
		this.setState({ isOpenChangePassword: !this.state.isOpenChangePassword })
	};

	toggleChangeOPPassword = () => {
		this.setState({ isOpenChangeOPPassword: !this.state.isOpenChangeOPPassword })
	};

	toggleResetOPPassword = () => {
		this.setState({ isOpenResetOPPassword: !this.state.isOpenResetOPPassword });
	};

	render() {
		const { userInfo } = this.props;
		return (
			<I18n ns="translations">
				{
					t => (
						<div>
							{
								userInfo ?
									(<div className="row">
										<div className="col-md-12 col-xs-12">
											<h3><label>{t('email_and_password')}</label></h3>
											<div className="property-name"><b>{t('email')}</b></div>
											<div>{userInfo.email}</div>
											<div className="property-name"><b>{t('password')}</b></div>
											<div>********</div>
											<div className="property-name"><b>{t('op_password')}</b></div>
											<div>********</div>
											<div>
												<button className="btn btn-danger margin-top-15px"
														onClick={this.toggleChangePassword}>
													<i className="fa fa-lock"/>
													<span>{t('change_password_')}</span>
												</button>
												<button className="btn btn-success margin-top-15px margin-left-15px"
														onClick={this.toggleChangeOPPassword}>
													<i className="fa fa-lock"/>
													<span>{t('change_op_password_')}</span>
												</button>
												{getEnv("ENABLE_RESET_OP_PASS") && (
												<button className="btn btn-primary margin-top-15px margin-left-15px"
														onClick={this.toggleResetOPPassword}>
													<i className="fa fa-lock"/>
													<span>{t('reset_op_password_')}</span>
												</button>
												)}
											</div>
										</div>
									</div>)
									:
									(<Loading parent1Class="text-center"/>)
							}
							<ChangePasswordModal
								isOpen={this.state.isOpenChangePassword}
								toggle={this.toggleChangePassword}
							/>
							<ChangeOPPasswordModal
								isOpen={this.state.isOpenChangeOPPassword}
								toggle={this.toggleChangeOPPassword}
							/>
							<ResetOPPasswordStepByStep
								isOpen={this.state.isOpenResetOPPassword}
								toggle={this.toggleResetOPPassword}
								onSkip={() => {
									this.setState({
										isOpenResetOPPassword: false
									})
								}}
							/>
						</div>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		userInfo: store.user.userInfo,
		passwordChanged: store.user.passwordChanged,
		opPasswordChanged: store.user.opPasswordChanged,
		userWallets: store.wallet.userWallets,
		resetOPPassDone: store.user.resetOPPassDone,
		walletUnblocked: store.wallet.walletUnblocked,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				getUserInfo,
				resetChangePasswordStatus,
				changeOPPasswordStatus,
				getAllUserWallet,
				resetOPPassword,
				resetOPPasswordStatus,
				resetUnblockWalletStatus
			},
			dispatch
		)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(General);
