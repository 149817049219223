import { ACTIONS } from '../01-actions/wallet';
import { AUTH_ACTIONS } from '../01-actions';
import { UTIL_ACTIONS } from '../01-actions/utils';

const initialState = {
	status: null,
	userWallets: null,
	selectedWallet: null,
	standardWallet: null,
	balanceTransferSelected: {},
	tfaCode: null,
	keyPair: null,
	keyPairInitializing: null,
	walletSaved: false,
	walletSaving: false,
	walletCreated: false,
	walletCreating: false,
	walletUnblocked: false,
	walletUnblocking: false,
	walletKeys: null,
	isFetching: false,
    walletRenamed:false,
	error: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.GET_ALL_USER_WALLET_PROGRESS:
		case ACTIONS.GET_AND_GROUP_USER_WALLET_PROGRESS:
			return {
				...state,
				status: action.type,
				isFetching: true,
			};
		case ACTIONS.INIT_KEYPAIR_PROGRESS:
			return {
				...state,
				status: action.type,
				keyPairInitializing: true,
			};
		case ACTIONS.SAVE_WALLET_PROGRESS:
			return {
				...state,
				status: action.type,
				walletSaving: true,
			};
		case ACTIONS.CREATE_WALLET_PROGRESS:
			return {
				...state,
				status: action.type,
				walletCreating: true,
			};
		case ACTIONS.UNBLOCK_WALLET_PROGRESS:
			return {
				...state,
				status: action.type,
				walletUnblocking: true,
			};
		case ACTIONS.GET_ALL_USER_WALLET_SUCCESS:
			const { userWallets } = action.data;
			return {
				...state,
				status: action.type,
				userWallets,
				selectedWallet: userWallets && userWallets.length > 0 && userWallets[0],
				isFetching: false,
			};
		case ACTIONS.INIT_KEYPAIR_SUCCESS:
			return {
				...state,
				status: action.type,
				keyPairInitializing: false,
				keyPair: action.data.keyPair,
			};
		case ACTIONS.SAVE_WALLET_SUCCESS:
			return {
				...state,
				status: action.type,
				walletSaved: true,
				walletSaving: false,
			};
		case ACTIONS.CREATE_WALLET_SUCCESS:
			return {
				...state,
				status: action.type,
				walletCreated: true,
				walletCreating: false,
			};
		case ACTIONS.UNBLOCK_WALLET_SUCCESS:
			return {
				...state,
				status: action.type,
				walletUnblocked: true,
				walletUnblocking: false,
			};
		case ACTIONS.GET_ALL_USER_WALLET_FAILED:
		case ACTIONS.GET_AND_GROUP_USER_WALLET_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				isFetching: false,
			};
		case ACTIONS.GET_AND_GROUP_USER_WALLET_SUCCESS:
			const { standardWallet } = action.data;
			return {
				...state,
				status: action.type,
				standardWallet,
				isFetching: false,
			};
		case ACTIONS.INIT_KEYPAIR_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				keyPairInitializing: false,
			};
		case ACTIONS.SAVE_WALLET_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				walletSaving: false,
			};
		case ACTIONS.CREATE_WALLET_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				walletCreating: false,
			};
		case ACTIONS.UNBLOCK_WALLET_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				walletUnblocking: false,
			};
		case ACTIONS.CHOOSE_WALLET:
			return {
				...state,
				status: action.type,
				selectedWallet: action.data.wallet,
			};
		case ACTIONS.SUBMIT_TFA_CODE:
			return {
				...state,
				status: action.type,
				tfaCode: action.data.code,
			};
		case ACTIONS.RESET_SAVE_WALLET_STATUS:
			return {
				...state,
				status: action.type,
				walletSaved: false,
				walletSaving: false,
			};
		case ACTIONS.RESET_CREATE_WALLET_STATUS:
			return {
				...state,
				status: action.type,
				walletCreated: false,
				walletCreating: false,
			};
		case ACTIONS.RESET_UNBLOCK_WALLET_STATUS:
			return {
				...state,
				status: action.type,
				walletUnblocked: false,
				walletUnblocking: false,
			};
		case ACTIONS.RESET_TFA_CODE:
			return {
				...state,
				status: action.type,
				tfaCode: null,
			};
		case ACTIONS.RESET_STATUS_RENAME:
			return {
				...state,
				status: action.type,
				walletRenamed: null,
			};
		case ACTIONS.WALLET_SECRET_KEY_REQUEST_PROGRESS:
        case ACTIONS.RENAME_WALLET_PROGRESS:
			return {
				...state,
				status: action.type,
			};
        case ACTIONS.RENAME_WALLET_SUCCESS:
        	const walletIndex =  [...state.userWallets].findIndex((item) => item._id === action.data.walletId)
            return {
                ...state,
                status: action.type,
                walletRenamed: true,
                selectedWallet: {...state.selectedWallet, name: action.data.newName},
                userWallets: [
                ...state.userWallets.slice(0, walletIndex),
                Object.assign({}, state.userWallets[walletIndex], {name: action.data.newName}),
                ...state.userWallets.slice(walletIndex + 1)
                ]
            };
		case ACTIONS.WALLET_SECRET_KEY_REQUEST_SUCCESS:
			return {
				...state,
				status: action.type,
				walletKeys: action.data.walletKeys,
			};
		case ACTIONS.WALLET_SECRET_KEY_REQUEST_FAILED:
        case ACTIONS.RENAME_WALLET_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
			};
		case ACTIONS.REFRESH_WALLET_KEY:
			return {
				...state,
				status: action.type,
				walletKeys: null,
			};
		case AUTH_ACTIONS.LOGOUT:
			return initialState;
		case UTIL_ACTIONS.RESET_ERROR:
			return {
				...state,
				error: null,
			};
		default:
			return state;
	}
}