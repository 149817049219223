import { ACTIONS } from '../01-actions/system';
import { UTIL_ACTIONS } from '../01-actions/utils';
import {AUTH_ACTIONS} from "../01-actions";

const initialState = {
    status: null,
    isCheckedMaintenance: false,
    maintenanceMode: false,
    maintenanceApp: false,
    isFetchingMaintenanceModeStatus: false,
    isChangingMaintenanceModeStatus: false,
    isFetchingMaintenanceAppStatus: false,
    isChangingMaintenanceAppStatus: false,
    timestampBackground: '',
    topUpEnabled: false,
    isFetchingTopUpStatus: false,
    isChangingTopUpStatus: false,
    url: '',
    error: null,
    productCodeEnabled: false,
    isChangingProductCodeStatus: false,
    isFetchingProductCodeStatus: false,
    repeatOrderCodeEnabled: false,
    isChangingRepeatOrderCodeStatus: false,
    isFetchingRepeatOrderCodeStatus: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.CHANGE_MAINTENANCE_MODE_STATUS_PROGRESS:
            return {
                ...state,
                status: action.type,
                isChangingMaintenanceModeStatus: true,
            };
        case ACTIONS.CHANGE_MAINTENANCE_APP_STATUS_PROGRESS:
            return {
                ...state,
                status: action.type,
                isChangingMaintenanceAppStatus: true,
            };
        case ACTIONS.GET_MAINTENANCE_MODE_STATUS_PROGRESS:
            return {
                ...state,
                status: action.type,
                isFetchingMaintenanceModeStatus: true,
            };
        case ACTIONS.GET_MAINTENANCE_APP_STATUS_PROGRESS:
            return {
                ...state,
                status: action.type,
                isFetchingMaintenanceAppStatus: true,
            };
        case ACTIONS.GET_MAINTENANCE_MODE_STATUS_SUCCESS:
            return {
                ...state,
                status: action.type,
                maintenanceMode: action.data.maintenanceMode,
                isFetchingMaintenanceModeStatus: false,
                isCheckedMaintenance: true,
            };
        case ACTIONS.GET_MAINTENANCE_APP_STATUS_SUCCESS:
            return {
                ...state,
                status: action.type,
                maintenanceApp: action.data.maintenanceApp,
                isFetchingMaintenanceAppStatus: false,
            };
        case ACTIONS.GET_URL_UPLOAD_BACKGROUND_SUCCESS:
            return {
                ...state,
                status: action.type,
                url: action.data.url,
            };
        case ACTIONS.GET_URL_UPLOAD_BACKGROUND_FAILED:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
            };
        case ACTIONS.CHANGE_MAINTENANCE_MODE_STATUS_SUCCESS:
            return {
                ...state,
                status: action.type,
                maintenanceMode: action.data.maintenanceMode,
                isChangingMaintenanceModeStatus: false,
            };
        case ACTIONS.CHANGE_MAINTENANCE_APP_STATUS_SUCCESS:
            return {
                ...state,
                status: action.type,
                maintenanceApp: action.data.maintenanceApp,
                isChangingMaintenanceAppStatus: false,
            };
        case ACTIONS.GET_MAINTENANCE_MODE_STATUS_FAILED:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
                isFetchingMaintenanceModeStatus: false,
                isCheckedMaintenance: true,
            };
        case ACTIONS.GET_MAINTENANCE_APP_STATUS_FAILED:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
                isFetchingMaintenanceAppStatus: false,
            };
        case ACTIONS.CHANGE_MAINTENANCE_MODE_STATUS_FAILED:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
                isChangingMaintenanceModeStatus: false,
            };
        case ACTIONS.CHANGE_MAINTENANCE_APP_STATUS_FAILED:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
                isChangingMaintenanceAppStatus: false,
            };
        case ACTIONS.UPDATE_MAINTENANCE_STATUS:
            return {
                ...state,
                status: action.type,
                maintenanceMode: action.data.maintenanceMode,
            };
        case ACTIONS.GET_TIMESTAMP_BACKGROUND_PROGRESS:
            return {
                ...state,
                status: action.type,
            };
        case ACTIONS.GET_TIMESTAMP_BACKGROUND_SUCCESS:
            return {
                ...state,
                status: action.type,
                timestampBackground: action.data.timestampBackground,
            };
        case ACTIONS.CHANGE_TOP_UP_STATUS_PROGRESS:
            return {
                ...state,
                status: action.type,
                isChangingTopUpStatus: true,
            };
        case ACTIONS.GET_TOP_UP_STATUS_PROGRESS:
            return {
                ...state,
                status: action.type,
                isFetchingTopUpStatus: true,
            };
        case ACTIONS.GET_TOP_UP_STATUS_SUCCESS:
            return {
                ...state,
                status: action.type,
                topUpEnabled: action.data.topUpEnabled,
                isFetchingTopUpStatus: false,
            };
        case ACTIONS.CHANGE_TOP_UP_STATUS_SUCCESS:
            return {
                ...state,
                status: action.type,
                topUpEnabled: action.data.topUpEnabled,
                isChangingTopUpStatus: false,
            };
        case ACTIONS.GET_TOP_UP_STATUS_FAILED:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
                isFetchingTopUpStatus: false,
            };
        case ACTIONS.CHANGE_TOP_UP_STATUS_FAILED:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
                isChangingTopUpStatus: false,
            };
        case ACTIONS.UPDATE_TOP_UP_STATUS:
            return {
                ...state,
                status: action.type,
                topUpEnabled: action.data.topUpEnabled,
            };
        case ACTIONS.CHANGE_PRODUCT_CODE_STATUS_PROGRESS:
            return {
                ...state,
                status: action.type,
                isChangingProductCodeStatus: true,
            };
        case ACTIONS.GET_PRODUCT_CODE_STATUS_PROGRESS:
            return {
                ...state,
                status: action.type,
                isFetchingProductCodeStatus: true,
            };
        case ACTIONS.GET_PRODUCT_CODE_STATUS_SUCCESS:
            return {
                ...state,
                status: action.type,
                productCodeEnabled: action.data.productCodeEnabled,
                isFetchingProductCodeStatus: false,
            };
        case ACTIONS.CHANGE_PRODUCT_CODE_STATUS_SUCCESS:
            return {
                ...state,
                status: action.type,
                productCodeEnabled: action.data.productCodeEnabled,
                isChangingProductCodeStatus: false,
            };
        case ACTIONS.GET_PRODUCT_CODE_STATUS_FAILED:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
                isFetchingProductCodeStatus: false,
            };
        case ACTIONS.CHANGE_PRODUCT_CODE_STATUS_FAILED:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
                isChangingProductCodeStatus: false,
            };
        case ACTIONS.UPDATE_PRODUCT_CODE_STATUS:
            return {
                ...state,
                status: action.type,
                productCodeEnabled: action.data.productCodeEnabled,
            };
        case ACTIONS.CHANGE_REPEAT_ORDER_CODE_STATUS_PROGRESS:
            return {
                ...state,
                status: action.type,
                isChangingRepeatOrderCodeStatus: true,
            };
        case ACTIONS.GET_REPEAT_ORDER_CODE_STATUS_PROGRESS:
            return {
                ...state,
                status: action.type,
                isFetchingRepeatOrderCodeStatus: true,
            };
        case ACTIONS.GET_REPEAT_ORDER_CODE_STATUS_SUCCESS:
            return {
                ...state,
                status: action.type,
                repeatOrderCodeEnabled: action.data.repeatOrderCodeEnabled,
                isFetchingRepeatOrderCodeStatus: false,
            };
        case ACTIONS.CHANGE_REPEAT_ORDER_CODE_STATUS_SUCCESS:
            return {
                ...state,
                status: action.type,
                repeatOrderCodeEnabled: action.data.repeatOrderCodeEnabled,
                isChangingRepeatOrderCodeStatus: false,
            };
        case ACTIONS.GET_REPEAT_ORDER_CODE_STATUS_FAILED:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
                isFetchingRepeatOrderCodeStatus: false,
            };
        case ACTIONS.CHANGE_REPEAT_ORDER_CODE_STATUS_FAILED:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
                isChangingRepeatOrderCodeStatus: false,
            };
        case ACTIONS.UPDATE_REPEAT_ORDER_CODE_STATUS:
            return {
                ...state,
                status: action.type,
                repeatOrderCodeEnabled: action.data.repeatOrderCodeEnabled,
            };
        case ACTIONS.GET_TIMESTAMP_BACKGROUND_FAILED:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
            };
        case UTIL_ACTIONS.RESET_ERROR:
        case AUTH_ACTIONS.LOGOUT:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}