import React, { Component } from 'react';
import RepeatOrderCodeList from '../RepeatOrderCodeList/repeatOrderCodeList';
import RequestRegisterCodeList from '../RequestRepeatOrderCodeList/requestRepeatOrderCodeList';

class RepeatOrderCodeTab extends Component {
    render() {
        return (
            <React.Fragment>
                <RepeatOrderCodeList/>
                <RequestRegisterCodeList/>
            </React.Fragment>
        );
    }
}

export default RepeatOrderCodeTab;