import { ACTIONS } from '../01-actions/socket';


const initialState = {
	status: null,
	socket: null,
	socketID: null,
	isSocketConnected: true,
	notifies: {
		data: [],
		amount: 0,
	}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.CONNECT_SOCKET:
			return {
				...state,
				status: action.type,
				socket: action.data.socket, // TODO: need move socket object out of Redux store
				isSocketConnected: true,
				socketID: new Date()
			};
		case ACTIONS.SOCKET_DISCONNECTED:
			return {
				...state,
				status: action.type,
				socket: null,
				isSocketConnected: false,
				socketID: null
			};
		default:
			return state;
	}
}
