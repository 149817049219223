import I18n from 'i18next';

const validate = values => {
	const errors = {};
	if (values.fundingCheckbox && !values.startingBalance) {
		errors.startingBalance = I18n.t('STARTING_BALANCE_REQUIRED');
	}
	return errors;
};

export default validate;