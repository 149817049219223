import I18n from 'i18next';

const validate = values => {
	const errors = {};
	if (!values.opPass) {
		errors.opPass = I18n.t('OP_PASS_REQUIRED');
	}
	if (values.opPass && values.opPass.length < 6) {
		errors.opPass = I18n.t('OP_PASS_MIN_LENGTH');
	}
	return errors;
};

export default validate;