import React, { Component } from 'react';
import { I18n } from 'react-i18next';
import './manualBackup.css';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from 'moment';
import {
	getAllBackUpFile,
	pagingBackUpFiles,
	backupManually,
	resetDBBackUpStatus,
	resetDeleteBackUpFileStatus,
} from "../../../../01-actions/adminDB";
import Paginate from 'react-paginate';
import { pushNotify } from "../../../../03-components/Notification";
import DeleteFileConfirm from './DeleteFileConfirm/deleteFileConfirm';
import i18n from "i18next";
import Loading from '../../../../03-components/Loading';

class ManualBackup extends Component {
	state = {
		nextBackupMinutes: 0,
		nextBackupSeconds: 0,
		interval: null,
		isOpenDeleteFileConfirm: false,
		backupFileName: null,
	};
	
	componentDidMount() {
		this.props.actions.getAllBackUpFile();
		
		const interval = setInterval(() => {
			const nextBackupSeconds = 59 - moment().seconds();
			const nextBackupMinutes = 59 - moment().minutes() >= 30 ? 59 - moment().minutes() - 30 : 59 - moment().minutes();
			this.setState({ nextBackupMinutes, nextBackupSeconds });
		}, 1000);
		this.setState({ interval });
	}

	componentWillUnmount() {
		clearInterval(this.state.interval);
	}
	
	componentWillReceiveProps(nextProps) {
		if (nextProps.dbBackedUp) {
			pushNotify('success', i18n.t('DATABASE_BACKED_UP_SUCCESSFULLY'));
			nextProps.actions.resetDBBackUpStatus();
			nextProps.actions.getAllBackUpFile();
		}
		if (nextProps.backUpFileDeleted) {
			pushNotify('success', i18n.t('BACK_UP_FILE_DELETED_SUCCESSFULLY'));
			nextProps.actions.resetDeleteBackUpFileStatus();
			this.setState({ isOpenDeleteFileConfirm: false });
			nextProps.actions.getAllBackUpFile();
		}
		if (nextProps.backUpFiles !== this.props.backUpFiles) {
			this.props.actions.pagingBackUpFiles(nextProps.backUpFiles);
		}
	}

	onPageChange = data => {
		const { actions, backUpFiles } = this.props;
		actions.pagingBackUpFiles(backUpFiles, data.selected);
	};

	manualBackUp = () => {
		this.props.actions.backupManually();
	};

	toggleDeleteFileConfirm = backupFileName => {
		this.setState({ backupFileName, isOpenDeleteFileConfirm: !this.state.isOpenDeleteFileConfirm });
	};
	
	render() {
		const {
			nextBackupMinutes,
			nextBackupSeconds,
			isOpenDeleteFileConfirm,
			backupFileName,
		} = this.state;
		const { backUpFiles, displayBackUpFiles, limit, dbBackingUp } = this.props;
		
		return (
			<I18n ns="translations">
				{
					t => (
						<div>
							<div className="backup-now-wrapper">
								<b>{t('run_backup_now')}</b>
								<br/>
								<button
									className="btn btn-primary btn-lg"
									onClick={this.manualBackUp}
									disabled={dbBackingUp}
								>
									{
										dbBackingUp ? <Loading/> : <i className="fa fa-download"/>
									}
									<span>{` ${t('backup_now')}`}</span>
								</button>
								<br/>
								<span>{`${t('next_auto_backup_in')} ${nextBackupMinutes}m : ${nextBackupSeconds}s`}</span>
							</div>
							<h3 className="margin-top-15px">{t('all_backups')}</h3>
							<div className="table-responsive">
								<table className="table table-hover">
									<thead>
									<tr>
										<th>
											#
										</th>
										<th>
											{t('file_name')}
										</th>
										<th>
											{t('size')}
										</th>
										<th className="text-right">
											{t('actions')}
										</th>
									</tr>
									</thead>
									<tbody>
									{
										displayBackUpFiles ?
											displayBackUpFiles.map((file, index) => (
												<tr key={index}>
													<td>
														{file.index}
													</td>
													<td className="text-primary">
														{file.Key}
													</td>
													<td>
														{`${(file.Size / 1024 / 1024).toFixed(2)} MB`}
													</td>
													<td className="text-right">
														<button
															className="btn btn-danger btn-sm"
															onClick={() => this.toggleDeleteFileConfirm(file.Key)}
														>
															<i className="fa fa-trash"/>
															<span>{t('delete')}</span>
														</button>
													</td>
												</tr>
											))
											:
											(<Loading isTableRow colSpan={8}/>)
									}
									</tbody>
								</table>
								<DeleteFileConfirm
									toggle={this.toggleDeleteFileConfirm}
									isOpen={isOpenDeleteFileConfirm}
									backupFileName={backupFileName}
								/>
							</div>
							{
								displayBackUpFiles && backUpFiles.length > limit ?
									(<div className="paginate-wrapper text-center margin-top-15px">
										<Paginate
											pageCount={backUpFiles.length / limit}
											pageRangeDisplayed={3}
											marginPagesDisplayed={3}
											previousLabel="<"
											nextLabel=">"
											breakLabel="..."
											onPageChange={this.onPageChange}
											disabledClassName="paginate-disabled"
										/>
									</div>)
									:
									null
							}
						</div>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		backUpFiles: store.adminDB.backUpFiles,
		displayBackUpFiles: store.adminDB.displayBackUpFiles,
		limit: store.adminDB.limit,
		dbBackingUp: store.adminDB.dbBackingUp,
		dbBackedUp: store.adminDB.dbBackedUp,
		backUpFileDeleted: store.adminDB.backUpFileDeleted,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				getAllBackUpFile,
				pagingBackUpFiles,
				backupManually,
				resetDBBackUpStatus,
				resetDeleteBackUpFileStatus,
			},
			dispatch
		)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ManualBackup);