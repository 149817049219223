import React, { Component } from 'react';
import { I18n } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from "redux";
import { getMaintenanceModeStatus } from '../../01-actions/system';
import { logout } from '../../01-actions/auth';
import { withRouter } from 'react-router-dom';
import ChangeLanguageBar from '../../03-components/ChangeLanguageBar';
import AuthenBackgroundWrapper from '../Common/AuthenBackgroundWrapper/AuthenBackgroundWrapper';

class Maintenance extends Component {
    componentDidMount() {
        this.props.actions.logout();
        this.props.actions.getMaintenanceModeStatus();
    }

    componentWillReceiveProps(nextProps) {
        const { isFetchingMaintenanceModeStatus } = this.props;
        if (isFetchingMaintenanceModeStatus && !nextProps.isFetchingMaintenanceModeStatus && !nextProps.maintenanceMode) {
            this.props.history.push("/login");
        }
    }

    render() {
        return (
            <I18n ns="translations">
                {
                    (t, {i18n}) => (
                        <React.Fragment>
                            <div className="container-fluid page-body-wrapper full-page-wrapper auth-page">
                                <ChangeLanguageBar i18n={i18n}/>
                                <AuthenBackgroundWrapper>
                                    <div className="login-form-wrapper">
                                        <div className="row w-100 login-middle">
                                            <div className="col-lg-6 mx-auto text-center text-white">
                                                <h1>{t('maintenance_text_1')}</h1>
                                                <h2>{t('maintenance_text_2')}</h2>
                                                <span>{t('maintenance_text_3')}</span>
                                                <br/>
                                                <br/>
                                                <h3 className="text-primary">{t('maintenance_text_4')}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="footer-text text-center">
                                        <span className="d-block text-center">
                                            <span>Copyright © 2019 </span>
                                            <a href="/" target="_blank" rel="noopener noreferrer">Triple</a>
                                            <span>. All rights reserved.</span>
                                        </span>
                                    </p>
                                </AuthenBackgroundWrapper>
                            </div>
                        </React.Fragment>
                    )
                }
            </I18n>
        )
    }
};

const mapStateToProps = store => {
    return {
        maintenanceMode: store.system.maintenanceMode,
        isFetchingMaintenanceModeStatus: store.system.isFetchingMaintenanceModeStatus,
    }
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
                getMaintenanceModeStatus,
                logout
			},
			dispatch
		)
	};
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Maintenance);