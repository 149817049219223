import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Steps from 'rc-steps';

// components
import SelectUserTitle from '../../../../03-components/SelectUserTitle/selectUserTitle';
import SelectWallet from '../../../../03-components/SelectWallet/selectWallet';
import ConfirmAddress from '../../../../03-components/ConfirmAddress/confirmAddress';
import SubmitButton from '../../../../03-components/ModalSubmitButton';
import {
    getAmountBalance
} from '../../../../05-utils/commonUtils';
// styles
import './createProductCodeModal.css';
import OPPassModal from "../../../../03-components/OPPassModal/OPPassModal";
import {I18n} from "react-i18next";
import { constants } from '../../../../05-utils/commonData';

class CreateProductCodeModal extends Component {
    state = {
        currentStep: 0,
        disabled: false,
        userTitle: {},
        walletAddress: '',
        isOpenOPPass: false
    };

    steps = ['choose_type_code', 'choose_wallet', 'finish'];

    componentWillReceiveProps(nextProps) {
        if (!this.props.productCodeCreated  && nextProps.productCodeCreated) {
            this.refreshInputAndToggle();
        }
    }

    toggleOPPass = () => this.setState({ isOpenOPPass: !this.state.isOpenOPPass });

    switchModal = () => {
        const {
            currentStep
        } = this.state;

        if (currentStep === 0) {
            this.setState({
                currentStep: 1
            })
        } else if (currentStep === 1) {
            this.setState({
                currentStep: 2
            })
        } else {
            this.setState({
                isOpenOPPass: true
            });
        }
    };

    onReceiveOPPass = opPass => {
        const {
            userTitle,
            walletAddress
        } = this.state;

        this.props.onCreate({
            type: userTitle.type,
            titleName: userTitle.name,
            sourceAddress: walletAddress,
            amount: getAmountBalance(userTitle.coin_amount, 3),
            asset: 'AGT',
            opPass
        })
    };

    handleSelectTitle = (data) => {
        this.setState({
            userTitle: data
        })
    };

    handleSelectAddress = (data) => {
        this.setState({
            walletAddress: data
        })
    };

    refreshInputAndToggle = () => {
        this.setState({
            currentStep: 0,
            disabled: false,
            userTitle: {},
            walletAddress: '',
            isOpenOPPass: false
        });
        this.props.toggle();
    };

    onBack = () => {
        if (this.state.currentStep === 0)
            this.props.onBack();
        else
            this.setState({
                currentStep: this.state.currentStep -1
            })
    };


    render() {
        const {
            isOpen,
            listTitle,
            userWallets,
            isFetchingTitles,
            productCodeCreating,
            asset
        } = this.props;

        const {
            currentStep,
            userTitle,
            walletAddress,
            isOpenOPPass
        } = this.state;
        return (
            <I18n ns="translations">
                {
                    t => (
                        <Modal isOpen={isOpen} className="modal-lg">
                            <ModalHeader toggle={this.refreshInputAndToggle}>
                                {t('create_new_code')}
                            </ModalHeader>
                            <ModalBody>
                                <Steps current={currentStep} labelPlacement="vertical" progressDot size="small">
                                    { this.steps && this.steps.map((step, index) => (
                                        <Steps.Step title={t(`${step}`)} key={index}/>
                                    ))}
                                </Steps>
                                {
                                    currentStep === 0 &&
                                    <SelectUserTitle
                                        listTitle={listTitle}
                                        handleSelectTitle={this.handleSelectTitle}
                                        isFetchingTitles={isFetchingTitles}
                                        userTitle={userTitle}
                                        asset={asset}
                                    />
                                }
                                {
                                    currentStep === 1 &&
                                    <SelectWallet
                                        userWallets={userWallets}
                                        handleSelectAddress={this.handleSelectAddress}
                                        userTitle={userTitle}
                                        walletAddress={walletAddress}
                                        minAmountAccepted={userTitle.coin_amount}
                                    />
                                }
                                {
                                    currentStep === 2 &&
                                    <ConfirmAddress
                                        titleSelected={userTitle}
                                        walletSelected={walletAddress}
                                        typeCode={constants.TYPE_CODE.REGISTER_CODE}
                                    />
                                }

                                <OPPassModal
                                    isOpen={isOpenOPPass}
                                    toggle={this.toggleOPPass}
                                    onSubmit={this.onReceiveOPPass}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <SubmitButton
                                    className="btn btn-info"
                                    text={t('back')}
                                    onClick={this.onBack}
                                />
                                <SubmitButton
                                    className="btn btn-primary"
                                    onClick={this.switchModal}
                                    disabled={ (currentStep === 0 && !userTitle.id) || (currentStep === 1 && !walletAddress)}
                                    text={currentStep === 2 ? t('generate') : t('confirm')}
                                    isSubmitting={currentStep === 2 ? productCodeCreating : false}
                                />
                            </ModalFooter>
                        </Modal>
                    )
                }
            </I18n>
        );
    }
}

export default CreateProductCodeModal;