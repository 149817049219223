import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Collapse, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {pushNotify} from "../../../03-components/Notification";
import {
    adminGetAllUserWallet,
    adminUnblockUserWallet,
    resetUnblockUserWalletStatus,
    resetErrorAdminWalletApiMessage,
    adminSendUserWalletInformation, ACTIONS
} from "../../../01-actions/adminWallet";

import { 
    getUserProfile, 
    changeStatusUser, 
    resetTfa, 
    changeTfaStatus, 
    editUserData, 
    editEmailData, 
    changeEditEmailStatus,
    resetErrorAdminUserApiMessage
} from "../../../01-actions/adminUser";
import { I18n } from 'react-i18next';
import { constants } from "../../../05-utils/commonData";
import i18n from 'i18next';
import './userProfile.css';
import {Field, reduxForm} from "redux-form";
import SubmitButton from "../../../03-components/ModalSubmitButton";
import ChangeEmail from './ChangeEmail/changeEmail';
import validate from "./validate";
import Loading from '../../../03-components/Loading';
import Alert from "../../../03-components/Alert";
import { formatDateTimeFull } from '../../../05-utils/commonUtils';

class UserProfile extends Component {
	state = {
        walletOpening: [],
        firstname: '',
        middlename: '',
        lastname: '',
        isShowEditUserModal: false,
        email: '',
        isShowEditEmailModal: false,
        checkDisable:false,
        isOpenConfirmSendWalletInfo: false
	};

	componentDidMount() {
		const { actions } = this.props;
		const { armId } = this.props.match.params;
		actions.adminGetAllUserWallet({ armId });
		actions.getUserProfile({ armId });
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.accountStatus !== this.props.accountStatus) {
            nextProps.accountStatus === constants.ACCOUNT_LOCKED ? pushNotify('success', i18n.t('LOCK_USER_SUCCESSFULLY')): pushNotify('success', i18n.t('UNLOCK_USER_SUCCESSFULLY'));
			nextProps.actions.getUserProfile({ armId: nextProps.userProfile.armId });
		}
		if (nextProps.walletUnblocked) {
			pushNotify('success', i18n.t('UNBLOCK_USER_WALLET_SUCCESSFULLY'));
			nextProps.actions.resetUnblockUserWalletStatus();
			nextProps.actions.adminGetAllUserWallet({ armId: nextProps.userProfile.armId });
		}
        if (nextProps.resetTfaStatus) {
            nextProps.actions.changeTfaStatus();
            pushNotify('success', i18n.t('RESET_TFA_SUCCESSFULLY'));
        }
         if (nextProps.editedEmail && nextProps.editedEmail !== this.props.editedEmail ) {
            nextProps.actions.changeEditEmailStatus();
            pushNotify('success', i18n.t('EDIT_EMAIL_SUCCESSFULLY'));
        }
         if (this.props.status !== ACTIONS.ADMIN_SEND_USER_WALLET_INFORMATION_SUCCESS && nextProps.status === ACTIONS.ADMIN_SEND_USER_WALLET_INFORMATION_SUCCESS) {
            pushNotify('success', i18n.t('ADMIN_SEND_USER_WALLET_INFORMATION_SUCCESS'));
        }
        if (nextProps.userProfile !== this.props.userProfile) {
            const { firstname, middlename, lastname, email } = nextProps.userProfile || {};
            this.setState({
                firstname,
                middlename,
                lastname,
                email,
            })
        }
	}

	toggleWallet = address =>{
		const { walletOpening } = this.state;
		walletOpening.includes(address) ? walletOpening.splice(walletOpening.indexOf(address), 1) : walletOpening.push(address);
		this.setState({ walletOpening });
	};

	changeStatusUser = () => {
		const { userProfile, actions } = this.props;
		actions.changeStatusUser({ userID: userProfile._id });
	};
    resetTfa = () => {
        const { userProfile, actions } = this.props;
        actions.resetTfa({ armId: userProfile.armId });
    };
    editUserSubmit = () => {
        const {firstname, middlename, lastname} = this.state;
        this.props.actions.editUserData({
            id: this.props.userProfile && this.props.userProfile._id,
            firstname: firstname,
            middlename: middlename,
            lastname: lastname
        });
        this.setState({isShowEditUserModal: false})
    };

    editEmaiSubmit = () => {
        const {email} = this.state;
        this.props.actions.editEmailData({
            id: this.props.userProfile && this.props.userProfile._id,
            email: email
        });
        this.setState({isShowEditEmailModal: false, checkDisable : false})
    };

	unblockUserWallet = address => {
		const { userWallets, actions } = this.props;
		const listWalletAddress = address ?
			[address]
			:
			userWallets.map(wallet => wallet.address);

		actions.adminUnblockUserWallet({ listWalletAddress });
    };

    sendWalletInformation = () => {
		const { userProfile, actions } = this.props;
		actions.adminSendUserWalletInformation({ userID: userProfile._id });
        this.setState({
            isOpenConfirmSendWalletInfo: false
        });
    };
    refreshInputAndToggle = () => {
        const { firstname, middlename, lastname, email } = this.props.userProfile || {};
        this.setState({
            walletOpening: [],
            firstname,
            middlename,
            lastname,
            isShowEditUserModal: false,
            email,
            isShowEditEmailModal: false,
            checkDisable: false,
        });
    };

    toggleEditEmail = () => this.setState({ isShowEditEmailModal: !this.state.isShowEditEmailModal });

    renderField = ({input, label, type, fieldValue, disabled, placeholder, hideLabel, meta: {touched, error}}) => (
        <div className="form-group">
            {
                hideLabel ? null : <label>{label}</label>
            }
            <div className="input-group">
                <input
                    {...input}
                    type={type}
                    className="form-control transfer-input"
                    placeholder={placeholder}
                    disabled={disabled}
                    value={fieldValue}
                    maxLength={25}
                    onChange={e => {
                        this.setState({[input.name]: e.target.value, checkDisable: true})
                    }}
                />
                {touched && error && <p className="text-danger">{`* ${error}`}</p>}
            </div>
        </div>
    );

    renderUserForm(t) {
        const { userProfile } = this.props;
        const {
            isShowEditUserModal,
            firstname,
            middlename,
            lastname
        } = this.state;
        return (
            <Modal isOpen={isShowEditUserModal} className="modal-lg">
                <ModalHeader toggle={this.refreshInputAndToggle}>{t('edit_user_info')}</ModalHeader>
                <ModalBody>
                    <form className="margin-top-1rem">
                        <Field
                            name="email"
                            type="text"
                            component={this.renderField}
                            disabled={true}
                            label={t('email')}
                            fieldValue={userProfile && userProfile.email}
                        />
                        <Field
                            name="firstname"
                            type="text"
                            component={this.renderField}
                            label={t('firstname')}
                            fieldValue={firstname}
                        />
                        <Field
                            name="middlename"
                            type="text"
                            component={this.renderField}
                            placeholder="optional"
                            label={t('middlename')}
                            fieldValue={middlename}
                        />
                        <Field
                            name="lastname"
                            type="text"
                            component={this.renderField}
                            label={t('lastname')}
                            fieldValue={lastname}
                        />
                    </form>
                </ModalBody>
                <ModalFooter>
                    <SubmitButton
                        className="btn btn-primary"
                        onClick={this.editUserSubmit}
                        disabled={
                            !firstname
                            || !lastname
                            || firstname.length > constants.MAX_LENGTH_NAME_OF_USER
                            || (middlename && middlename.length > constants.MAX_LENGTH_NAME_OF_USER)
                            || lastname.length > constants.MAX_LENGTH_NAME_OF_USER
                        }
                        text="OK"
                    />
                </ModalFooter>
            </Modal>
        )
    }   
    renderEmailForm(t) {
        // const {userProfile} = this.props;
        const {isShowEditEmailModal, email} = this.state;
        return (
            <Modal isOpen={isShowEditEmailModal} className="modal-lg">
                <ModalHeader toggle={this.refreshInputAndToggle}>{t('edit_email_of_user')}</ModalHeader>
                <ModalBody>
                    <form className="margin-top-1rem">
                        <Field
                            name="email"
                            type="text"
                            component={this.renderField}
                            label={t('email')}
                            fieldValue={this.state.email}
                        />
                    </form>
                </ModalBody>
                <ModalFooter>
                    <SubmitButton
                        className="btn btn-primary"
                        onClick={this.editEmaiSubmit}
                        disabled={!this.state.checkDisable || !email}
                        text="OK"
                    />
                </ModalFooter>
            </Modal>
        )
    }
	render() {
		const {
			isFetching,
			userWallets,
			userProfile,
		} = this.props;

		return (
			<I18n ns="translations">
				{
					t => (
                        <React.Fragment>
						<div className="row grid-margin">
                        {this.renderUserForm(t)}
                            {
                                <ChangeEmail
                                    isOpen={this.state.isShowEditEmailModal}
                                    toggle={this.toggleEditEmail}
                                    email={this.props.userProfile && this.props.userProfile.email}
                                    userID={this.props.userProfile && this.props.userProfile._id}
                                />
                            }
							<div className="col-lg-5 margin-bottom-15px">
								<div className="card">
									<h4 className="card-title">{t('user_info')}</h4>
									{
										userProfile ?
											(<div>
												<div className="property-name"><b>{t('email')}</b></div>
												<div className="d-flex justify-content-left align-items-center">
                                                    <div className="ml-0">
                                                        {userProfile.email}
                                                    </div>
                                                    <button
                                                        className="btn btn-info ml-2"
                                                        onClick={() => this.setState({isShowEditEmailModal: true})}
                                                    >
                                                        <i className="far fa-envelope"/>
                                                        <span>{t('edit_email')}</span>
                                                    </button>
                                                </div>
												<div className="property-name"><b>{t('triple_id')}</b></div>
												<div>{userProfile.armId}</div>
												<div className="property-name"><b>{t('usercode')}</b></div>
												<div>{userProfile.userCode || 'none'}</div>
												<div className="property-name"><b>{t('first_name')}</b></div>
												<div>{userProfile.firstname || 'none'}</div>
												<div className="property-name"><b>{t('middle_name')}</b></div>
												<div>{userProfile.middlename || 'none'}</div>
												<div className="property-name"><b>{t('last_name')}</b></div>
												<div>{userProfile.lastname || 'none'}</div>
												<div className="property-name"><b>{t('created_at')}</b></div>
												<div>{formatDateTimeFull(userProfile && userProfile.createdAt)}</div>
												<div>
													<button className="btn btn-danger margin-top-15px" onClick={this.changeStatusUser}>
														{
															userProfile.accountStatus === constants.ACCOUNT_LOCKED ?
																(<span>
																	<i className="fa fa-unlock"/>
																	<span>{t('unlock_user')}</span>
																</span>)
                                                                    :
                                                                    (<span>
																	<i className="fa fa-lock"/>
																	<span>{t('lock_user')}</span>
																</span>)
														}
													</button>
                                                    {/*<button className="btn btn-success margin-top-15px ml-2" onClick={this.resetTfa}>*/}

                                                    {/*            <span>*/}
													{/*				<i className="fas fa-redo"/>*/}
													{/*				<span>{t('reset_tfa')}</span>*/}
													{/*			</span>*/}


                                                    {/*</button>*/}
                                                    <button
                                                            className="btn btn-primary margin-top-15px ml-2"
                                                            onClick={() => this.setState({isShowEditUserModal: true})}>
                                                        <span>
                                                            <i className="fa fa-user"/>
                                                            <span>{t('edit_user')}</span>
                                                        </span>
                                                    </button>
                                                    <button
                                                            className="btn btn-success margin-top-15px ml-2"
                                                            onClick={() => {this.setState({isOpenConfirmSendWalletInfo: true})}}>
                                                        <span>
                                                            <i className="fa fa-wallet"/>
                                                            <span>{t('send_wallet_info')}</span>
                                                        </span>
                                                    </button>

                                                    <Alert isOpen={this.state.isOpenConfirmSendWalletInfo}
                                                           onClose={ () => {
                                                               this.setState({
                                                                   isOpenConfirmSendWalletInfo: false
                                                               });
                                                           } }
                                                           title={t(`confirm`)}
                                                           content={t('confirm_send_user_wallet_information')}
                                                           onOK={this.sendWalletInformation}
                                                           buttonOK={t('OK')}
                                                           buttonClose={t('close')}
                                                    />
												</div>

											</div>)
											:
											(<Loading parent1Class="text-center"/>)
									}
								</div>
							</div>
							<div className="col-lg-7">
								<div className="card">
									<div className="user-wallets-header">
										<h4 className="card-title">{t('all_wallets')}</h4>
										{/*<button*/}
										{/*	className="btn btn-primary"*/}
										{/*	disabled={!(userWallets && userWallets.some(wallet => wallet.block)) || walletUnblocking}*/}
										{/*	onClick={() => this.unblockUserWallet()}*/}
										{/*>*/}
										{/*	{*/}
										{/*		walletUnblocking ?*/}
										{/*			(<Loading/>)*/}
										{/*			:*/}
										{/*			(<i className="fa fa-unlock"/>)*/}
										{/*	}*/}
										{/*	<span>{` ${t('unblock_all_wallet')}`}</span>*/}
										{/*</button>*/}
									</div>
									{
										!isFetching ?
											userWallets && userWallets.length > 0 ?
												userWallets.map((wallet, index) => (
													<div className="user-wallet-wrapper" key={index}>
														<div>
															<b>{`${t('wallet_name')}:`}</b>
															<span className="margin-bottom-15px">{` ${wallet.name}`}</span>
															<br/>
															<b>{`${t('wallet_address')}:`}</b>
															<span>{` ${wallet.address} `}</span>
															{/*{*/}
															{/*	wallet.block && (*/}
															{/*		<button*/}
															{/*			className="btn btn-link padding-unset"*/}
															{/*			title={t('unblock_this_wallet')}*/}
															{/*			onClick={() => this.unblockUserWallet(wallet.address)}*/}
															{/*			disabled={walletUnblocking}*/}
															{/*		>*/}
															{/*			<span className="text-center">*/}
															{/*				<i className="fa fa-unlock"/>*/}
															{/*			</span>*/}
                                                            {/*        </button>*/}
                                                            {/*    )*/}
                                                            {/*}*/}
                                                        </div>
                                                        <button
                                                            className="btn btn-link padding-unset"
                                                            onClick={() => this.toggleWallet(wallet.address)}
                                                        >
                                                            {t('wallet_balances')}
                                                        </button>
                                                        <Collapse
                                                            isOpen={this.state.walletOpening.includes(wallet.address)}>
                                                            {
                                                                wallet.balances && wallet.balances.map((balance, index) => (
                                                                    <div className="user-balance-wrapper" key={index}>
                                                                        <div>
                                                                            <span>{`${t('asset')}:`}</span><b>{` ${balance.asset_code || "RIA"}`}</b>
                                                                        </div>
                                                                        <div>
                                                                            <span>{`${t('amount')}:`}</span><b>{` ${balance.balance}`}</b>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </Collapse>
                                                    </div>
                                                ))
                                                :
                                                (<div>{t('user_not_have_wallet')}</div>)
                                            :
                                            (<Loading parent1Class="text-center"/>)
                                    }
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
        </I18n>
    );
}
}

const mapStateToProps = store => {
	return {
        status: store.adminWallet.status,
		userWallets: store.adminWallet.userWallets,
		isFetching: store.adminWallet.isFetching,
		walletUnblocking: store.adminWallet.walletUnblocking,
		walletUnblocked: store.adminWallet.walletUnblocked,
		userProfile: store.adminUser.userProfile,
		accountStatus: store.adminUser.accountStatus,
        resetTfaStatus: store.adminUser.resetTfaStatus,
        editedEmail: store.adminUser.editedEmail,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				adminGetAllUserWallet,
				getUserProfile,
				changeStatusUser,
				adminUnblockUserWallet,
				resetUnblockUserWalletStatus,
                resetTfa,
                changeTfaStatus,
                editUserData,
                editEmailData,
                changeEditEmailStatus,
                resetErrorAdminWalletApiMessage,
                resetErrorAdminUserApiMessage,
                adminSendUserWalletInformation
			},
			dispatch
		)
	};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(reduxForm({
    form: 'userForm',
    validate,
})(UserProfile));