import { createSelector } from 'reselect'
import { constants } from '../05-utils/commonData';

const getErrorAddressBook = state => state.addressBook.error;
const getErrorAdminDB = state => state.adminDB.error;
const getErrorAdminNews = state => state.adminNews.error;
const getErrorAdminUser = state => state.adminUser.error;
const getErrorAdminWallet = state => state.adminWallet.error;
const getErrorAsset = state => state.asset.error;
const getErrorEmail = state => state.email.error;
const getErrorSystem = state => state.system.error;
const getErrorTransaction = state => state.transaction.error;
const getErrorUser = state => state.user.error;
const getErrorWallet = state => state.wallet.error;

const getTokenErrors = createSelector(
    [
        getErrorAddressBook,
        getErrorAdminDB,
        getErrorAdminNews,
        getErrorAdminUser,
        getErrorAdminWallet,
        getErrorAsset,
        getErrorEmail,
        getErrorSystem,
        getErrorTransaction,
        getErrorUser,
        getErrorWallet,
    ],
    (
        addressBookErr,
        adminDBErr,
        adminNewsErr,
        adminUserErr,
        adminWalletErr,
        assetErr,
        emailErr,
        systemErr,
        transactionErr,
        userErr,
        walletErr,
    ) => {
        const errorsList = [
            addressBookErr,
            adminDBErr,
            adminNewsErr,
            adminUserErr,
            adminWalletErr,
            assetErr,
            emailErr,
            systemErr,
            transactionErr,
            userErr,
            walletErr,
        ];
        return errorsList.find(error => error && constants.tokenErrors.includes(error.errorCode));
    }
);

export {
    getTokenErrors,
}