import React, { PureComponent, Fragment } from 'react';

export default class AuthCard extends PureComponent {
    render() {
        const {
            intro,
            redirectOnClick,
            btnText,
        } = this.props;

        return  (
            <Fragment>
                <div className="logo-wrapper">
                    <img src="/images/auth/logo.png" alt=""/>
                    <br/>
                    {/*<span className="text-white">TRIPLE WALLET</span>*/}
                </div>
                <div className="btn-wrapper">
                    <p className="login-intro">{intro}</p>
                    {
                        !intro && (<br/>)
                    }
                    <button
                        className="btn btn-outline-white-custom btn-rounded"
                        onClick={redirectOnClick}
                    >
                        {btnText}
                    </button>
                </div>
                <div/>
                <img className="img-top" src="/images/auth/decor_2.png" alt=""/>
                <img className="img-bottom" src="/images/auth/decor_1.png" alt=""/>
            </Fragment>
        );
    }
}