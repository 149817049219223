import React, { Component } from 'react';
import { I18n } from 'react-i18next';
import ConfirmResetModal from './ConfirmResetModal/confirmResetModal';
import { pushNotify } from "../../../03-components/Notification";
import { getUserName, isChineseUser } from "../../../05-utils/commonUtils";
import { getListUserInfo, resetAdminResetUserStatus } from "../../../01-actions/adminUser";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Paginate from 'react-paginate';
import i18n from "i18next";
import Loading from '../../../03-components/Loading';

class ResetUser extends Component {
	state = {
		usersSelected: [],
		isOpenConfirmReset: false,
		searchParam: '',
	};
	
	componentDidMount() {
		this.props.actions.getListUserInfo({});
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.userReset) {
			pushNotify('success', i18n.t('USER_RESET_SUCCESSFULLY'));
			nextProps.actions.resetAdminResetUserStatus();
			this.setState({ isOpenConfirmReset: false, usersSelected: [] });
		}
	}

	onKeySearchPress = e => {
		if (e.key === 'Enter')
			this.props.actions.getListUserInfo({ searchParam: this.state.searchParam });
	};

	onSearchChange = e => {
		this.setState({ searchParam: e.target.value.trim() }, () => {
			if (this.state.searchParam === '')
				this.props.actions.getListUserInfo({});
		});

	};

	onPageChange = data => this.props.actions.getListUserInfo({
		searchParam: this.state.searchParam,
		numPage: data.selected,
	});

	onUserSelected = (e, userID) => {
		const { usersSelected } = this.state;
		e.target.checked ? usersSelected.push(userID) : usersSelected.splice(usersSelected.indexOf(userID), 1);
		this.setState({ usersSelected });
	};

	toggleConfirmReset = () => this.setState({ isOpenConfirmReset: !this.state.isOpenConfirmReset });
	
	render() {
		const { totalUser, users, isFetching } = this.props;
		const { usersSelected, isOpenConfirmReset } = this.state;
		
		return (
			<I18n ns="translations">
				{
					(t, {i18n}) => (
						<div className="row">
							<div className="col-lg-12 grid-margin">
								<div className="card">
									<h4 className="card-title">{t('reset_user_and_resend_email')}</h4>
									<b className="text-danger">
										<i className="fa fa-exclamation-triangle"/>
										<span>{t('reset_user_intro')}</span>
									</b>
									<div className="input-group margin-bottom-15px margin-top-15px">
										<input
											type="text"
											className="form-control"
											placeholder={t('search_user')}
											onKeyPress={this.onKeySearchPress}
											onChange={this.onSearchChange}
										/>
									</div>
									<div>
										<button
											className="btn btn-primary"
											onClick={this.toggleConfirmReset}
											disabled={usersSelected.length === 0}
										>
											{t('reset_and_send_email')}
										</button>
										<ConfirmResetModal
											toggle={this.toggleConfirmReset}
											isOpen={isOpenConfirmReset}
											usersSelected={usersSelected}
										/>
									</div>
									<div className="table-responsive">
										<table className="table table-hover">
											<thead>
											<tr>
												<th>
													#
												</th>
												<th>
													{t('email_or_usercode')}
												</th>
												<th>
													{t('name')}
												</th>
												<th className="text-center">
													{t('ga_verified')}
												</th>
												<th>
													{t('control')}
												</th>
											</tr>
											</thead>
											<tbody>
											{
												users ?
													users.map((user, index) => (
														<tr key={index}>
															<td>
																{user.index}
															</td>
															<td className="text-primary">
																{isChineseUser(user.userCode) ? user.userCode : user.email}
															</td>
															<td>
																{getUserName(user)}
															</td>
															<td className="text-center">
																{
																	user.verifyGA ?
																		(<i className="text-success fa fa-check"/>)
																		:
																		(<i className="text-danger fa fa-times"/>)
																}
															</td>
															<td>
																<div className="form-check margin-bottom-15px">
																	<label className="form-check-label">
																		<input
																			type="checkbox"
																			className="form-check-input"
																			onChange={e => this.onUserSelected(e, user._id)}
																			checked={usersSelected.includes(user._id)}
																		/>
																		&nbsp;
																		<i className="input-helper"/>
																	</label>
																</div>
															</td>
														</tr>
													))
													:
													null
											}
											</tbody>
										</table>
									</div>
									{
										users &&
											(<div className="paginate-wrapper text-center margin-top-15px">
												<Paginate
													pageCount={totalUser / 50}
													pageRangeDisplayed={3}
													marginPagesDisplayed={3}
													previousLabel="<"
													nextLabel=">"
													breakLabel="..."
													onPageChange={this.onPageChange}
													disabledClassName="paginate-disabled"
												/>
											</div>)
									}
									{
										isFetching && (<Loading parent1Class="text-center"/>)
									}
								</div>
							</div>
						</div>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		users: store.adminUser.users,
		totalUser: store.adminUser.totalUser,
		isFetching: store.adminUser.isFetching,
		userReset: store.adminUser.userReset,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				getListUserInfo,
				resetAdminResetUserStatus,
			},
			dispatch
		)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetUser);