import React, { Component } from 'react';
import { I18n } from 'react-i18next'
import './eZpayTopUp.css';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import EZPayRow from "../../../../../03-components/EZPayRow";
import BigNumber from 'bignumber.js';
import SubmitButton from "../../../../../03-components/ModalSubmitButton";
import ModalTemplate from './modalTemplate';
import Loading from "../../../../../03-components/Loading";
import EventRegister, { EVENT_CLOSE_EZPAY_TOP_UP } from '../../../../../05-utils/eventRegister';

class EZPayTransferToCard extends Component {
    state = {
        currentAvailableTCT: null,
        amountAGTNeed: 0,
        totalFee: 0,
        transferFeeTopUp: 0,
        totalTransferAGT: 0,
        totalTransferTCT: 0,
        amountTCTNeed: 0,
        requestAmount: null
    };

    componentDidMount() {
        this.closeEzpayTopUp = EventRegister.on(EVENT_CLOSE_EZPAY_TOP_UP, () => {
            this.setState({
                currentAvailableTCT: null,
                amountAGTNeed: 0,
                totalFee: 0,
                transferFeeTopUp: 0,
                totalTransferAGT: 0,
                totalTransferTCT: 0,
                amountTCTNeed: 0,
                requestAmount: 0
            });
        });
    }

    componentWillUnmount() {
        EventRegister.off(this.closeEzpayTopUp);
    }

    refreshInputAndToggle = () => {
        this.props.toggle();
    };

    onEnterAmount = ({ event, availableAmountTCT, availableAmountAGT  }) => {
        const {
            ezpFee,
            wcgFee,
            exchangeAGTToTCTInfo,
        } = this.props;
        const amount = event.target.value;
        let amountTCT = new BigNumber(amount);
        let transferFeeTopUp;
        let totalTransferTCT;
        if (amountTCT.gt(0)) {
            let topUpFee = new BigNumber(1)
                .minus(wcgFee)
                .times(new BigNumber(1).minus(ezpFee));
            // total TCT need for topup 'amount' input
            totalTransferTCT = amountTCT.dividedBy(topUpFee.toString() || 1);
            // In case: total TCT need for topUp > total available TCT have
            if (new BigNumber(totalTransferTCT).gt(availableAmountTCT)) {
                // Calculate TCT need for enough amount top up
                let amountTCTNeed = new BigNumber(totalTransferTCT).minus(availableAmountTCT || 0).toString();
                //Calculate AGT need (TCT*rate)
                let amountAGTNeed = exchangeAGTToTCTInfo ? new BigNumber(amountTCTNeed).multipliedBy(exchangeAGTToTCTInfo.rate).toString() : 0;
                // Calculate AGT fee OTC
                let totalFee = exchangeAGTToTCTInfo ? new BigNumber(amountTCTNeed).multipliedBy(exchangeAGTToTCTInfo.rate || 0).multipliedBy(exchangeAGTToTCTInfo.fee || 0).toString() : 0;
                // Calculate total amount AGT for OTC (AGT/TCT) and fee OTC
                let totalTransferAGT = new BigNumber(amountAGTNeed).plus(totalFee.toString()).toString();
                // round up all number
                BigNumber.config({
                    ROUNDING_MODE: BigNumber.ROUND_UP,
                    DECIMAL_PLACES: 3
                });
                amountAGTNeed = new BigNumber(amountAGTNeed, 10).toString();
                totalFee = new BigNumber(totalFee, 10).toString();
                totalTransferAGT = new BigNumber(totalTransferAGT, 10).toString();
                this.setState({amountAGTNeed, totalFee, totalTransferAGT, amountTCTNeed});
                if (Number(availableAmountAGT) < Number(totalTransferAGT) && Number(availableAmountTCT) < Number(totalTransferTCT)) {
                    event.preventDefault();
                    return;
                }
            } else {
                this.setState({
                    amountAGTNeed: 0,
                    totalFee: 0,
                    amountTCTNeed: 0,
                    totalTransferAGT: 0
                });
            }
            BigNumber.config({
                ROUNDING_MODE: BigNumber.ROUND_UP,
                DECIMAL_PLACES: 3
            });
            totalTransferTCT = new BigNumber(totalTransferTCT, 10).toString();
            // total fee topup (WCGFee and EZPFee)
            transferFeeTopUp = new BigNumber(totalTransferTCT.toString()).minus(amountTCT).toString();
            this.setState({transferFeeTopUp, totalTransferTCT, requestAmount: amount});
        } else {
            this.setState({
                requestAmount: 0,
                amountAGTNeed: 0,
                transferFeeTopUp: 0,
                totalTransferTCT: 0,
                totalTransferAGT: 0,
                totalFee: 0,
            });
        }
    };


    render() {
        const {
            isOpen,
            balanceAGT,
            balanceTCT,
            onPressBack,
            onPressRequest,
            isFetchingPrepareForTopUp,
            isFetchingOTCCurrency,
            exchangeAGTToTCTInfo
        } = this.props;
        const {
            amountAGTNeed,
            totalFee,
            transferFeeTopUp,
            totalTransferTCT,
            totalTransferAGT,
            amountTCTNeed,
            requestAmount
        } = this.state;
        let availableAmountTCT = new BigNumber(balanceTCT && balanceTCT.avaiable_balance.replace(/,/g, ""));
        let availableAmountAGT = Number(balanceTCT && balanceAGT.avaiable_balance.replace(/,/g, ""));
        return (
            <I18n ns="translations">
                {
                    t => (
                        <ModalTemplate
                            isOpen={isOpen}
                            titleText={t('transfer_to_card')}
                            refreshInputAndToggle={this.refreshInputAndToggle}
                        >
                            <div className='transfer-to-card-body'>
                                <div className='transfer-to-card-image-bg-step2'>
                                    <div className='transfer-to-card-amount-wrapper'>
                                        <div className='row'>
                                            <div className='col-sm-6'>
                                                <div className='row border-right-sm'>
                                                    <div className='col-12'>
                                                        <EZPayRow label={t('request_amount')}
                                                            requestAmount={requestAmount}
                                                            isUnderline={true}
                                                            textClass='text-size-18'
                                                            isVerticalLine={true}
                                                            isInput={true}
                                                            onEnterAmount={event =>
                                                                this.onEnterAmount({ event, availableAmountTCT, availableAmountAGT })
                                                            } />
                                                        {!isFetchingOTCCurrency ?
                                                            !exchangeAGTToTCTInfo ?
                                                                <div className='row'>
                                                                    <div className='col-12'>
                                                                        <div className='not-allow-otc margin-top-half-rem margin-bottom-half-rem' >
                                                                            <p className=''>{t('not_allow_otc')}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : <div>
                                                                    <EZPayRow label={t('need_otc_to_tct')}
                                                                        value={amountAGTNeed}
                                                                        textClass='text-size-14'
                                                                        marginClass='margin-top-half-rem' />
                                                                    <EZPayRow label={t('otc_rate')}
                                                                        value={exchangeAGTToTCTInfo && exchangeAGTToTCTInfo.rate}
                                                                        textClass='text-size-14'
                                                                        isLoading={true}
                                                                        marginClass='margin-top-half-rem' />
                                                                    <EZPayRow label={t('otc_fee')}
                                                                        value={exchangeAGTToTCTInfo && exchangeAGTToTCTInfo.fee}
                                                                        textClass='text-size-14'
                                                                        isLoading={true}
                                                                        marginClass='margin-top-half-rem' />
                                                                    <EZPayRow label={t('total_otc_fee')}
                                                                        value={totalFee}
                                                                        isUnderline={true}
                                                                        textClass='text-size-18'
                                                                        isVerticalLine={true}
                                                                        marginClass='margin-top-half-rem' />
                                                                </div>
                                                            : (<Loading parent1Class="text-center" />)
                                                        }
                                                        <EZPayRow label={t('transfer_fee')}
                                                            value={transferFeeTopUp}
                                                            textClass='text-size-14'
                                                            marginClass='margin-top-half-rem' />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-12 hidden-large-screen'>
                                                        <EZPayRow label={t('available_agt_amount')}
                                                            value={balanceAGT && balanceAGT.avaiable_balance}
                                                            isUnderline={true}
                                                            isLoading={true}
                                                            textClass='text-size-18' />
                                                        <EZPayRow label={t('available_tct')}
                                                            value={balanceTCT && balanceTCT.avaiable_balance}
                                                            isUnderline={true}
                                                            isLoading={true}
                                                            textClass='text-size-18'
                                                            marginClass='margin-top-half-rem' />
                                                        <EZPayRow label={t('in_order')}
                                                            value={balanceTCT && balanceTCT.in_order_balance}
                                                            isUnderline={true}
                                                            isLoading={true}
                                                            textClass='text-size-18'
                                                            marginClass='margin-top-half-rem' />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <EZPayRow label={t('total_transfer')}
                                                            value={`${totalTransferAGT} (AGT)`}
                                                            isUnderline={true}
                                                            textClass='text-size-18'
                                                            isVerticalLine={true}
                                                            marginClass='margin-top-1rem' />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <EZPayRow label={t('')}
                                                            value={`${amountTCTNeed ? availableAmountTCT : totalTransferTCT} (TCT)`}
                                                            isUnderline={true}
                                                            textClass='text-size-18' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-6'>
                                                <div className='row'>
                                                    <div className='col-12 hide-small-screen  hidden-medium-screen'>
                                                        <EZPayRow label={t('available_agt_amount')}
                                                            value={balanceAGT && balanceAGT.avaiable_balance}
                                                            isUnderline={true}
                                                            isLoading={true}
                                                            textClass='text-size-18' />
                                                        <EZPayRow label={t('available_tct')}
                                                            value={balanceTCT && balanceTCT.avaiable_balance}
                                                            isUnderline={true}
                                                            isLoading={true}
                                                            textClass='text-size-18'
                                                            marginClass='margin-top-half-rem' />
                                                        <EZPayRow label={t('in_order')}
                                                            value={balanceTCT && balanceTCT.in_order_balance}
                                                            isUnderline={true}
                                                            isLoading={true}
                                                            textClass='text-size-18'
                                                            marginClass='margin-top-half-rem' />
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='ezpay-note-wrapper margin-top-1rem' style={{ borderColor: "grey.500" }}>
                                                            <span className='text-black text-size-12'>{t('ezpay_note')}</span><br />
                                                            <span className='text-black text-size-12'>{t('note_1')}</span><br />
                                                            <span className='text-black text-size-12'>{t('note_2')}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center margin-top-1rem margin-bottom-half-rem'>
                                        <SubmitButton
                                            className="btn btn-primary btn-fw select-application-id-button"
                                            onClick={onPressBack}
                                            text={t('back')}
                                        />
                                        <SubmitButton
                                            className="btn btn-primary btn-fw select-application-id-button"
                                            onClick={() => {
                                                onPressRequest({
                                                    amount: requestAmount
                                                })
                                            }}
                                            isSubmitting={isFetchingPrepareForTopUp}
                                            disabled={!requestAmount || requestAmount < 100 || availableAmountAGT < Number(totalTransferAGT) || (!isFetchingOTCCurrency && !exchangeAGTToTCTInfo && availableAmountTCT < Number(totalTransferTCT))}
                                            text={t('request')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </ModalTemplate>
                    )
                }
            </I18n>
        );
    }
}

const mapStateToProps = store => {
    return {
        balanceAGT: store.topUp.balanceAGT,
        balanceTCT: store.topUp.balanceTCT,
        ezpFee: store.topUp.ezpFee,
        wcgFee: store.topUp.wcgFee,
        isUnlimited: store.topUp.isUnlimited,
        isFetchingInfoEzpay: store.topUp.isFetchingInfoEzpay,
        isFetchingPrepareForTopUp: store.topUp.isFetchingPrepareForTopUp,
        isFetchingOTCCurrency: store.topUp.isFetchingOTCCurrency,
        exchangeAGTToTCTInfo: store.topUp.exchangeAGTToTCTInfo,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(
            {

            },
            dispatch
        )
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EZPayTransferToCard);