import { ACTIONS } from '../01-actions/topUp';
import { AUTH_ACTIONS } from '../01-actions';
import { UTIL_ACTIONS } from '../01-actions/utils';

const initialState = {
	status: null,
	error: null,
	usercode: null,
	balanceTCT: null,
	balanceAGT: null,
	ezpFee: 0,
	wcgFee: 0,
	isUnlimited: null,
	isFetchingInfoEzpay: false,
	isAllowTopUp: false,
	isFetchingSGPBalance: false,
	isFetchingPrepareForTopUp: false,
	accessToken: null,
	isLoginProgress: false,
	isTopUpProgress: false,
	userTopUpInfoLogID: null,
	listCardsOfEZPay: [],
	listCardsOfAlliedCard: [],
	isFetchingCards: false,
	isFetchingOTCCurrency: false,
	exchangeAGTToTCTInfo: null,
	topUpHistories: null,
	isFetchingTopUpHistory: false,
	topUpHistoryPageCount: 0,
	displayTopUpHistory: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.GET_USER_CODE_PROGRESS:
			return {
				...state,
				status: action.type,
			};
		case ACTIONS.GET_USER_CODE_SUCCESS:
			return {
				...state,
				status: action.type,
				usercode: action.data.usercode,
			};
		case ACTIONS.GET_USER_CODE_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
			};
		case ACTIONS.GET_SGP_BALANCE_INFO_PROGRESS:
			return {
				...state,
				status: action.type,
				isFetchingSGPBalance: true
			};
		case ACTIONS.GET_SGP_BALANCE_INFO_SUCCESS:
			return {
				...state,
				status: action.type,
				balanceAGT: action.data.balanceAGT,
				balanceTCT: action.data.balanceTCT,
				isFetchingSGPBalance: false
			};
		case ACTIONS.GET_SGP_BALANCE_INFO_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				isFetchingSGPBalance: false
			};
		case ACTIONS.GET_OTC_CURRENCY_INFO_PROGRESS:
			return {
				...state,
				status: action.type,
				isFetchingOTCCurrency: true
			};
		case ACTIONS.GET_OTC_CURRENCY_INFO_SUCCESS:
			const exchangeAGTToTCTInfo = action.data.otcCurrencyInfo && action.data.otcCurrencyInfo.exchange_markets.find(item => item.type === "AGT/TCT");
			return {
				...state,
				status: action.type,
				exchangeAGTToTCTInfo,
				isFetchingOTCCurrency: false
			};
		case ACTIONS.GET_OTC_CURRENCY_INFO_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				isFetchingOTCCurrency: false
			};
		case ACTIONS.GET_INFO_EZPAY_PROGRESS:
			return {
				...state,
				status: action.type,
				isFetchingInfoEzpay: true,
			};
		case ACTIONS.GET_INFO_EZPAY_SUCCESS:
			return {
				...state,
				status: action.type,
				ezpFee: action.data.ezpFee,
				wcgFee: action.data.wcgFee,
				isUnlimited: action.data.isUnlimited,
				isFetchingInfoEzpay: false,
			};
		case ACTIONS.GET_INFO_EZPAY_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				isFetchingInfoEzpay: false,
			};
		case ACTIONS.PREPARE_FOR_TOP_UP_PROGRESS:
			return {
				...state,
				status: action.type,
				isFetchingPrepareForTopUp: true,
			};
		case ACTIONS.PREPARE_FOR_TOP_UP_SUCCESS:
			return {
				...state,
				status: action.type,
				userTopUpInfoLogID: action.data.userTopUpInfoLogID,
				isFetchingPrepareForTopUp: false,
			};
		case ACTIONS.PREPARE_FOR_TOP_UP_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				isFetchingPrepareForTopUp: false,
			};
		case ACTIONS.LOGIN_SGP_PROGRESS:
			return {
				...state,
				status: action.type,
				isLoginProgress: true
			};
		case ACTIONS.LOGIN_SGP_SUCCESS:
			return {
				...state,
				status: action.type,
				accessToken: action.data.accessToken,
				isLoginProgress: false
			};
		case ACTIONS.LOGIN_SGP_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				isLoginProgress: false,
			};
		case ACTIONS.OTC_AND_TOP_UP_PROGRESS:
			return {
				...state,
				status: action.type,
				isTopUpProgress: true
			};
		case ACTIONS.OTC_AND_TOP_UP_SUCCESS:
			return {
				...state,
				status: action.type,
				isTopUpProgress: false
			};
		case ACTIONS.OTC_AND_TOP_UP_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				isTopUpProgress: false,
			};
		case ACTIONS.GET_LIST_CARDS_TOP_UP_PROGRESS:
			return {
				...state,
				status: action.type,
				isFetchingCards: true
			};
		case ACTIONS.GET_LIST_CARDS_TOP_UP_SUCCESS:
			return {
				...state,
				status: action.type,
				listCardsOfEZPay: action.data.listCardsOfEZPay,
				listCardsOfAlliedCard: action.data.listCardsOfAlliedCard,
				isFetchingCards: false
			};
		case ACTIONS.GET_LIST_CARDS_TOP_UP_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				isFetchingCards: false,
			};
		case ACTIONS.GET_HISTORY_TOP_UP_PROGRESS:
			return {
				...state,
				status: action.type,
				isFetchingTopUpHistory: true
			};
		case ACTIONS.GET_HISTORY_TOP_UP_SUCCESS:
			return {
				...state,
				topUpHistories: state.topUpHistories ? state.topUpHistories.concat(action.data.topUpHistories) : action.data.topUpHistories,
				topUpHistoryPageCount: action.data.pageCount,
				displayTopUpHistory: action.data.topUpHistories,
				status: action.type,
				isFetchingTopUpHistory: false
			};
		case ACTIONS.GET_HISTORY_TOP_UP_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				isFetchingTopUpHistory: false,
			};
		case ACTIONS.PAGING_TOP_UP_HISTORY:
			return {
				...state,
				status: action.type,
				displayTopUpHistory: action.data.displayTopUpHistory,
			};
		case UTIL_ACTIONS.RESET_ERROR:
			return {
				...state,
				error: null,
			};
		case AUTH_ACTIONS.LOGOUT:
			return initialState;
		default:
			return state;
	}
}