import React, {PureComponent} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Countdown from "react-countdown-now";
import {sprintf} from "sprintf-js";
import i18n from "i18next";

export default class CountDownItem extends PureComponent {

    rendererCountDown = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <span></span>
        } else {
            // Render a countdown
            return <span className='text-danger'>{sprintf(i18n.t('remaining_time_code'), days, hours, minutes, seconds )} </span>;
        }
    };
    render() {
        const { date } = this.props;
        return (
            <Countdown
                date={date}
                renderer={this.rendererCountDown}
            />
        );
    }
}
