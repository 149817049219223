import React, {Component} from 'react';
import { I18n } from 'react-i18next';
import './index.css'
import { withRouter } from 'react-router-dom';
import { compose } from "redux";
import { constants } from '../../05-utils/commonData';

class Header extends Component {
	state = {
		currentFlag: `/images/flags/${localStorage.getItem('i18nextLng')}.png`,
		isOpenChooseLanguage: false,
		isOpenLogout: false,
	};

	componentWillMount() {
		document.addEventListener('mousedown', this.handleOutsideClick, false);
	}

	changeLanguage = (lang, i18n) => {
		i18n.changeLanguage(lang);
		this.setState({
			currentFlag: `/images/flags/${lang}.png`,
			isOpenChooseLanguage: false,
		})
	};

	toggleChooseLanguage = () => {
		this.setState({ isOpenChooseLanguage: true });
	};

	toggleLogout = () => {
		this.setState({ isOpenLogout: true });
	};

	handleOutsideClick = e => {
		if (this.nodeLogout && !this.nodeLogout.contains(e.target) && this.nodeChooseLanguage && !this.nodeChooseLanguage.contains(e.target)) {
			this.setState({ isOpenChooseLanguage: false, isOpenLogout: false });
		}
	};

	onViewAllClick = () => {
		this.props.history.push('/wallet/advance-transaction');
	}

	render() {
		const { userInfo, logout, toggleMenu, loginMethod } = this.props;
		const { isOpenChooseLanguage, isOpenLogout } = this.state;

		return (
			<I18n ns="translations">
				{
					(t, {i18n}) => (
						<nav className="navbar header-color default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
							<div className="navbar-menu-wrapper d-flex align-items-center">
								<div className="navbar-nav-left">
									<button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
											onClick={toggleMenu}>
										<span className="mdi mdi-menu btn-open-menu"/>
									</button>
									<img className="logo" src="/favicon.png" alt="" />
									<h4 className="navbar-title title-color">TRIPLE Wallet</h4>
								</div>
								<ul className="navbar-nav navbar-nav-right">
									<li className="nav-item dropdown">
										<button
											className={`btn btn-link nav-link dropdown-toggle cursor-pointer ${isOpenChooseLanguage ? 'show' : ''}`}
											id="UserDropdown"
											data-toggle="dropdown"
											aria-expanded={isOpenChooseLanguage}
											onClick={this.toggleChooseLanguage}
										>
												<img className="flag-language" src={this.state.currentFlag} alt=""/>
										</button>
										<div
											className={`dropdown-menu dropdown-menu-right navbar-dropdown padding-unset text-center ${isOpenChooseLanguage ? 'show' : ''}`}
											aria-labelledby="UserDropdown"
											ref={node => this.nodeChooseLanguage = node}
										>
											<button
												className="btn btn-link dropdown-item"
												onClick={() => this.changeLanguage('en', i18n)}
											>
												<img className="flag-language" src="/images/flags/en.png" alt="" />
											</button>
											{/*<button*/}
											{/*	className="btn btn-link dropdown-item"*/}
											{/*	onClick={() => this.changeLanguage('ja', i18n)}*/}
											{/*>*/}
											{/*	<img className="flag-language" src="/images/flags/ja.png" alt="" />*/}
											{/*</button>*/}
											<button
												className="btn btn-link dropdown-item"
												onClick={() => this.changeLanguage('ch', i18n)}
											>
												<img className="flag-language" src="/images/flags/ch.png" alt="" />
											</button>
										</div>
									</li>
									<li className="nav-item dropdown hide-logout">
										<button
											className={`btn btn-link dropdown-toggle cursor-pointer ${isOpenLogout ? 'show' : ''}`}
											id="UserDropdown"
											data-toggle="dropdown"
											aria-expanded={isOpenLogout}
											onClick={this.toggleLogout}
										>
											<span className="profile-text">{userInfo && (loginMethod === constants.LOGIN_METHOD.USER_CODE ? userInfo.userCode : userInfo.email)}</span>
											{/*<img className="img-xs rounded-circle" src="/images/faces/face1.jpg" alt="" />*/}
										</button>
										<div
											className={`dropdown-menu dropdown-menu-right navbar-dropdown padding-15px text-center ${isOpenLogout ? 'show' : ''}`}
											aria-labelledby="UserDropdown"
											ref={node => this.nodeLogout = node}
										>
											<p>{userInfo && (loginMethod === constants.LOGIN_METHOD.USER_CODE ? userInfo.userCode : userInfo.email)}</p>
											<div/>
											<button
												className="btn btn-rounded btn-fw btn-logout"
												onClick={logout}
											>
												{t('logout')}
											</button>
										</div>
									</li>
								</ul>
							</div>
						</nav>
					)
				}
			</I18n>
		);
	}
}

export default compose(withRouter)(Header);