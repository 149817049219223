import React, { Component } from 'react';
import { I18n } from 'react-i18next'
import './eZpayTopUp.css';
import ModalTemplate from './modalTemplate';
import Loading from "../../../../../03-components/Loading";

export default class EZPaySelectSystem extends Component {
    refreshInputAndToggle = () => {
        this.props.toggle();
    };

    render() {
        const {
            isOpen,
            onPressEZpay,
            listCardsOfEZPay,
            listCardsOfAlliedCard,
            isFetchingCards,
        } = this.props;
        let isAllowTopUp = listCardsOfEZPay.length || listCardsOfAlliedCard.length;
        return (
            <I18n ns="translations">
                {
                    t => (
                        <ModalTemplate
                            isOpen={isOpen}
                            titleText={t('transfer_to_card')}
                            refreshInputAndToggle={this.refreshInputAndToggle}
                        >
                            <div className='transfer-to-card-body'>
                                {isFetchingCards ? (<Loading parent1Class="text-center padding-15px"/>)
                                    : isAllowTopUp ? <div className="select-system-wrapper">
                                        <span>{t('select_system_to_transfer')}</span>
                                        <div className="row wrapper-system-top-up">
                                            <button className="btn btn-link col-lg-12 col-md-12" onClick={onPressEZpay}>
                                                <img className="logo-system-top-up" src="/images/ezpay/ezp.png" alt=""/>
                                            </button>
                                            {/* <button className="btn btn-link col-lg-12 col-md-12" onClick={onPressAllied}>
                                            <img className="logo-system-top-up" src="/images/ezpay/allied.png" alt=""/>
                                        </button> */}
                                        </div>
                                    </div>
                                    : <div className='attention-allow-topup'>
                                            <span
                                                className='text-center text-size-18'>{t('you_are_not_enough_condition_for_top_up')}</span>
                                        <div className='text-center margin-top-1rem margin-bottom-half-rem'>
                                            <button className="btn btn-primary btn-fw select-application-id-button"
                                                    onClick={this.props.onClose}>
                                                <span>{t('close')}</span>
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </ModalTemplate>
                    )
                }
            </I18n>
        );
    }
}
