import React, { Component } from 'react';
import { I18n } from 'react-i18next'
import './eZpayTopUp.css';
import ModalTemplate from './modalTemplate';
import EventRegister, { EVENT_CLOSE_EZPAY_TOP_UP } from '../../../../../05-utils/eventRegister';
import Loading from "../../../../../03-components/Loading";
import { sprintf } from 'sprintf-js';

export default class EZPaySelectApplicationId extends Component {
    state = {
        applicationIdSelected: '',
    };

    componentDidMount() {
        this.closeEzpayTopUp = EventRegister.on(EVENT_CLOSE_EZPAY_TOP_UP, () => {
            this.setState({ applicationIdSelected: '' });
        });
    }

    componentWillUnmount() {
        EventRegister.off(this.closeEzpayTopUp);
    }

    refreshInputAndToggle = () => {
        this.setState({ applicationIdSelected: '' });
        this.props.toggle();
    };

    onApplicationIdSelected = e => {
        let choosedCard = this.props.data && this.props.data.find(card => card.cardNumber ===  e.target.value);
        this.props.onChangeCard({choosedCard});
        this.setState({ applicationIdSelected: e.target.value });
    };

    render() {
        const {
            isOpen,
            onPressBack,
            onPressNext,
            data,
            isFetchingCards,
            systemSelected
        } = this.props;
        const { applicationIdSelected } = this.state;
        let listApplicationId = data && data.map(item => {
            let card_prefix = item.cardNumber.substring(0,4);
            let card_suffix = item.cardNumber.substring(item.cardNumber.length -4 ,item.cardNumber.length);
            let card_id = card_prefix + "-****-****-" + card_suffix;
            return { ...item, card_id}
        });
        return (
            <I18n ns="translations">
                {
                    t => (
                        <ModalTemplate
                            isOpen={isOpen}
                            titleText={t('transfer_to_card')}
                            refreshInputAndToggle={this.refreshInputAndToggle}
                        >
                            <div className='transfer-to-card-body'>
                                <div className='transfer-to-card-body'>
                                    {isFetchingCards ? (<Loading parent1Class="text-center padding-15px"/>)
                                        :
                                        <div className="select-application-id-wrapper">
                                            <span>{t('ezpay_select_application_id_intro')}</span>
                                            <div className="row wrapper-application-id-top-up">
                                                {
                                                    data && data.length ?
                                                        <div className="form-group mx-auto">
                                                            <label>{t('application_with_card_number')}</label>
                                                            <select
                                                                className="form-control select-application-id"
                                                                value={this.state.applicationIdSelected}
                                                                onChange={this.onApplicationIdSelected}
                                                            >
                                                                <option value="">{t('select_an_application_id')}</option>
                                                                {
                                                                    listApplicationId && listApplicationId.map((card, index) => (
                                                                        <option key={index} value={card.cardNumber}>
                                                                            {card.card_id}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                        :
                                                        <div className="mx-auto">
                                                            <span className='text-center text-size-18'>
                                                                {sprintf(t('list_card_is_empty'), systemSelected === 3 ? 'EZPay' : 'AlliedCard' )}
                                                            </span>
                                                        </div>
                                                }
                                            </div>
                                            <div className='text-center'>
                                                <button
                                                    className="btn btn-primary btn-fw select-application-id-button"
                                                    onClick={onPressBack}
                                                >
                                                    <span>{t('back')}</span>
                                                </button>
                                                <button
                                                    className="btn btn-primary btn-fw select-application-id-button"
                                                    onClick={onPressNext}
                                                    disabled={!applicationIdSelected}
                                                >
                                                    <span>{t('next')}</span>
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </ModalTemplate>
                    )
                }
            </I18n>
        );
    }
}
