import I18n from 'i18next';

const validate = values => {
	const errors = {};
	if (!values.nameWallet || !values.nameWallet.trim()) {
		errors.nameWallet = I18n.t('NAME_REQUIRED');
	}
	return errors;
};

export default validate;