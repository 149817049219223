const errors = {
	VALIDATION_ERROR: { errorCode: 1, errorMessage: 'Validation error' },
	EMAIL_EXISTED_ERROR: { errorCode: 2, errorMessage: 'Email already existed' },
	SYSTEM_ERROR: { errorCode: 3, errorMessage: 'System error' },
	LOGIN_INCORRECT_ERROR: { errorCode: 4, errorMessage: 'Login email/password not correct' },
	PHONE_NUMBER_ERROR: { errorCode: 5, errorMessage: 'Please update your phone number first' },
	VERIFICATION_ERROR: { errorCode: 6, errorMessage: 'Verification code not correct' },
	ADDRESS_CONFLICT_ERROR: { errorCode: 7, errorMessage: 'Address conflict between wallets' },
	NO_WALLET_ERROR: { errorCode: 8, errorMessage: 'No wallet selected' },
	INPUT_ERROR: { errorCode: 9, errorMessage: 'Invalid input value' },
	NOT_FOUND_ERROR: { errorCode: 10, errorMessage: 'Resource not found' },
	USER_STATE_ERROR: { errorCode: 11, errorMessage: 'User is not in valid state' },
	TOKEN_INVALID_ERROR: { errorCode: 12, errorMessage: 'User token is not valid' },
	VERIFICATION_REQUIRED_ERROR: { errorCode: 13, errorMessage: 'This feature requires TFA to be enabled' },
	WRONG_PASSWORD_ERROR: { errorCode: 14, errorMessage: 'Password is not correct' },
	ISSUER_EXISTED_ERROR: { errorCode: 15, errorMessage: 'Issuer already existed' },
	WALLET_UNDERFUNDED: { errorCode: 16, errorMessage: 'Fund Wallet underfunded' },
	INVALID_INPUT_ERROR: { errorCode: 17, errorMessage: 'Invalid encoded string' },
	MALFORMED_REQUEST_ERROR: { errorCode: 18, errorMessage: 'The request is malformed' },
	NOT_ISSUER_ERROR: { errorCode: 19, errorMessage: 'User is not an issuer' },
	NO_ACTION_ERROR: { errorCode: 20, errorMessage: 'No action found with provided name' },
	EXECUTION_HALTED_ERROR: { errorCode: 21, errorMessage: 'Execution halted due to error' },
	AMOUNT_REQUIRE_NUMBER: { errorCode: 22, errorMessage: 'Amount require number' },
	NOT_FOUND_WALLET: { errorCode: 23, errorMessage: 'Not Found Wallet' },
	NOT_FOUND_DESTINATION_ACCOUNT: { errorCode: 24, errorMessage: 'The destination account does not exist!' },
	TRANSACTION_ERROR: { errorCode: 25, errorMessage: 'Transaction Error' },
	NOT_POSSESSED_ERROR: { errorCode: 26, errorMessage: 'User not possess the requested resource' },
	EMAIL_NOT_VERIFIED_ERROR: { errorCode: 27, errorMessage: 'Your account has not been verified' },
	OP_LOW_RESERVE: { errorCode: 28, errorMessage: 'Operation - Low Reserve' },
	TFA_REQUIRE_ENABLED: { errorCode: 29, errorMessage: 'Two factor authenticate require enabled' },
	QUERY_DB_ERR: { errorCode: 30, errorMessage: 'Error when query to Database' },
	ASSET_EXISTED_ERROR: { errorCode: 31, errorMessage: 'Asset name already existed' },
	RESOURCE_NOT_FOUND: { errorCode: 32, errorMessage: 'Resource not found' },
	ASSET_NOT_FOUND: { errorCode: 33, errorMessage: 'Asset not found' },
	HOLDING_WALLET_ERROR: { errorCode: 34, errorMessage: 'Holding wallet require not equal Issuer Wallet' },
	// TX_INSUFFICIENT_BALANCE: {errorCode: 35, errorMessage: 'Holding wallet not enough money'},
	REQUIRE_SUPER_ADMIN_ROLE: { errorCode: 36, errorMessage: 'Require Supper Admin role' },
	PRICE_AND_CURRENCY_NOT_VALID: { errorCode: 37, errorMessage: 'Price and Currency is not valid' },
	CURRENCY_EXIST: { errorCode: 38, errorMessage: 'This currency has exist' },
	EMAIL_NOT_EXISTED_ERROR: { errorCode: 39, errorMessage: 'Email does not exist in system' },
	DUPLICATED_ICO_REQUEST: { errorCode: 40, errorMessage: 'You can only have one request for each ico packet' },
	ICO_RESERVATION_NOT_FOUND: { errorCode: 41, errorMessage: 'Requested ICO reservation not found' },
	ICO_PLAN_EMPTY_ERROR: { errorCode: 42, errorMessage: 'Can not purchase an empty ICO plan' },
	ICO_NOT_READY_ERROR: { errorCode: 43, errorMessage: 'The ICO is currently not available' },
	REQUIRE_SET_PHONE_NUMBER: { errorCode: 44, errorMessage: 'Please set your phone number' },
	YOU_ARE_NOT_ALLOWED: { errorCode: 45, errorMessage: 'You are not allowed to do this action' },
	QUERY_S3_ERROR: { errorCode: 46, errorMessage: 'Error when access to Amazon S3' },
	WRONG_PRIVATE_KEY_ERROR: { errorCode: 47, errorMessage: 'Private key is not correct' },
	ASSET_EXIST: { errorCode: 48, errorMessage: 'This Asset has exist' },
	DONT_OWN_WALLET: { errorCode: 49, errorMessage: "You don't own this wallet" },
	INCORRECT_PASSWORD: { errorCode: 50, errorMessage: "Password is not correct" },
	INCORRECT_PASSWORD_LENGTH: { errorCode: 51, errorMessage: "Password's length must more than 6 characters and less than 200 characters" },
	CODE_EXPIRED: { errorCode: 52, errorMessage: "Verification code expired" },
	CAN_NOT_UPDATE_PACKET: { errorCode: 53, errorMessage: "ICO is running so you can't edit any packet" },
	WRONG_ASSET_TYPE: { errorCode: 54, errorMessage: "Can not create offer with the provided assets" },
	WRONG_PARAMS_VALUE: { errorCode: 55, errorMessage: "The value of parameters is invalid" },
	ASSET_NOT_TRUSTED: { errorCode: 56, errorMessage: "You must trust the buying asset first" },
	NOT_MERCHANT_ERROR: { errorCode: 57, errorMessage: "User is not a merchant" },
	MERCHANT_EXIST: { errorCode: 58, errorMessage: "User is already a merchant" },
	NOT_FOUND_MERCHANT_STORE: { errorCode: 59, errorMessage: 'Not Found Merchant Store' },
	REQUEST_COOLDOWN: { errorCode: 60, errorMessage: "Wait for cooldown" },
	WALLET_EXISTED: { errorCode: 61, errorMessage: "The wallet has existed" },
	ICO_IS_RUNNING: { errorCode: 62, errorMessage: "ICO is running. Disable it before perform this action" },
	ICO_PACKET_CONTAIN_RESERVATION: { errorCode: 63, errorMessage: "Cannot remove a packet that contains reservation" },
	NOT_WEBSOCKET_CONNECTION: { errorCode: 64, errorMessage: "This request is not a websocket connection" },
	ISSUER_NOT_APPROVE: { errorCode: 65, errorMessage: "Issuer does not Approve!" },
	MERCHANT_NOT_APPROVE: { errorCode: 66, errorMessage: "Merchant does not Approve!" },
	SOURCE_WALLET_ISSUED: { errorCode: 67, errorMessage: "Your source wallet issued an asset" },
	SOURCE_WALLET_USED: { errorCode: 68, errorMessage: "Your source wallet is used by a Merchant Store" },
	MAXIMUM_AMOUNT_EXCEEDED: { errorCode: 69, errorMessage: "Selected ICO amount is not allowed" },
	WRONG_CODE: { errorCode: 70, errorMessage: "The code provided is not correct" },
	ASSET_ALREADY_TRUSTED: { errorCode: 71, errorMessage: "The selected asset has already been trusted." },
	WALLET_STATE_ERROR: { errorCode: 72, errorMessage: "The wallet is not in correct state." },
	DIRTY_ERROR: { errorCode: 73, errorMessage: "Cannot remove used resource" },
	ISSUING_PROCESS_REQUIRED: { errorCode: 74, errorMessage: "This action must be performed through issuing process." },
	ISSUING_WALLET_NOT_ALLOWED: { errorCode: 75, errorMessage: "Issuing wallet is not allowed to perform this action." },
	INVALID_SECKET_KEY: { errorCode: 76, errorMessage: 'Secret key is invalid' },
	HOLDING_FEE_WALLET: { errorCode: 77, errorMessage: "Issuing wallet can't be holding fee wallet" },
	USER_INCORRECT: { errorCode: 78, errorMessage: 'User is incorrect' },
	ISSUER_REQUEST_CHANGE_INFO: { errorCode: 79, errorMessage: 'Issuer request maybe change information!' },
	ISSUER_INACTIVE: { errorCode: 80, errorMessage: 'Issuer has inactive by admin!' },
	APP_EXISTED_ERROR: { errorCode: 81, errorMessage: 'App already existed' },
	REDIRECT_URI_NOT_MATCHED: { errorCode: 82, errorMessage: 'Redirect URI is not matched' },
	REQUEST_EXPIRED: { errorCode: 83, errorMessage: 'Request is expired' },
	OAUTH_APP_AUTHENTICATION_ERROR: { errorCode: 84, errorMessage: 'OAuth app authentication error' },
	WALLET_NOT_TRUST_ASSET: { errorCode: 85, errorMessage: 'Wallet is not trust this asset' },
	ASSET_NOT_BELONG_TO_ADMIN_WALLET: { errorCode: 86, errorMessage: 'This asset is not belong to admin wallet' },
	REQUIRE_SUB_SYSTEM_ADMIN: { errorCode: 87, errorMessage: 'Require sub system admin' },
	ORDER_ID_EXISTS: { errorCode: 88, errorMessage: 'Order already exists in system' },
	REQUIRE_SUB_SYSTEM_APP: { errorCode: 89, errorMessage: 'Require sub system app' },
	MULTI_PAYMENT_REQUEST_NOT_FOUND: { errorCode: 90, errorMessage: 'Multi Payment Request Not Found' },
	NOT_FOUND_OAUTH_APP: { errorCode: 91, errorMessage: 'Not found oauth app' },
	NOT_FOUND_USER: { errorCode: 92, errorMessage: 'Not found user' },
	WALLET_TYPE_IS_NOT_SUPPORTED: { errorCode: 93, errorMessage: 'Wallet type is not supported' },
	DO_NOT_MATCH_WALLET_TYPE: { errorCode: 94, errorMessage: 'Do not match wallet type' },
	ETHEREUM_TRANSACTION_ERROR: { errorCode: 95, errorMessage: 'Ethereum transaction error' },
	RIPPLE_TRANSACTION_ERROR: { errorCode: 96, errorMessage: 'Ripple transaction error' },
	ETHEREUM_SYSTEM_ERROR: { errorCode: 97, errorMessage: 'Ethereum error' },
	RIPPLE_SYSTEM_ERROR: { errorCode: 98, errorMessage: 'Ripple error' },
	NOT_ENOUGH_AMOUNT: { errorCode: 99, errorMessage: 'Not enough amount' },
	WALLET_BLOCK: { errorCode: 100, errorMessage: 'This wallet is blocked.' },
	WALLET_INACTIVE: { errorCode: 101, errorMessage: 'This wallet is inactive.' },
	TRANSACTION_ID_INCORRECT: { errorCode: 102, errorMessage: 'Transaction id incorrect.' },
	ADDRESS_OR_TRANSACTION_ID_INCORRECT: { errorCode: 103, errorMessage: 'Address or Transaction id incorrect.' },
	NOT_ICO_MAKER_ERROR: { errorCode: 104, errorMessage: 'User is not an ICO Maker.' },
	HOLDING_FEE_WALLET_UNTRUST_ERROR: { errorCode: 105, errorMessage: "Holding fee wallet of an asset can't untrust this asset." },
	ACCOUNT_LOCKED: { errorCode: 106, errorMessage: 'Your account has been locked by security. Please contact with the administrator.' },
	UNTRUST_INVALID_BALANCE: { errorCode: 107, errorMessage: 'Asset balance must be equal to 0' },
	PERMISSION_DENIED: { errorCode: 108, errorMessage: 'Permission denied' },
	REQUIRED_SCOPE_MISSING: { errorCode: 109, errorMessage: "The user hasn't authorized the application to perform this action" },
	MAX_LENGTH_FIRSTNAME: { errorCode: 110, errorMessage: 'Firstname must less than 50 character.' },
	MAX_LENGTH_LASTNAME: { errorCode: 111, errorMessage: 'Lastname must less than 50 character.' },
	MAX_LENGTH_PASSWORD: { errorCode: 112, errorMessage: "Password's maximum length is 200 characters." },
	MAX_LENGTH_ADDRESS: { errorCode: 113, errorMessage: "Wallet Address 's maximum length is 200 characters." },
	REQUIRE_3RD_SYSTEM_APP: { errorCode: 114, errorMessage: 'Require 3rd party system app' },
	SERVER_IS_MAINTENANCE: { errorCode: 115, errorMessage: 'Api server is maintenancing' },
	TOKEN_REVOKED_ERROR: { errorCode: 116, errorMessage: 'User token was revoked' },
	USER_3RD_ERROR: { errorCode: 117, errorMessage: "Your account has been created by 3rd system, you can't access to ArmNetwork at the moment" },
	CANNOT_SEND_FOR_ISSUER: { errorCode: 118, errorMessage: "Cannot send an Asset to its Issuer address" },
	LIMIT_REQUEST_REACH: { errorCode: 119, errorMessage: "The limit of this request is reached" },
	ALREADY_MULTI_SIG_WALLET: { errorCode: 120, errorMessage: "The wallet has already been a multi-sig wallet" },
	NOT_SIGNER_ERROR: { errorCode: 121, errorMessage: "Your are not a singer" },
	EMAIL_NOT_SUBSCRIBE: { errorCode: 122, errorMessage: "The email has not subscribed Triam Network yet" },
	NEWS_ALREADY_SENT: { errorCode: 123, errorMessage: "This news has already been sent to subscribers" },
	INVALID_STARTING_BALANCE: { errorCode: 124, errorMessage: "Starting balance must be a number and at least 20" },
	CONTACT_EXIST: { errorCode: 125, errorMessage: "The contact for this address already exists" },
	SYSTEM_NAME_EXISTED_ERROR: { errorCode: 126, errorMessage: 'System name already existed' },
	SIGNER_ALREADY_SIGNED: { errorCode: 127, errorMessage: 'Signer has already signed this transaction' },
	SIGNER_REQUIRED: { errorCode: 128, errorMessage: 'Setting multisign wallet require at least 1 signer' },
	TRANSACTION_ID_EXISTS: { errorCode: 129, errorMessage: 'Transaction ID already exists in system' },
	SIGNER_WALLET_INVALID: { errorCode: 130, errorMessage: 'Signer wallet not found, inactive or blocked' },
	SENDING_TO_INACTIVE_WALLET: { errorCode: 131, errorMessage: 'You must send at least 20 RIA to the inactive wallet' },
	CANNOT_SEND_TOKEN_TO_ITSELF: { errorCode: 132, errorMessage: 'The wallet can not send token to itself' },
	HOLDING_FEE_MULTISIGN_CREATE: {
		errorCode: 133,
		errorMessage: 'Holding fee wallet is multisign wallet, you can only setup after create asset'
	},
	HOLDING_FEE_MULTISIGN_CHANGE: {
		errorCode: 134,
		errorMessage: 'Holding fee wallet is multisign wallet, you need to trust asset first'
	},
	MULTI_SIGN_TRANSACTION_COMPLETED: { errorCode: 135, errorMessage: "This transaction completed" },
	NOT_MULTI_SIGN_WALLET: { errorCode: 136, errorMessage: "It is not multi sign wallet" },
	EMAIL_ALREADY_SUBSCRIBE: { errorCode: 137, errorMessage: "The email has already subscribed Triam Network" },
	CANT_ADD_YOUR_OTHER_WALLET_AS_SIGNER: { errorCode: 138, errorMessage: 'Cannot add your other wallet as a signer' },
	SIGNER_CANT_BECOME_SHARED_WALLET_OR_MULTISIGN_WALLET: {
		errorCode: 139,
		errorMessage: 'Cannot setting a signer to become shared wallet or multisig wallet'
	},
	MULTISIGN_WALLET_CANT_BECOME_SIGNER: {
		errorCode: 140,
		errorMessage: 'Cannot setting a multisign wallet or shared wallet to become signer'
	},
	SIGNER_NOT_SAME_ACCOUNT: { errorCode: 141, errorMessage: "Shared Wallet can't have 2 signers same an account" },
	INVALID_EMAIL: { errorCode: 142, errorMessage: 'Your email address is invalid' },
	MULTISIGN_WALLET_CANT_BECOME_SHARED_WALLET: {
		errorCode: 143,
		errorMessage: 'Cannot setting a multisig wallet to become shared wallet'
	},
	ALREADY_SHARED_WALLET: { errorCode: 144, errorMessage: 'The wallet has already been a shared wallet' },
	SHARED_WALLET_CANT_BECOME_MULTISIG_WALLET: {
		errorCode: 145,
		errorMessage: 'Cannot setting a shared wallet to become multisig wallet'
	},
	CANT_SETTING_FIRST_WALLET: { errorCode: 146, errorMessage: 'Cant setting this wallet because it has used in robin system' },
	ACCOUNT_ALREADY_EXIST: { errorCode: 147, errorMessage: 'Account already exists' },
	THE_USER_HAS_NOT_BEEN_VERIFIED_IDENTITY_CARD: { errorCode: 148, errorMessage: 'THE_USER_HAS_NOT_BEEN_VERIFIED_IDENTITY_CARD' },
	INVALID_WALLET_ADDRESS: { errorCode: 149, errorMessage: 'Invalid wallet address' },
	CP_EXCHANGE_IS_DISABLED: {errorCode: 150, errorMessage: 'CP Exchange Feature is disable'},
	CANNOT_SET_ADMIN_WALLET_TO_HOLDING: {errorCode: 151, errorMessage: 'Cannot set admin wallet to holding money from game'},
	PASSPORT_NUMBER_NOT_FOUND: {errorCode: 152, errorMessage: 'Passport number not found'},
    AMOUNT_TOP_UP_EXCEED: {errorCode: 155, errorMessage: 'Amount top up exceed specifed amount'},
	OP_PASS_INVALID: {errorCode: 156, errorMessage: 'OP Password is invalid'},
	OP_PASS_IS_REQUIRED: {errorCode: 157, errorMessage: 'OP Password is required'},
	INCORRECT_ALL_PRIVATE_KEY: {errorCode: 158, errorMessage: 'User need to enter at least one private key'},
	CANNOT_SEND_TO_MERGED_WALLET: {errorCode: 161, errorMessage: 'Cannot send to merged wallet'},
	INCORRECT_OP_PASSWORD: { errorCode: 159, errorMessage: "OP Password is not correct" },
	INCORRECT_OP_PASSWORD_LENGTH: { errorCode: 160, errorMessage: "OP Password's length must more than 6 characters and less than 200 characters" },
	CANNOT_ACTIVE_MERGED_WALLET: {errorCode: 162, errorMessage: 'Cannot active a merged wallet'},
	OP_PASS_MUST_CONTAIN_UPPERCASE_CHARACTER: {
		errorCode: 163,
		errorMessage: 'The OP Password must contain at least an uppercase character'
	},

	OP_PASS_MUST_CONTAIN_LOWERCASE_CHARACTER: {
		errorCode: 164,
		errorMessage: 'The OP Password must contain at least an lowercase character'
	},

	OP_PASS_MUST_CONTAIN_DIGITS: {
		errorCode: 165,
		errorMessage: 'The OP Password must contain at least a digits'
	},

	OP_PASS_MUST_CONTAIN_SPECIAL_CHARACTER: {
		errorCode: 166,
		errorMessage: 'OP Password must  contain at least a special characters'
	},
	OP_PASS_EXIST: {
		errorCode: 167,
		errorMessage: 'You set OP Password already'
	},
	OP_PASS_DIFFERENT: {
		errorCode: 168,
		errorMessage: 'Password and OP Password must different'
	},
	AMOUNT_IS_INVALID: { errorCode: 169, errorMessage: 'Amount is invalid' },
	OP_PASS_ONLY_CONTAIN_LATIN_CHARACTER: {
		errorCode: 170,
		errorMessage: 'The OP Password only contains Latin characters'
	},
	USER_IS_NOT_ENOUGH_CONDITION_TOP_UP: { errorCode: 171, errorMessage: 'User is not enough condition for top up!'},
	CANNOT_GET_SGP_BALANCE_INFO: { errorCode: 172, errorMessage: "Can't get sgp balance info!"},
	CANNOT_GET_OTC_CURRENCY_INFO: { errorCode: 173, errorMessage: "Can't get OTC currency info!"},
	CANNOT_GET_INFO_FOR_TOP_UP: { errorCode: 174, errorMessage: "Can't get info for top up!"},
	CANNOT_LOGIN_TO_SGP_ERROR: { errorCode: 175, errorMessage: "Can't login to SGP!"},
	CANNOT_OTC_EXCHANGE_IN_SGP_ERROR: { errorCode: 176, errorMessage: "Can't OTC Exchange in SGP!"},
	CANNOT_TOP_UP_ERROR: { errorCode: 177, errorMessage: "Can't top up in SGP!"},
	WRONG_SGP_CODE: { errorCode: 178, errorMessage: 'The SGP code provided is not correct!'},
	WRONG_SGP_PASSWORD: { errorCode: 179, errorMessage: 'The SGP password is not correct!'},
	SGP_ACCOUNT_DISABLED: { errorCode: 180, errorMessage: 'The SGP Account is disabled!'},
	SGP_ACCOUNT_BLOCKED: { errorCode: 181, errorMessage: 'The SGP Account is blocked!'},
	NOT_LOGGED_IN_SGP: { errorCode: 182, errorMessage: 'Please login to SGP!'},
	SGP_ERR_AMOUNT_OTC_LESS_THAN_MINIMUM_AMOUNT: { errorCode: 183, errorMessage: 'Amount need OTC must be greater than minimum amount!'},
	SGP_ERR_NOT_ENOUGH_AMOUNT_FOR_OTC_EXCHANGE: { errorCode: 184, errorMessage: 'Source account balance not enough for OTC!'},
	SGP_ERR_AMOUNT_TOP_UP_LESS_THAN_MINIMUM_AMOUNT: { errorCode: 185, errorMessage: 'Amount top up must higher than 100!'},
	SGP_ERR_SOMETHING_WRONG: { errorCode: 186, errorMessage: 'Something is wrong, plesae check information and do it again!'},
	SGP_ERR_NOT_REQUIRE_TFA: { errorCode: 187, errorMessage: 'Require active twofactor in SGP Exchange!'},
	OTHER_REQUEST_IS_PROCESSING: { errorCode: 188, errorMessage: "Other request is processing, please try again later!"},
	TOP_UP_IS_DISABLED: { errorCode: 189, errorMessage: "Top Up function is disabled now!"},
	CANNOT_SEND_DATA_TO_TRIPLE_SYSTEM_ERROR: { errorCode: 190, errorMessage: "Can't send data to TripleSystem!"},
	MIDDLE_SYSTEM_USER_IS_NOT_FOUND: { errorCode: 191, errorMessage: "User is not found in Middle System!"},
	MIDDLE_SYSTEM_CARD_IS_NOT_FOUND: { errorCode: 192, errorMessage: "Card is not found in Middle System!"},
	SGP_ERR_ONLY_LOGIN_ONE_BROWSER: { errorCode: 193, errorMessage: "Your SGP account is being logged in other browser, please try again later!"},
	CANNOT_ORDER_FIRST_WALLET: { errorCode: 194, errorMessage: "Can't order first wallet!"},
	CANNOT_GET_LIST_TITLE_OF_TRIPLE_SYSTEM_ERROR: { errorCode: 195, errorMessage: "Can't get list titles of Triple System!"},
	PRODUCT_CODE_HAS_BEEN_USED: { errorCode: 196, errorMessage: "Product code has been used!"},
	PRODUCT_CODE_IS_NOT_MATCH: { errorCode: 197, errorMessage: "Product code isn't match!"},
	PRODUCT_CODE_HAS_BEEN_REJECTED: { errorCode: 198, errorMessage: "Product code has been rejected!"},
	AMOUNT_IS_NOT_MATCH: { errorCode: 199, errorMessage: "Amount isn't match!"},
	TITLE_NOT_EXIST: { errorCode: 200, errorMessage: "Title isn't exist!"},
	PRODUCT_CODE_IS_NOT_EXISTED: { errorCode: 201, errorMessage: "Product code isn't existed!"},
	WRONG_DATA_WHEN_REJECT_PRODUCT_CODE: { errorCode: 202, errorMessage: "Wrong data when reject this product code!"},
	PRODUCT_CODE_IS_DISABLED: { errorCode: 203, errorMessage: "Product key feature disabled!"},
	TRIPLE_PAYMENT_ERROR: { errorCode: 204, errorMessage: "Triple Payment error!"},
	REQUEST_PRODUCT_CODE_IS_NOT_EXISTED: { errorCode: 205, errorMessage: "Request product key isn't existed!"},
	INVALID_CALLBACK_MAC_AUTHORIZATION: { errorCode: 206, errorMessage: "Invalid Callback Mac Authorization!"},
	REQUEST_PRODUCT_CODE_STATUS_ERROR: { errorCode: 207, errorMessage: "Request Product Key status is wrong!"},
	MAX_REQUEST_CODE_OF_USER: { errorCode: 208, errorMessage: "Only a maximum of 5 requests create product key are allowed in the new status" },
	CANNOT_CANCEL_THIS_REQUEST_CODE: { errorCode: 209, errorMessage: "Can't cancel this request!" },
	MAX_REQUEST_CODE_OF_USER_ERROR: { errorCode: 210, errorMessage: "You has been locked by security because request use product code incorrectly many times in a row . Please wait for cooldown.!" },
	REPEAT_ORDER_CODE_HAS_BEEN_USED: { errorCode: 211, errorMessage: "Repeat order code has been used!"},
	REPEAT_ORDER_CODE_HAS_BEEN_REJECTED: { errorCode: 212, errorMessage: "Repeat order code has been rejected!"},
	REPEAT_ORDER_CODE_IS_NOT_EXISTED: { errorCode: 213, errorMessage: "Repeat order code isn\'t existed!"},
	REPEAT_ORDER_CODE_IS_DISABLED: { errorCode: 214, errorMessage: "Repeat order code feature disabled!"},
	EXCEEDS_THE_MAXIMUM_PACKAGES_ALLOW: { errorCode: 215, errorMessage: "Exceeds the maximum packages allow !"},
	REPEAT_ORDER_CODE_IS_NOT_MATCH: { errorCode: 216, errorMessage: "Repeat order code isn\'t match!"},
	SGP_ERR_AMOUNT_TOP_UP_GREATER_THAN_MAXIMUM_AMOUNT: { errorCode: 217, errorMessage: 'Amount need OTC must be less than maximum amount!'},
	/* -----Horizon Error-----*/
	CREATE_ACCOUNT_MALFORMED: { errorCode: 1088, errorMessage: 'Invalid destination' },
	CREATE_ACCOUNT_UNDERFUNDED: { errorCode: 1089, errorMessage: 'Not enough funds in source account' },
	CREATE_ACCOUNT_LOW_RESERVE: { errorCode: 1090, errorMessage: 'Would create an account below the min reserve' },
	CREATE_ACCOUNT_ALREADY_EXIST: { errorCode: 1091, errorMessage: 'Account already exists' },
	PAYMENT_MALFORMED: { errorCode: 1092, errorMessage: 'Payment bad input' },
	PAYMENT_UNDERFUNDED: { errorCode: 1093, errorMessage: 'Not enough funds in source account' },
	PAYMENT_SRC_NO_TRUST: { errorCode: 1094, errorMessage: 'No trust line on source account' },
	PAYMENT_SRC_NOT_AUTHORIZED: { errorCode: 1095, errorMessage: 'Source not authorized to transfer' },
	PAYMENT_NO_DESTINATION: { errorCode: 1096, errorMessage: 'Destination account does not exist' },
	PAYMENT_NO_TRUST: { errorCode: 1097, errorMessage: 'Destination missing a trust line for asset' },
	PAYMENT_NOT_AUTHORIZED: { errorCode: 1098, errorMessage: 'Destination not authorized to hold asset' },
	PAYMENT_LINE_FULL: { errorCode: 1099, errorMessage: 'Destination would go above their limit' },
	PAYMENT_NO_ISSUER: { errorCode: 1100, errorMessage: 'Missing issuer on asset' },
	PATH_PAYMENT_MALFORMED: { errorCode: 1101, errorMessage: 'Path payment bad input' },
	PATH_PAYMENT_UNDERFUNDED: { errorCode: 1102, errorMessage: 'Not enough funds in source account' },
	PATH_PAYMENT_SRC_NO_TRUST: { errorCode: 1103, errorMessage: 'No trust line on source account' },
	PATH_PAYMENT_SRC_NOT_AUTHORIZED: { errorCode: 1104, errorMessage: 'Source not authorized to transfer' },
	PATH_PAYMENT_NO_DESTINATION: { errorCode: 1105, errorMessage: 'Destination account does not exist' },
	PATH_PAYMENT_NO_TRUST: { errorCode: 1106, errorMessage: 'Destination missing a trust line for asset' },
	PATH_PAYMENT_NOT_AUTHORIZED: { errorCode: 1107, errorMessage: 'Destination not authorized to hold asset' },
	PATH_PAYMENT_LINE_FULL: { errorCode: 1108, errorMessage: 'Destination would go above their limit' },
	PATH_PAYMENT_NO_ISSUER: { errorCode: 1109, errorMessage: 'Missing issuer on one asset' },
	PATH_PAYMENT_TOO_FEW_OFFERS: { errorCode: 1110, errorMessage: 'Not enough offers to satisfy path' },
	PATH_PAYMENT_OFFER_CROSS_SELF: { errorCode: 1111, errorMessage: 'Would cross one of its own offers' },
	PATH_PAYMENT_OVER_SENDMAX: { errorCode: 1112, errorMessage: 'Could not satisfy sendmax' },
	MANAGE_OFFER_MALFORMED: { errorCode: 1113, errorMessage: 'Generated offer would be invalid' },
	MANAGE_OFFER_SELL_NO_TRUST: { errorCode: 1114, errorMessage: "No trust line for what we're selling" },
	MANAGE_OFFER_BUY_NO_TRUST: { errorCode: 1115, errorMessage: "No trust line for what we're buying" },
	MANAGE_OFFER_SELL_NOT_AUTHORIZED: { errorCode: 1116, errorMessage: 'Not authorized to sell' },
	MANAGE_OFFER_BUY_NOT_AUTHORIZED: { errorCode: 1117, errorMessage: 'Not authorized to buy' },
	MANAGE_OFFER_LINE_FULL: { errorCode: 1118, errorMessage: "Can't receive more of what it's buying" },
	MANAGE_OFFER_UNDERFUNDED: { errorCode: 1119, errorMessage: "Doesn't hold what it's trying to sell" },
	MANAGE_OFFER_CROSS_SELF: { errorCode: 1120, errorMessage: "Doesn't hold what it's trying to sell" },
	MANAGE_OFFER_SELL_NO_ISSUER: { errorCode: 1121, errorMessage: "No issuer for what we're selling" },
	MANAGE_OFFER_BUY_NO_ISSUER: { errorCode: 1122, errorMessage: "No issuer for what we're buying" },
	MANAGE_OFFER_NOT_FOUND: { errorCode: 1123, errorMessage: 'OfferID does not match an existing offer' },
	MANAGE_OFFER_LOW_RESERVE: { errorCode: 1124, errorMessage: 'Not enough funds to create a new Offer' },
	SET_OPTIONS_SUCCESS: { errorCode: 1125, errorMessage: 'Success' },
	SET_OPTIONS_LOW_RESERVE: { errorCode: 1126, errorMessage: 'Not enough funds to add a signer' },
	SET_OPTIONS_TOO_MANY_SIGNERS: { errorCode: 1127, errorMessage: 'Max number of signers already reached' },
	SET_OPTIONS_BAD_FLAGS: { errorCode: 1128, errorMessage: 'Invalid combination of clear/set flags' },
	SET_OPTIONS_INVALID_INFLATION: { errorCode: 1129, errorMessage: 'Inflation account does not exist' },
	SET_OPTIONS_CANT_CHANGE: { errorCode: 1130, errorMessage: 'Can no longer change this option' },
	SET_OPTIONS_UNKNOWN_FLAG: { errorCode: 1131, errorMessage: "Can't set an unknown flag" },
	SET_OPTIONS_THRESHOLD_OUT_OF_RANGE: { errorCode: 1132, errorMessage: 'Bad value for weight/threshold' },
	SET_OPTIONS_BAD_SIGNER: { errorCode: 1133, errorMessage: 'Signer cannot be master key' },
	SET_OPTIONS_INVALID_HOME_DOMAIN: { errorCode: 1134, errorMessage: 'Malformed home domain' },
	CHANGE_TRUST_SUCCESS: { errorCode: 1135, errorMessage: 'Success' },
	CHANGE_TRUST_MALFORMED: { errorCode: 1136, errorMessage: 'Bad input' },
	CHANGE_TRUST_NO_ISSUER: { errorCode: 1137, errorMessage: 'Could not find issuer' },
	CHANGE_TRUST_INVALID_LIMIT: { errorCode: 1138, errorMessage: 'Cannot drop limit below balance' },
	CHANGE_TRUST_LOW_RESERVE: { errorCode: 1139, errorMessage: 'Not enough funds to create a new trust line,' },
	CHANGE_TRUST_SELF_NOT_ALLOWED: { errorCode: 1140, errorMessage: 'Trusting self is not allowed' },
	ALLOW_TRUST_SUCCESS: { errorCode: 1141, errorMessage: 'Success' },
	ALLOW_TRUST_MALFORMED: { errorCode: 1142, errorMessage: 'Asset is not ASSET_TYPE_ALPHA NUM' },
	ALLOW_TRUST_NO_TRUST_LINE: { errorCode: 1143, errorMessage: 'Trustor does not have a trustline' },
	ALLOW_TRUST_TRUST_NOT_REQUIRED: { errorCode: 1144, errorMessage: 'Source account does not require trust' },
	ALLOW_TRUST_CANT_REVOKE: { errorCode: 1145, errorMessage: "Source account can't revoke trust" },
	ALLOW_TRUST_SELF_NOT_ALLOWED: { errorCode: 1146, errorMessage: 'Trusting self is not allowed' },
	ACCOUNT_MERGE_SUCCESS: { errorCode: 1147, errorMessage: 'Success' },
	ACCOUNT_MERGE_MALFORMED: { errorCode: 1148, errorMessage: "Can't merge onto itself'" },
	ACCOUNT_MERGE_NO_ACCOUNT: { errorCode: 1149, errorMessage: 'Destination does not exist' },
	ACCOUNT_MERGE_IMMUTABLE_SET: { errorCode: 1150, errorMessage: 'Source account has AUTH_IMMUTABLE set' },
	ACCOUNT_MERGE_HAS_SUB_ENTRIES: { errorCode: 1151, errorMessage: 'Account has trust lines/offers' },
	INFLATION_SUCCESS: { errorCode: 1152, errorMessage: 'Success' },
	INFLATION_NOT_TIME: { errorCode: 1153, errorMessage: 'Failure' },
	MANAGE_DATA_SUCCESS: { errorCode: 1154, errorMessage: 'Success' },
	MANAGE_DATA_NOT_SUPPORTED_YET: { errorCode: 1155, errorMessage: "The network hasn't moved to this protocol change yet" },
	MANAGE_DATA_NAME_NOT_FOUND: { errorCode: 1156, errorMessage: "Trying to remove a Data Entry that isn't there" },
	MANAGE_DATA_LOW_RESERVE: { errorCode: 1157, errorMessage: 'Not enough funds to create a new Data Entry' },
	MANAGE_DATA_INVALID_NAME: { errorCode: 1158, errorMessage: 'Name not a valid string' },
	OP_INNER: { errorCode: 1159, errorMessage: 'Inner object result is valid' },
	OP_BAD_AUTH: { errorCode: 1160, errorMessage: 'Too few valid signatures / wrong network' },
	OP_NO_ACCOUNT: { errorCode: 1161, errorMessage: 'Source account was not found' },
	TX_SUCCESS: { errorCode: 1162, errorMessage: 'Success' },
	TX_FAILED: { errorCode: 1163, errorMessage: 'Failed' },
	TX_TOO_EARLY: { errorCode: 1164, errorMessage: 'Ledger closeTime before minTime' },
	TX_TOO_LATE: { errorCode: 1165, errorMessage: 'Ledger closeTime after maxTime' },
	TX_MISSING_OPERATION: { errorCode: 1166, errorMessage: 'No operation was specified' },
	TX_BAD_SEQ: { errorCode: 1167, errorMessage: 'Sequence number does not match source account' },
	TX_BAD_AUTH: { errorCode: 1168, errorMessage: 'Too few valid signatures / wrong network' },
	TX_INSUFFICIENT_BALANCE: { errorCode: 1169, errorMessage: 'Fee would bring account below reserve' },
	TX_NO_ACCOUNT: { errorCode: 1170, errorMessage: 'Source account not found' },
	TX_INSUFFICIENT_FEE: { errorCode: 1171, errorMessage: 'Fee is too small' },
	TX_BAD_AUTH_EXTRA: { errorCode: 1172, errorMessage: 'Unused signatures attached to transaction' },
	TX_INTERNAL_ERROR: { errorCode: 1173, errorMessage: 'An unknown error occurred' },
	TX_SIGNER_INCORRECT: { errorCode: 1178, errorMessage: 'Some keys are invalid, check your keys again.' },
	TX_SIGNER_NOT_ENOUGH: { errorCode: 1179, errorMessage: 'Not enough keys.' },
	TX_SIGNER_EXCEED: { errorCode: 1180, errorMessage: 'Too many keys.' },
	OP_SIGNER_CURRENT: { errorCode: 1181, errorMessage: 'Do not change weight of keys use to sign.' },
	WEIGHT_REQUIRED_INTEGER: {
		errorCode: 1182,
		errorMessage: 'Weight must be integer number and greater than or equal to 0.'
	},
	TX_UNSAFE_SETTING: {
		errorCode: 1183,
		errorMessage: 'Unsafe setting, total weight of signers must greater than or equals to admin permission weight, Admin Role must greater than or equals Paymenter Role and Allower Role must lower than or equals to Admin and Paymenter.'
	},
	OP_UNDERFUNDED: { errorCode: 1184, errorMessage: 'Not enough money.' },
	/* ------End Horizon Error------*/
};


const constants = {
	MIN_USER_PASSWORD_LENGTH: 6,
	MAX_USER_PASSWORD_LENGTH: 200,
	WALLET_KEY_LENGTH: 56,
	tokenErrors: [116, 12],
	MAX_BYTE_LENGTH_MEMO: 28,
	SUPER_ADMIN: 999,
	ACCOUNT_LOCKED: 1,
	STANDARD_WALLET: 0,
	MULTISIGN_WALLET: 1,
	MULTISIGN_WALLET_CUSTOM: 0,
	MULTISIGN_WALLET_HOT: 1,
	MULTISIGN_WALLET_COLD: 2,
	VERIFICATION_REQUIRED_ERROR: 13,
	URL_SCHEME_PREFIX: 'triam-network://',
	MAX_LENGTH_NAME_OF_USER: 50,
	NUMBER_FIRST_WALLET_CREATE_AT_ONCE_TIME: 50,
	ONLY_LATIN_CHARACTERS_REGEX: /^(([A-Za-z0-9]|[~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]|\s){6,200})$/gi,
	MUST_CONTAINS_SPECIAL_CHARACTERS_REGEX: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
	MUST_CONTAINS_UPPERCASE_CHARACTERS_REGEX: /[A-Z]/,
	MUST_CONTAINS_LOWERCASE_CHARACTERS_REGEX: /[a-z]/,
	MUST_CONTAINS_DIGITS_REGEX: /[0-9]/,
	SYSTEM: {
		EZPAY_CARD: 3,
		ALLIED_CARD: 4
	},
	EMAIL_REGEX: /\S+@\S+\.\S+/,
	CHINESE_FAKE_EMAIL_REGEX: /GT+[0-9]{6}@triple-wallet(?![\s\S])/,
	CHINESE_USERCODE_REGEX: /GT+[0-9]{6}(?![\s\S])/,
	OUTSIDE_CHINESE_USERCODE_REGEX: /GN+[0-9]{6}(?![\s\S])/,
	LOGIN_METHOD: {
		EMAIL: 'email',
		USER_CODE: 'user_code'
	},
	REGION: {
		CHINESE: 0,
		NON_CHINESE: 1
	},
	PRODUCT_CODE_STATUS: {
		NEW: 0,
		USED: 1,
		REJECTED: 2
	},
	TYPE_REPEAT_ORDER_CODE: {
		RETAIL: "retail",
		WHOLESALE: "wholesale"
	},
	WAY_TO_SHIP: {
		ONCE_TIME: 1,
		IN_12_TIMES: 12,
	},
	TYPE_CODE: {
		REGISTER_CODE: 'register_code',
		REPEAT_ORDER_CODE: 'repeat_order_code'
	}
};

export { constants, errors };
