import React, {Component} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './tfaCode.css';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { I18n } from 'react-i18next';
import { submitTFACode } from "../../../01-actions/wallet";
import { Field, reduxForm } from "redux-form";
import validate from "./validate";

class TfaCode extends Component {
	state = {
		code: '',
	};

	onCodeChange = e => {
		this.setState({ code: e.target.value });
	};
	
	onSubmitCode = () => {
		this.props.onSubmit(this.state.code);
		this.refreshInputAndToggle();
	};

	renderField = ({ input, label, type, value, meta: { touched, error } }) => (
		<div className="form-group">
			<label>{label}</label>
			<div className="input-group">
				<input
					{...input}
					type={type}
					className="form-control input-tfa"
					value={value}
					onChange={e => this.setState({ [input.name]: e.target.value })}
				/>
				{touched && error && <p className="text-danger">{`* ${error}`}</p>}
			</div>
		</div>
	);

	refreshInputAndToggle = () => {
		this.setState({ code: '' });
		const { toggle } = this.props;
		toggle && toggle();
	};
	
	render() {
		const { isOpen } = this.props;
		return (
			<I18n ns="translations">
				{
					t => (
						<Modal isOpen={isOpen} toggle={this.refreshInputAndToggle}>
							<ModalHeader toggle={this.refreshInputAndToggle}>{t('two_factor_authentication')}</ModalHeader>
							<ModalBody className="text-center">
								<img className="ga-guide" src="/images/ga-guide.png" alt=""/>
								<div className="margin-bottom-15px text-center">
									<div>{t('enter_tfa_intro')}</div>
								</div>
								<Field
									name="code"
									type="number"
									component={this.renderField}
								/>
							</ModalBody>
							<ModalFooter>
								<button className="btn btn-primary" onClick={this.onSubmitCode}>{t('confirm')}</button>
							</ModalFooter>
						</Modal>
					)
				}
			</I18n>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				submitTFACode,
			},
			dispatch
		)
	};
};

export default connect(
	null,
	mapDispatchToProps
)(reduxForm({
	form: 'tfaCode',
	validate,
})(TfaCode));