import React, { Component } from 'react';

// actions
import {
    getAmountBalance
} from '../../05-utils/commonUtils';

// styles
import './selectWallet.scss';
import {I18n} from "react-i18next";

class SelectWallet extends Component {
    constructor() {
        super();

        this.state = {
            balance: 0,
            address: '',
            listWallet: [],
            haveWallets: true
        }
    }

    componentDidMount() {
        const {
            userWallets,
            walletAddress,
            minAmountAccepted,
        } = this.props;
        if (walletAddress) {
            const walletSelected = userWallets && userWallets.find(wallet => wallet.address === walletAddress);
            const dataBalance = walletSelected.balances.find(item => item.asset_code === 'AGT') || {};
            this.setState({
                address: walletAddress,
                balance: Number(dataBalance.balance) || 0
            });
        }
        const wallets = userWallets && userWallets.filter(wallet => wallet.balances && wallet.balances.find(balance => balance.asset_code === 'AGT' && Number(balance.balance) > Number(minAmountAccepted)));
        if (wallets.length > 0) {
            this.setState({
                listWallet: wallets
            });
        } else this.setState({
            haveWallets: false
        })
    }

    handleSelectWallet = (event) => {
        const {
            handleSelectAddress
        } = this.props;

        const {
            listWallet
        } = this.state;

        const addressSelect = event.target.value;
        const walletSelected = listWallet && listWallet.find(wallet => wallet.address === addressSelect);
        const dataBalance = walletSelected.balances.find(item => item.asset_code === 'AGT') || {};

        this.setState({
            address: addressSelect,
            balance: Number(dataBalance.balance) || 0
        });
        handleSelectAddress(addressSelect);
    };

    render() {
        const {
            listWallet,
            haveWallets
        } = this.state;
        
        return (
            <I18n ns="translations">
                {
                    t => (
                        <div>
                            { haveWallets ?
                                <React.Fragment>
                                    <h4 className='heading-select-wallet'>{t('select_wallet')}</h4>
                                    <select
                                        id='walletSelected'
                                        className="form-control cp-gbt-select margin-top-15px"
                                        onChange={this.handleSelectWallet}
                                        value={this.state.address}
                                    >
                                        <option value="">{t('select_wallet')}</option>
                                        {
                                            !!listWallet && listWallet.map((wallet, index) => {
                                                return  <option key={index} value={wallet.address}>{wallet.address}</option>
                                            })
                                        }
                                    </select>
                                    <div className='margin-top-15px'>
                                        {t('wallet_amount')} {getAmountBalance(this.state.balance, 3) || 0} AGT
                                    </div>
                                </React.Fragment>
                                : <div className='text-center'>{t('no_have_wallet_valid')}</div>
                            }
                        </div>
                    )
                }
            </I18n>
        );
    }
}

export default SelectWallet;