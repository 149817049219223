import React, {Component} from 'react';
import { I18n } from 'react-i18next';
import Tab from '../../../03-components/Tab';
import RepeatOrderCodeManagement from "./RepeatOrderCodeTab/repeatOrderCodeManagement";
import RegisterCodeManagement from "./RegisterCodeTab/registerCodeManagement";

class CodeManagement extends Component {
    state = {
        currentTab: 'register_code'
    };

    onTabClick = (e, tabName) => {
        e.preventDefault();
        this.setState({ currentTab: tabName });
    };

    render() {
        const {
            currentTab
        } = this.state;
        let contentJSX;

        if (currentTab === 'repeat_order_code') {
            contentJSX = <RepeatOrderCodeManagement/>;
        } else {
            contentJSX = <RegisterCodeManagement/>;
        }

        return (
            <I18n ns="translations">
                {
                    t => (
                        <div className="grid-margin">
                            <div className="card">
                                <ul className="nav nav-tabs tab-solid tab-solid-danger" role="tablist">
                                    <Tab
                                        tabName="register_code"
                                        currentTab={currentTab}
                                        onTabClick={this.onTabClick}
                                        text={t('register_code')}
                                    />
                                    <Tab
                                        tabName="repeat_order_code"
                                        currentTab={currentTab}
                                        onTabClick={this.onTabClick}
                                        text={t('repeat_order_code')}
                                    />
                                </ul>
                                {
                                    contentJSX
                                }
                            </div>
                        </div>
                    )
                }
            </I18n>
        );
    }
}


export default CodeManagement;