import { constants } from '../../../../../05-utils/commonData';
import I18n from 'i18next';

const validate = values => {
	const errors = {};
	if (!values.password) {
		errors.password = I18n.t('OP_PASSWORD_REQUIRED');
	}
	if (!values.newPassword) {
		errors.newPassword = I18n.t('NEW_OP_PASSWORD_REQUIRED');
	}
	if (values.newPassword && (values.newPassword.length < constants.MIN_USER_PASSWORD_LENGTH || values.newPassword.length > constants.MAX_USER_PASSWORD_LENGTH)) {
		errors.newPassword = I18n.t('INCORRECT_OP_PASSWORD_LENGTH');
	}
	else if(values.newPassword && !new RegExp(constants.ONLY_LATIN_CHARACTERS_REGEX).test(values.newPassword)){
		errors.newPassword = I18n.t('OP_PASS_ONLY_CONTAIN_LATIN_CHARACTER');
	}
    else if(values.newPassword && !constants.MUST_CONTAINS_UPPERCASE_CHARACTERS_REGEX.test(values.newPassword)){
		errors.newPassword = I18n.t('OP_PASS_MUST_CONTAIN_UPPERCASE_CHARACTER');
	}
    else if(values.newPassword && !constants.MUST_CONTAINS_LOWERCASE_CHARACTERS_REGEX.test(values.newPassword)){
		errors.newPassword = I18n.t('OP_PASS_MUST_CONTAIN_LOWERCASE_CHARACTER');
	}
    else if(values.newPassword && !constants.MUST_CONTAINS_DIGITS_REGEX.test(values.newPassword)){
		errors.newPassword = I18n.t('OP_PASS_MUST_CONTAIN_DIGITS');
	}
    else if(values.newPassword && !constants.MUST_CONTAINS_SPECIAL_CHARACTERS_REGEX.test(values.newPassword)){
		errors.newPassword = I18n.t('OP_PASS_MUST_CONTAIN_SPECIAL_CHARACTER');
	}
	if (!values.confirmPassword) {
		errors.confirmPassword = I18n.t('CONFIRM_OP_PASSWORD_REQUIRED');
	}
	if (values.newPassword !== values.confirmPassword) {
		errors.confirmPassword = I18n.t('OP_PASSWORD_NOT_MATCH');
	}
	return errors;
};

export default validate;