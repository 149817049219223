import { constants } from '../../../../../05-utils/commonData';
import I18n from 'i18next';

const validate = values => {
	const errors = {};
	if (!values.password) {
		errors.password = I18n.t('PASSWORD_REQUIRED');
	}
	if (!values.newPassword) {
		errors.newPassword = I18n.t('NEW_PASSWORD_REQUIRED');
	}
	if (values.newPassword && (values.newPassword.length < constants.MIN_USER_PASSWORD_LENGTH || values.newPassword.length > constants.MAX_USER_PASSWORD_LENGTH)) {
		errors.newPassword = I18n.t('INCORRECT_PASSWORD_LENGTH');
	}
	if (!values.confirmPassword) {
		errors.confirmPassword = I18n.t('CONFIRM_PASSWORD_REQUIRED');
	}
	if (values.newPassword !== values.confirmPassword) {
		errors.confirmPassword = I18n.t('PASSWORD_NOT_MATCH');
	}
	return errors;
};

export default validate;