import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// components
import SelectUserTitle from '../../../../03-components/SelectUserTitle/selectUserTitle';
import SubmitButton from '../../../../03-components/ModalSubmitButton';
import {
    getAmountBalance
} from '../../../../05-utils/commonUtils';
// styles
import './createRequestCodeModal.css';
import {I18n} from "react-i18next";

class CreateRequestCodeModal extends Component {
    state = {
        userTitle: {}
    };


    componentWillReceiveProps(nextProps) {
        if (!this.props.requestProductCodeCreated  && nextProps.requestProductCodeCreated) {
            this.props.onOpenNotify();
            this.refreshInputAndToggle();
        }
    }

    createRequestProductCode = () => {
        const {
            userTitle,
        } = this.state;

        this.props.onCreateRequest({
            type: userTitle.type,
            titleName: userTitle.name,
            amount: getAmountBalance(userTitle.USDT, 7),
            asset: this.props.asset
        })
    };

    handleSelectTitle = (data) => {
        this.setState({
            userTitle: data
        })
    };

    refreshInputAndToggle = () => {
        this.setState({
            userTitle: {}
        });
        this.props.toggle();
    };

    render() {
        const {
            isOpen,
            asset,
            listTitle,
            isFetchingTitles,
            requestProductCodeCreating,
            onBack
        } = this.props;

        const {
            userTitle
        } = this.state;
        return (
            <I18n ns="translations">
                {
                    t => (
                        <Modal isOpen={isOpen} className="modal-lg">
                            <ModalHeader toggle={this.refreshInputAndToggle}>
                                {t('create_new_code')}
                            </ModalHeader>
                            <ModalBody>
                                <SelectUserTitle
                                    listTitle={listTitle}
                                    handleSelectTitle={this.handleSelectTitle}
                                    isFetchingTitles={isFetchingTitles}
                                    userTitle={userTitle}
                                    asset={asset}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <SubmitButton
                                    className="btn btn-info"
                                    text={t('back')}
                                    onClick={onBack}
                                />
                                <SubmitButton
                                    className="btn btn-primary"
                                    onClick={this.createRequestProductCode}
                                    disabled={ !userTitle.id}
                                    text={t('generate')}
                                    isSubmitting={requestProductCodeCreating}
                                />
                            </ModalFooter>
                        </Modal>
                    )
                }
            </I18n>
        );
    }
}

export default CreateRequestCodeModal;