import React, {PureComponent} from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter
} from 'reactstrap';

export default class Alert extends PureComponent {
	constructor(props) {
		super(props);
		this.handleClose = this.handleClose.bind(this);
	}
	
	handleClose() {
		this.props.onClose();
	}
	
	render() {
		return (
			<Modal isOpen={this.props.isOpen}>
				<ModalHeader>{this.props.title}</ModalHeader>
				<ModalBody>{this.props.content}</ModalBody>
				<ModalFooter>
					<Button className="btn btn-success" onClick={this.props.onOK}>{this.props.buttonOK}</Button>
					<Button onClick={this.handleClose}>{this.props.buttonClose}</Button>
				</ModalFooter>
			</Modal>
		);
	}
	
}