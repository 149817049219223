import React, {Component} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import CKEditor from "react-ckeditor-component";

import SubmitButton from '../../../../03-components/ModalSubmitButton';
import validate from './validate';
import { connect } from "react-redux";
import { I18n } from 'react-i18next';

class ModalNews extends Component {
	state = {
		title: '',
		content: '',
	};

	componentDidUpdate(prevProps) {
		const { oldTitle, oldContent } = this.props;
		if (oldTitle !== prevProps.oldTitle || oldContent !== prevProps.oldContent) {
			this.setState({ title: oldTitle, content: oldContent });
		}
	}
	
	renderField = ({ input, label, type, fieldValue, meta: { touched, error } }) => (
		<div className="form-group">
			<label>{label}</label>
			<div className="input-group">
				<input
					{...input}
					type={type}
					className="form-control create-wallet-input"
					value={fieldValue}
					onChange={e => this.setState({ [input.name]: e.target.value })}
				/>
				{touched && error && <p className="text-danger">{`* ${error}`}</p>}
			</div>
		</div>
	);
	
	renderCKEditor = ({ label, fieldValue, meta: { touched, error } }) => (
		<div className="form-group">
			<label>{label}</label>
			<div className="input-group">
				<CKEditor
					activeClass="p10"
					content={fieldValue}
					events={{
						"change": e => this.setState({ content: e.editor.getData() })
					}}
				/>
				{touched && error && <p className="text-danger">{`* ${error}`}</p>}
			</div>
		</div>
	);
	
	execAction = () => {
		const { title, content } = this.state;
		const { newsId } = this.props;
		this.props.action({
			newsId,
			title: title.trim(),
			content: content.trim(),
		});
		this.refreshInputAndToggle();
	};

	refreshInputAndToggle = () => {
		this.setState({title: this.props.oldTitle ? this.props.oldTitle: '' , content: this.props.oldContent ? this.props.oldContent:'' })
        this.props.toggle();
	};
	
	render() {
		const {
			isOpen,
			modalTitle,
			btnText,
			contactAdding,
			contactEditing,
			invalid,
		} = this.props;
		const { content, title } = this.state;
		return (
			<I18n ns="translations">
				{
					t => (
						<Modal isOpen={isOpen} className="modal-lg">
							<ModalHeader toggle={this.refreshInputAndToggle}>{modalTitle}</ModalHeader>
							<ModalBody>
								<form className="margin-top-1rem">
									<Field
										name="title"
										type="text"
										component={this.renderField}
										label={t('title')}
										fieldValue={title}
									/>
									<Field
										name="content"
										component={this.renderCKEditor}
										label={t('content')}
										fieldValue={content}
									/>
								</form>
							</ModalBody>
							<ModalFooter>
								<SubmitButton
									className="btn btn-primary"
									onClick={this.execAction}
									isSubmitting={contactAdding || contactEditing}
									disabled={invalid || !title || !content}
									text={btnText}
								/>
							</ModalFooter>
						</Modal>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		contactAdding: store.addressBook.contactAdding,
		contactEditing: store.addressBook.contactEditing,
	};
};

export default connect(
	mapStateToProps,
	null
)(reduxForm({
	form: 'modalNews',
	validate,
})(ModalNews));
