
import { ACTIONS } from '../01-actions/adminUser';
import { AUTH_ACTIONS } from '../01-actions';
import { UTIL_ACTIONS } from '../01-actions/utils';

const initialState = {
	status: null,
	users: null,
	totalUser: null,
	isFetching: false,
	userProfile: null,
	accountStatus: null,
	userResetting: false,
	userReset: false,
	error: null,
    resetTfaStatus: false,
    editedEmail: false,
	dataImportCSV: {
		totalRobinIdsImport: 0,
		totalRobinIdsSuccess: 0
	}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.GET_LIST_USER_INFO_PROGRESS:
			return {
				...state,
				status: action.type,
				isFetching: true,
			};
		case ACTIONS.GET_LIST_USER_INFO_SUCCESS:
			return {
				...state,
				status: action.type,
				users: action.data.users,
				totalUser: action.data.totalUser ? action.data.totalUser : state.totalUser,
				isFetching: false,
			};
		case ACTIONS.GET_LIST_USER_INFO_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				isFetching: false,
			};
		case ACTIONS.GET_USER_PROFILE_SUCCESS:
			return {
				...state,
				status: action.type,
				users: action.data.users,
				userProfile: action.data.userProfile,
			};
        case ACTIONS.EDIT_USER_SUCCESS:
            return {
                ...state,
                status: action.type,
                userProfile: Object.assign({}, state.userProfile, action.data.userInfo),
            };
        case ACTIONS.EDIT_EMAIL_SUCCESS:
            return {
                ...state,
                status: action.type,
                userProfile: Object.assign({}, state.userProfile, action.data),
                editedEmail: true
            };
		case ACTIONS.GET_USER_PROFILE_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
			};
		case ACTIONS.CHANGE_STATUS_USER_PROGRESS:
        case ACTIONS.RESET_TFA_USER_PROGRESS:
        case ACTIONS.GET_USER_PROFILE_PROGRESS:
        case ACTIONS.EDIT_USER_PROGRESS:
        case ACTIONS.EDIT_EMAIL_PROGRESS:
			return {
				...state,
				status: action.type,
			};
        case ACTIONS.RESET_TFA_USER_SUCCESS:
        case ACTIONS.CHANGE_TFA_STATUS:
            return {
                ...state,
                status: action.type,
                resetTfaStatus:action.data.status,
            };

		case ACTIONS.CHANGE_STATUS_USER_SUCCESS:
			return {
				...state,
				status: action.type,
				accountStatus: action.data.accountStatus,
			};
		case ACTIONS.CHANGE_STATUS_USER_FAILED:
        case ACTIONS.RESET_TFA_USER_FAILED:
        case ACTIONS.EDIT_USER_FAILED:
        case ACTIONS.EDIT_EMAIL_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
			};
		case ACTIONS.ADMIN_RESET_USER_PROGRESS:
			return {
				...state,
				status: action.type,
				userResetting: true,
			};
		case ACTIONS.RESET_EDIT_EMAIL_STATUS:
			return {
				...state,
				editedEmail: null,
			};
			
		case ACTIONS.ADMIN_RESET_USER_SUCCESS:
			return {
				...state,
				status: action.type,
				userResetting: false,
				userReset: true,
			};
		case ACTIONS.ADMIN_RESET_USER_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				userResetting: false,
			};
		case ACTIONS.RESET_ADMIN_RESET_USER_STATUS:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				userReset: false,
			};
		case ACTIONS.RESET_ERROR_ADMIN_USER_API_MESSAGE:
			return {
				...state,
				error: null,
			};
		case AUTH_ACTIONS.LOGOUT:
			return initialState;
		case UTIL_ACTIONS.RESET_ERROR:
			return {
				...state,
				error: null,
			};
		default:
			return state;
	}
}