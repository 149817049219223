import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { I18n } from 'react-i18next';
import { Collapse } from 'reactstrap';
import {
	getHistoryTransaction,
	pagingTransactionHistory,
	resetTransactionHistoryStore,
} from "../../../../01-actions/transaction";
import { getAndGroupUserWallet } from "../../../../01-actions/wallet";
import {
	formatDateTimeFull,
	getIconTransaction,
	getTransactionDescription
} from "../../../../05-utils/commonUtils";
import TransactionDetail from '../../../../03-components/TransactionDetail';
import './index.css';
import Loading from '../../../../03-components/Loading';
import { getEnv } from '../../../../env';
import Button from '../../../../03-components/ModalSubmitButton';

class Transaction extends Component {
	state = {
		addressSelected: '',
		addresses: [],
		currentPage: 1,
	};
	
	componentDidMount() {
		this.props.actions.getAndGroupUserWallet();
	}
	
	componentWillReceiveProps(nextProps) {
		const { standardWallet, transactionsHistory, actions } = nextProps;
		if (standardWallet !== this.props.standardWallet && standardWallet && standardWallet.length > 0 && standardWallet[0]) {
			const addresses = standardWallet.filter(wallet => wallet.active);
			if (addresses.length !== 0) {
				this.setState({ addressSelected: addresses[0].address, addresses }, () => {
					actions.getHistoryTransaction({ address: this.state.addressSelected });
				});
			}
		}

		if (transactionsHistory !== this.props.transactionsHistory) {
			this.setState({ transactionsHistory });
		}
	}

	componentWillUnmount() {
		this.props.actions.resetTransactionHistoryStore();
	}

	onWalletSelected = e => {
		const { resetTransactionHistoryStore, getHistoryTransaction } = this.props.actions;
		this.setState({ addressSelected: e.target.value, currentPage: 1 }, () => {
			resetTransactionHistoryStore();
			getHistoryTransaction({ address: this.state.addressSelected });
		});
	};

	onPressPrev = () => {
		const { prevPagingToken, actions } = this.props;
		actions.getHistoryTransaction({
			address: this.state.addressSelected,
			cursor: prevPagingToken,
			order: 'asc',
		});
		this.setState({ currentPage: this.state.currentPage - 1 });
	};

	onPressNext = () => {
		const { nextPagingToken, actions } = this.props;
		actions.getHistoryTransaction({
			address: this.state.addressSelected,
			cursor: nextPagingToken,
			order: 'desc',
		});
		this.setState({ currentPage: this.state.currentPage + 1 });
	};

    renderTransactionDetail = (t, transaction) => {

        switch(transaction.type) {
            case 'change_trust':
                return (
                    <React.Fragment>
                    <div className="charge-trust col-md-2">
                        <span className="fa fa-handshake-o"/>
                        <div><label>{t('charge_trust')}</label></div>
                    </div>
                    <div className="col-md-8 text-left info">
                        <div className='mb-2'>{t('trust_asset')} : {transaction.asset_code}</div>
                        <div className='mb-2'>{t('issue_wallet')} : {transaction.asset_issuer}</div>
                        <div>{t('time')} : {formatDateTimeFull(transaction.created_at)}</div>
                    </div>
                    </React.Fragment>
                );
            case 'create_account':
                return (
                    <React.Fragment>
                        <div className="create-wallet col-md-2">
                            <span className="fa fa-user-circle"/>
                            <div><label>{t('create_wallet')}</label></div>
                        </div>
                        <div className="col-md-8 text-left info">
                            <div className='mb-2'>{t('funder_wallet')} : {transaction.funder}</div>
                            <div className='mb-2'>{t('new_wallet')} : {transaction.account}</div>
                            <div className='mb-2'>{t('amount')} : {getTransactionDescription(transaction)}</div>
                            <div>{t('time')} : {formatDateTimeFull(transaction.created_at)}</div>
                        </div>
                    </React.Fragment>
                );
            case 'payment':
                return (
                    <React.Fragment>
                        <div className="payment col-md-2">
                            <span className="fa fa-money"/>
                            <div><label>{t('payment')}</label></div>
                        </div>
                        <div className="col-md-8 text-left info">
                            <div className='mb-2'>{t('sender_wallet')} : {transaction.source_account}</div>
                            <div className='mb-2'>{t('receiver_wallet')} : {transaction.to}</div>
                            <div className='mb-2'>{t('amount')} : {getTransactionDescription(transaction)}</div>
                            <div>{t('time')} : {formatDateTimeFull(transaction.created_at)}</div>
                        </div>
                    </React.Fragment>
                );
            default:
                return (
                    <React.Fragment>
                        <div className="merge-wallet col-md-2">
                            <span className="fa fa-compress"/>
                            <div><label>{t('merge_wallet')}</label></div>
                        </div>
                        <div className="col-md-8 text-left info">
                            <div className='mb-2'>{t('source_wallet')} : {transaction.account}</div>
                            <div className='mb-2'>{t('destination_wallet')} : {transaction.into}</div>
                            <div>{t('time')} : {formatDateTimeFull(transaction.created_at)}</div>
                        </div>
                    </React.Fragment>
                )
        }
    };
	
	render() {
		const {
			isFetchingWallet,
			isFetchingHistory,
		} = this.props;
		const {
			addresses,
			transactionsHistory,
			currentPage,
			addressSelected,
		} = this.state;
		
		return (
			<I18n ns="translations">
				{
					t => (
						<div className="col-lg-12">
							<h4 className="card-title">{t('transaction_history')}</h4>
							<div className="form-group">
								{
									isFetchingWallet ?
										(<Loading parent1Class="text-center"/>)
										:
										addresses && addresses.length !== 0 && (
											<select
												className="form-control cp-gbt-select"
												value={this.state.addressSelected}
												onChange={this.onWalletSelected}
											>
												{
													isFetchingWallet ? null : addresses.map((wallet, index) => (
														<option key={index} value={wallet.address}>{wallet.name}</option>
													))
												}
											</select>
										)
								}
							</div>
							<div className="table-responsive">
								<table className="table table-hover">
									<tbody>
									{
										isFetchingWallet ?
											null
											:
											isFetchingHistory ?
												(<Loading isTableRow/>)
												:
												Array.isArray(transactionsHistory) && transactionsHistory.length > 0 ?
												transactionsHistory.map((tran, index) => (
														<React.Fragment  key={index}>
															<tr className="hover-item-tb" onClick={() => this.setState({
																		transactionsHistory: [
																			...transactionsHistory.slice(0, index),
																			Object.assign({}, transactionsHistory[index],  {isOpen: transactionsHistory[index] && transactionsHistory[index].isOpen ? false : true}),
																			...transactionsHistory.slice(index + 1)
																		]
																	})}>
																<td>
																	{formatDateTimeFull(tran.created_at)}
																</td>
																<td>
																	<i className={getIconTransaction(tran, this.state.addressSelected)}/>
																</td>
																<td>
																	{t(tran.type)}
																</td>
																<td>
																	<b>{getTransactionDescription(tran)}</b>
																</td>
																<td>
																	{tran.memo && (<i className="text-success fas fa-comment mr-2"/>)}
																	{tran.memo}
																</td>
																<td className="transaction-hash-cell">
																	<a
																		target="_blank"
																		rel="noopener noreferrer"
																		href={`${getEnv('REACT_APP_DASHBOARD_URI')}/blockscan-tx/${tran.transaction_hash}`}
																	>
																		{`${tran.transaction_hash.substring(0, 10)}...`}
																	</a>
																</td>
																<td className="history-transaction-icon">
																	<div>
																		<i className={`fa fa-chevron-${transactionsHistory[index] && transactionsHistory[index].isOpen ? "up" : "down"}`}/>
																	</div>
																</td>
															</tr>
															<tr>
																<td colSpan={7}>
																	<Collapse isOpen={transactionsHistory[index] && transactionsHistory[index].isOpen}>
																		<div className="transaction-detail align-items-center row">
																			<TransactionDetail 
																				transaction={tran}
																				t={t} />
																		</div>
																	</Collapse>
																</td>
															</tr>
														</React.Fragment>
													))
													:
													(<tr className="text-center">
														<td>
															<div>{t('no_data_to_show')}</div>
														</td>
													</tr>)
									}
									</tbody>
								</table>
							</div>
							{
								addressSelected && !isFetchingWallet && !isFetchingHistory ?
									(<div className="paginate-wrapper text-center margin-top-15px">
										<Button
											className='btn btn-primary m-2'
											text={t('previous')}
											onClick={this.onPressPrev}
											disabled={currentPage === 1}
										/>
										<Button
											className='btn btn-primary m-2'
											text={t('next')}
											onClick={this.onPressNext}
											disabled={transactionsHistory && transactionsHistory.length < 10}
										/>
									</div>)
									:
									null
							}
						</div>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		transactionsHistory: store.transaction.transactionsHistory,
		nextPagingToken: store.transaction.nextPagingToken,
		prevPagingToken: store.transaction.prevPagingToken,
        standardWallet: store.wallet.standardWallet,
		isFetchingWallet: store.wallet.isFetching,
        multisignWallet: store.wallet.multisignWallet,
		isFetchingHistory: store.transaction.isFetching,
		pagingToken: store.transaction.pagingToken,
		canGetMoreTransactionHistory: store.transaction.canGetMoreTransactionHistory,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions: bindActionCreators(
			{
				getHistoryTransaction,
                getAndGroupUserWallet,
				pagingTransactionHistory,
				resetTransactionHistoryStore,
			},
			dispatch
		)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Transaction);