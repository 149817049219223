import React, {Component} from 'react';
import './assetLogo.css';

export default class AssetLogo extends Component {
	state = {
		isLoadFailed: false,
	};
	render() {
		const { src, assetName, inTrustCoin, className } = this.props;
		return (
			<div className={`asset-logo ${className} ${!inTrustCoin ? 'asset-logo-large' : ''}`}>
				<img
					src={src || "/images/asset-logo/RIA.png"}
					onError={e => {
						e.target.src = "/images/asset-logo/default.png";
						this.setState({ isLoadFailed: true })
					}}
					alt=""
					style={inTrustCoin ? { height: 54, width: 78 } : { height: 37, width: 54 }}
				/>
				{
					this.state.isLoadFailed ? (<span style={{fontSize: inTrustCoin ? 10 : 8}}>{assetName && assetName.substring(0, 3)}</span>) : null
				}
			</div>
		);
	}
}