import React, { Component } from 'react';
import { editEmailData } from "../../../../01-actions/adminUser";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import validate from './validate';
import { I18n } from 'react-i18next';
import {Field, reduxForm} from "redux-form";
import SubmitButton from "../../../../03-components/ModalSubmitButton";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

class ChangeEmail extends Component {

	constructor(props){
		super(props);
		this.state = {
			email: props.email
		};
	}

	componentWillReceiveProps(nextProps){
		if(this.props.email !== nextProps.email){
			this.setState({email: nextProps.email});
		}
	}

	editEmaiSubmit = () => {
		const { email } = this.state;
		this.props.actions.editEmailData({
			id: this.props.userID,
			email,
		});
		this.refreshInputAndToggle();
	};

	refreshInputAndToggle = () => {
		this.setState({ email: this.props.email});
		this.props.toggle();
	}

	renderField = ({input, label, type, fieldValue, meta: {touched, error}}) => (
		<div className="form-group">
			<label>{label}</label>
			<div className="input-group">
				<input
					{...input}
					type={type}
					className="form-control transfer-input"
					value={fieldValue}
					onChange={e => {
						this.setState({[input.name]: e.target.value})
					}}
				/>
				{touched && error && <p className="text-danger">{`* ${error}`}</p>}
			</div>
		</div>
	);

	render() {
		const { email, invalid } = this.state;

		return (
			<I18n ns="translations">
				{
					t => (
						<Modal isOpen={this.props.isOpen} className="modal-lg">
							<ModalHeader toggle={this.refreshInputAndToggle}>{t('edit_email_of_user')}</ModalHeader>
							<ModalBody>
								<form className="margin-top-1rem">
									<Field
										name="email"
										type="text"
										component={this.renderField}
										label={t('email')}
										fieldValue={email}
									/>
								</form>
							</ModalBody>
							<ModalFooter>
								<SubmitButton
									className="btn btn-primary"
									onClick={this.editEmaiSubmit}
									disabled={invalid}
									text="OK"
								/>
							</ModalFooter>
						</Modal>
					)
				}
			</I18n>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				editEmailData,
			},
			dispatch
		)
	};
};

export default connect(null, mapDispatchToProps)(reduxForm({form: 'changeEmail',validate})(ChangeEmail));