import { callApi } from "../05-utils/commonUtils";
import { updateStatus } from "./utils";
import { errors } from "../05-utils/commonData";

const ACTIONS = {
	GET_MAINTENANCE_MODE_STATUS_PROGRESS: "GET_MAINTENANCE_MODE_STATUS_PROGRESS",
	GET_MAINTENANCE_MODE_STATUS_SUCCESS: "GET_MAINTENANCE_MODE_STATUS_SUCCESS",
	GET_MAINTENANCE_MODE_STATUS_FAILED: "GET_MAINTENANCE_MODE_STATUS_FAILED",
	CHANGE_MAINTENANCE_MODE_STATUS_PROGRESS: "CHANGE_MAINTENANCE_MODE_STATUS_PROGRESS",
	CHANGE_MAINTENANCE_MODE_STATUS_SUCCESS: "CHANGE_MAINTENANCE_MODE_STATUS_SUCCESS",
	CHANGE_MAINTENANCE_MODE_STATUS_FAILED: "CHANGE_MAINTENANCE_MODE_STATUS_FAILED",
	GET_MAINTENANCE_APP_STATUS_PROGRESS: "GET_MAINTENANCE_APP_STATUS_PROGRESS",
	GET_MAINTENANCE_APP_STATUS_SUCCESS: "GET_MAINTENANCE_APP_STATUS_SUCCESS",
	GET_MAINTENANCE_APP_STATUS_FAILED: "GET_MAINTENANCE_APP_STATUS_FAILED",
	CHANGE_MAINTENANCE_APP_STATUS_PROGRESS: "CHANGE_MAINTENANCE_APP_STATUS_PROGRESS",
	CHANGE_MAINTENANCE_APP_STATUS_SUCCESS: "CHANGE_MAINTENANCE_APP_STATUS_SUCCESS",
	CHANGE_MAINTENANCE_APP_STATUS_FAILED: "CHANGE_MAINTENANCE_APP_STATUS_FAILED",
    GET_URL_UPLOAD_BACKGROUND_PROGRESS: "GET_URL_UPLOAD_BACKGROUND_PROGRESS",
    GET_URL_UPLOAD_BACKGROUND_SUCCESS: "GET_URL_UPLOAD_BACKGROUND_SUCCESS",
    GET_URL_UPLOAD_BACKGROUND_FAILED: "GET_URL_UPLOAD_BACKGROUND_FAILED",
    GET_TIMESTAMP_BACKGROUND_PROGRESS: "GET_TIMESTAMP_BACKGROUND_PROGRESS",
    GET_TIMESTAMP_BACKGROUND_SUCCESS: "GET_TIMESTAMP_BACKGROUND_SUCCESS",
    GET_TIMESTAMP_BACKGROUND_FAILED: "GET_TIMESTAMP_BACKGROUND_FAILED",
    UPDATE_MAINTENANCE_STATUS: "UPDATE_MAINTENANCE_STATUS",
    GET_TOP_UP_STATUS_PROGRESS: "GET_TOP_UP_STATUS_PROGRESS",
    GET_TOP_UP_STATUS_SUCCESS: "GET_TOP_UP_STATUS_SUCCESS",
    GET_TOP_UP_STATUS_FAILED: "GET_TOP_UP_STATUS_FAILED",
    CHANGE_TOP_UP_STATUS_PROGRESS: "CHANGE_TOP_UP_STATUS_PROGRESS",
    CHANGE_TOP_UP_STATUS_SUCCESS: "CHANGE_TOP_UP_STATUS_SUCCESS",
    CHANGE_TOP_UP_STATUS_FAILED: "CHANGE_TOP_UP_STATUS_FAILED",
    UPDATE_TOP_UP_STATUS: "UPDATE_TOP_UP_STATUS",
    GET_PRODUCT_CODE_STATUS_PROGRESS: "GET_PRODUCT_CODE_STATUS_PROGRESS",
    GET_PRODUCT_CODE_STATUS_SUCCESS: "GET_PRODUCT_CODE_STATUS_SUCCESS",
    GET_PRODUCT_CODE_STATUS_FAILED: "GET_PRODUCT_CODE_STATUS_FAILED",
    CHANGE_PRODUCT_CODE_STATUS_PROGRESS: "CHANGE_PRODUCT_CODE_STATUS_PROGRESS",
    CHANGE_PRODUCT_CODE_STATUS_SUCCESS: "CHANGE_PRODUCT_CODE_STATUS_SUCCESS",
    CHANGE_PRODUCT_CODE_STATUS_FAILED: "CHANGE_PRODUCT_CODE_STATUS_FAILED",
    UPDATE_PRODUCT_CODE_STATUS: "UPDATE_PRODUCT_CODE_STATUS",
	GET_REPEAT_ORDER_CODE_STATUS_PROGRESS: "GET_REPEAT_ORDER_CODE_STATUS_PROGRESS",
	GET_REPEAT_ORDER_CODE_STATUS_SUCCESS: "GET_REPEAT_ORDER_CODE_STATUS_SUCCESS",
	GET_REPEAT_ORDER_CODE_STATUS_FAILED: "GET_REPEAT_ORDER_CODE_STATUS_FAILED",
    CHANGE_REPEAT_ORDER_CODE_STATUS_PROGRESS: "CHANGE_REPEAT_ORDER_CODE_STATUS_PROGRESS",
    CHANGE_REPEAT_ORDER_CODE_STATUS_SUCCESS: "CHANGE_REPEAT_ORDER_CODE_STATUS_SUCCESS",
    CHANGE_REPEAT_ORDER_CODE_STATUS_FAILED: "CHANGE_REPEAT_ORDER_CODE_STATUS_FAILED",
    UPDATE_REPEAT_ORDER_CODE_STATUS: "UPDATE_REPEAT_ORDER_CODE_STATUS",
};

const getUrlUploadBackground = () => {
	const data = {
		name: "admin_update_background",
		params: {}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.GET_URL_UPLOAD_BACKGROUND_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.GET_URL_UPLOAD_BACKGROUND_SUCCESS, { url: response.result }));
				else {
					dispatch(updateStatus(ACTIONS.GET_URL_UPLOAD_BACKGROUND_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("getUrlUploadBackground", err);
				dispatch(updateStatus(ACTIONS.GET_URL_UPLOAD_BACKGROUND_FAILED, {error: err}));
			});
	};
};

const getMaintenanceModeStatus = () => {
	const data = {
		name: "check_maintenance_status",
		params: {}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.GET_MAINTENANCE_MODE_STATUS_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.GET_MAINTENANCE_MODE_STATUS_SUCCESS, { maintenanceMode: response.result }));
				else {
					if (errors.SERVER_IS_MAINTENANCE.errorCode === response.error.errorCode) {
						return dispatch(updateStatus(ACTIONS.GET_MAINTENANCE_MODE_STATUS_SUCCESS, { maintenanceMode: true }));
					}
					dispatch(updateStatus(ACTIONS.GET_MAINTENANCE_MODE_STATUS_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("getMaintenanceModeStatus", err);
				dispatch(updateStatus(ACTIONS.GET_MAINTENANCE_MODE_STATUS_FAILED, {error: err}));
			});
	};
};

const changeMaintenanceModeStatus = () => {
	const data = {
		name: "change_maintenance_status",
		params: {}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.CHANGE_MAINTENANCE_MODE_STATUS_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.CHANGE_MAINTENANCE_MODE_STATUS_SUCCESS, { maintenanceMode: response.result }));
				else {
					dispatch(updateStatus(ACTIONS.CHANGE_MAINTENANCE_MODE_STATUS_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("changeMaintenanceModeStatus", err);
				dispatch(updateStatus(ACTIONS.CHANGE_MAINTENANCE_MODE_STATUS_FAILED, {error: err}));
			});
	};
};

const getMaintenanceAppStatus = () => {
	const data = {
		name: "check_maintenance_app_status",
		params: {}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.GET_MAINTENANCE_APP_STATUS_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.GET_MAINTENANCE_APP_STATUS_SUCCESS, { maintenanceApp: response.result.IS_MAINTENANCE_APP }));
				else {
					dispatch(updateStatus(ACTIONS.GET_MAINTENANCE_APP_STATUS_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("getMaintenanceAppStatus", err);
				dispatch(updateStatus(ACTIONS.GET_MAINTENANCE_APP_STATUS_FAILED, {error: err}));
			});
	};
};

const changeMaintenanceAppStatus = () => {
	const data = {
		name: "change_maintenance_app_status",
		params: {}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.CHANGE_MAINTENANCE_APP_STATUS_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.CHANGE_MAINTENANCE_APP_STATUS_SUCCESS, { maintenanceApp: response.result.IS_MAINTENANCE_APP }));
				else {
					dispatch(updateStatus(ACTIONS.CHANGE_MAINTENANCE_APP_STATUS_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("changeMaintenanceAppStatus", err);
				dispatch(updateStatus(ACTIONS.CHANGE_MAINTENANCE_APP_STATUS_FAILED, {error: err}));
			});
	};
};

const getTimestampBackground = () => {
	const data = {
		name: "get_timestamp_background",
		params: {}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.GET_TIMESTAMP_BACKGROUND_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.GET_TIMESTAMP_BACKGROUND_SUCCESS, { timestampBackground: response.result }));
				else {
					dispatch(updateStatus(ACTIONS.GET_TIMESTAMP_BACKGROUND_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("getTimestampBackground", err);
				dispatch(updateStatus(ACTIONS.GET_TIMESTAMP_BACKGROUND_FAILED, {error: err}));
			});
	};
};

const getTopUpStatus = () => {
	const data = {
		name: "get_top_up_status",
		params: {}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.GET_TOP_UP_STATUS_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.GET_TOP_UP_STATUS_SUCCESS, { topUpEnabled: response.result }));
				else {
					dispatch(updateStatus(ACTIONS.GET_TOP_UP_STATUS_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("getTopUpStatus", err);
				dispatch(updateStatus(ACTIONS.GET_TOP_UP_STATUS_FAILED, {error: err}));
			});
	};
};

const changeTopUpStatus = () => {
	const data = {
		name: "change_top_up_status",
		params: {}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.CHANGE_TOP_UP_STATUS_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.CHANGE_TOP_UP_STATUS_SUCCESS, { topUpEnabled: response.result }));
				else {
					dispatch(updateStatus(ACTIONS.CHANGE_TOP_UP_STATUS_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("chanopUpStatus", err);
				dispatch(updateStatus(ACTIONS.CHANGE_TOP_UP_STATUS_FAILED, {error: err}));
			});
	};
};

const updateTopUpStatus = topUpEnabled => dispatch => dispatch(updateStatus(ACTIONS.UPDATE_TOP_UP_STATUS, { topUpEnabled }));

const updateMaintenanceStatus = maintenance => dispatch => {
	if (typeof maintenance.IS_MAINTENANCE === 'boolean') {
		dispatch(updateStatus(ACTIONS.UPDATE_MAINTENANCE_STATUS, { maintenanceMode: maintenance.IS_MAINTENANCE }));
	}
};

const getProductCodeStatus = () => {
	const data = {
		name: "get_paycode_status",
		params: {}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.GET_PRODUCT_CODE_STATUS_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.GET_PRODUCT_CODE_STATUS_SUCCESS, { productCodeEnabled: response.result }));
				else {
					dispatch(updateStatus(ACTIONS.GET_PRODUCT_CODE_STATUS_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("getProductCodeStatus", err);
				dispatch(updateStatus(ACTIONS.GET_PRODUCT_CODE_STATUS_FAILED, {error: err}));
			});
	};
};

const changeProductCodeStatus = () => {
	const data = {
		name: "change_paycode_status",
		params: {}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.CHANGE_PRODUCT_CODE_STATUS_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.CHANGE_PRODUCT_CODE_STATUS_SUCCESS, { productCodeEnabled: response.result }));
				else {
					dispatch(updateStatus(ACTIONS.CHANGE_PRODUCT_CODE_STATUS_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("changeProductCodeStatus", err);
				dispatch(updateStatus(ACTIONS.CHANGE_PRODUCT_CODE_STATUS_FAILED, {error: err}));
			});
	};
};

const updateProductCodeStatus = productCodeEnabled => dispatch => dispatch(updateStatus(ACTIONS.UPDATE_PRODUCT_CODE_STATUS, { productCodeEnabled }));

const getRepeatOrderCodeStatus = () => {
	const data = {
		name: "get_repeat_order_code_status",
		params: {}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.GET_REPEAT_ORDER_CODE_STATUS_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.GET_REPEAT_ORDER_CODE_STATUS_SUCCESS, { repeatOrderCodeEnabled: response.result }));
				else {
					dispatch(updateStatus(ACTIONS.GET_REPEAT_ORDER_CODE_STATUS_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("getRepeatOrderCodeStatus", err);
				dispatch(updateStatus(ACTIONS.GET_REPEAT_ORDER_CODE_STATUS_FAILED, {error: err}));
			});
	};
};

const changeRepeatOrderCodeStatus = () => {
	const data = {
		name: "change_repeat_order_code_status",
		params: {}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.CHANGE_REPEAT_ORDER_CODE_STATUS_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.CHANGE_REPEAT_ORDER_CODE_STATUS_SUCCESS, { repeatOrderCodeEnabled: response.result }));
				else {
					dispatch(updateStatus(ACTIONS.CHANGE_REPEAT_ORDER_CODE_STATUS_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("changeRepeatOrderCodeStatus", err);
				dispatch(updateStatus(ACTIONS.CHANGE_REPEAT_ORDER_CODE_STATUS_FAILED, {error: err}));
			});
	};
};

const updateRepeatOrderCodeStatus = repeatOrderCodeEnabled => dispatch => dispatch(updateStatus(ACTIONS.UPDATE_PRODUCT_CODE_STATUS, { repeatOrderCodeEnabled }));

export {
	ACTIONS,
	changeMaintenanceModeStatus,
	getMaintenanceModeStatus,
	getUrlUploadBackground,
	getMaintenanceAppStatus,
	changeMaintenanceAppStatus,
	updateMaintenanceStatus,
	getTimestampBackground,
	getTopUpStatus,
	changeTopUpStatus,
	updateTopUpStatus,
	getProductCodeStatus,
	changeProductCodeStatus,
	updateProductCodeStatus,
	getRepeatOrderCodeStatus,
	changeRepeatOrderCodeStatus,
	updateRepeatOrderCodeStatus
};