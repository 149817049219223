import React, {Component} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';

import SubmitButton from '../../../../03-components/ModalSubmitButton';
import validate from './validate';
import {connect} from "react-redux";
import { I18n } from 'react-i18next';

class ModalContact extends Component {
	state = {
		name: '',
		address: '',
	};

	componentDidUpdate(prevProps) {
		const { oldName, oldAddress } = this.props;
		if (oldName !== prevProps.oldName || oldAddress !== prevProps.oldAddress) {
			this.setState({ name: oldName, address: oldAddress });
		}
	}
	
	renderField = ({ input, label, type, fieldValue, disabled, meta: { touched, error } }) => (
		<div className="form-group">
			<label>{label}</label>
			<div className="input-group">
				<input
					{...input}
					type={type}
					className="form-control create-wallet-input"
					disabled={disabled}
					value={fieldValue}
					onChange={e => this.setState({ [input.name]: e.target.value })}
				/>
				{touched && error && <p className="text-danger">{`* ${error}`}</p>}
			</div>
		</div>
	);
	
	execAction = () => {
		const { name, address } = this.state;
		const { contactId } = this.props;
		this.props.action({
			contactId,
			name: name.trim(),
			address: address.trim(),
		});
		this.refreshInputAndToggle();
	};

	refreshInputAndToggle = () => {
	    this.setState({name: this.props.oldName ? this.props.oldName: '' , address: this.props.oldAddress ? this.props.oldAddress:'' })
		this.props.toggle();
	};
	
	render() {
		const {
			isOpen,
			title,
			btnText,
			contactAdding,
			contactEditing,
			invalid
		} = this.props;
		const { name, address } = this.state;
		return (
			<I18n ns="translations">
				{
					t => (
						<Modal isOpen={isOpen} className="modal-lg">
							<ModalHeader toggle={this.refreshInputAndToggle}>{title}</ModalHeader>
							<ModalBody>
								<form className="margin-top-1rem">
									<Field
										name="name"
										type="text"
										component={this.renderField}
										label={t('name')}
										fieldValue={name}
									/>
									<Field
										name="address"
										type="text"
										component={this.renderField}
										label={t('address')}
										fieldValue={address}
									/>
								</form>
							</ModalBody>
							<ModalFooter>
								<SubmitButton
									className="btn btn-primary"
									onClick={this.execAction}
									isSubmitting={contactAdding || contactEditing}
									disabled={invalid || !name.trim() || !address.trim()}
									text={btnText}
								/>
							</ModalFooter>
						</Modal>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		contactAdding: store.addressBook.contactAdding,
		contactEditing: store.addressBook.contactEditing,
	};
};

export default connect(
	mapStateToProps,
	null
)(reduxForm({
	form: 'modalContact',
	validate,
})(ModalContact));
