import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {LocalizeProvider} from 'react-localize-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import './05-utils/commonStyles.css';

import App from './04-containers/App/App';
import configStore from './store';
import './i18n';
import { unregister } from './registerServiceWorker';

let store = configStore();

ReactDOM.render(
	<LocalizeProvider>
		<Provider store={store}>
			<BrowserRouter>
				<App/>
			</BrowserRouter>
		</Provider>
	</LocalizeProvider>,
	document.getElementById('root')
);

unregister();
