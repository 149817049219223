import  * as socketIOClient from "socket.io-client";
import * as sailsIOClient from "sails.io.js";
import { updateStatus } from "./utils";
import { getEnv } from "../env";
import { updateTopUpStatus, updateProductCodeStatus, updateRepeatOrderCodeStatus } from "./system";
import { notifyProductCodeCreated } from "./productCode";
import { notifyRepeatOrderCodeCreated } from "./repeatOrderCode";
import EventRegister, {EVENT_CREATED_PRODUCT_CODE_BY_USDT} from "../05-utils/eventRegister";
import { constants } from "../05-utils/commonData";

const io = sailsIOClient(socketIOClient);
io.sails.url = getEnv('REACT_APP_API_SERVER');
io.sails.environment = 'production';
io.sails.autoConnect = false;
io.sails.useCORSRouteToGetCookie = false;
let socket ;
const ACTIONS = {
    CONNECT_SOCKET: "CONNECT_SOCKET",
    SOCKET_DISCONNECTED: "SOCKET_DISCONNECTED",
};

const socketMessageType = {
    UPDATE_MAINTENANCE_STATUS: 11,
    UPDATE_FEATURE_STATUS: 12,
    PRODUCT_CODE_NOTIFY: 13,
};

const connectSocket = (userID) => {
    // console.log("Socket init, ", io.sails.url);
    return (dispatch) => {
        socket = io.sails.connect();
        socket.on('connect', function () {
            dispatch(updateStatus(ACTIONS.CONNECT_SOCKET, { socket }));
            var dataJoin = {
                name: 'multi_subscribe',
                params: {
                    roomName: [
                        'RESTORE_DB.' + userID,
                        'MAINTENANCE',
                        'SYSTEM_CONTROL',
                        'PRODUCT_CODE.'+ userID
                    ]
                }
            };

            socket.post('/socket', dataJoin, function (res) {
                if (!res.success) {
                    console.log('Error establish realtime connection. Please reload page to try again.')
                }
            });
        });

        socket.on('disconnect', () => {
            console.log("Socket disconnect");
            dispatch({ type: ACTIONS.SOCKET_DISCONNECTED });
        });

        socket.on('notification', message => {
            switch (message.type_i) {
                case socketMessageType.UPDATE_MAINTENANCE_STATUS:
                    // dispatch(updateMaintenanceStatus(message.data));
                    break;
                case socketMessageType.UPDATE_FEATURE_STATUS:
                    const { enableTopUp, enablePayCode, enableRepeatOrderCode } = message.data; // value is 'true', 'false' or null
                    if (enableTopUp) {
                        dispatch(updateTopUpStatus(enableTopUp === 'true'));
                    }
                    if (enablePayCode) {
                        dispatch(updateProductCodeStatus(enablePayCode === 'true'));
                    }
                    if (enablePayCode) {
                        dispatch(updateRepeatOrderCodeStatus(enableRepeatOrderCode === 'true'));
                    }
                    break;
                case socketMessageType.PRODUCT_CODE_NOTIFY:
                    if (message.data.type === constants.TYPE_CODE.REGISTER_CODE)
                        dispatch(notifyProductCodeCreated({code: message.data.code, requestCodeId: message.data.requestCodeId}));
                    else dispatch(notifyRepeatOrderCodeCreated({code: message.data.code, requestCodeId: message.data.requestCodeId}));
                    EventRegister.emit(EVENT_CREATED_PRODUCT_CODE_BY_USDT, message.data);
                    break;
                default:
                    console.log(message);
            }
        });
    };
};



export {
	ACTIONS,
    connectSocket,
};