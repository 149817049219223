import { getByteLengthString } from '../../../../../05-utils/commonUtils';
import { constants } from '../../../../../05-utils/commonData';
import I18n from 'i18next';

const validate = values => {
	const errors = {};
	if (!values.destinationEntered && !values.destinationSelected) {
		errors.destinationEntered = I18n.t('DESTINATION_REQUIRED');
	}
	if (values.destinationEntered && values.destinationEntered.length !== 56) {
		errors.destinationEntered = I18n.t('DESTINATION_INVALID');
	}
	if (!values.amount) {
		errors.amount = I18n.t('AMOUNT_REQUIRED');
	}
	if (Number(values.amount) <= 0) {
		errors.amount = I18n.t('AMOUNT_INVALID');
	}
	if (values.memo && getByteLengthString(values.memo) > constants.MAX_BYTE_LENGTH_MEMO) {
		errors.memo = I18n.t('MEMO_TOO_LONG');
	}
	return errors;
};

export default validate;