import React, { Component } from 'react';
import I18n from 'i18next';

export default class TransferButton extends Component {
    render() {
        const { authorized_flag, onClick, disabled, className } = this.props;

        return authorized_flag !== "0" ? (
            <div className={className}>
                <button
                    type="button"
                    className={`btn btn-send btn-fw ${className}`}
                    onClick={onClick}
                    disabled={disabled}
                >
                    {I18n.t('send_')}
                </button>
            </div>
        )
        :
        (
            <div className={`not-allowed-wrapper ${className}`}>
                <div className="hide-small-screen"/>
                <div>
                    <i class="fas fa-exclamation-triangle text-warning margin-right-half-rem"/>
                    <b>{I18n.t('not_allowed')}</b>
                </div>
            </div>
        );
    }
}