import React, {Component} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { I18n } from 'react-i18next';

import { settingOPPassword, getUserInfo, settingOPPasswordStatus } from "../../../01-actions/user";
import validate from './validate';
import SubmitButton from '../../../03-components/ModalSubmitButton/index';
import './settingOPPasswordModal.css';

class SettingOPPassword extends Component {
	state = {
		opPassword: '',
		confirmOPPassword: '',
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps.opPasswordSettingDone) {
			nextProps.actions.settingOPPasswordStatus();
			this.refreshInputAndToggle();
		}
	}

	renderField = ({ input, label, type, fieldValue, meta: { touched, error } }) => (
		<div className="form-group">
			<label>{label}</label>
			<div className="input-group">
				<input
					{...input}
					type={type}
					className="form-control transfer-input"
					value={fieldValue}
					onChange={e => this.setState({ [input.name]: e.target.value.trim() })}
				/>
				{touched && error && <p className="text-danger">{`* ${error}`}</p>}
			</div>
		</div>
	);

	settingOPPassword = () => {
		const { opPassword } = this.state;
		this.props.actions.settingOPPassword({ opPassword });
	};

	refreshInputAndToggle = () => {
		this.setState({ opPassword: '', confirmOPPassword: ''});
		this.props.toggle();
	};
	
	render() {
		const {
			isOpen,
			invalid,
			opPasswordSettingContinue,
		} = this.props;

		const {
			opPassword,
			confirmOPPassword
        } = this.state;

		return (
			<I18n ns="translations">
				{
					t => (
						
						<Modal isOpen={isOpen} className="modal-lg">
							<ModalHeader>{t('setting_op_password_')}</ModalHeader>
							<ModalBody>
								<div className="change-password-attention">
									<p>{t('attention_')}</p>
									<p className='text-left'>{t('setting_op_password_intro')}</p>
								</div>
								<form className="margin-top-1rem">
									<Field
										name="opPassword"
										type="password"
										component={this.renderField}
										label={t('op_password')}
										fieldValue={opPassword}
									/>
									<Field
										name="confirmOPPassword"
										type="password"
										component={this.renderField}
										label={t('confirm_op_password')}
										fieldValue={confirmOPPassword}
									/>
								</form>
							</ModalBody>
							<ModalFooter>
								<SubmitButton
									className="btn btn-primary"
									onClick={this.settingOPPassword}
									isSubmitting={opPasswordSettingContinue}
									disabled={invalid || !opPassword || !confirmOPPassword}
									text={t('set_up')}
								/>
							</ModalFooter>
						</Modal>

					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		opPasswordSettingDone: store.user.opPasswordSettingDone,
		opPasswordSettingContinue: store.user.opPasswordSettingContinue,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				getUserInfo,
				settingOPPassword,
				settingOPPasswordStatus,
			},
			dispatch
		)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(reduxForm({
	form: 'settingOPPassword',
	validate,
})(SettingOPPassword));
