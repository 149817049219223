import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';

import { pushNotify } from '../../03-components/Notification';
import { withRouter } from 'react-router-dom';
import SubmitButton from '../../03-components/ModalSubmitButton';
import { createUser, resetCreateUserStatus } from '../../01-actions/user';
import './index.css';
import { bindActionCreators, compose } from "redux";
import { I18n } from 'react-i18next';
import { oauthPopup } from "../../05-utils/commonUtils";
import { constants } from "../../05-utils/commonData";
import validate from './validate';
import i18n from 'i18next';
import ChangeLanguageBar from '../../03-components/ChangeLanguageBar';
import AuthCard from '../../03-components/AuthCard';
import AuthenBackgroundWrapper from '../Common/AuthenBackgroundWrapper/AuthenBackgroundWrapper';

class RegisterPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
        };
    }

    // componentDidMount() {
    //     this.props.actions.getAuthStatus();
    // }

    componentWillReceiveProps(nextProps) {
        if (nextProps.registered) {
            pushNotify('success', i18n.t('USER_CREATED_SUCCESSFULLY'));
            nextProps.actions.resetCreateUserStatus();
            setTimeout(() => {
                this.props.history.push('/wallet');
            }, 2000);
        }
    }

    renderField = ({ input, label, type, fieldValue, meta: { touched, error } }) => (
        <div className="form-group">
            <label>{label}</label>
            <div className="input-group">
                <input
                    {...input}
                    type={type}
                    className="form-control login-input"
                    value={fieldValue}
                    onChange={e => this.setState({ [input.name]: e.target.value.trim() })}
                />
            </div>
            {touched && error && <p className="text-danger">{`* ${error}`}</p>}
        </div>
    );

    onCreate = e => {
        e.preventDefault();
        const { email } = this.state;
        this.props.actions.createUser({ email });
    };

    openRobinDialog = () => {
        oauthPopup({
            width: 500,
            height: 550,
            path: constants.OAUTH_ROBIN,
            windowName: "windowName",
            callback: (code) => this.props.actions.loginWithCode({ code })
        });
    };

    render() {
        const {  registering, invalid } = this.props;
        const { email } = this.state;

        return (
            <I18n ns="translations">
                {
                    (t, {i18n}) => (
                        <div className="container-scroller">
                            {/* {
                                token ? <Redirect to="/wallet"/> : null
                            } */}
                            <div className="container-fluid page-body-wrapper full-page-wrapper auth-page">
                                <ChangeLanguageBar i18n={i18n}/>
                                <AuthenBackgroundWrapper>
                                    <div className="login-form-wrapper">
                                        <div className="row w-100 login-middle">
                                            <div className="col-lg-8 mx-auto">
                                                <div className="auth-custom-card row">
                                                    <div className="col-md-5 col-sm-12 triple left">
                                                        <AuthCard
                                                            btnText={t('login_')}
                                                            redirectOnClick={() => this.props.history.push('/login')}
                                                        />
                                                    </div>
                                                    <div className="col-md-7 col-sm-12 auth-form">
                                                        <div className="text-center">
                                                            <h3>{t('register')}</h3>
                                                            <span>{t('register_intro')}</span>
                                                        </div>
                                                        <div>
                                                            <Field
                                                                name="email"
                                                                type="text"
                                                                component={this.renderField}
                                                                label={t('address_email_create_robin_sns')}
                                                                fieldValue={email}
                                                            />
                                                        </div>
                                                        <div className="text-center submit-btn-wrapper">
                                                            <SubmitButton
                                                                className="btn btn-success btn-rounded mb-2"
                                                                onClick={this.onCreate}
                                                                disabled={invalid || !email}
                                                                isSubmitting={registering}
                                                                text={t('create')}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5 col-sm-12 triple right hide-large-screen">
                                                        <AuthCard
                                                            btnText={t('login_')}
                                                            redirectOnClick={() => this.props.history.push('/login')}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </AuthenBackgroundWrapper>
                            </div>
                        </div>
                    )
                }
            </I18n>
        );
    }
}

const mapStateToProps = store => {
    return {
        token: store.user.token,
        registering: store.user.registering,
        registered: store.user.registered,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions : bindActionCreators(
            {
                createUser,
                resetCreateUserStatus,
            },
            dispatch
        )
    };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(reduxForm({ form: 'register', validate })(RegisterPage));