import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// components
import SubmitButton from '../../../../03-components/ModalSubmitButton';
// styles
import './notifyModal.css';
import {I18n} from "react-i18next";
import QrCode from "qrcode.react";
import { formatDateTimeFull } from '../../../../05-utils/commonUtils';

class NotifyRequestCodeInfoModal extends Component {

    render() {
        const {
            isOpen,
            asset,
            requestCode,
            onClose,
            toggle
        } = this.props;

        return (
            <I18n ns="translations">
                {
                    t => (
                        <Modal isOpen={isOpen} className="modal-lg">
                            <ModalHeader toggle={toggle}>
                                {t('request_product_code_info')}
                            </ModalHeader>
                            <ModalBody>
                                <div className="notify-request-product-code align-items-center row">
                                    <div className="create-wallet col-md-4">
                                        <QrCode size={120} value={requestCode && requestCode.address} />
                                    </div>
                                    <div className="col-md-8 text-left info">
                                        <div className='mb-2'>
                                            <b>{t('address')} :</b> {requestCode && requestCode.address}
                                        </div>
                                        <div className='mb-2'>
                                            <b>
                                                {t('amount')} :
                                                <span className='text-success'>{` ${requestCode && requestCode.amount} ${asset}`}</span>
                                            </b>
                                        </div>
                                        <div className='mb-2'>
                                            <b>{t('created_at')} :</b> {formatDateTimeFull(requestCode && requestCode.createdAt)}
                                        </div>
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <SubmitButton
                                    className="btn btn-primary"
                                    onClick={onClose}
                                    text={t('ok')}
                                />
                            </ModalFooter>
                        </Modal>
                    )
                }
            </I18n>
        );
    }
}

export default NotifyRequestCodeInfoModal;