import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Steps from 'rc-steps';

// components
import EnterNumberOfPackage from '../../../../03-components/EnterNumberOfPackage/enterNumberOfPackage';
import SelectWallet from '../../../../03-components/SelectWallet/selectWallet';
import ConfirmAddress from '../../../../03-components/ConfirmAddress/confirmAddress';
import SubmitButton from '../../../../03-components/ModalSubmitButton';
import OPPassModal from "../../../../03-components/OPPassModal/OPPassModal";
import { constants } from "../../../../05-utils/commonData";
import {I18n} from "react-i18next";
import BigNumber from "bignumber.js";

class CreateRepeatOrderCodeModal extends Component {
    state = {
        currentStep: 0,
        disabled: false,
        numberOfPackage: 0,
        walletAddress: '',
        isOpenOPPass: false,
        totalAmount: 0,
    };

    steps = ['enter_number_of_package', 'choose_wallet', 'finish'];

    componentWillReceiveProps(nextProps) {
        if (!this.props.repeatOrderCodeCreated  && nextProps.repeatOrderCodeCreated) {
            this.refreshInputAndToggle();
        }
    }

    toggleOPPass = () => this.setState({ isOpenOPPass: !this.state.isOpenOPPass });

    switchModal = () => {
        const {
            currentStep
        } = this.state;

        if (currentStep === 0) {
            this.setState({
                currentStep: 1
            })
        } else if (currentStep === 1) {
            this.setState({
                currentStep: 2
            })
        } else {
            this.setState({
                isOpenOPPass: true
            });
        }
    };

    onReceiveOPPass = opPass => {
        const {
            numberOfPackage,
            walletAddress
        } = this.state;
        this.props.onCreate({
            numberOfPackage,
            sourceAddress: walletAddress,
            opPass
        })
    };

    handleSelectAddress = (data) => {
        this.setState({
            walletAddress: data
        })
    };

    handleNumberOfPackageChange = numberOfPackage => {
        this.setState({ numberOfPackage });
    };

    onEnterAmount = ({ event, numberOfPackage  }) => {
        const { asset, infoBuying } = this.props;
        let price;
        let fee;
        price = infoBuying.retail.find(item => numberOfPackage >= item.min && numberOfPackage <= item.max && item.coin_type === asset);
        if (!price) {
            event.preventDefault();
            this.setState({totalAmount: 0});
            return;
        }
        fee = price.shipping_fee;
        price = new BigNumber(price.fee).times(numberOfPackage).toString();
        let amount = new BigNumber(price.toString()).plus(fee).toString() || 0;
        this.setState({totalAmount: amount});
    };

    refreshInputAndToggle = () => {
        this.setState({
            currentStep: 0,
            disabled: false,
            numberOfPackage: 0,
            walletAddress: '',
            isOpenOPPass: false,
            totalAmount: 0
        });
    };

    onBack = () => {
        if (this.state.currentStep === 0)
            this.props.onBack();
        else
            this.setState({
                currentStep: this.state.currentStep -1
            })
    };

    render() {
        const {
            isOpen,
            userWallets,
            asset,
            infoBuying,
            repeatOrderCodeCreating
        } = this.props;

        const {
            currentStep,
            walletAddress,
            isOpenOPPass,
            numberOfPackage,
            totalAmount
        } = this.state;
        return (
            <I18n ns="translations">
                {
                    t => (
                        <Modal isOpen={isOpen} className="modal-lg">
                            <ModalHeader toggle={() => {
                                this.refreshInputAndToggle();
                                this.props.toggle();
                            }}>
                                {t('create_new_code')}
                            </ModalHeader>
                            <ModalBody>
                                <Steps current={currentStep} labelPlacement="vertical" progressDot size="small">
                                    { this.steps && this.steps.map((step, index) => (
                                        <Steps.Step title={t(`${step}`)} key={index}/>
                                    ))}
                                </Steps>
                                {
                                    currentStep === 0 &&
                                    <EnterNumberOfPackage
                                        handleNumberOfPackageChange={this.handleNumberOfPackageChange}
                                        asset={asset}
                                        infoBuying={infoBuying}
                                        totalAmount={totalAmount}
                                        onEnterAmount={this.onEnterAmount}
                                    />
                                }
                                {
                                    currentStep === 1 &&
                                    <SelectWallet
                                        userWallets={userWallets}
                                        handleSelectAddress={this.handleSelectAddress}
                                        walletAddress={walletAddress}
                                        minAmountAccepted={totalAmount}
                                    />
                                }
                                {
                                    currentStep === 2 &&
                                    <ConfirmAddress
                                        numberOfPackage={numberOfPackage}
                                        totalAmount={totalAmount}
                                        walletSelected={walletAddress}
                                        type={constants.TYPE_REPEAT_ORDER_CODE.RETAIL}
                                    />
                                }

                                <OPPassModal
                                    isOpen={isOpenOPPass}
                                    toggle={this.toggleOPPass}
                                    onSubmit={this.onReceiveOPPass}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <SubmitButton
                                    className="btn btn-info"
                                    text={t('back')}
                                    onClick={this.onBack}
                                />
                                <SubmitButton
                                    className="btn btn-primary"
                                    onClick={this.switchModal}
                                    disabled={(currentStep === 1 && !walletAddress) || (currentStep === 0 && (Number(numberOfPackage) > Number(infoBuying && infoBuying.limit) || Number(numberOfPackage) <= 0))}
                                    text={currentStep === 2 ? t('generate') : t('confirm')}
                                    isSubmitting={currentStep === 2 ? repeatOrderCodeCreating : false}
                                />
                            </ModalFooter>
                        </Modal>
                    )
                }
            </I18n>
        );
    }
}

export default CreateRepeatOrderCodeModal;