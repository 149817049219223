import React, {Component} from 'react';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { I18n } from 'react-i18next';
import i18n from 'i18next';
import {
	getAllUserWallet,
	resetSaveWalletStatus,
	resetCreateWalletStatus,
	resetTfaCode,
	resetUnblockWalletStatus,
	chooseWallet,
	initKeyPair,
} from "../../../01-actions/wallet";
import { getAmountBalance } from '../../../05-utils/commonUtils';
import { resetTrustStatus, getAllAsset } from "../../../01-actions/asset";
import { getUserInfo } from "../../../01-actions/user";

import { pushNotify } from '../../../03-components/Notification';
import TrustCoin from './Modal/TrustCoin/trustCoin';
import CreateWallet from './Modal/CreateWallet/createWallet';
import TransferMoney from './Modal/TransferMoney/transferMoney';
import EditNameWallet from './Modal/EditNameWallet/editNameWallet';
import ChooseWallet from '../../Common/ChooseWallet/chooseWallet';
import UnblockWallet from '../../Common/UnblockWallet/unblockWallet';
import AssetLogo from '../../../03-components/AssetLogo/assetLogo';
import './myWallet.css';
import Loading from '../../../03-components/Loading';
import TransferButton from '../../../03-components/TransferButton/transferButton';
import EZPayContainer from './Modal/eZpayTopUp/container';


class MyWallet extends Component {
	state = {
		isOpenChooseWallet: false,
		isOpenTrustCoin: false,
		isOpenTransferMoney: false,
		isOpenCreateWallet: false,
		isOpenUnblockWallet: false,
        isOpenEditNameWallet: false,
		balanceTransferSelected: null
	};
	
	componentDidMount() {
		const { getAllUserWallet } = this.props.actions;
		if (!this.props.userWallets)
			getAllUserWallet();
	}
	
	componentWillReceiveProps(nextProps) {
		if (nextProps.moneyTransferred) {
			pushNotify('success', i18n.t('TRANSFER_MONEY_SUCCESSFULLY'));
			nextProps.actions.getAllUserWallet();
		}
		if (nextProps.trustLineCreated) {
			pushNotify('success', i18n.t('TRUST_ASSET_SUCCESSFULLY'));
			nextProps.actions.resetTrustStatus();
			this.setState({ isOpenTrustCoin: false });
			nextProps.actions.getAllUserWallet();
		}
		if (nextProps.walletSaved) {
			pushNotify('success', i18n.t('WALLET_SAVED_SUCCESSFULLY'));
			nextProps.actions.resetSaveWalletStatus();
			this.setState({ isOpenCreateWallet: false });
			nextProps.actions.getAllUserWallet();
		}
		if (nextProps.walletCreated) {
			pushNotify('success', i18n.t('WALLET_CREATED_SUCCESSFULLY'));
			nextProps.actions.resetCreateWalletStatus();
			this.setState({ isOpenCreateWallet: false });
			nextProps.actions.getAllUserWallet();
		}
		if (nextProps.walletUnblocked) {
			pushNotify('success', i18n.t('WALLET_UNBLOCKED_SUCCESSFULLY'));
			nextProps.actions.resetUnblockWalletStatus();
			this.setState({ isOpenUnblockWallet: false });
			nextProps.actions.getAllUserWallet();
		}
	}

	toggleChooseWallet = () => {
		this.setState({ isOpenChooseWallet: !this.state.isOpenChooseWallet });
	};

	toggleTrustCoin = () => {
		this.props.actions.getAllAsset();
		this.setState({ isOpenTrustCoin: !this.state.isOpenTrustCoin });
	};

	toggleTransferMoney = (balance) => {
		this.setState({
            isOpenTransferMoney: !this.state.isOpenTransferMoney,
			balanceTransferSelected: balance,
		});
	};

	toggleCreateWallet = () => {
		this.props.actions.initKeyPair();
		this.setState({ isOpenCreateWallet: !this.state.isOpenCreateWallet });
	};

	toggleUnblockWallet = () => {
		this.setState({ isOpenUnblockWallet: !this.state.isOpenUnblockWallet });
	};
    toggleEditNameWallet = () => {
        this.setState({ isOpenEditNameWallet: !this.state.isOpenEditNameWallet });
    };

	render() {
		const {
			isOpenChooseWallet,
			isOpenTrustCoin,
			isOpenTransferMoney,
			isOpenCreateWallet,
			isOpenUnblockWallet,
			balanceTransferSelected,
			isOpenEditNameWallet,
		} = this.state;
		const {
			userWallets,
			selectedWallet,
			isFetching,
		} = this.props;
		return (
			<I18n ns="translations">
				{
					t => (
						<div>
							<div className="row">
								<div className="col-xs-12 col-md-12 col-lg-12">
									<div className="create-wallet-wrapper">
										<button className="btn btn-send text-right" onClick={this.toggleCreateWallet}>
											<i className="mdi mdi-plus-circle-outline"/>
											<span>{t('create_wallet')}</span>
										</button>
									</div>
								</div>

								{
									isFetching ? null :
										<CreateWallet isOpen={isOpenCreateWallet} toggle={this.toggleCreateWallet}
													  wallets={userWallets}/>
								}
							</div>
							<div className="row">
								<div className="col-xs-12 col-lg-10 grid-margin">
									{
										!isFetching && userWallets && userWallets.length === 0 ?
											(<div className="card">
												<div className="text-center">
													<span className="font-18">{t('dont_have_wallet_intro')}</span>
												</div>
											</div>)
											:
											(<div className="card">
												{
													isFetching || !selectedWallet ?
														(<Loading
															loadingClass="text-white"
															parent1Class="address-spinner"
															parent2Class="address-wrapper"
														/>)
														:
														(<div className="address-wrapper">
															<img className="img-qr" src={selectedWallet.qrcode} alt=""/>
															<div className="text-wrapper">
																<h5>
																	<span>{selectedWallet.name}</span>
																	{
																		selectedWallet.block ?
																			(<button className="btn btn-link"
																					 onClick={this.toggleUnblockWallet}>
																				<i className="fa fa-unlock"/>
																			</button>)
																			:
																			null
																	}
                                                                    <button className="btn btn-link" onClick={this.toggleEditNameWallet}>
                                                                        <i className="far fa-edit"/>
                                                                    </button>
																</h5>
																<UnblockWallet
																	isOpen={isOpenUnblockWallet}
																	toggle={this.toggleUnblockWallet}
																	walletID={selectedWallet._id}
																/>
																<div>
																	<b>{selectedWallet.address}</b>
																	<CopyToClipboard text={selectedWallet.address} onCopy={() => pushNotify('success', t('copied'))}>
																		<button className="btn btn-link">
																			<i className="far fa-copy"/>
																		</button>
																	</CopyToClipboard>
																</div>
															</div>
															<div className="select-wallet-btn">
																<button className="btn btn-light btn-sm" onClick={this.toggleChooseWallet}>
																	<i className="mdi mdi-chevron-down"/>
																</button>
																<ChooseWallet
																	isOpen={isOpenChooseWallet}
																	toggle={this.toggleChooseWallet}
																	wallets={userWallets}
																	chooseWallet={this.props.actions.chooseWallet}
																/>
															</div>
														</div>)
												}
												{
													isFetching ?
														(<Loading parent1Class="loading-asset"/>)
														:
														(
															<React.Fragment>
																{
																	selectedWallet && selectedWallet.block && (
																		<div className="text-center text-danger padding-15px">
																			<b>{t('wallet_blocked_intro')}</b>
																		</div>
																	)
																}
																{
																	selectedWallet && !selectedWallet.active ?
																		(<div className="unactive-wallet-intro">{t('wallet_not_active_intro')}</div>)
																		:
																		(<div className="table-responsive">
																			<table className="table balance-table">
																				<tbody>
																				{
																					selectedWallet && selectedWallet.balances && selectedWallet.balances.map((balance, index) => (
																						<tr key={index}>
																							<td className="py-1">
																								<AssetLogo src={balance.logo} assetName={balance.asset_code}/>
																							</td>
																							<td>
																								<b className="font-18">{balance.asset_code || "RIA"}</b>
																								<br className="hide-large-screen"/>
																								<b className="font-18 hide-large-screen">{getAmountBalance(balance.balance, balance.decimalPlace)}</b>
																								<br/>
																								<TransferButton
																									authorized_flag={balance.authorized_flag}
																									onClick={() => this.toggleTransferMoney(balance)}
																									disabled={selectedWallet.block}
																									className="btn-send hide-large-screen hide-medium-screen"
																								/>
																							</td>
																							<td className="hide-medium-screen hide-small-screen">
																								<b className="font-18">{getAmountBalance(balance.balance, balance.decimalPlace)}</b>
																							</td>
																							<td className="text-right hide-small-screen">
																								<TransferButton
																									authorized_flag={balance.authorized_flag}
																									onClick={() => this.toggleTransferMoney(balance)}
																									disabled={selectedWallet.block}
																								/>
																							</td>
																						</tr>
																					))
																				}
																				</tbody>
																			</table>
																		</div>)
																}
															</React.Fragment>
														)
												}
												{
													isFetching || !balanceTransferSelected ? null : (
														<TransferMoney
															isOpen={isOpenTransferMoney}
															toggle={this.toggleTransferMoney}
															walletName={selectedWallet && selectedWallet.name}
															walletAddress={selectedWallet && selectedWallet.address}
															balance={balanceTransferSelected}
															type="standard"
														/>
													)
												}
												{
													isFetching || (selectedWallet && (selectedWallet.block || !selectedWallet.active)) ?
														null
														:
														(<button className="btn btn-inverse-primary"
																 onClick={this.toggleTrustCoin}>
															<i className="mdi mdi-plus-circle-outline"/>
															<span>{t('trust_coin')}</span>
														</button>)
												}
												{
													isFetching ?
														null
														:
														(<TrustCoin
															isOpen={isOpenTrustCoin}
															toggle={this.toggleTrustCoin}
															address={selectedWallet && selectedWallet.address}
														/>)
												}
                                                {
                                                    isFetching ?
                                                        null
                                                        :
                                                        (<EditNameWallet
                                                            isOpen={isOpenEditNameWallet}
                                                            toggle={this.toggleEditNameWallet}
                                                            wallet={selectedWallet}
                                                        />)
                                                }
											</div>)
									}
								</div>
								<div className="col-xs-12 col-lg-2 grid-margin">
									<div className="transfer-btn-wrapper">
										<EZPayContainer/>
									</div>
								</div>
							</div>
						</div>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		authStatus: store.auth.status,
		authError: store.auth.authError,
		userWallets: store.wallet.userWallets,
		selectedWallet: store.wallet.selectedWallet,
		isFetching: store.wallet.isFetching,
		moneyTransferred: store.transaction.moneyTransferred,
		trustLineCreated: store.asset.trustLineCreated,
		walletSaved: store.wallet.walletSaved,
		walletCreated: store.wallet.walletCreated,
		walletUnblocked: store.wallet.walletUnblocked,
		userInfo: store.user.userInfo,
		usercode: store.topUp.usercode,
		sgpBalance: store.topUp.sgpBalance,
		ezpFee: store.topUp.ezpFee,
		wcgFee: store.topUp.wcgFee,
		isUnlimited: store.topUp.isUnlimited,
		isFetchingInfoEzpay: store.topUp.isFetchingInfoEzpay
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				getAllUserWallet,
				resetTrustStatus,
				resetSaveWalletStatus,
				resetCreateWalletStatus,
				resetUnblockWalletStatus,
				resetTfaCode,
				getUserInfo,
				chooseWallet,
				initKeyPair,
				getAllAsset
			},
			dispatch
		)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MyWallet);