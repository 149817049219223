import { ACTIONS } from '../01-actions/email';
import { AUTH_ACTIONS } from '../01-actions';
import { UTIL_ACTIONS } from '../01-actions/utils';

const initialState = {
	status: null,
	emailVerified: false,
	verifyEmailResent: false,
	error: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.VERIFY_EMAIL_PROGRESS:
		case ACTIONS.RESEND_VERIFY_EMAIL_PROGRESS:
			return {
				...state,
				status: action.type
			};
		case ACTIONS.VERIFY_EMAIL_SUCCESS:
			return {
				...state,
				status: action.type,
				emailVerified: true,
			};
		case ACTIONS.RESEND_VERIFY_EMAIL_SUCCESS:
			return {
				...state,
				status: action.type,
				verifyEmailResent: true,
			};
		case ACTIONS.VERIFY_EMAIL_FAILED:
		case ACTIONS.RESEND_VERIFY_EMAIL_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
			};
		case AUTH_ACTIONS.LOGOUT:
			return initialState;
		case UTIL_ACTIONS.RESET_ERROR:
			return {
				...state,
				error: null,
			};
		default:
			return state;
	}
}