import React, {Component} from 'react';
import { I18n } from 'react-i18next';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
    ACTIONS,
    searchAssetsFromTriam,
    importAssetFromTriam,
    getAllAsset,
    filterAssets,
    clearAssetSearch,
    removeAsset
} from "../../../01-actions/asset";
import Loading from '../../../03-components/Loading';
import AssetLogo from "../../../03-components/AssetLogo/assetLogo";
import ImportAssetModal from './Modal/importAsset';
import DetailAssetModal from './Modal/detailAsset';
import ConfirmDeleteAssetModal from './Modal/confirmDeleteAssetModal';
import './assetManagement.css';
import {Button} from "reactstrap";

class AssetManagement extends Component {
    state = {
        searchParam: '',
        isOpenImportAsset: false,
        isOpenDetailAsset: false,
        isOpenDeleteAsset: false,
        asset: null
    };

    componentDidMount() {
        this.props.actions.getAllAsset({with_issuer_info: true});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.status !== ACTIONS.IMPORT_ASSET_FROM_TRIAM_SUCCESS && this.props.status === ACTIONS.IMPORT_ASSET_FROM_TRIAM_SUCCESS)
            || (prevProps.status !== ACTIONS.REMOVE_ASSET_SUCCESS && this.props.status === ACTIONS.REMOVE_ASSET_SUCCESS)){
            this.props.actions.getAllAsset({with_issuer_info: true});
        }
    }

    onKeySearchPress = e => {
        if (e.key === 'Enter')
            this.props.actions.filterAssets(this.props.assets, this.state.searchParam);
    };

    onSearchChange = e => {
        this.setState({ searchParam: e.target.value.trim() });
    };

    handleDeleteAsset = () => {
        // open modal confirm delete asset modal
        this.setState({
            isOpenDetailAsset: false,
            isOpenDeleteAsset: true
        });
    };

    render() {
        const { assetsFiltered, isFetching } = this.props;
        const { asset } = this.state;
        return (
            <I18n ns="translations">
                {
                    t => (
                        <div className="row">
                            <ImportAssetModal
                                isOpen={this.state.isOpenImportAsset}
                                onClose={ () => {
                                    this.setState({
                                        isOpenImportAsset: false
                                    });
                                } }
                            />
                            <DetailAssetModal
                                isOpen={this.state.isOpenDetailAsset}
                                asset={this.state.asset}
                                onCancel={() => this.setState({isOpenDetailAsset: false})}
                                onDelete={this.handleDeleteAsset}
                            />
                            <ConfirmDeleteAssetModal
                                isOpen={this.state.isOpenDeleteAsset}
                                onCancel={() => this.setState({isOpenDeleteAsset: false})}
                                onDelete={ () => {
                                    this.props.actions.removeAsset({id: asset && asset._id});
                                    this.setState({
                                        isOpenDeleteAsset: false
                                    });
                                } }
                            />
                            <div className="col-lg-12 grid-margin">
                                <div className="card">
                                    <h4 className="card-title">{t('asset_management')}</h4>
                                    <div className="input-group margin-bottom-15px margin-top-15px">
                                        <Button
                                            className="mr-3"
                                            color="primary"
                                            onClick={() => this.setState({
                                                isOpenImportAsset:true
                                            })}
                                        >{t('import_asset')}
                                        </Button>
                                    </div>
                                    <div className="input-group margin-bottom-15px margin-top-15px">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('search_asset')}
                                            onKeyPress={this.onKeySearchPress}
                                            onChange={this.onSearchChange}
                                        />
                                    </div>
                                    <div className="row list-token">
                                        {
                                            assetsFiltered.map((asset, index) => (
                                                <div
                                                    className={`token-wrapper`} key={index}
                                                    onClick={ () => {
                                                        this.setState({
                                                            isOpenDetailAsset: true,
                                                            asset: asset
                                                        })
                                                    }}
                                                >
                                                    <AssetLogo className="margin-bottom-half-rem" src={asset.logo}
                                                               assetName={asset.name}/>
                                                    <p><b>{asset.name || 'RIA'}</b></p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    {
                                        isFetching && (<Loading parent1Class="text-center"/>)
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
            </I18n>
        );
    }
}

const mapStateToProps = store => {
    return {
        status: store.asset.status,
        error: store.asset.error,
        isFetching: store.asset.isFetching,
        assets: store.asset.assets,
        assetsFiltered: store.asset.assetsFiltered,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions : bindActionCreators(
            {
                searchAssetsFromTriam,
                importAssetFromTriam,
                getAllAsset,
                filterAssets,
                clearAssetSearch,
                removeAsset
            },
            dispatch
        )
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetManagement);