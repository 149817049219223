export const UTIL_ACTIONS = {
	RESET_ERROR: 'RESET_ERROR',
}

export const updateStatus = (status, statusInfo) => {
	return {
		type: status,
		data: statusInfo
	}
};

export const resetError = () => dispatch => dispatch(updateStatus(UTIL_ACTIONS.RESET_ERROR));