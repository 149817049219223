import {calculateTopUpHistoryItem, callApi} from "../05-utils/commonUtils";
import { updateStatus } from "./utils";

const ACTIONS = {
	GET_USER_CODE_PROGRESS: "GET_USER_CODE_PROGRESS",
	GET_USER_CODE_SUCCESS: "GET_USER_CODE_SUCCESS",
	GET_USER_CODE_FAILED: "GET_USER_CODE_FAILED",
	GET_OTC_CURRENCY_INFO_PROGRESS: "GET_OTC_CURRENCY_INFO_PROGRESS",
	GET_OTC_CURRENCY_INFO_SUCCESS: "GET_OTC_CURRENCY_INFO_SUCCESS",
	GET_OTC_CURRENCY_INFO_FAILED: "GET_OTC_CURRENCY_INFO_FAILED",
	GET_SGP_BALANCE_INFO_PROGRESS: "GET_SGP_BALANCE_INFO_PROGRESS",
	GET_SGP_BALANCE_INFO_SUCCESS: "GET_SGP_BALANCE_INFO_SUCCESS",
	GET_SGP_BALANCE_INFO_FAILED: "GET_SGP_BALANCE_INFO_FAILED",
	GET_LIST_CARDS_TOP_UP_PROGRESS: "GET_LIST_CARDS_TOP_UP_PROGRESS",
	GET_LIST_CARDS_TOP_UP_SUCCESS: "GET_LIST_CARDS_TOP_UP_SUCCESS",
	GET_LIST_CARDS_TOP_UP_FAILED: "GET_LIST_CARDS_TOP_UP_FAILED",
	GET_INFO_EZPAY_PROGRESS: "GET_INFO_EZPAY_PROGRESS",
	GET_INFO_EZPAY_SUCCESS: "GET_INFO_EZPAY_SUCCESS",
	GET_INFO_EZPAY_FAILED: "GET_INFO_EZPAY_FAILED",
	PREPARE_FOR_TOP_UP_PROGRESS: "PREPARE_FOR_TOP_UP_PROGRESS",
	PREPARE_FOR_TOP_UP_SUCCESS: "PREPARE_FOR_TOP_UP_SUCCESS",
	PREPARE_FOR_TOP_UP_FAILED: "PREPARE_FOR_TOP_UP_FAILED",
	LOGIN_SGP_PROGRESS: "LOGIN_SGP_PROGRESS",
	LOGIN_SGP_SUCCESS: "LOGIN_SGP_SUCCESS",
	LOGIN_SGP_FAILED: "LOGIN_SGP_FAILED",
	OTC_AND_TOP_UP_PROGRESS: "OTC_AND_TOP_UP_PROGRESS",
	OTC_AND_TOP_UP_SUCCESS: "OTC_AND_TOP_UP_SUCCESS",
	OTC_AND_TOP_UP_FAILED: "OTC_AND_TOP_UP_FAILED",
	GET_HISTORY_TOP_UP_PROGRESS: "GET_HISTORY_TOP_UP_PROGRESS",
	GET_HISTORY_TOP_UP_SUCCESS: "GET_HISTORY_TOP_UP_SUCCESS",
	GET_HISTORY_TOP_UP_FAILED: "GET_HISTORY_TOP_UP_FAILED",
	PAGING_TOP_UP_HISTORY: "PAGING_TOP_UP_HISTORY",
};

const getUserCodes = () => {
	const data = {
		name: "get_user_code_by_arm_id",
		params: {}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.GET_USER_CODE_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.GET_USER_CODE_SUCCESS, { usercode: response.result.user_code }));
				else {
					dispatch(updateStatus(ACTIONS.GET_USER_CODE_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("getUserCodes", err);
				dispatch(updateStatus(ACTIONS.GET_USER_CODE_FAILED, {error: err}));
			});
	};
};

const getListCardsTopUp = payload => {
	const data = {
		name: "get_list_cards_top_up",
		params: {
			usercode: payload.usercode
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.GET_LIST_CARDS_TOP_UP_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success) {
					const listCardsOfEZPay = response.result.listCardsOfEZPay;
					const listCardsOfAlliedCard = response.result.listCardsOfAlliedCard;
					dispatch(updateStatus(ACTIONS.GET_LIST_CARDS_TOP_UP_SUCCESS, {
						listCardsOfEZPay,
						listCardsOfAlliedCard
					}));
				}
				else {
					dispatch(updateStatus(ACTIONS.GET_LIST_CARDS_TOP_UP_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("getListCardsTopUp", err);
				dispatch(updateStatus(ACTIONS.GET_LIST_CARDS_TOP_UP_FAILED, {error: err}));
			});
	};
};

const getSGPBalanceInfo = payload => {
	const data = {
		name: "get_sgp_balance_info",
		params: {
			sgpExchangeUserId: payload.sgpExchangeUserId
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.GET_SGP_BALANCE_INFO_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success) {
					const sgpBalance = response.result.sgpBalance;
					const balanceAGT = sgpBalance && sgpBalance.find(balance => balance.currency === 'AGT');
					const balanceTCT = sgpBalance && sgpBalance.find(balance => balance.currency === 'TCT');
					dispatch(updateStatus(ACTIONS.GET_SGP_BALANCE_INFO_SUCCESS, { balanceAGT, balanceTCT }));
				}
				else {
					dispatch(updateStatus(ACTIONS.GET_SGP_BALANCE_INFO_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("getSGPBalanceInfo", err);
				dispatch(updateStatus(ACTIONS.GET_SGP_BALANCE_INFO_FAILED, {error: err}));
			});
	};
};

const getOTCCurrencyInfo = () => {
	const data = {
		name: "get_otc_currency_info",
		params: {}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.GET_OTC_CURRENCY_INFO_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.GET_OTC_CURRENCY_INFO_SUCCESS, { otcCurrencyInfo: response.result }));
				else {
					dispatch(updateStatus(ACTIONS.GET_OTC_CURRENCY_INFO_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("getOTCCurrencyInfo", err);
				dispatch(updateStatus(ACTIONS.GET_OTC_CURRENCY_INFO_FAILED, {error: err}));
			});
	};
};

const getTopUpInfo = payload => {
	const data = {
		name: 'get_top_up_info',
		params: {
			usercode: payload.usercode,
			card_type: payload.card_type,
			base_currency: payload.base_currency
		},
	};
	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.GET_INFO_EZPAY_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success) {
					const { ezpFee, wcgFee, isUnlimited } = response.result;
					dispatch(updateStatus(ACTIONS.GET_INFO_EZPAY_SUCCESS, {
						ezpFee,
						wcgFee,
						isUnlimited
					}));
				}
				else {
					dispatch(updateStatus(ACTIONS.GET_INFO_EZPAY_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("getInfoEzpay", err);
				dispatch(updateStatus(ACTIONS.GET_INFO_EZPAY_FAILED, { error: err }));
			});
	};
};

const prepareForTopUp = payload => {
	const data = {
		name: 'prepare_for_top_up',
		params: {
			usercode: payload.usercode,
			amount: Number(payload.amount),
			cardNumber: payload.cardNumber,
			sgpExchangeUserId: payload.sgpExchangeUserId
		},
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.PREPARE_FOR_TOP_UP_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success) {
					dispatch(updateStatus(ACTIONS.PREPARE_FOR_TOP_UP_SUCCESS, {
						userTopUpInfoLogID: response.result && response.result.userTopUpInfoLogID
					}));
				}
				else {
					dispatch(updateStatus(ACTIONS.PREPARE_FOR_TOP_UP_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("prepareForTopUp", err);
				dispatch(updateStatus(ACTIONS.PREPARE_FOR_TOP_UP_FAILED, { error: err }));
			});
	};
};

const loginSGP = payload => {
	const data = {
		name: 'login_sgp',
		params: { sn: payload.sgpExchangeUserId, password: payload.password },
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.LOGIN_SGP_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success) {
					dispatch(updateStatus(ACTIONS.LOGIN_SGP_SUCCESS, {
						accessToken: response.result && response.result.access_token
					}));
				}
				else {
					dispatch(updateStatus(ACTIONS.LOGIN_SGP_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("prepareForTopUp", err);
				dispatch(updateStatus(ACTIONS.LOGIN_SGP_FAILED, { error: err }));
			});
	};
};

const otcAndTopUp = payload => {
	const { usercode, amount, userTopUpInfoLogID, otp, access_token, cardNumber, sgpExchangeUserId } = payload;
	const data = {
		name: 'otc_exchange_and_top_up_ezpay',
		params: {
			usercode,
			amount,
			userTopUpInfoLogID,
			otp,
			access_token,
			cardNumber,
			sgpExchangeUserId
		},
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.OTC_AND_TOP_UP_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success) {
					dispatch(updateStatus(ACTIONS.OTC_AND_TOP_UP_SUCCESS, {}));
				}
				else {
					dispatch(updateStatus(ACTIONS.OTC_AND_TOP_UP_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("otcAndTopUp", err);
				dispatch(updateStatus(ACTIONS.OTC_AND_TOP_UP_FAILED, { error: err }));
			});
	};
};

const getHistoryTopUp = payload => {
	const { numPage } = payload;
	const data = {
		name: 'get_top_up_history',
		params: {
			numPage
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.GET_HISTORY_TOP_UP_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success) {
					const { allTransactions, pageCount } = response.result;
					let topUpHistories = allTransactions.map(item => calculateTopUpHistoryItem(item));
					dispatch(updateStatus(ACTIONS.GET_HISTORY_TOP_UP_SUCCESS, {
						topUpHistories,
						pageCount,
					}));
				}
				else {
					dispatch(updateStatus(ACTIONS.GET_HISTORY_TOP_UP_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("getHistoryTopUp", err);
				dispatch(updateStatus(ACTIONS.GET_HISTORY_TOP_UP_FAILED, { error: err }));
			});
	};
};

const pagingTopUpHistory = (topUpHistories, page = 0, limit = 10) => {
	const displayTopUpHistory = topUpHistories.slice(page * limit, (page + 1) * limit);
	return dispatch => dispatch(updateStatus(ACTIONS.PAGING_TOP_UP_HISTORY, { displayTopUpHistory }));
};

export {
	ACTIONS,
	getUserCodes,
	getSGPBalanceInfo,
	getOTCCurrencyInfo,
	getTopUpInfo,
	prepareForTopUp,
	loginSGP,
	otcAndTopUp,
	getListCardsTopUp,
	getHistoryTopUp,
	pagingTopUpHistory
};