import React, { Component } from 'react';
import { I18n } from 'react-i18next';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { getAllBackUpFile, resetRestoreDatabaseStatus, restoreSuccessStatus, restoreProgressStatus } from "../../../../01-actions/adminDB";
import ConfirmRestore from "./ConfirmRestore/confirmRestore";
import i18n from "i18next";
import { pushNotify } from "../../../../03-components/Notification";
import SailsSocket from "../../../../05-utils/socket";

class RestoreDatabase extends Component {
	state = {
		backUpFileSelected: "",
		isOpenConfirmRestore: false,
	};

	componentDidMount() {
		const { actions, socket } = this.props;
		actions.getAllBackUpFile();

		SailsSocket.restoreDBSub(socket, actions);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.databaseRestored) {
			pushNotify('success', i18n.t('DATABASE_RESTORED_SUCCESSFULLY'));
			nextProps.actions.resetRestoreDatabaseStatus();
			nextProps.actions.getAllBackUpFile();
			this.toggleConfirmRestore();
		}
	}

	onFileSelected = e => {
		this.setState({ backUpFileSelected: e.target.value });
	};

	toggleConfirmRestore = () => this.setState({ isOpenConfirmRestore: !this.state.isOpenConfirmRestore });

	render() {
		const { backUpFileSelected, isOpenConfirmRestore } = this.state;
		const { backUpFiles } = this.props;

		return (
			<I18n ns="translations">
				{
					t => (
						<div>
							<div className="form-group">
								<b>{t('select_a_back_up_file')}</b>
								<select
									className="form-control"
									value={backUpFileSelected}
									onChange={this.onFileSelected}
								>
									<option value="">{`-- ${t('select_a_back_up_file')} --`}</option>
									{
										backUpFiles && backUpFiles.map((file, index) => (
											<option key={index} value={file.Key}>
												{file.Key}
											</option>
										))
									}
								</select>
							</div>
							<div className="text-center">
								<button
									className="btn btn-primary btn-lg"
									onClick={this.toggleConfirmRestore}
									disabled={backUpFileSelected === ""}
								>
									<i className="fa fa-undo"/>
									<span>{t('restore_now')}</span>
								</button>
								<ConfirmRestore
									toggle={this.toggleConfirmRestore}
									isOpen={isOpenConfirmRestore}
									backupFileName={backUpFileSelected}
								/>
							</div>
						</div>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		backUpFiles: store.adminDB.backUpFiles,
		databaseRestored: store.adminDB.databaseRestored,
		socket: store.socket.socket,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				getAllBackUpFile,
				resetRestoreDatabaseStatus,
				restoreSuccessStatus,
				restoreProgressStatus,
			},
			dispatch
		)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RestoreDatabase);