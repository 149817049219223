export default {
	"logout": "Logout",
	"login": "Login",
	"login_": "LOGIN",
	"forgot_password": "Forgot Password?",
	"password": "Password",
	"warning": "Warning",
	"delete_address_book_confirm": "Do you want to remove this address?",
	"cancel": "Cancel",
	"delete": "Delete",
	"address_book": "Address Book",
	"name": "Name",
	"address": "Address",
	"actions": "Actions",
	"add_address_book": "Add address book",
	"add": "Add",
	"edit_address_book": "Edit address book",
	"save": "Save",
	"transaction_history": "Transaction history",
	"transaction": "Transaction",
	"wallet_selection": "Wallet selection",
	"destination_wallet": "Destination wallet",
	"send_": "SEND",
	"create_wallet": "Create wallet",
	"attention_": "!!! --- ATTENTION --- !!!",
	"create_wallet_attention": "Your wallet secret key is IMPORTANT and will be needed for recovery in some situation, such as CHANGING PASSWORD or ACCOUNT LOST. Please write down or save it in a safe place. Exposing of secret key results in LOSING WALLET.",
	"wallet_address": "Wallet address",
	"wallet_secret_key": "Wallet secret key",
	"copy": "Copy",
	"wallet_name_optional": "Wallet name (optional)",
	"transfer_money": "Transfer Money",
	"wallet": "Wallet",
	"balance": "Balance",
	"available": " available",
	"enter_the_destination": "Enter the destination",
	"select_the_destination_from_address_book": "Select the destination from address book",
	"select_the_destination": "Select the destination",
	"amount": "Amount",
	"memo": "Memo",
	"select_token": "Select token",
	"search_token": "Search token",
	"search_asset_by_name": "Search asset by name...",
	"OK": "OK",
	"unblock_wallet": "Unblock wallet",
	"private_key": "Private key",
	"unblock": "Unblock",
	"dont_have_wallet_intro": "You don't have any wallet for now. Create your first wallet for free!",
	"copied": "Copied",
	"wallet_not_active_intro": "This wallet is not active. In order to make it available, you need to have the RIA coin sent from another active wallet. Activation of wallet requires al least 20RIA coin.",
	"wallet_blocked_intro": "This wallet is blocked due to password change. You need the Secret key and Password to unblock.",
	"trust_coin": "Trust coin",
	"change_password": "Change password",
	"change_password_attention": "Changing password will block ALL your wallets. Please make sure you have SECRET KEY of these wallets to unblock them later.",
	"new_password": "New password",
	"confirm_password": "Confirm password",
	"change_": "CHANGE",
	"email_and_password": "Email & Password",
	"email": "Email",
	"change_password_": " Change password",
	"tfa_verification": "Setting TFA",
	"tfa_verification_intro": "TFA Verification adds an extra layer of security to your account.",
	"status": "Status",
	"enabled_": " enabled",
	"disabled_": " disabled",
	"why_should_i_keep_tfa_enabled": "Why should I keep TFA enabled?",
	"preferred_tfa_method": "Preferred TFA method",
	"google_authenticator": "Google Authenticator",
	"verified_": " verified",
	"not_verified_": " not verified",
	"tfa_required_when_login": "TFA required when login",
	"reset_": " Reset",
	"account_verification": "Account verification",
	"verify_account_intro": "Verify your account to unlock all Triple Wallet's features",
	"email_": " Email: ",
	"recent_login_history": "Recent login history",
	"date": "Date",
	"ip_address": "IP address",
	"geo_location": "Geo-location",
	"general": "General",
	"security": "Security",
	"two_factor_authentication": "Two factor authentication",
	"enter_tfa_intro": "Please provide the corresponding code for your account.",
	"confirm": "Confirm",
	"my_wallet": "My wallets",
	"setting": "Setting",
	"history": "History",
	"product_key_store": 'Product Key Store',
	"contact": "Contact",
	"two_factor_authentication_reset": "Two Factor Authentication reset",
	"two_factor_authentication_setup": "Two Factor Authentication setup",
	"reset_ga_intro": "You are about to reset your Google Authenticator settings. To verify this is you, please provide your TFA reset code in the input below. After reset, you can setup the TFA again and obtain a new reset code.",
	"ga_reset_code": "GA Reset code",
	"setup": "Setup",
	"tfa_disabled_intro": "Your Two Factor Authentication has not been setup. Some features may have been disabled.",
	"set_ga_intro": "You are about to change Two Factor Authentication settings. To verify it's you, please provide your password in the text box below.",
	"ga_secret": "GA Secret",
	"verify_ga_intro": "This is your Google Authenticator reset code. Please write it down or save it somewhere as you will need it to reset your TFA when your phone is lost. This code will only be shown once.",
	"verify": "Verify",
	"scan_qr_code_intro": "Please use Google Authenticator app to scan this QR Code.",
	"enter_verify_ga_intro": "Then provide the corresponding code for your account.",
	"user_management": "User Management",
	"reset_user": "Reset User",
	"first_wallet_store": "First Wallet Store",
	"database_backup": "Database backup",
	"news": "News",
	"mail_box": "Mailbox",
	"first_name": "First Name",
	"middle_name": "Middle Name",
	"last_name": "Last Name",
	"ga_verified": "GA Verified",
	"admin_mode": "Admin mode",
	"user_mode": "User mode",
	"control": "Control",
	"important": "IMPORTANT",
	"reset_user_confirm": "Please notice, this action will RESET the password of users selected, then send an email contains the login details.",
	"summary": "Summary",
	"search_user": "Search user...",
	"unblock_this_wallet": "Unblock this wallet",
	"user_info": "User info",
	"lock_user": "Lock user",
	"unlock_user": "Unlock user",
	"unblock_all_wallet": "Unblock all wallets",
	"all_wallets": "All wallets",
	"reset_user_and_resend_email": "Reset user and resend email",
	"reset_user_intro": "This function will reset password and resend user login details (email, User Code, new password and first wallet info)",
	"reset_and_send_email": "Reset & Send email",
	"manual_backup": "Manual backup",
	"restore_database": "Restore database",
	"select_a_back_up_file": "Select a back up file",
	"restore_now": "Restore now",
	"run_backup_now": "Run backup now",
	"backup_now": "Back up now",
	"next_auto_backup_in": "Next auto back up in ",
	"file_name": "File name",
	"size": "Size",
	"restore": "Restore",
	"restore_database_intro": "Are your sure? This action can not undo!",
	"delete_back_up_file_confirm": "You are deleting the database backup file! Are you sure?",
	"display_secret_key": "Display secret keys",
	"select_a_wallet": "Select a wallet",
	"secret_key": "Secret key",
	"please_enter_your_password_to_continue": "Please enter your password to continue",
	"display": "Display",
	"exchange": "Exchange",
	"go_to_settings": "Go to settings",
	"create_account": "Create account",
	"payment": "Payment",
	"change_trust": "Change trust",
	"profile": "Profile",
	"user_not_have_wallet": "This user does not have any wallet.",
	"wallet_name": "Wallet name",
	"wallet_balances": "Wallet balances",
	"asset": "Asset",
	"total": "Total",
	"wallets": "wallets",
	"created_at": "Created at",
	"title": "Title",
	"content": "Content",
	"edit": "Edit",
	"create_news": "Create news",
	"create": "Create",
	"delete_news_confirm": "You are deleting the news! Are you sure?",
	"login_by_robin_account": "Login by Robin account",
    "edit_user": "Edit user",
    "edit_user_info": "Edit user info",
    "firstname": "First Name",
    "middlename": "Middle Name (optional)",
    "lastname": "Last Name",
    "reset_tfa": "Reset TFA",
    "charge_trust": "Change trust",
    "trust_asset": "Trust asset",
    "issue_wallet": "Issue wallet",
    "time": "Time",
    "funder_wallet": "Funder wallet",
    "new_wallet": "New wallet",
    "sender_wallet": "Sender wallet",
    "receiver_wallet": "Receiver wallet",
    "register": "REGISTER",
    "id_member_free": "ID member FREE",
    "address_email_create_robin_sns": "Email create ROBIN SNS",
    "transaction_status": "Transaction status",
    "rename_wallet": "Rename wallet",
    "please_enter_keyword": "Please enter keyword...",
    "edit_email_of_user": "Edit email of user",
	"high_permission_intro": "Admin permission include the following action: add signer, update signer, delete signer and change setting wallet",
	"med_permission_intro": "Payment & Trust permission include the following action: transfer money, issuing asset, trust, untrust asset",
	"low_permission_intro": "Allow trust permission include only allow trust action",
	"signatures": "Signatures",
	"weight": "Weight",
	"master_key": "Master key",
	"signer": "Signer",
	"payment_and_trust": "Payment and trust",
	"allow_trust_permision": "Allow trust permision",
	"enter_private_keys": "Enter private keys",
	"please_enter_the_secret_keys": "Please enter the secret keys",
	"generate": "Generate",
	"save_keypairs_intro": "Please make sure to keep the following three key pairs. This key pair is displayed only once. It is impossible to re-issue or redisplay, so if you lose it you will lose the coins in Wallet.",
	"keypairs": "keypairs",
	"make_sure_you_backed_up_keypairs": "Make sure you backed up keypairs",
	"download_csv": "DOWNLOAD CSV",
	"setup_now": "Setup now",
	"setup_wallet": "Setup wallet",
	"previous": "Previous",
	"next": "Next",
	"edit_email": "Edit email",
	"please_provide_your_email_address": "Please provide your email address. We will send you an email containing secret code which will be used to reset your password.",
	"send_code_to_user": "Send code to user",
	"i_already_have_code": "I already have code",
	"code": "Reset password code",
	"reset_password": "Reset password",
    "success": "SUCCESS",
    "failure": "FAILURE",
    "transaction_detail_status": "Transaction detail status",
    "transaction_fee": "Transaction fee",
    "number_of_operation": "Number of operations",
    "admin_permission": "Admin permission",
    "admin_detail_permisstion": "Admin permission include the following action: add signer, update signer, delete signer and change setting wallet",
    "payment_permisstion": "Payment & Trust & Issuing permission",
    "payment_detail_permisstion": "Payment & Trust permission include the following action: transfer money, issuing asset, trust, untrust asset",
    "trust_permission": "Allow Trust permission",
    "trust_detail_permission": "Allow trust permission include only allow trust action",
    "Submit": "Submit",
    "signer_address": "Signer address",
    "all": "All",
    "view": "View",
	"inactive": "inactive",
	"blocked": "blocked",
    "signed": "signed",
    "Signed": "Signed",
    "sign_submit": "Sign submit",
    "signature": "Signature",
    "require_permission": "Require permission",
    "total_signed_weight": "Total signed weight",
    "created_by": "Created by",
    "weight_required": "Weight required",
    "set_options": "setOptions",
    "is_sign": "Is signed",
	"change_setting": "Change setting",
    "confirm_signer": "Confirm signer",
    "signer_transaction": "You will sign this transaction by",
	"no_data_to_show": "No data to show",
	"source_wallet": "Source wallet",
    "master_weight": "Master weight",
    "allower_trust": "Allower trust",
    "payment_trust": "Payment trust",
    "admin_": "Admin",
    "weight_": "Weight",
    "property_": "Property",
    "action_": "Action",
    "asset_": "Asset",
    "Issuer": "Issuer",
    "current_wallet": "Current Wallet",
    "update_thresholds":"Update Thresholds",
    "add_signer": "Add Signer",
	"maintenance_mode": "Maintenance mode",
	"maintenance_mode_app": "Maintenance mode application",
	"maintenance_text_1": ":( Oops!",
	"maintenance_text_2": "Temporarily down for maintenance.",
	"maintenance_text_3": "Sorry for the inconvenience but we’re performing some maintenance at the moment. We’ll be back online shortly!",
	"maintenance_text_4": "Triple Wallet",
	"view_all": "View all",
	"register_intro": "Please register with FREE member ID.",
	"login_intro": "Please log in with your e-mail address.",
	"redirect_to_register_intro": "Register with FREE member ID",
	"all_backups": "All backups",
	"transaction_fail": "Transaction Failure",
	"operations": "operations",
	"transaction_id": "Transaction ID",
    "change_background":"Change background",
    "required_size":"Required size: 100Kb ~ 1Mb",
    "update":"Update",
    "file_not_valid": "File is not valid (currently ",
    "card_charge_button": "Send",
    "triple_id":"Triple ID",
    "gbt_balance":"GBT Balance",
    "top_up_amount":"Top Up Amount",
	"amount_can_charge": "Amount can charge",
	"unlimited": "Unlimited",
	"charge_gbt_quantity": "GBT total quantity",
	"charge_desired_amount": "Reflected quantity",
	"choose_wallet": "Choose wallet",
	"generate_keypairs": "Generate keypairs",
	"finish": "Finish",
	"upload_csv": "Upload CSV file",
	"import_asset": "Import asset",
	"search_asset": "Search asset",
	"import_": "Import",
	"notification_import_csv": "Success: %s/%s have been imported!",
	"notification": "Notification",
	"asset_management": "Asset management",
	"remove_": "Remove",
	"are_you_sure_remove_this_asset?": "Are you sure remove this asset?",
	"confirm_": "Confirm",
	"detail_asset": "Detail asset",
	"user_email": "User email",
	"capital_": "Capital",
	"decimalPlace_": "Decimal Place",
	"ratio_": "Ration",
	"fee_": "Fee",
	"ok": "OK",
	"not_allowed": "Not allowed",
	"no_asset_": "No asset",
	"asset_name_not_allow_special_character": "Asset name is not allow special character",
	"op_pass": "OP Pass",
	"op_pass_verification": "OP Pass verification",
	"select_system_to_transfer": "Select system to transfer",
	"ezpay_select_application_id_intro": "Please select Application ID which want to transfer",
	"application_with_card_number": "Application with card number",
	"back": "Back",
	"select_an_application_id": "Select an application id",
	"enter_your_sgp_password": "Enter your SGP Password",
	"enter_authentate_code_to_proceed": "Enter Authentate code to proceed",
	"enable_top_up": "Enable top up",
	"request_code": "Request code",
	"title_name": "Title name",
	"cancel_request_code_confirm": "You are cancelling this request code, all of USDT amount you paid for this request will be lost. This action can not be undo. You still want to continue?",
	"cancel_request": "Cancel request",
	"repeat_order": "Repeat order",
	"12_months_repeat_order": "12 months repeat order",
	"number_of_package": "Number of package",
	"total_amount": "Total amount",
	"enter_number_of_package": "Enter number of package",
	"purchase_a_repeat_order_code": "Purchase a repeat order code",
	"delivery_at_the_same_time":"Delivery at the same time for 12 months %s %s (Free shipping)",
	"monthly_delivery_for_12_months":"Monthly delivery for 12 months %s %s (%s + %s x %s shipping fee)",
	"choose_the_way_to_ship": "Choose the way to ship",

	"NETWORK_ERROR": "Network error",
	"VALIDATION_ERROR": "Validation error",
	"EMAIL_EXISTED_ERROR": "Email already existed",
	"SYSTEM_ERROR": "Sorry, something went wrong there. Try again.",
	"LOGIN_INCORRECT_ERROR": "Login email/password not correct",
	"ACCOUNT_LOCKED": "Your account has been locked by security. Please contact with the administrator.",
	"PHONE_NUMBER_ERROR": "Please update your phone number first",
	"VERIFICATION_ERROR": "Verification code not correct",
	"ADDRESS_CONFLICT_ERROR": "Address conflict between wallets",
	"NO_WALLET_ERROR": "No wallet selected",
	"INPUT_ERROR": "Invalid input value",
	"NOT_FOUND_ERROR": "Resource not found",
	"USER_STATE_ERROR": "User is not in valid state",
	"TOKEN_INVALID_ERROR": "User token is not valid",
	"VERIFICATION_REQUIRED_ERROR": "This feature requires TFA to be enabled",
	"WRONG_PASSWORD_ERROR": "Password is not correct",
	"ISSUER_EXISTED_ERROR": "Issuer already existed",
	"WALLET_UNDERFUNDED": "Fund Wallet underfunded",
	"INVALID_INPUT_ERROR": "Invalid encoded string",
	"MALFORMED_REQUEST_ERROR": "The request is malformed",
	"NOT_ISSUER_ERROR": "User is not an issuer",
	"NO_ACTION_ERROR": "No action found with provided name",
	"EXECUTION_HALTED_ERROR": "Execution halted due to error",
	"AMOUNT_REQUIRE_NUMBER": "Amount require number",
	"NOT_FOUND_WALLET": "Not Found Wallet",
	"WALLET_INACTIVE": "This wallet is inactive.",
	"WALLET_BLOCK": "This wallet is blocked.",
	"NOT_FOUND_DESTINATION_ACCOUNT": "The destination account does not exist!",
	"TRANSACTION_ERROR": "Transaction Error",
	"NOT_POSSESSED_ERROR": "User not possess the requested resource",
	"EMAIL_NOT_VERIFIED_ERROR": "Your account has not been verified",
	"OP_LOW_RESERVE": "Operation - Low Reserve",
	"TFA_REQUIRE_ENABLED": "Two factor authenticate require enabled",
	"QUERY_DB_ERR": "Error when query to Database",
	"ASSET_EXISTED_ERROR": "Asset name already existed",
	"RESOURCE_NOT_FOUND": "Resource not found",
	"ASSET_NOT_FOUND": "Asset not found",
	"HOLDING_WALLET_ERROR": "Holding wallet require not equal Issuer Wallet",
	"TX_INSUFFICIENT_BALANCE": "Holding wallet not enough money",
	"REQUIRE_SUPER_ADMIN_ROLE": "Require Supper Admin role",
	"PRICE_AND_CURRENCY_NOT_VALID": "Price and Currency is not valid",
	"CURRENCY_EXIST": "This currency has exist",
	"EMAIL_NOT_EXISTED_ERROR": "Email does not exist in system",
	"DUPLICATED_ICO_REQUEST": "You can only have one request for each ico packet",
	"ICO_RESERVATION_NOT_FOUND": "Requested ICO reservation not found",
	"ICO_PLAN_EMPTY_ERROR": "Can not purchase an empty ICO plan",
	"ICO_NOT_READY_ERROR": "The ICO is currently not available",
	"REQUIRE_SET_PHONE_NUMBER": "Please set your phone number",
	"YOU_ARE_NOT_ALLOWED": "You are not allowed to do this action",
	"QUERY_S3_ERROR": "Error when access to Amazon S3",
	"WRONG_PRIVATE_KEY_ERROR": "Private key is not correct",
	"ASSET_EXIST": "This Asset has exist",
	"DONT_OWN_WALLET": "You don't own this wallet",
	"INCORRECT_PASSWORD": "Password is not correct",
	"INCORRECT_PASSWORD_LENGTH": "Password's length must more than 6 characters and less than 200 characters",
	"CODE_EXPIRED": "Verification code expired",
	"CAN_NOT_UPDATE_PACKET": "ICO is running so you can't edit any packet",
	"WRONG_ASSET_TYPE": "Can not create offer with the provided assets",
	"WRONG_PARAMS_VALUE": "The value of parameters is invalid",
	"ASSET_NOT_TRUSTED": "You must trust the buying asset first",
	"NOT_MERCHANT_ERROR": "User is not a merchant",
	"MERCHANT_EXIST": "User is already a merchant",
	"NOT_FOUND_MERCHANT_STORE": "Not Found Merchant Store",
	"REQUEST_COOLDOWN": "Wait for cooldown",
	"WALLET_EXISTED": "The wallet has existed",
	"ICO_IS_RUNNING": "ICO is running. Disable it before perform this action",
	"ICO_PACKET_CONTAIN_RESERVATION": "Cannot remove a packet that contains reservation",
	"NOT_WEBSOCKET_CONNECTION": "This request is not a websocket connection",
	"ISSUER_NOT_APPROVE": "Issuer does not Approve!",
	"MERCHANT_NOT_APPROVE": "Merchant does not Approve!",
	"SOURCE_WALLET_ISSUED": "Your source wallet issued an asset",
	"SOURCE_WALLET_USED": "Your source wallet is used by a Merchant Store",
	"MAXIMUM_AMOUNT_EXCEEDED": "Selected ICO amount is not allowed",
	"WRONG_CODE": "The code provided is not correct",
	"ASSET_ALREADY_TRUSTED": "The selected asset has already been trusted",
	"WALLET_STATE_ERROR": "The wallet is not in correct state",
	"DIRTY_ERROR": "Cannot remove used resource",
	"ISSUING_PROCESS_REQUIRED": "This action must be performed through issuing process",
	"ISSUING_WALLET_NOT_ALLOWED": "Issuing wallet is not allowed to perform this action",
	"INVALID_SECKET_KEY": "Secret key is invalid",
	"HOLDING_FEE_WALLET": "Issuing wallet can't be holding fee wallet",
	"tfa_login": "TFA required when login",
	"ERROR_OCCURRED": "Error occurred!",
	"CANT_DELETE_BACKUP_FILE": "Can't delete backup file",
	"BACKUP_ERROR": "Backup Error!",
	"LOGIN_AGAIN": "Success. You must login again!",
	"ERROR_WHEN_RESTORE": "Error when restore",
	"CANT_GET_CURRENCY": "Can't get currency",
	"UPDATE_SUCCESS": "Update success.",
	"CANT_UPDATE_ICO_CONTROL": "Can't update ICO Control",
	"CANT_ADD_NEW_CURRENCY": "Can't add new currency",
	"INPUT_ALL_INFORMATION": "Please input all information!",
	"SUCCESS": "Success!",
	"NEED_MORE_ARMCOIN_IN_WALLET_TRUST_NEW_ASSET": "You need more RIA in wallet in order to trust the new asset",
	"CANT_CREATE_OFFER": "Can't create offer",
	"ESTABLISH_REALTIME_CONNECTION": "Establish realtime connection. Please reload page to try again.",
	"CONNECTION_ATTEMPT_FAILED": "Connection attempt failed",
	"CONNECTION_TO_SERVER_INTERRUPTED": "Connection to server has been interrupted.",
	"SELL_BUY_SAME_ASSETS": "Don't sell and buy the same assets",
	"SESSION_EXPIRED": "Session has expired, please login again",
	"CANT_GET_TRUST_ASSET_REQUESTS": "Can't get trust asset requests",
	"ALLOW_TRUST_ERROR": "Allow trust error",
	"CANT_ADD_NEW_CONTACT": "Can't add new contact!",
	"CANT_UPDATE_CONTACT": "Can't update contact!",
	"CANT_DELETE_CONTACT": "Can't delete contact!",
	"WRONG_ADDRESS_VALUE": "Wrong address value",
	"CONFIRM_REMOVE_CONTACT": "Do you want to remove this contact?",
	"CONTACT_EXIST": "The address already exists",
	"ISSUING_WALLET_CANT_HOLDING_FEE_WALLET": "Issuing Wallet can't be Holding Fee Wallet",
	"CREATE_ASSET_WITHOUT_LOGO": "New asset created without logo",
	"CANT_ISSUE_MONEY_TO_ISSUING_WALLET": "You can not issue money to the issuing wallet",
	"ISSUING_WALLET_OR_HOLDING_WALLET_NOT_ENOUGH_ARM": "Either issuing wallet or holding wallet does not have enough RIA to perform this operation",
	"NEED_MORE_MONEY_HOLDING_WALLET_TRUST_NEW_ASSET": "You need more money in holding wallet to trust the new asset",
	"NEW_STORE_CREATED": "New store created",
	"CANT_UPLOAD_STORE_LOGO": "Cannot upload store logo",
	"CANT_MERGE_WALLET": "Can't merge wallet",
	"MERGE_WALLET_SUCCESS": "Merge wallet successfully",
	"RENEW_ERROR": "Renew Error",
	"SAVE_ERROR": "Save Error",
	"FUNDING_WALLET_NOT_ENOUGH_ARM": "Funding wallet does not have enough RIA to perform this operation",
	"RESTORE_ERROR": "Restore error",
	"GENERATE_ERROR": "Generate error",
	"CANT_SEND_MONEY": "Can'n send money.",
	"WALLET_UNBLOCK": "Wallet is unblock.",
	"NATIVE_ASSET_TRUST_BY_DEFAULT": "Native asset is trusted by default",
	"ACTIVE_WALLET_SUCCESS": "Active wallet successfully",
	"CANT_RENAME_WALLET": "Can't rename wallet",
	"ADDRESS_OR_TRANSACTION_INCORRECT": "Wallet Address incorrect",
	"CANT_FETCH_ICO_REALTIME_STATUS": "Cannot fetch ICO realtime status",
	"NEW_MAIL_SENT": "New mail sent",
	"CANT_REQUEST_NEW_VERIFICATION_MAIL": "New mail sent",
	"WALLET_NOT_TRUST_ASSET": "Wallet is not trust this asset",
	"ASSET_NOT_BELONG_TO_ADMIN_WALLET": "This asset is not belong to admin wallet",
	"REQUIRE_SUB_SYSTEM_ADMIN": "Require sub system admin",
	"CREATE_ACCOUNT_MALFORMED": "Invalid destination",
	"CREATE_ACCOUNT_UNDERFUNDED": "Not enough funds in source account",
	"CREATE_ACCOUNT_LOW_RESERVE": "Would create an account below the min reserve",
	"CREATE_ACCOUNT_ALREADY_EXIST": "Wallet has been trust",
	"PAYMENT_MALFORMED": "Payment bad input",
	"PAYMENT_UNDERFUNDED": "Not enough funds in source account",
	"PAYMENT_SRC_NO_TRUST": "No trust line on source account",
	"PAYMENT_SRC_NOT_AUTHORIZED": "Source not authorized to transfer",
	"PAYMENT_NO_DESTINATION": "Destination account does not exist",
	"PAYMENT_NO_TRUST": "Destination missing a trust line for asset",
	"PAYMENT_NOT_AUTHORIZED": "Destination not authorized to hold asset",
	"PAYMENT_LINE_FULL": "Destination would go above their limit",
	"PAYMENT_NO_ISSUER": "Missing issuer on asset",
	"PATH_PAYMENT_MALFORMED": "Path payment bad input",
	"PATH_PAYMENT_UNDERFUNDED": "Not enough funds in source account",
	"PATH_PAYMENT_SRC_NO_TRUST": "No trust line on source account",
	"PATH_PAYMENT_SRC_NOT_AUTHORIZED": "Source not authorized to transfer",
	"PATH_PAYMENT_NO_DESTINATION": "Destination account does not exist",
	"PATH_PAYMENT_NO_TRUST": "Destination missing a trust line for asset",
	"PATH_PAYMENT_NOT_AUTHORIZED": "Destination not authorized to hold asset",
	"PATH_PAYMENT_LINE_FULL": "Destination would go above their limit",
	"PATH_PAYMENT_NO_ISSUER": "Missing issuer on one asset",
	"PATH_PAYMENT_TOO_FEW_OFFERS": "Not enough offers to satisfy path",
	"PATH_PAYMENT_OFFER_CROSS_SELF": "Would cross one of its own offers",
	"PATH_PAYMENT_OVER_SENDMAX": "Could not satisfy sendmax",
	"MANAGE_OFFER_MALFORMED": "Generated offer would be invalid",
	"MANAGE_OFFER_SELL_NO_TRUST": "No trust line for what we're selling",
	"MANAGE_OFFER_BUY_NO_TRUST": "No trust line for what we're buying",
	"MANAGE_OFFER_SELL_NOT_AUTHORIZED": "Not authorized to sell",
	"MANAGE_OFFER_BUY_NOT_AUTHORIZED": "Not authorized to buy",
	"MANAGE_OFFER_LINE_FULL": "Can't receive more of what it's buying",
	"MANAGE_OFFER_UNDERFUNDED": "Doesn't hold what it's trying to sell",
	"MANAGE_OFFER_CROSS_SELF": "Doesn't hold what it's trying to sell",
	"MANAGE_OFFER_SELL_NO_ISSUER": "No issuer for what we're selling",
	"MANAGE_OFFER_BUY_NO_ISSUER": "No issuer for what we're buying",
	"MANAGE_OFFER_NOT_FOUND": "OfferID does not match an existing offer",
	"MANAGE_OFFER_LOW_RESERVE": "Not enough funds to create a new Offer",
	"SET_OPTIONS_SUCCESS": "Success",
	"SET_OPTIONS_LOW_RESERVE": "Not enough funds to add a signer",
	"SET_OPTIONS_TOO_MANY_SIGNERS": "Max number of signers already reached",
	"SET_OPTIONS_BAD_FLAGS": "Invalid combination of clear/set flags",
	"SET_OPTIONS_INVALID_INFLATION": "Inflation account does not exist",
	"SET_OPTIONS_CANT_CHANGE": "Can no longer change this option",
	"SET_OPTIONS_UNKNOWN_FLAG": "Can't set an unknown flag",
	"SET_OPTIONS_THRESHOLD_OUT_OF_RANGE": "Bad value for weight/threshold",
	"SET_OPTIONS_BAD_SIGNER": "Signer cannot be master key",
	"SET_OPTIONS_INVALID_HOME_DOMAIN": "Malformed home domain",
	"CHANGE_TRUST_SUCCESS": "Success",
	"CHANGE_TRUST_MALFORMED": "Bad input",
	"CHANGE_TRUST_NO_ISSUER": "Could not find issuer",
	"CHANGE_TRUST_INVALID_LIMIT": "Cannot drop limit below balance",
	"CHANGE_TRUST_LOW_RESERVE": "Not enough funds to create a new trust line,",
	"CHANGE_TRUST_SELF_NOT_ALLOWED": "Trusting self is not allowed",
	"ALLOW_TRUST_SUCCESS": "Success",
	"ALLOW_TRUST_MALFORMED": "Asset is not ASSET_TYPE_ALPHA NUM",
	"ALLOW_TRUST_NO_TRUST_LINE": "Trustor does not have a trustline",
	"ALLOW_TRUST_TRUST_NOT_REQUIRED": "Source account does not require trust",
	"ALLOW_TRUST_CANT_REVOKE": "Source account can't revoke trust,",
	"ALLOW_TRUST_SELF_NOT_ALLOWED": "Trusting self is not allowed",
	"ACCOUNT_MERGE_SUCCESS": "Success",
	"ACCOUNT_MERGE_MALFORMED": "Can't merge onto itself",
	"ACCOUNT_MERGE_NO_ACCOUNT": "Destination does not exist",
	"ACCOUNT_MERGE_IMMUTABLE_SET": "Source account has AUTH_IMMUTABLE set",
	"ACCOUNT_MERGE_HAS_SUB_ENTRIES": "Account has trust lines/offers",
	"INFLATION_SUCCESS": "Success",
	"INFLATION_NOT_TIME": "Failure",
	"MANAGE_DATA_SUCCESS": "Success",
	"MANAGE_DATA_NOT_SUPPORTED_YET": "The network hasn't moved to this protocol change yet",
	"MANAGE_DATA_NAME_NOT_FOUND": "Trying to remove a Data Entry that isn't there",
	"MANAGE_DATA_LOW_RESERVE": "Not enough funds to create a new Data Entry",
	"MANAGE_DATA_INVALID_NAME": "Name not a valid string",
	"OP_INNER": "Inner object result is valid",
	"OP_BAD_AUTH": "Too few valid signatures / wrong network",
	"OP_NO_ACCOUNT": "Source account was not found",
	"TX_SUCCESS": "Success",
	"TX_FAILED": "Failed",
	"TX_TOO_EARLY": "Ledger closeTime before minTime",
	"TX_TOO_LATE": "Ledger closeTime after maxTime",
	"TX_MISSING_OPERATION": "No operation was specified",
	"TX_BAD_SEQ": "Sequence number does not match source account",
	"TX_BAD_AUTH": "Too few valid signatures / wrong network",
	"TX_NO_ACCOUNT": "Source account not found",
	"TX_INSUFFICIENT_FEE": "Fee is too small",
	"TX_BAD_AUTH_EXTRA": "Unused signatures attached to transaction",
	"TX_INTERNAL_ERROR": "An unknown error occurred",
	"NOT_ENOUGH_AMOUNT": "Not enough amount",
	"SENDING_TO_INACTIVE_WALLET": "You must send at least 20 RIA to the inactive wallet",
	"CANNOT_SEND_TOKEN_TO_ITSELF": "The wallet can not send token to itself",
    "AUTHORIZATION_NONE": "AUTHORIZATION_NONE",
    "AUTHORIZATION_REQUIRED": "AUTHORIZATION_REQUIRED",
	"ACCOUNT_ALREADY_EXIST": "Account already exists",
	"OP_UNDERFUNDED": "Not enough money.",
	"OP_SIGNER_CURRENT": "Do not change weight of keys use to sign.",
	"THE_USER_HAS_NOT_BEEN_VERIFIED_IDENTITY_CARD": "The user has not been verified identity card",
	"NOT_FOUND_USER": "Not found user",
	"INVALID_WALLET_ADDRESS": "Invalid wallet address",
    "TX_UNSAFE_SETTING": "Unsafe setting, total weight of signers must greater than or equals to admin permission weight, Admin Role must greater than or equals Paymenter Role and Allower Role must lower than or equals to Admin and Paymenter.",
    "WEIGHT_REQUIRED_INTEGER": "Weight must be integer number and greater than or equal to 0.",
	"SIGNER_WALLET_INVALID": "Signer wallet not found or inactive or blocked",
    "DUPPLICATE_SIGNNER_ADDRESS": "Signner is dupplicate",
	"SERVER_IS_MAINTENANCE": "Server is maintenance!",
	"NOT_ENOUGH_PERMISSION": "You have not enough permission.",
	"PASSPORT_NUMBER_NOT_FOUND": "Passport number not found",
	"AMOUNT_TOP_UP_EXCEED": "Amount top up exceed specifed amount",
	"SEARCH_ASSET_FIELD_IS_REQUIRE": "You must fill in search asset field.",
	"INCORRECT_OP_PASSWORD": "OP Password is not correct",
	"INCORRECT_OP_PASSWORD_LENGTH": "OP Password's length must more than 6 characters and less than 200 characters",
	"CANNOT_ACTIVE_MERGED_WALLET": "Cannot active a merged wallet",
	"USER_IS_NOT_ENOUGH_CONDITION_TOP_UP": "User is not enough condition for top up!",
	"CANNOT_GET_SGP_BALANCE_INFO": "Can't get sgp balance info!",
	"CANNOT_GET_OTC_CURRENCY_INFO": "Can't get OTC currency info!",
	"CANNOT_GET_INFO_FOR_TOP_UP": "Can't get info for top up!",
	"CANNOT_LOGIN_TO_SGP_ERROR": "Can't login to SGP!",
	"CANNOT_OTC_EXCHANGE_IN_SGP_ERROR": "Can't OTC Exchange in SGP!",
	"CANNOT_TOP_UP_ERROR": "Can't top up in SGP!",
	"WRONG_SGP_CODE": "The SGP code provided is not correct!",
	"WRONG_SGP_PASSWORD": "The SGP password is not correct!",
	"SGP_ACCOUNT_DISABLED": "The SGP Account is disabled!",
	"SGP_ACCOUNT_BLOCKED": "The SGP Account is blocked!",
	"NOT_LOGGED_IN_SGP": "Please login to SGP!",
	"SGP_ERR_AMOUNT_OTC_LESS_THAN_MINIMUM_AMOUNT": "Amount need OTC must be greater than minimum amount!",
	"SGP_ERR_NOT_ENOUGH_AMOUNT_FOR_OTC_EXCHANGE": "Source account balance not enough for OTC!",
	"SGP_ERR_AMOUNT_TOP_UP_LESS_THAN_MINIMUM_AMOUNT": "Amount top up must higher than 100!",
	"SGP_ERR_SOMETHING_WRONG": "Something is wrong, plesae check information and do it again!",
	"SGP_ERR_NOT_REQUIRE_TFA": "Require active twofactor in SGP Exchange!",
	"AMOUNT_IS_INVALID": "Amount is invalid' ",
	"PASSWORD_REQUIRED": "Password is required",
	"ADDRESS_REQUIRED": "Address is required",
	"NAME_REQUIRED": "Name is required",
	"NAME_INVALID": "Name is invalid",
	"ADDRESS_INVALID": "Address is invalid",
	"STARTING_BALANCE_REQUIRED": "Starting balance is required",
	"DESTINATION_REQUIRED": "Destination is required",
	"DESTINATION_INVALID": "Destination is invalid",
	"AMOUNT_REQUIRED": "Amount is required",
	"AMOUNT_INVALID": "Amount is invalid",
	"MEMO_TOO_LONG": "Memo is to long",
	"PRIVATE_KEY_REQUIRED": "Private key is required",
	"PRIVATE_KEY_INVALID": "Private key is invalid",
	"NEW_PASSWORD_REQUIRED": "New password is required",
	"CONFIRM_PASSWORD_REQUIRED": "Confirm password is required",
	"NEW_PASSWORD_MIN_LENGTH": "New password at least 6 characters",
	"PASSWORD_NOT_MATCH": "Password and confirm password does not match",
	"TFA_CODE_REQUIRED": "Tfa code is required",
	"TFA_CODE_MIN_LENGTH": "Tfa code at least 6 characters",
	"RESET_CODE_REQUIRED": "Reset code is required",
	"CODE_REQUIRED": "TFA Code is required",
	"EMAIL_REQUIRED": "Email is required",
	"EMAIL_INVALID": "Email is invalid",
	"FIRST_NAME_REQUIRED":"First Name is required",
	"LAST_NAME_REQUIRED":"Last Name is required",
	"NAME_MUST_LESS_THAN":"Name must less than %s characters",
    "WEIGHT_REQUIRED": "Weight is required",
    "WEIGHT_INVALID": "Weight is invalid",
	"AMOUNT_EXCEEDS_GBT_AMOUNT_AVAILABLE": "Amount exceeds GBT amount available",
	"AMOUNT_MUST_MORE_THAN_MIN_GBT_AMOUNT": "Amount must more than Min GBT amount",
	"AMOUNT_MUST_LESS_THAN_MAX_GBT_AMOUNT": "Amount must less than Max GBT amount",
	"AMOUNT_MUST_BE_A_NATURAL_NUMBER": "Amount must be a natural number",
	"OP_PASS_REQUIRED": "OP Pass is required",
	"OP_PASS_MIN_LENGTH": "OP Pass at least 6 characters",
	"OTHER_REQUEST_IS_PROCESSING": "Other request is processing, please try again later!",
	"TOP_UP_IS_DISABLED": "Top Up function is disabled now!",
	"CANNOT_SEND_DATA_TO_TRIPLE_SYSTEM_ERROR": "Can't send data to TripleSystem!",
	"MIDDLE_SYSTEM_USER_IS_NOT_FOUND": "User is not found in Middle System!",
	"MIDDLE_SYSTEM_CARD_IS_NOT_FOUND": "Card is not found in Middle System!",
	"SGP_ERR_ONLY_LOGIN_ONE_BROWSER": "Your SGP account is being logged in other browser, please try again later!",
	"MAX_REQUEST_CODE_OF_USER_ERROR": "You has been locked by security because request use product code incorrectly many times in a row . Please wait for cooldown.!" ,
	"REPEAT_ORDER_CODE_HAS_BEEN_USED": "Repeat order code has been used!",
	"REPEAT_ORDER_CODE_HAS_BEEN_REJECTED": "Repeat order code has been rejected!",
	"REPEAT_ORDER_CODE_IS_NOT_EXISTED": "Repeat order code isn\'t existed!",
	"REPEAT_ORDER_CODE_IS_DISABLED": "Repeat order code feature disabled!",
	"EXCEEDS_THE_MAXIMUM_PACKAGES_ALLOW": "Exceeds the maximum packages allow !",
	"REPEAT_ORDER_CODE_IS_NOT_MATCH": "Repeat order code isn\'t match!",
	"SGP_ERR_AMOUNT_TOP_UP_GREATER_THAN_MAXIMUM_AMOUNT": 'Amount need OTC must be less than maximum amount!',

	"ADDRESS_ADDED_SUCCESSFULLY": "Address added successfully",
	"ADDRESS_EDITED_SUCCESSFULLY": "Address book edited successfully",
	"ADDRESS_DELETED_SUCCESSFULLY": "Address deleted successfully",
	"TRANSFER_MONEY_SUCCESSFULLY": "Transfer money successfully",
	"TRUST_ASSET_SUCCESSFULLY": "Trust asset successfully",
	"WALLET_SAVED_SUCCESSFULLY": "Wallet saved successfully",
	"WALLET_CREATED_SUCCESSFULLY": "Wallet created successfully",
	"WALLET_UNBLOCKED_SUCCESSFULLY": "Wallet unblocked successfully",
	"TFA_LOGIN_REQUIRE_CHANGE_SUCCESSFULLY": "TFA require when login changed successfully",
	"PASSWORD_CHANGE_SUCCESSFULLY": "Pasword changed successfully",
	"GA_RESET_SUCCESSFULLY": "GA reset successfully",
	"GA_VERIFIED_SUCCESSFULLY": "GA verfied successfully",
	"LOCK_USER_SUCCESSFULLY": "Lock user successfully",
	"UNLOCK_USER_SUCCESSFULLY": "Unlock user successfully",
	"UNBLOCK_USER_WALLET_SUCCESSFULLY": "Unblock user wallet successfully",
	"USER_RESET_SUCCESSFULLY": "User reset successfully",
	"DATABASE_BACKED_UP_SUCCESSFULLY": "Database backed up successfully",
	"BACK_UP_FILE_DELETED_SUCCESSFULLY": "Back up file deleted successfully",
	"DATABASE_RESTORED_SUCCESSFULLY": "Database restored successfully",
	"NEWS_CREATED_SUCCESSFULLY": "News created successfully",
	"NEWS_EDITED_SUCCESSFULLY": "News edited successfully",
	"NEWS_DELETED_SUCCESSFULLY": "News deleted successfully",
	"RENAME_WALLET_SUCCESSFULLY": "Rename wallet successfully",
	"RESET_TFA_SUCCESSFULLY": "Reset TFA successfully",
	"EDIT_EMAIL_SUCCESSFULLY": "Edit email successfully",
	"SENT_CODE_SUCCESSFULLY" : "Sent code successfully",
	"RESET_PASSWORD_SUCCESSFULLY": "Reset password successfully",
	"USER_CREATED_SUCCESSFULLY": "User created successfully",
	"CHANGE_SETTING_SUCCESSFULLY": "Change setting successfully",
	"TRANSACTION_SUBMIT_FAILED": "Transaction submit failed",
	"INVALID_EMAIL": "Your email address is invalid. Please enter a valid address.",
	"CANT_ADD_YOUR_OTHER_WALLET_AS_SIGNER": "Cannot add your other wallet as a signer",
    "SIGN_SUCCESSFULLY": "Sign transaction successfully",
	"CHANGE_BACKGROUND_SUCCESSFULLY": "Change background successfully",
	"EZPAY_TOP_UP_SUCCESS_1": "Your transfer request was succeeded",
	"EZPAY_TOP_UP_SUCCESS_2": "You can track you request status at history",
	"CANCEL_REQUEST_CODE_SUCCESSFULLY": "Cancel request code successfully",

	"op_password": "OP Password",
	"change_op_password_": "Change OP password",
	"reset_op_password_": "Reset OP password",
	"private_key_input_": "Private key input",
	"OP_PASSWORD_REQUIRED": "OP Password is required",
	"NEW_OP_PASSWORD_REQUIRED": "New OP password is required",
	"CONFIRM_OP_PASSWORD_REQUIRED": "Confirm OP password is required",
	"NEW_OP_PASSWORD_MIN_LENGTH": "New OP password at least 6 characters",
	"OP_PASSWORD_NOT_MATCH": "OP Password and confirm OP password does not match",
	"OP_PASSWORD_CHANGE_SUCCESSFULLY": "OP Pasword changed successfully",
	"RESET_OP_PASSWORD_SUCCESSFULLY": "Reset OP Pasword successfully",
	"reset_op_password_attention": "Reset OP password need at least one correct private key. Other wallets will be block if you entered an incorrect private key. Do you want continue? ",
	"main_wallet_private_key_attention": "Please choose your main wallet address and enter it's private key to reset OP password now!",
	"unblock_wallet_attention": "Please enter correct private key with its wallet address in order to unblock your wallets.",
	"confirm_unblock_wallet_": "Confirm unblock wallet",
	"wallet_login": "WALLET Login",
	"OP_PASS_INVALID": "OP Password is invalid",
	"OP_PASS_IS_REQUIRED":  "OP Password is required",
	"INCORRECT_ALL_PRIVATE_KEY": "User need to enter at least one private key",
	"new_op_password": "New OP password",
	"confirm_op_password": "Confirm OP password",
	"UNBLOCK_WALLET_SUCCESS": "Unblock wallet success",
	"close": "Close",
	"select_wallet": "Select wallet",
	"skip": "Skip",
	"reset": "Reset",
	"attention": "Attention",
	"done": "Done",
	"CANNOT_SEND_TO_MERGED_WALLET": 'Cannot send to merged wallet!',
	"OP_PASS_MUST_CONTAIN_UPPERCASE_CHARACTER": 'The OP Password must contain at least an uppercase character',
	"OP_PASS_MUST_CONTAIN_LOWERCASE_CHARACTER": 'The OP Password must contain at least an lowercase character',
	"OP_PASS_MUST_CONTAIN_DIGITS": 'The OP Password must contain at least a digits',
	"OP_PASS_MUST_CONTAIN_SPECIAL_CHARACTER": 'OP Password must  contain at least a special characters',
	"OP_PASS_EXIST": 'You set OP Password already',
	"OP_PASS_DIFFERENT": 'Password and OP Password must different',
	'setting_op_password_': "Setting OP Password",
	"set_up": "Set up",
	"setting_op_password_intro": "OP pass to increase the security of the system. OP pass will be used to authenticate transactions. From now, every transaction will need OP pass to be executed. Please setting OP pass below.",
	"SETTING_OP_PASSWORD_SUCCESSFULLY": 'Setting OP password successfully',
	"send_wallet_info": "Send user wallet info",
	"confirm_send_user_wallet_information": "Do you want to send email to user with their wallet information ?",
	"ADMIN_SEND_USER_WALLET_INFORMATION_SUCCESS": "Send email for user wallet information successfully!",
	"OP_PASS_ONLY_CONTAIN_LATIN_CHARACTER": "The OP Password only contains Latin characters!",
	"transfer_to_card": "Transfer to card",
	"get_agt": "Get AGT",
	"request_amount": "Request Amount",
	"current_available_transfer": "Current available transfer (TCT)",
	"need_otc_to_tct": "Need OTC to TCT (AGT)",
	"otc_rate": "OTC Rate",
	"otc_fee": "OTC Fee",
	"total_otc_fee": "Total OTC Fee",
	"total_transfer": "Total transfer",
	"transfer_fee": "Transfer Fee (TCT)",
	"available_agt_amount": "Available AGT Amount",
	"available_tct": "Available TCT",
	"available_tct_amount": "Available TCT Amount",
	"in_order": "In Order",
	"ezpay_note": "Note: This request will occurring normally following the step as below:",
	"note_1": "1/ Auto convert AGT to TCT using OTC in SGP System",
	"note_2": "2/ Tranfer TCT in Ezpay Card",
	"type_here": "Type here...",
	"you_are_not_enough_condition_for_top_up": "You are not enough condition for top up!",
	"request": "Request",
	"ezpay_transfer": "Ezpay Transfer",
	"pending": "Pending",
	"approved": "Approved",
	"reject": "Reject",
	"success_": "Success",
	"otc_amount": "OTC Amount",
	"fee_otc": "Fee OTC",
	"total_topup_amount": "Total Topup Amount",
	"top_up_fee": "Topup Fee",
	"not_allow_otc": "You can't OTC now, so you can only top up using available TCT amount!",
	"EMAIL_OR_USERCODE_INVALID": "Email or usercode is invalid!",
	"email_or_usercode": "Email or Usercode",
	"usercode": "User code",
	"list_card_is_empty": "List cards from %s is empty!",
	"international": "International",
	"chinese": "Chinese",
	"new": "NEW",
	"used": "USED",
	"rejected": "REJECTED",
	"product_code": "Product Key",
	"user_code": "UserCode",
	"region": "Region",
	"product_code_management": "Product Key Management",
	"search_code": "Search code...",
	"available_agt": "Available AGT :",
	"purchased_agt": "Purchased AGT :",
	"rejected_agt": "Rejected AGT :",
	"confirm_reject_product_code": "Confirm reject product key",
	"enable_product_code": "Enable product key",
	"submit": "Submit",
	"CANNOT_GET_LIST_TITLE_OF_TRIPLE_SYSTEM_ERROR":  "Can't get list titles of Triple System!",
	"PRODUCT_CODE_HAS_BEEN_USED":  "Product key has been used!",
	"PRODUCT_CODE_IS_NOT_MATCH": "Product key isn't match!",
	"PRODUCT_CODE_HAS_BEEN_REJECTED": "Product key has been rejected!",
	"AMOUNT_IS_NOT_MATCH": "Amount isn't match!",
	"TITLE_NOT_EXIST": "Title isn't exist!",
	"PRODUCT_CODE_IS_NOT_EXISTED": "Product key isn't existed!",
	"WRONG_DATA_WHEN_REJECT_PRODUCT_CODE": "Wrong data when reject this product key!",
	"REJECT_PRODUCT_CODE_SUCCESSFULLY": "Reject product key successfully!",
	"CREATE_PRODUCT_CODE_SUCCESSFULLY": "Create product key successfully!",
	"already_used": "Already used",
	"purchased_key": "Purchased Key",
	"create_new_code": "Create new code",
	"choose_type_code": "Choose type code",
	"purchase_a_product_key": "Purchase a product key",
	"wallet_amount": "Wallet amount : ",
	"type_code": "Type code",
	"COPIED": "Copied",
	"no_have_wallet_valid": "Don't have valid wallets!",
	"time_of_statistics": "Time of statistics :",
	"request_product_code_info": "Request create product key information",
	"select_asset_for_create_product_code": "Choose asset do you want pay for create product key:",
	"notify": "Notify",
	"notify_created_product_code": "You have created successfully product key with the information below:",
	"remaining_time_code": "%sd %sh:%sm:%ss",
	"remaining_time": "Remaining time",
	"CREATE_REQUEST_PRODUCT_CODE_SUCCESSFULLY": "Create request product code successfully!",
	"PRODUCT_CODE_IS_DISABLED": "Product code feature disabled!",
	"TRIPLE_PAYMENT_ERROR": "Triple Payment error!",
	"REQUEST_PRODUCT_CODE_IS_NOT_EXISTED": "Request product code isn't existed!",
	"INVALID_CALLBACK_MAC_AUTHORIZATION": "Invalid Callback Mac Authorization!",
	"REQUEST_PRODUCT_CODE_STATUS_ERROR": "Request Product code status is wrong!",
	"MAX_REQUEST_CODE_OF_USER": "Only a maximum of 5 requests create product code are allowed in the new status" ,
	"CANNOT_CANCEL_THIS_REQUEST_CODE": "Can't cancel this request!",
	"used_by": "Used By",
	"repeat_order_code": "Repeat order code",
	"register_code": "Register code",
	"type": "Type",
	"unit_price": "Unit price",
	"fee": "Fee",
	"number_of_shipping": "Number of shipping",
	"repeat_order_code_management": "Repeat order code management",
	"max_amount_package_error": "You can only buy up to %s packages!",
	"min_amount_package_error": "Number of package is invalid!",
	"number_or_package": "Number of packages",
	"enable_repeat_order_code": "Enable repeat order code",
	"enter_number_of_packages": "Enter number of packages",
	"CREATE_REPEAT_ORDER_CODE_SUCCESSFULLY": "Create repeat order code successfully!",
	"CREATE_REQUEST_REPEAT_ORDER_CODE_SUCCESSFULLY": "Create request repeat order code successfully!"
}
