import React, {Component} from "react";
import {formatDateTimeFull, bitwiseAnd} from "../../05-utils/commonUtils";
import './index.css';

export default class TransactionDetail extends Component {
    render() {
        const { operate, sourceAddress, createdOn, t } = this.props;
        switch(operate.type) {
            case 'setOptions':
                return (
                    <React.Fragment>
                        <div className="charge-trust col-md-2 padding-0">
                            <span className="fa fa-wrench"/>
                            <div><label>{t('set_options')}</label></div>
                        </div>
                        <div className="col-md-10 text-left info padding-0">
                            { operate.signer &&
                                <React.Fragment>
                                    <div className='mb-2 text-font'><b>{t('signer_address')} :</b> {operate.signer.ed25519PublicKey}</div>
                                    <div className='mb-2 text-font'><b>{t('weight_')} :</b> {operate.signer.weight}</div>
                                </React.Fragment>
                            }
                            { operate.masterWeight !== undefined && <div className='mb-2 text-font'><b>{t('master_weight')} :</b> {operate.masterWeight}</div>
                            }
                            { operate.lowThreshold !== undefined  && <div className='mb-2 text-font'><b>{t('allower_trust')} :</b> {operate.lowThreshold}</div>
                            }
                            { operate.medThreshold !== undefined  && <div className='mb-2 text-font'><b>{t('payment_trust')} :</b> {operate.medThreshold}</div>
                            }
                            { operate.highThreshold !== undefined && <div className='mb-2 text-font'><b>{t('admin_')} :</b> {operate.highThreshold}</div>
                            }
                            { operate.setFlags &&
                            <React.Fragment>
                                <div className='mb-2 text-font'><b>{t('property_')} :</b> {bitwiseAnd(operate.setFlags, 1) === 0 ? t('AUTHORIZATION_NONE') : t('AUTHORIZATION_REQUIRED')}</div>
                            </React.Fragment>
                            }
                            <div><b>{t('time')} :</b> {formatDateTimeFull(createdOn)}</div>
                        </div>
                    </React.Fragment>
                );
            case 'changeTrust':
                return (
                    <React.Fragment>
                        <div className="charge-trust col-md-2 padding-0">
                            <span className="fa fa-handshake-o"/>
                            <div><label>{t('charge_trust')}</label></div>
                        </div>
                        <div className="col-md-10 text-left info padding-0">
                            <div className='mb-2 text-font'><b>{t('wallet')} :</b> {operate.source || sourceAddress}</div>
                            <div className='mb-2 text-font'><b>{t('action_')} :</b> {operate.limit === 0 ? "Untrust" : "Trust"}</div>
                            <div className='mb-2 text-font'><b>{t('asset_')} :</b> <span style={{color: "green"}}>{operate.line.issuer ? operate.line.code : 'RIA'}</span></div>
                            {operate.line.issuer && <div className='mb-2 text-font'><b>{t('Issuer')} :</b> {operate.line.issuer}</div>}
                            <div><b>{t('time')} :</b> {formatDateTimeFull(createdOn)}</div>
                        </div>
                    </React.Fragment>
                );
            case 'createAccount':
                return (
                    <React.Fragment>
                        <div className="create-wallet col-md-2 padding-0">
                            <span className="fa fa-user-circle"/>
                            <div><label>{t('create_wallet')}</label></div>
                        </div>
                        <div className="col-md-10 text-left info padding-0">
                            <div className='mb-2 text-font'><b>{t('funder_wallet')} :</b> {operate.source || sourceAddress}</div>
                            <div className='mb-2 text-font'><b>{t('new_wallet')} :</b> {operate.destination}</div>
                            <div className='mb-2 text-font'><b>{t('amount')} :</b> <span style={{color: "red"}}>{`${operate.startingBalance} RIA`}</span></div>
                            <div><b>{t('time')} :</b> {formatDateTimeFull(createdOn)}</div>
                        </div>
                    </React.Fragment>
                );
            case 'payment':
                return (
                    <React.Fragment>
                        <div className="payment col-md-2 padding-0">
                            <span className="fa fa-money"/>
                            <div><label>{t('payment')}</label></div>
                        </div>
                        <div className="col-md-10 text-left info padding-0">
                            <div className='mb-2 text-font'><b>{t('sender_wallet')} :</b> {operate.source || sourceAddress}</div>
                            <div className='mb-2 text-font'><b>{t('receiver_wallet')} :</b> {operate.destination}</div>
                            <div className='mb-2 text-font'><b>{t('amount')} :</b> <span style={{color: "green"}}>{`${operate.amount} ${operate.asset.issuer ? operate.asset.code : 'RIA'}`}</span></div>
                            <div><b>{t('time')} :</b> {formatDateTimeFull(createdOn)}</div>
                        </div>
                    </React.Fragment>
                );
            case 'allowTrust':
                return (
                    <React.Fragment>
                        <div className="payment col-md-2 padding-0">
                            <span className="fa fa-money"/>
                            <div><label>{t('payment')}</label></div>
                        </div>
                        <div className="col-md-10 text-left info padding-0">
                            <div className='mb-2 text-font'><b>{t('wallet')} :</b> {operate.trustor}</div>
                            <div className='mb-2 text-font'><b>{t('asset_')} :</b> {operate.asset.issuer ? operate.asset.code : 'RIA'}</div>
                            <div className='mb-2 text-font'><b>{t('asset_')} :</b> {operate.authorize ? 'Allow trust' : 'Reject trust'}</div>
                            <div><b>{t('time')} :</b> {formatDateTimeFull(createdOn)}</div>
                        </div>
                    </React.Fragment>
                );
            case 'accountMerge':
                return (
                    <React.Fragment>
                        <div className="merge-wallet col-md-2">
                            <span className="fa fa-compress" />
                            <div><label>{t('merge_wallet')}</label></div>
                        </div>
                        <div className="col-md-10 text-left info">
                            <div className='mb-2 text-font'><b>{t('wallet')} :</b> {operate.account || sourceAddress}</div>
                            <div className='mb-2 text-font'><b>{t('destination_wallet')} :</b> {operate.into}</div>
                            <div><b>{t('time')} :</b> {formatDateTimeFull(createdOn)}</div>
                        </div>
                    </React.Fragment>
                )
            default:
                return (
                    <React.Fragment>
                        <div className="merge-wallet col-md-2 padding-0">
                            <span className="fa fa-compress"/>
                            <div><label>{operate.type}</label></div>
                        </div>
                        <div className="col-md-10 text-left info">
                            <div><b>{t('time')} :</b> {formatDateTimeFull(createdOn)}</div>
                        </div>
                    </React.Fragment>
                )
        }
    }
}