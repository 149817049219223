import React, { Component } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { I18n } from 'react-i18next';
import './chooseWallet.css';

class ChooseWallet extends Component {
	onWalletSelected = wallet => {
		const { chooseWallet, toggle } = this.props;
		chooseWallet(wallet);
		toggle();
	};
	
	render() {
		const { isOpen, toggle, wallets } = this.props;
		return (
			<I18n ns="translations">
				{
					t => (
						<Modal isOpen={isOpen} toggle={toggle} className="modal-lg">
							<ModalHeader toggle={toggle}>
								<span>{t('wallet_selection')}</span>
							</ModalHeader>
							<div className="table-responsive my-wallets">
								<table className="table margin-none">
									<tbody>
									{
										wallets.map((wallet, index) => (
											<tr onClick={() => this.onWalletSelected(wallet)} key={index}>
												<td>
													{`${wallet.name} - ${wallet.address}`}
												</td>
											</tr>
										))
									}
									</tbody>
								</table>
							</div>
						</Modal>
					)
				}
			</I18n>
		);
	}
}

export default ChooseWallet;
