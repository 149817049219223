import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-i18next';
import { t } from "i18next";
import Paginate from "react-paginate";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { pushNotify } from '../../../../03-components/Notification';
import {
    getUserProductCodes
} from '../../../../01-actions/productCode';
import { formatDateTimeFull } from '../../../../05-utils/commonUtils';
import { constants } from '../../../../05-utils/commonData';
import './registerCodeList.css';
import Loading from '../../../../03-components/Loading';

class RegisterCodeList extends Component {
    componentDidMount() {
        const {
            getUserProductCodes,
        } = this.props.actions;
        getUserProductCodes({});
    }

    onPageChange = data => this.props.actions.getUserProductCodes({
        numPage: data.selected,
    });

    renderStatus = (code) => {
        switch (Number(code.status)) {
            case constants.PRODUCT_CODE_STATUS.NEW:
                return <span className="badge badge-primary">{t('new')}</span>;
            case constants.PRODUCT_CODE_STATUS.USED:
                return <span className="badge badge-success">{t('already_used')}</span>;
            case constants.PRODUCT_CODE_STATUS.REJECTED:
                return <span className="badge badge-danger">{t('rejected')}</span>;
            default:
                return <span className="badge badge-primary">{t('new')}</span>
        }
    };
    render() {
        const {
            productCodes,
            totalProductCode,
            isFetchingProductCode,
        } = this.props;

        return (
            <I18n ns="translations">
                {
                    t => (
                        <div className='col-lg-12'>
                            <h4 className='card-title'>{t('purchased_key')}</h4>
                            <div className='table-responsive list-purchased-key mt-3'>
                                {
                                    isFetchingProductCode ?
                                        <Loading parent1Class="text-center" />
                                        :
                                        <table className='table table-hover wrapper-table-code'>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        {t('created_at')}
                                                    </th>
                                                    <th>
                                                        {t('product_code')}
                                                    </th>
                                                    <th>
                                                        {t('status')}
                                                    </th>
                                                    <th>
                                                        {t('user_code')}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!!productCodes.length ? productCodes.map((productCode, index) =>
                                                    <tr className='item-purchased-key' key={index}>
                                                        <td className='wrapper-btn-copied'>
                                                            {formatDateTimeFull(productCode.createdAt)}
                                                        </td>
                                                        <td className='text-primary'>
                                                            {productCode.code}
                                                            <CopyToClipboard text={productCode.code} onCopy={() => pushNotify('success', t('copied'))}>
                                                                <button className="btn btn-link">
                                                                    <i className="far fa-copy" />
                                                                </button>
                                                            </CopyToClipboard>
                                                        </td>
                                                        <td>{this.renderStatus(productCode)}</td>
                                                        <td>{productCode.usedBy}</td>
                                                    </tr>
                                                )
                                                : (<tr className="text-center">
                                                    <td colSpan={7}>
                                                        <div>{t('no_data_to_show')}</div>
                                                    </td>
                                                </tr>)
                                                }
                                            </tbody>
                                        </table>
                                }
                            </div>
                            {
                                productCodes.length ?
                                    (<div className="paginate-wrapper text-center margin-top-15px">
                                        <Paginate
                                            pageCount={totalProductCode / 10}
                                            pageRangeDisplayed={3}
                                            marginPagesDisplayed={3}
                                            previousLabel="<"
                                            nextLabel=">"
                                            breakLabel="..."
                                            onPageChange={this.onPageChange}
                                            disabledClassName="paginate-disabled"
                                        />
                                    </div>) : null
                            }
                        </div>
                    )
                }
            </I18n>
        );
    }
}

const mapStateToProps = store => {
    return {
        productCodes: store.productCode.productCodes,
        listTitle: store.productCode.listTitle,
        totalProductCode: store.productCode.totalProductCode,
        isFetchingProductCode: store.productCode.isFetchingProductCode,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(
            {
                getUserProductCodes,
            },
            dispatch
        )
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterCodeList);