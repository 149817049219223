import { callApi } from "../05-utils/commonUtils";
import { updateStatus } from "./utils";

const ACTIONS = {
	GET_HISTORY_TRANSACTION_PROGRESS: "GET_HISTORY_TRANSACTION_PROGRESS",
	GET_HISTORY_TRANSACTION_SUCCESS: "GET_HISTORY_TRANSACTION_SUCCESS",
	GET_HISTORY_TRANSACTION_FAILED: "GET_HISTORY_TRANSACTION_FAILED",
	TRANSFER_MONEY_PROGRESS: "TRANSFER_MONEY_PROGRESS",
	TRANSFER_MONEY_SUCCESS: "TRANSFER_MONEY_SUCCESS",
	TRANSFER_MONEY_FAILED: "TRANSFER_MONEY_FAILED",
	RESET_TRANSFER_STATUS: "RESET_TRANSFER_STATUS",
	PAGING_TRANSACTION_HISTORY: "PAGING_TRANSACTION_HISTORY",
    GET_TRANSACTION_FAIL_HISTORY_PROGRESS: "GET_TRANSACTION_FAIL_HISTORY_PROGRESS",
    GET_TRANSACTION_FAIL_HISTORY_SUCCESS: "GET_TRANSACTION_FAIL_HISTORY_SUCCESS",
	GET_TRANSACTION_FAIL_HISTORY_FAILED: "GET_TRANSACTION_FAIL_HISTORY_FAILED",
	PAGING_TRANSACTION_FAIL_HISTORY: "PAGING_TRANSACTION_FAIL_HISTORY",
    SIGN_SUBMIT_PROGRESS: "SIGN_SUBMIT_PROGRESS",
    SIGN_SUBMIT_SUCCESS: "SIGN_SUBMIT_SUCCESS",
    SIGN_SUBMIT_FAILED: "SIGN_SUBMIT_FAILED",
	RESET_HISTORY_PAGE_COUNT: "RESET_HISTORY_PAGE_COUNT",
	RESET_SIGNED_TRANSACTION_STATUS: "RESET_SIGNED_TRANSACTION_STATUS",
	RESET_TRANSACTION_FAILED_PAGE: "RESET_TRANSACTION_FAILED_PAGE",
};

const getHistoryTransaction = payload => {
	const data = {
		name: "get_operations_of_wallet",
		params: {
			address: payload.address,
			cursor: payload.cursor,
			order: payload.order,
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.GET_HISTORY_TRANSACTION_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success){
					dispatch(updateStatus(ACTIONS.GET_HISTORY_TRANSACTION_SUCCESS, { transactionsHistory: response.result }));
				}
				else {
					dispatch(updateStatus(ACTIONS.GET_HISTORY_TRANSACTION_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("getHistoryTransaction", err);
				dispatch(updateStatus(ACTIONS.GET_HISTORY_TRANSACTION_FAILED, {error: err}));
			});
	};
};

const transferMoney = payload => {
	const data = {
		name: "transfer_money",
		params: {
			sourceAddress: payload.sourceAddress,
			amount: payload.amount,
			asset: payload.asset,
			destination: payload.destination,
			memo: payload.memo,
			code: payload.code,
			opPass: payload.opPass,
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.TRANSFER_MONEY_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.TRANSFER_MONEY_SUCCESS, {}));
				else {
					dispatch(updateStatus(ACTIONS.TRANSFER_MONEY_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("transferMoney", err);
				dispatch(updateStatus(ACTIONS.TRANSFER_MONEY_FAILED, {error: err}));
			});
	};
};

const getTransactionsFailHistory = payload => {
	const data = {
		name: "get_transactions_fail_history",
		params: {
			address: payload.address,
			numPage: payload.numPage,
			limit: payload.limit,
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.GET_TRANSACTION_FAIL_HISTORY_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success) {
					const { transactionsFail, limit, pageCount } = response.result;
					dispatch(updateStatus(ACTIONS.GET_TRANSACTION_FAIL_HISTORY_SUCCESS, { transactionsFail, limit, pageCount }));
				}
				else {
					dispatch(updateStatus(ACTIONS.GET_TRANSACTION_FAIL_HISTORY_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("getTransactionsFailHistory", err);
				dispatch(updateStatus(ACTIONS.GET_TRANSACTION_FAIL_HISTORY_FAILED, {error: err}));
			});
	};
};

const pagingTransactionHistory = (transactionHistory, page = 0, limit = 10) => {
	const displayTransactionsHistory = transactionHistory.slice(page * limit, (page + 1) * limit);
	return dispatch => dispatch(updateStatus(ACTIONS.PAGING_TRANSACTION_HISTORY, { displayTransactionsHistory, limit }));
};

const pagingTransactionFailHistory = (transactionFailHistory, page = 0, limit = 10) => {
	const displayTransactionFail = transactionFailHistory.slice(page * limit, (page + 1) * limit);
	return dispatch => dispatch(updateStatus(ACTIONS.PAGING_TRANSACTION_FAIL_HISTORY, { displayTransactionFail, limit }));
};

const resetTransferStatus = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_TRANSFER_STATUS, {}));

const resetTransactionHistoryStore = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_HISTORY_PAGE_COUNT, {}));

const resetSignedTransactionStatus = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_SIGNED_TRANSACTION_STATUS, {}));

const resetTransactionFailedPage = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_TRANSACTION_FAILED_PAGE, {}));

export {
	ACTIONS,
	getHistoryTransaction,
	transferMoney,
	resetTransferStatus,
	pagingTransactionHistory,
	resetTransactionHistoryStore,
	resetSignedTransactionStatus,
	getTransactionsFailHistory,
	resetTransactionFailedPage,
	pagingTransactionFailHistory,
};