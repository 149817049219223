import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { I18n } from 'react-i18next';
import i18n from 'i18next';

import {
	saveWallet,
	createWallet,
	resetTfaCode,
} from '../../../../../01-actions/wallet';
import validate from './validate';
import TFAModal from '../../../TfaCode/tfaCode';
import './createWallet.css';
import SubmitButton from '../../../../../03-components/ModalSubmitButton';
import { pushNotify } from '../../../../../03-components/Notification';
import OPPassModal from "../../../../../03-components/OPPassModal/OPPassModal";

class CreateWallet extends Component {
	state = {
		isOpenTFA: false,
		isOpenOPPass: false,
		isFundingWallet: false,
		fundingWalletSelected: JSON.stringify(this.props.wallets && this.props.wallets[0]),
		name: '',
		startingBalance: '20',
		opPass: ''
	};
	
	componentWillReceiveProps(nextProps) {
		if (nextProps.walletSaved) {
			this.refreshInputAndToggle();
		}
		if (nextProps.walletCreated) {
			this.refreshInputAndToggle();
		}
	}
	
	renderField = ({ input, label, type, fieldValue, disabled, meta: { touched, error } }) => (
		<div className="form-group">
			<label>{label}</label>
			<div className="input-group">
				<input
					{...input}
					type={type}
					className="form-control create-wallet-input"
					disabled={disabled}
					value={fieldValue}
					onChange={e => this.setState({ [input.name]: e.target.value })}
				/>
				{touched && error && <p className="text-danger">{`* ${error}`}</p>}
			</div>
		</div>
	);
	
	renderCheckbox = ({ input, fieldValue }) => (
		<label>
			<input
				{...input}
				type="checkbox"
				value={fieldValue}
				onChange={this.onCheckboxChange}
			/>
			<span> Funding wallet</span>
		</label>
	);

	toggleTFAModal = () => {
		this.setState({ isOpenTFA: !this.state.isOpenTFA });
	};

	toggleOPPassModal = () => {
		this.setState({ isOpenOPPass: !this.state.isOpenOPPass });
	};

	onCheckboxChange = () => {
		this.setState({ isFundingWallet: !this.state.isFundingWallet });
	};

	onFundingWalletSelected = e => {
		this.setState({ fundingWalletSelected: e.target.value });
	};

	onReceiveTFACode = code => {
		this.setState({ isOpenTFA: false });

		this.props.actions.saveWallet({
			name: this.state.name.trim(),
			secretKey: this.props.keyPair.secretKey,
			opPass: this.state.opPass,
			code
		});
	};

	onReceiveOPPass = opPass => {

		const { userInfo } = this.props;
		this.setState({
			isOpenOPPass: false,
			opPass,
			isOpenTFA: !!(userInfo && userInfo.tfaEnabled)
		});
		if (userInfo && !userInfo.tfaEnabled) {
			this.props.actions.saveWallet({
				name: this.state.name.trim(),
				secretKey: this.props.keyPair.secretKey,
				opPass
			});
		}
	};

	refreshInputAndToggle = () => {
		this.setState({ name: '', startingBalance: '20', isFundingWallet: false });
		this.props.toggle();
	};
	
	render() {
		const {
			// isFundingWallet,
			// fundingWalletSelected,
			name,
			// startingBalance,
		} = this.state;
		const {
			isOpen,
			// wallets,
			keyPair,
			// keyPairInitializing,
			walletSaving,
			// walletCreating,
			// invalid,
		} = this.props;
		return (
			<I18n ns="translations">
				{
					t => (
						<Modal isOpen={isOpen} className="modal-lg">
							<ModalHeader toggle={this.refreshInputAndToggle}>{t('create_wallet')}</ModalHeader>
							<ModalBody>
								<div className="create-wallet-attention">
									<p>{t('attention_')}</p>
									<p>{t('create_wallet_attention')}</p>
								</div>
								<form className="margin-top-1rem">
									<Field
										name="walletAddress"
										type="text"
										component={this.renderField}
										label={t('wallet_address')}
										disabled={true}
										fieldValue={keyPair && keyPair.publicKey}
									/>
									<label>{t('wallet_secret_key')}</label>
									<div className="input-group margin-bottom-1rem">
										<input className="form-control secret-key-input" disabled
											   defaultValue={keyPair && keyPair.secretKey}/>
										<span className="input-group-btn">
											<CopyToClipboard
												text={keyPair && keyPair.secretKey}
												onCopy={() => pushNotify('success', i18n.t('copied'))}
											>
												<button className="wallet-address-copy btn btn-light" type="button">
													<span aria-hidden="false">{t('copy')}</span>
												</button>
											</CopyToClipboard>
										</span>
									</div>
									<Field
										name="name"
										type="text"
										component={this.renderField}
										label={t('wallet_name_optional')}
										fieldValue={name}
									/>
									{/*<Field*/}
									{/*name="fundingCheckbox"*/}
									{/*component={this.renderCheckbox}*/}
									{/*fieldValue={isFundingWallet}*/}
									{/*/>*/}
									{/*<div className="form-group">*/}
									{/*<select*/}
									{/*className="form-control cp-gbt-select"*/}
									{/*disabled={!isFundingWallet}*/}
									{/*value={fundingWalletSelected}*/}
									{/*onChange={this.onFundingWalletSelected}*/}
									{/*>*/}
									{/*{*/}
									{/*wallets && wallets.map((wallet, index) => <option key={index} value={JSON.stringify(wallet)}>{wallet.name}</option>)*/}
									{/*}*/}
									{/*</select>*/}
									{/*</div>*/}
									{/*<Field*/}
									{/*name="startingBalance"*/}
									{/*type="text"*/}
									{/*component={this.renderField}*/}
									{/*label="Starting balance (Minimum starting balance is 20 RIA)"*/}
									{/*disabled={!isFundingWallet}*/}
									{/*fieldValue={startingBalance}*/}
									{/*/>*/}
								</form>
							</ModalBody>
							<ModalFooter>
								<SubmitButton className="btn btn-info" onClick={this.toggleOPPassModal}
											  isSubmitting={walletSaving} text={t('save')}/>
								{/*<SubmitButton className="btn btn-primary" onClick={this.toggleTFAModal} isSubmitting={walletCreating} disabled={invalid || !isFundingWallet || keyPairInitializing} text="Create"/>*/}
								<TFAModal isOpen={this.state.isOpenTFA} toggle={this.toggleTFAModal}
										  onSubmit={this.onReceiveTFACode}/>
								<OPPassModal isOpen={this.state.isOpenOPPass} toggle={this.toggleOPPassModal}
										  onSubmit={this.onReceiveOPPass}/>
							</ModalFooter>
						</Modal>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		tfaCode: store.wallet.tfaCode,
		keyPair: store.wallet.keyPair,
		keyPairInitializing: store.wallet.keyPairInitializing,
		walletSaving: store.wallet.walletSaving,
		walletSaved: store.wallet.walletSaved,
		walletCreating: store.wallet.walletCreating,
		walletCreated: store.wallet.walletCreated,
		userInfo: store.user.userInfo,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				saveWallet,
				createWallet,
				resetTfaCode,
			},
			dispatch
		)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(reduxForm({
	form: 'createWallet',
	validate,
})(CreateWallet));
