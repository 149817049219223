import React, {Component} from 'react';

export default class Footer extends Component {
	render() {
		return (
			<div className="container-fluid footer-wrapper">
				{/*<p className="d-block text-center text-white">*/}
				{/*	<span>Copyright © 2019 </span>*/}
				{/*	<a href="" target="_blank" rel="noopener noreferrer">Triple</a>*/}
				{/*	<span>. All rights reserved.</span>*/}
				{/*</p>*/}
			</div>
		);
	}
}
