import React, { Component } from 'react';

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { I18n } from 'react-i18next';
import FetchingAmount from './fetchingAmount';
import TransferToCard from './transferToCard';
import SelectSystem from './selectSystem';
import SelectApplicationId from './selectApplicationId';
import EnterSgpPassword from './enterSgpPassword';
import EnterSgpTfa from './enterSgpTfa';
import TopUpSuccess from './topUpSuccess';
import { constants } from '../../../../../05-utils/commonData';
import {
	ACTIONS,
	getUserCodes,
	getSGPBalanceInfo,
	getOTCCurrencyInfo,
	getTopUpInfo,
	prepareForTopUp,
	loginSGP,
	otcAndTopUp,
	getListCardsTopUp
} from "../../../../../01-actions/topUp";
import { getTopUpStatus } from '../../../../../01-actions/system';

class EZPayContainer extends Component {
	state = {
		isOpenFetchingAmount: false,
		isOpenTransferToCard: false,
		isOpenSelectSystem: false,
		isOpenSelectApplicationId: false,
		isOpenEnterSgpPassword: false,
		isOpenEnterSgpTfa: false,
		isOpenTopUpSuccess: false,
		requestAmount: 0,
		systemSelected: 0,
		sgpExchangeUserId: null,
		currency: null,
		cardNumber: null,
		listCards: [],
	};

	componentDidMount() {
		const { getUserCodes, getTopUpStatus } = this.props.actions;
		getTopUpStatus();
		if (!this.props.usercode)
			getUserCodes();
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.usercode !== nextProps.usercode && nextProps.usercode) {
			nextProps.actions.getListCardsTopUp({ usercode: nextProps.usercode });
		}

		if (this.props.status !== nextProps.status && nextProps.status === ACTIONS.PREPARE_FOR_TOP_UP_SUCCESS) {
			this.toggleEnterSgpPassword();
		}
		if (this.props.status !== nextProps.status && nextProps.status === ACTIONS.LOGIN_SGP_SUCCESS) {
			this.toggleEnterSgpPassword();
			this.toggleEnterSgpTfa();
		}
		if (this.props.status !== nextProps.status && nextProps.status === ACTIONS.OTC_AND_TOP_UP_SUCCESS) {
			this.setState({ isOpenTransferToCard: false, isOpenEnterSgpTfa: false });
			this.toggleTopUpSuccess();
			this.props.actions.getSGPBalanceInfo({ sgpExchangeUserId: this.state.sgpExchangeUserId });
		}
	}

	toggleFetchingAmount = () => {
		const { actions } = this.props;
		const { sgpExchangeUserId } = this.state;
		actions.getSGPBalanceInfo({ sgpExchangeUserId });
		this.setState({ isOpenFetchingAmount: !this.state.isOpenFetchingAmount });
	};

	toggleTransferToCard = () => {
		this.props.actions.getOTCCurrencyInfo();
		this.setState({ isOpenTransferToCard: !this.state.isOpenTransferToCard });
	};

	toggleSelectSystem = () => {
		this.setState({ isOpenSelectSystem: !this.state.isOpenSelectSystem });
	};

	toggleSelectApplicationId = () => {
		this.setState({ isOpenSelectApplicationId: !this.state.isOpenSelectApplicationId });
	};

	toggleEnterSgpPassword = () => {
		this.setState({ isOpenEnterSgpPassword: !this.state.isOpenEnterSgpPassword });
	};

	toggleEnterSgpTfa = () => {
		this.setState({ isOpenEnterSgpTfa: !this.state.isOpenEnterSgpTfa });
	};

	toggleTopUpSuccess = () => {
		this.setState({ isOpenTopUpSuccess: !this.state.isOpenTopUpSuccess });
	};

	onConfirmFetchingAmount = () => {
		this.toggleTransferToCard();
		this.toggleFetchingAmount();
	};

	onSelectEZPaySystem = () => {
		const { listCardsOfEZPay } = this.props;
		this.toggleSelectSystem();
		this.setState({ systemSelected: constants.SYSTEM.EZPAY_CARD, listCards: listCardsOfEZPay });
		this.toggleSelectApplicationId();
	};

	onSelectAlliedCardSystem = () => {
		const { listCardsOfAlliedCard } = this.props;
		this.toggleSelectSystem();
		this.setState({ systemSelected: constants.SYSTEM.ALLIED_CARD, listCards: listCardsOfAlliedCard });
		this.toggleSelectApplicationId();
	};

	onConfirmApplicationId = () => {
		const { actions, usercode } = this.props;
		const { systemSelected, currency } = this.state;
		actions.getTopUpInfo({usercode, card_type: systemSelected, base_currency: currency});
		this.toggleSelectApplicationId();
		this.toggleFetchingAmount();
	};

	onBackToSelectSystem = () => {
		this.toggleSelectApplicationId();
		this.toggleSelectSystem();
	};

	onBackToSelectApplicationId = () => {
		this.toggleSelectApplicationId();
		this.toggleFetchingAmount();
	};

	onBackToFetchingAmount = () => {
		this.toggleTransferToCard();
		this.toggleFetchingAmount();
	};

	onConfirmTransferToCard = () => {
		this.toggleEnterSgpPassword();
	};

	onConfirmSgpPassword = (value) => {
		const { sgpExchangeUserId} = this.state;
		this.props.actions.loginSGP({ sgpExchangeUserId, password: value.sgpPassword});
	};

	onConfirmSgpTfa = (value) => {
		const { usercode, userTopUpInfoLogID, accessToken} = this.props;
		const { requestAmount, cardNumber, sgpExchangeUserId} = this.state;
		this.props.actions.otcAndTopUp({
			usercode,
			userTopUpInfoLogID,
			amount: requestAmount,
			access_token: accessToken,
			otp: value.tfaCode,
			cardNumber,
			sgpExchangeUserId
		});
	};

	prepareForTopUpAction = ({ amount }) => {
		const { cardNumber, sgpExchangeUserId} = this.state;
		this.setState({ requestAmount: Number(amount) });
		this.props.actions.prepareForTopUp({ usercode: this.props.usercode, amount, cardNumber, sgpExchangeUserId });
	};

	render() {
		const {
			isOpenTransferToCard,
			isOpenFetchingAmount,
			isOpenSelectSystem,
			isOpenSelectApplicationId,
			isOpenEnterSgpPassword,
			isOpenEnterSgpTfa,
			isOpenTopUpSuccess,
			listCards,
		} = this.state;
		const {
			usercode,
			isAllowTopUp,
			balanceAGT,
			balanceTCT,
			isFetchingSGPBalance,
			isLoginProgress,
			isTopUpProgress,
			listCardsOfAlliedCard,
			listCardsOfEZPay,
			isFetchingCards,
			topUpEnabled,
		} = this.props;
		return (
			<I18n ns="translations">
				{
					t => (
						<div>
							{topUpEnabled && usercode &&
								<button className="btn btn-send btn-fw" onClick={this.toggleSelectSystem} >
									<span>{t('transfer_to_card')}</span>
								</button>
							}
							<SelectSystem
								isOpen={isOpenSelectSystem}
								toggle={this.toggleSelectSystem}
								onPressEZpay={this.onSelectEZPaySystem}
								onPressAllied={this.onSelectAlliedCardSystem}
								isFetchingCards={isFetchingCards}
								listCardsOfEZPay={listCardsOfEZPay}
								listCardsOfAlliedCard={listCardsOfAlliedCard}
								onClose={this.toggleSelectSystem}
							/>
							<SelectApplicationId
								isOpen={isOpenSelectApplicationId}
								toggle={this.toggleSelectApplicationId}
								onPressBack={this.onBackToSelectSystem}
								onPressNext={() => {
									this.onConfirmApplicationId()
								}}
								onChangeCard={(value)=> {
									this.setState({
										currency: value.choosedCard.currency,
										sgpExchangeUserId: value.choosedCard.exchangeUserCode,
										cardNumber: value.choosedCard.cardNumber
									})
								}}
								data={listCards}
								isFetchingCards={isFetchingCards}
								systemSelected={this.state.systemSelected}
							/>
							<FetchingAmount
								isOpen={isOpenFetchingAmount}
								toggle={this.toggleFetchingAmount}
								onSubmit={this.onConfirmFetchingAmount}
								isAllowTopUp={isAllowTopUp}
								sgpBalance={{balanceAGT, balanceTCT}}
								isFetchingSGPBalance={isFetchingSGPBalance}
								onClose={this.toggleFetchingAmount}
								onPressBack={this.onBackToSelectApplicationId}
							/>
							<TransferToCard
								isOpen={isOpenTransferToCard}
								toggle={this.toggleTransferToCard}
								onSubmit={this.onConfirmTransferToCard}
								onPressBack={this.onBackToFetchingAmount}
								onPressRequest={this.prepareForTopUpAction}
							/>
							<EnterSgpPassword
								isOpen={isOpenEnterSgpPassword}
								toggle={this.toggleEnterSgpPassword}
								onConfirmSgpPassword={(value) => this.onConfirmSgpPassword(value)}
								isSubmitting={isLoginProgress}
							/>
							<EnterSgpTfa
								isOpen={isOpenEnterSgpTfa}
								toggle={this.toggleEnterSgpTfa}
								onSubmit={(value) => this.onConfirmSgpTfa(value)}
								isSubmitting={isTopUpProgress}
							/>
							<TopUpSuccess
								isOpen={isOpenTopUpSuccess}
								toggle={this.toggleTopUpSuccess}
							/>
						</div>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		usercode: store.topUp.usercode,
		status: store.topUp.status,
		userTopUpInfoLogID: store.topUp.userTopUpInfoLogID,
		accessToken: store.topUp.accessToken,
		isAllowTopUp: store.topUp.isAllowTopUp,
		balanceAGT: store.topUp.balanceAGT,
		balanceTCT: store.topUp.balanceTCT,
		isFetchingSGPBalance: store.topUp.isFetchingSGPBalance,
		isTopUpProgress: store.topUp.isTopUpProgress,
		isLoginProgress: store.topUp.isLoginProgress,
		listCardsOfEZPay: store.topUp.listCardsOfEZPay,
		listCardsOfAlliedCard: store.topUp.listCardsOfAlliedCard,
		selectedSystem: store.topUp.selectedSystem,
		currency: store.topUp.currency,
		isFetchingCards: store.topUp.isFetchingCards,
		topUpEnabled: store.system.topUpEnabled,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions: bindActionCreators(
			{
				getTopUpInfo,
				getOTCCurrencyInfo,
				getUserCodes,
				getSGPBalanceInfo,
				prepareForTopUp,
				loginSGP,
				otcAndTopUp,
				getListCardsTopUp,
				getTopUpStatus,
			},
			dispatch
		)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EZPayContainer);