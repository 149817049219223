import React, {Component} from 'react';
import { I18n } from 'react-i18next';
import ManualBackup from './ManualBackup/manualBackup';
import RestoreDatabase from './RestoreDatabase/restoreDatabase';
import Tab from '../../../03-components/Tab';

export default class DatabaseBackup extends Component {
	state = {
		currentTab: 'manual_backup'
	};

	onTabClick = (e, tabName) => {
		e.preventDefault();
		this.setState({ currentTab: tabName });
	};

	render() {
		const { currentTab } = this.state;

		return (
			<I18n ns="translations">
				{
					t => (
						<div className="row">
							<div className="col-lg-12 grid-margin">
								<div className="card">
									<h4 className="card-title">{t('database_backup')}</h4>
									<ul className="nav nav-tabs tab-solid tab-solid-danger" role="tablist">
										<Tab
											tabName="manual_backup"
											currentTab={currentTab}
											onTabClick={this.onTabClick}
											text={t('manual_backup')}
										/>
										<Tab
											tabName="restore_database"
											currentTab={currentTab}
											onTabClick={this.onTabClick}
											text={t('restore_database')}
										/>
									</ul>
									{
										currentTab === 'manual_backup' ? <ManualBackup/> : <RestoreDatabase/>
									}
								</div>
							</div>
						</div>
					)
				}
			</I18n>
		);
	}
}