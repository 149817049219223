import React, {Component} from 'react';
import {Route, Switch, Redirect, withRouter} from 'react-router-dom';
import './App.css';
import HomePage from '../Home';
import LoginPage from '../Login/login';
import WalletPage from '../Wallet';
import AdminPage from '../Admin';
import ErrorPage from '../Error';
// import ForgotPasswordPage from '../ForgotPassword/forgotPassword';
import RegisterPage from '../RegisterPage';
import MaintenancePage from '../Maintenance/maintenance';
import QrUrlSchemePage from '../QrUrlScheme/qrUrlScheme';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from "redux";
import { isSuperAdmin } from "../../05-utils/commonUtils";
import { getMaintenanceModeStatus } from '../../01-actions/system';
import { isMaintenanceMode, getTokenErrors, getAllErrors } from '../../07-selectors';
import { logout } from '../../01-actions/auth';
import Notification, { pushNotify } from '../../03-components/Notification';
import { getErrorMessage } from "../../05-utils/commonUtils";
import { resetError } from "../../01-actions/utils";

class App extends Component {

	componentDidMount() {
		this.props.actions.getMaintenanceModeStatus();
	}

	componentWillReceiveProps(nextProps) {
		const { userInfo, isMaintenanceMode, location, tokenErrors, actions } = nextProps;
		if (isMaintenanceMode
			&& !location.pathname.includes('/login')
			&& !location.pathname.includes('/maintenance')
		) {
			if (userInfo && isSuperAdmin(userInfo)) {
				return;
			}
			// avoid error, wait to show toast message
			setTimeout(() => {
				nextProps.history.push('/maintenance');
			}, 300);
		}
		if (tokenErrors !== this.props.tokenErrors) {
			actions.logout();
		}
		if (nextProps.errorApi) {
			pushNotify('error', getErrorMessage(nextProps.errorApi));
			actions.resetError();
		}
	}

	render() {
		return (
			<div className="app">
				<Switch>
					<Route exact path="/" component={HomePage}/>
					<Route path="/login" component={LoginPage}/>
                    <Route path="/register" component={RegisterPage}/>
					{/* <Route path="/forgot-password" component={ForgotPasswordPage}/> */}
					<Route path="/wallet" component={WalletPage}/>
					<Route path="/admin" component={AdminPage}/>
					<Route path="/error" component={ErrorPage}/>
					<Route path="/maintenance" component={MaintenancePage}/>
					<Route path="/qr" component={QrUrlSchemePage}/>
					<Redirect to="/error/404"/>
				</Switch>
				<Notification/>
			</div>
		);
	}
}

const mapStateToProps = (store, props) => {
	return {
		isMaintenanceMode: isMaintenanceMode(store, props),
		tokenErrors: getTokenErrors(store, props),
		userInfo: store.user.userInfo,
		errorApi: getAllErrors(store, props),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				getMaintenanceModeStatus,
				resetError,
				logout,
			},
			dispatch
		)
	};
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(App);
