import React, { Component } from 'react';
import RegisterCodeList from '../RegisterCodeList/registerCodeList';
import RequestRegisterCodeList from '../RequestRegisterCodeList/requestRegisterCodeList';

class RegisterCodeTab extends Component {
    render() {
        return (
            <React.Fragment>
                <RegisterCodeList/>
                <RequestRegisterCodeList/>
            </React.Fragment>
        );
    }
}

export default RegisterCodeTab;