import React, {PureComponent} from "react";
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {I18n} from "react-i18next";
import {renameWallet, changeStatusRenameWallet} from "../../../../../01-actions/wallet";
import "./editNameWallet.css";
import SubmitButton from "../../../../../03-components/ModalSubmitButton";
import {pushNotify} from "../../../../../03-components/Notification";
import i18n from 'i18next';
import validate from './validate';

class EditNameWallet extends PureComponent {
    state = {
        nameWallet: this.props.wallet && this.props.wallet.name,
        checkDisable: false
    };

    componentWillReceiveProps(nextProps) {
        if(nextProps.wallet && nextProps.wallet !== this.props.wallet){
                this.setState({nameWallet: nextProps.wallet.name});
        }
        if (nextProps.walletRenamed && nextProps.walletRenamed !== this.props.walletRenamed) {
            nextProps.actions.changeStatusRenameWallet();

            pushNotify('success', i18n.t('RENAME_WALLET_SUCCESSFULLY'));
        }
    }

    renderField = ({input, label, type, fieldValue, disabled, meta: {touched, error}}) => (
        <div className="form-group">
            <label>{label}</label>
            <div className="input-group">
                <input
                    {...input}
                    type={type}
                    className="form-control create-wallet-input"
                    disabled={disabled}
                    value={fieldValue}
                    onChange={e => this.setState({[input.name]: e.target.value, checkDisable: true})}
                />
                {touched && error && <p className="text-danger">{`* ${error}`}</p>}
            </div>
        </div>
    );

    refreshInputAndToggle = () => {
        this.setState({nameWallet: this.props.wallet.name  })
        this.props.toggle();
    };

    renameWallet = () => {
        this.props.actions.renameWallet({
            newName: this.state.nameWallet.trim(),
            walletId: this.props.wallet && this.props.wallet._id
        });
        this.setState({checkDisable : false});
        this.props.toggle();
    };

    render() {
        const {isOpen, invalid} = this.props;
        return (
            <I18n ns="translations">
                {t => (
                    <Modal isOpen={isOpen} className="modal-lg">
                        <ModalHeader toggle={this.refreshInputAndToggle}>{t("rename_wallet")}</ModalHeader>
                        <ModalBody>
                            <form className="margin-top-1rem">
                                <Field
                                    name="nameWallet"
                                    type="text"
                                    component={this.renderField}
                                    label={t("wallet_address")}
                                    fieldValue={this.state.nameWallet}
                                />
                            </form>
                        </ModalBody>
                        <ModalFooter>
                            <SubmitButton
                                className="btn btn-info"
                                onClick={this.renameWallet}
                                text={t("save")}
                                disabled={invalid || !this.state.checkDisable || !this.state.nameWallet.trim()}
                            />
                        </ModalFooter>
                    </Modal>
                )}
            </I18n>
        );
    }
}

const mapStateToProps = store => {
    return {
        walletRenamed: store.wallet.walletRenamed,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(
            {
                renameWallet,
                changeStatusRenameWallet
            },
            dispatch
        )
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: "renameWallet",
        validate
    })(EditNameWallet)
);
