import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './02-reducers';
import { createLogger } from 'redux-logger'

const loggerMiddleware = createLogger({
	predicate: (getState, action) => {
		return process.env.REACT_APP_STAGE !== "PRO"
			&& process.env.REACT_APP_STAGE !== "STG"
			&& !action.type.startsWith("@@redux-form");
	}
});

const initialState = {};
const middlewares = [thunkMiddleware, loggerMiddleware];

export default () => {
	return createStore(
		rootReducer,
		initialState,
		applyMiddleware(...middlewares)
	);
}