import React, {Component} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { I18n } from 'react-i18next';
import SubmitButton from '../../../../../03-components/ModalSubmitButton/index';
// import './resetOPPassword.css';
import Steps from 'rc-steps';
import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import ResetOPPassword from "../ResetOPPassword/resetOPPassword";
import PrivateKeyInput from "../PrivateKeyInput/privateKeyInput";
import UnblockManyWallet from "../UnblockManyWallet/unblockManyWallet";
import ConfirmResetOPPass from "../ConfirmResetOPPass/confirmResetOPPass";
import { resetOPPassword } from "../../../../../01-actions/user";
import { getAllUserWallet } from '../../../../../01-actions/wallet';

class ResetOPPasswordStepByStep extends Component {
    state = {
        currentStep: 0,
        disabled: false
    };

    formData = {};

    steps = ['attention', 'new_op_password', 'private_key_input_', 'unblock_wallet'];

    refreshInputAndToggle = () => {
        if (this.state.currentStep === 3) {
            this.props.actions.getAllUserWallet();
        }
        this.formData = {};
        this.setState({
            currentStep: 0,
            disabled: false
        });
        this.props.toggle();
    };

    handleSubmit(e) {
        e.preventDefault();
        if (this.state.currentStep === 2 && this.formData.newPassword && this.formData.address && this.formData.privateKey) {
            this.props.actions.resetOPPassword({
                newPassword: this.formData.newPassword,
                wallets: [
                    {
                        address: this.formData.address,
                        privateKey: this.formData.privateKey
                    }
                ]
            });
        }
        let s = this.state.currentStep + 1;
        if (s === 4) {
            s = 0;
        }
        this.setState({
            currentStep: s,
            disabled: true
        });
    }

    onChangeField(field, value) {
        this.formData = { ...this.formData, [field]: value };
        if ((this.state.currentStep === 1 && (!this.formData.newPassword || !this.formData.confirmPassword))
             || (this.state.currentStep === 2 && (!this.formData.address || !this.formData.privateKey))
            || (this.props.userWallets.length && (!this.formData.newPassword || !this.formData.confirmPassword) ))
            this.setState({
                disabled: true
            });
        else
            this.setState({
                disabled: false
            })
    }

    onPressDone = () => {
        this.formData = {};
        this.setState({
            currentStep: 0,
            disabled: false
        });
        this.props.onSkip && this.props.onSkip()
        this.props.actions.getAllUserWallet();
    }

    render() {
        const {
            isOpen,
            userWallets
        } = this.props;

        const {
            currentStep,
            disabled
        } = this.state;

        return (
            <I18n ns="translations">
                {
                    t => (
                        <Modal isOpen={isOpen} className="modal-lg">
                            <ModalHeader toggle={this.refreshInputAndToggle}>{t('reset_op_password_')}</ModalHeader>
                            <ModalBody>
                                <form className="margin-top-1rem">
                                    {
                                        userWallets && userWallets.length ?
                                            <div>
                                                <Steps current={currentStep} labelPlacement="vertical" progressDot size="large">
                                                    { this.steps && this.steps.map((step, index) => (
                                                        <Steps.Step title={t(step)} key={index}/>
                                                    ))}
                                                </Steps>
                                                {
                                                    currentStep === 0 &&
                                                    <ConfirmResetOPPass />
                                                }
                                                {
                                                    currentStep === 1 &&
                                                    <ResetOPPassword onChangeField={this.onChangeField.bind(this)} />
                                                }
                                                {
                                                    currentStep === 2 &&
                                                    userWallets && userWallets.length &&
                                                    <PrivateKeyInput onChangeField={this.onChangeField.bind(this)} />
                                                }
                                                {
                                                    currentStep === 3 &&
                                                    <UnblockManyWallet
                                                        value={{ walletAddress: this.formData.address, newOPPasswordReset: this.formData.newPassword}}
                                                    />
                                                }
                                            </div>
                                        : <ResetOPPassword onChangeField={this.onChangeField.bind(this)} />
                                    }
                                </form>
                            </ModalBody>
                            <ModalFooter>
                                {
                                    userWallets && userWallets.length ?
                                        <div>
                                            {
                                                currentStep === 0 &&
                                                <SubmitButton
                                                    className="btn btn-danger"
                                                    onClick={() => {
                                                        this.formData = {};
                                                        this.setState({
                                                            currentStep: 0,
                                                            disabled: false
                                                        });
                                                        this.props.onSkip && this.props.onSkip()
                                                    }}
                                                    text={t('close')}
                                                />
                                            }
                                            {
                                                currentStep >= 3 ?
                                                    <SubmitButton
                                                        className="btn btn-success"
                                                        onClick={this.onPressDone}
                                                        text={t('done')}
                                                    />
                                                    : (currentStep === 2 ?
                                                        <SubmitButton
                                                            className="btn btn-primary"
                                                            onClick={this.handleSubmit.bind(this)}
                                                            text={t('reset')}
                                                            disabled={disabled }
                                                        />
                                                        : <SubmitButton
                                                            className="btn btn-primary margin-left-5px"
                                                            onClick={this.handleSubmit.bind(this)}
                                                            text={t('next')}
                                                            disabled={disabled }
                                                        />)
                                            }
                                        </div>
                                    : <SubmitButton
                                            className="btn btn-success"
                                            onClick={() => {
                                                this.props.actions.resetOPPassword({
                                                    newPassword: this.formData.newPassword
                                                });
                                                this.formData = {};
                                                this.setState({
                                                    currentStep: 0,
                                                    disabled: false
                                                });
                                                this.props.onSkip && this.props.onSkip();
                                            }}
                                            disabled={ disabled || (!(userWallets && userWallets.length) && (!this.formData.newPassword || !this.formData.confirmPassword))}
                                            text={t('done')}
                                        />
                                }
                            </ModalFooter>
                        </Modal>

                    )
                }
            </I18n>
        );
    }
}

const mapStateToProps = store => {
    return {
        userWallets: store.wallet.userWallets
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions : bindActionCreators(
            {
                resetOPPassword,
                getAllUserWallet,
            },
            dispatch
        )
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(reduxForm({
    form: 'resetOPPasswordStepByStep'
})(ResetOPPasswordStepByStep));
