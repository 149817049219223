import React, { PureComponent } from 'react';
import QrCode from 'qrcode.react';
import { formatDateTimeFull } from '../../05-utils/commonUtils'
import CountDownItem from "../../03-components/CountDownItem/countDownItem";

export default class Tab extends PureComponent {
    render() {
        const { amount, asset, address, createdAt, t, expiredAt } = this.props;

        return (
            <React.Fragment>
                <div className="create-wallet col-4">
                    <QrCode size={120} value={address} />
                </div>
                <div className="col-8 text-left info">
                    <div className='mb-2'>
                        <b>{t('address')} :</b> {address}
                    </div>
                    <div className='mb-2'>
                        <b>
                            {t('amount')} :
                            <span className='text-success'>{` ${amount} ${asset}`}</span>
                        </b>
                    </div>
                    <div className='mb-2'>
                        <b>{t('created_at')} :</b> {formatDateTimeFull(createdAt)}
                    </div>
                    <div className='mb-2'>
                        <b>{t('remaining_time')} : </b>
                        <CountDownItem
                            date={expiredAt}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
