import React, { Component } from 'react';
import { I18n } from 'react-i18next';
import Paginate from 'react-paginate';

import './firstWalletStore.css';
import { bindActionCreators } from "redux";
import {
	getAllFirstWalletNonUser,
	pagingFirstWallet,
	createMultiFirstWallet,
	resetCreateMultiFirstWalletStatus,
} from "../../../01-actions/adminWallet";
import { connect } from "react-redux";
import { formatDateTimeFull } from "../../../05-utils/commonUtils";
import { pushNotify } from "../../../03-components/Notification";
import i18n from "i18next";
import { constants } from "../../../05-utils/commonData";
import TFAModal from '../../Wallet/TfaCode/tfaCode';
import Loading from '../../../03-components/Loading';

class FirstWalletStore extends Component {
	state = {
		isOpenTFA: false,
	};

	componentDidMount() {
		this.props.actions.getAllFirstWalletNonUser();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.firstWallets !== this.props.firstWallets) {
			nextProps.actions.pagingFirstWallet(nextProps.firstWallets);
		}
		if (nextProps.multiFirstWalletCreated) {
			pushNotify('success', `${constants.NUMBER_FIRST_WALLET_CREATE_AT_ONCE_TIME} ${i18n.t('FIRST_WALLETS_CREATED_SUCCESSFULLY')}`);
			nextProps.actions.resetCreateMultiFirstWalletStatus();
			this.setState({ isOpenTFA: false });
			nextProps.actions.getAllFirstWalletNonUser();
		}
	}

	onPageChange = data => {
		const { actions, firstWallets } = this.props;
		actions.pagingFirstWallet(firstWallets, data.selected);
	};

	toggleTFAModal = () => {
		this.setState({ isOpenTFA: !this.state.isOpenTFA });
	};

	onReceiveTFACode = code => {
		this.setState({isOpenTFA: false});
		this.props.actions.createMultiFirstWallet({
			code,
			numberOfWallet: constants.NUMBER_FIRST_WALLET_CREATE_AT_ONCE_TIME,
		});
	};
	
	render() {
		const {
			firstWallets,
			displayFirstWallets,
			limit,
			multiFirstWalletCreating,
		} = this.props;
		
		return (
			<I18n ns="translations">
				{
					(t, {i18n}) => (
						<div className="row">
							<div className="col-lg-12 grid-margin">
								<div className="card">
									<h4 className="card-title">{t('first_wallet_store')}</h4>
									<div className="create-first-wallet-wrapper">
										<b className="text-primary">
											<span>{`${t('total')}:`}</span>
											<span>{` ${firstWallets ? firstWallets.length : '...'} `}</span>
											<span>{t('wallets')}</span>
										</b>
										<div/>
										<button
											className="btn btn-primary"
											disabled={multiFirstWalletCreating}
											onClick={this.toggleTFAModal}
										>
											{
												multiFirstWalletCreating ? (<Loading/>) : (<i className="fa fa-plus"/>)
											}
											<span>{`${t(`${constants.NUMBER_FIRST_WALLET_CREATE_AT_ONCE_TIME} `)}${t('first_wallet')}`}</span>
										</button>
									</div>
									<TFAModal
										isOpen={this.state.isOpenTFA}
										toggle={this.toggleTFAModal}
										onSubmit={this.onReceiveTFACode}
									/>
									<div className="table-responsive">
										<table className="table table-hover">
											<thead>
											<tr>
												<th>
													#
												</th>
												<th>
													{t('wallet_name')}
												</th>
												<th>
													{t('wallet_address')}
												</th>
												<th>
													{t('created_at')}
												</th>
											</tr>
											</thead>
											<tbody>
											{
												displayFirstWallets ?
													displayFirstWallets.map((wallet, index) => (
														<tr key={index}>
															<td>
																<p className="margin-none">{wallet.index}</p>
															</td>
															<td className="text-primary">
																<p className="margin-none">{wallet.name}</p>
															</td>
															<td>
																<p className="margin-none">{wallet.address}</p>
															</td>
															<td>
																<p className="margin-none">{formatDateTimeFull(wallet.createdAt)}</p>
															</td>
														</tr>
													))
													:
													(<Loading isTableRow colSpan={4}/>)
											}
											</tbody>
										</table>
									</div>
									{
										displayFirstWallets && firstWallets.length > limit ?
											(<div className="paginate-wrapper text-center margin-top-15px">
												<Paginate
													pageCount={firstWallets.length / limit}
													pageRangeDisplayed={3}
													marginPagesDisplayed={3}
													previousLabel="<"
													nextLabel=">"
													breakLabel="..."
													onPageChange={this.onPageChange}
													disabledClassName="paginate-disabled"
												/>
											</div>)
											:
											null
									}
								</div>
							</div>
						</div>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		firstWallets: store.adminWallet.firstWallets,
		displayFirstWallets: store.adminWallet.displayFirstWallets,
		limit: store.adminWallet.limit,
		multiFirstWalletCreated: store.adminWallet.multiFirstWalletCreated,
		multiFirstWalletCreating: store.adminWallet.multiFirstWalletCreating,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				getAllFirstWalletNonUser,
				pagingFirstWallet,
				createMultiFirstWallet,
				resetCreateMultiFirstWalletStatus,
			},
			dispatch
		)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(FirstWalletStore);