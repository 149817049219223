import React, {Component} from 'react';
import { I18n } from 'react-i18next';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import RegisterCodeTab from './RegisterCodeTab/registerCodeTab';
import RepeatOrderCodeTab from './RepeatOrderCodeTab/repeatOrderCodeTab';
import Tab from '../../../03-components/Tab';
import './productKeyStore.css';
import ChooseAssetCreateCode from "./ChooseAssetModal/ChooseAssetCreateCode";
import {pushNotify} from "../../../03-components/Notification";
import i18n from "i18next";

import {isRetailUser} from "../../../05-utils/commonUtils";

import {
	createProductCode,
	createRequestProductCode,
	getListTitleByRegion,
	getUserProductCodes,
	resetStatusCreateProductCode,
	resetStatusCreateRequestProductCode,
	getUserRequestProductCodes
} from "../../../01-actions/productCode";
import {
	getPriceAndFeeRepeatOrderCode,
	createRepeatOrderCode,
	resetStatusCreateRepeatOrderCode,
	createRequestRepeatOrderCode,
	resetStatusCreateRequestRepeatOrderCode
} from "../../../01-actions/repeatOrderCode";
import {getAllUserWallet} from "../../../01-actions/wallet";
import CreateCodeModal from "./CreateProductCodeModal/createProductCodeModal";
import CreateRequestCodeModal from "./CreateRequestCodeModal/createRequestCodeModal";
import NotifyRequestCodeInfoModal from "./NotifyModal/notifyRequestCodeInfoModal";
import {isChineseUser} from "../../../05-utils/commonUtils";
import {constants} from "../../../05-utils/commonData";
import {getEnv} from "../../../env";
import CreateRepeatOrderCodeModal from "./CreateRepeatOrderCodeModal/createRepeatOrderCodeModal";
import RequestRepeatOrderCodeModal from './RequestRepeatOrderCodeModal/requestRepeatOrderCodeModal';
import Create12MonthsRepeatOrderCodeModal from './Create12MonthsRepeatOrderCodeModal/create12MonthsRepeatOrderCodeModal';
import Request12MonthsRepeatOrderCodeModal from './Request12MonthsRepeatOrderCodeModal/request12MonthsRepeatOrderCodeModal';
import BigNumber from "bignumber.js";

export const TAB_KEY = {
	REGISTER_CODE: 'REGISTER_CODE',
	REPEAT_ORDER_CODE: 'REPEAT_ORDER_CODE',
};

class ProductKeyStore extends Component {
	state = {
		currentTab: TAB_KEY.REGISTER_CODE,
		isOpenChooseAsset: false,
		isOpenCreateCode: false,
		isOpenRequestCode: false,
		asset: '',
		isNotifyRequestCode: false,
		isOpenCreateRepeatOrderCode: false,
		isOpenCreate12MonthsRepeatOrderCode: false,
		isOpenRequestRepeatOrderCode: false,
		isOpenRequest12MonthsRepeatOrderCode: false,
		typeOfCodeSelected: null,
		detailBuying: []
	};

	componentDidMount() {
		const { userInfo, productCodeEnabled, repeatOrderCodeEnabled  } = this.props;
		if (window.location.href.endsWith(constants.TYPE_CODE.REPEAT_ORDER_CODE)){
			this.setState({ currentTab: TAB_KEY.REPEAT_ORDER_CODE });
			return;
		}
		if (userInfo && isRetailUser(userInfo.userCode)) {
			this.setState({ currentTab: TAB_KEY.REPEAT_ORDER_CODE });
			return;
		}
		if (!productCodeEnabled && repeatOrderCodeEnabled) {
			this.setState({ currentTab: TAB_KEY.REPEAT_ORDER_CODE });
		}
	}

	onTabClick = (e, tabName) => {
		e.preventDefault();
		this.setState({ currentTab: tabName });
	};

	componentWillReceiveProps(nextProps) {
		if ((this.props.userInfo !== nextProps.userInfo) && !((nextProps.productCodeEnabled && (nextProps.userInfo && !isChineseUser(nextProps.userInfo.userCode))) || getEnv("ENABLE_PRODUCT_CODE_FOR_CHINA"))) {
			this.props.history.push("/wallet");
		}

		if (!this.props.productCodeCreated && nextProps.productCodeCreated) {
			pushNotify('success', i18n.t('CREATE_PRODUCT_CODE_SUCCESSFULLY'));
			this.setState({ currentTab: TAB_KEY.REGISTER_CODE });
			nextProps.actions.resetStatusCreateProductCode();
		}
		if (!this.props.requestProductCodeCreated && nextProps.requestProductCodeCreated) {
			pushNotify('success', i18n.t('CREATE_REQUEST_PRODUCT_CODE_SUCCESSFULLY'));
			this.setState({ currentTab: TAB_KEY.REGISTER_CODE });
			nextProps.actions.resetStatusCreateRequestProductCode();
		}
		if (!this.props.repeatOrderCodeCreated && nextProps.repeatOrderCodeCreated) {
			pushNotify('success', i18n.t('CREATE_REPEAT_ORDER_CODE_SUCCESSFULLY'));
			if (this.state.typeOfCodeSelected === constants.TYPE_REPEAT_ORDER_CODE.RETAIL)
				this.toggleCreateRepeatOrderCode();
			else this.toggleCreate12MonthsRepeatOrderCode();
			this.setState({ currentTab: TAB_KEY.REPEAT_ORDER_CODE });
			nextProps.actions.resetStatusCreateRepeatOrderCode();
		}
		if (!this.props.requestRepeatOrderCodeCreated && nextProps.requestRepeatOrderCodeCreated) {
			pushNotify('success', i18n.t('CREATE_REQUEST_REPEAT_ORDER_CODE_SUCCESSFULLY'));
			if (this.state.typeOfCodeSelected === constants.TYPE_REPEAT_ORDER_CODE.RETAIL)
				this.toggleRequestRepeatOrderCode();
			else this.toggleRequest12MonthsRepeatOrderCode();
			nextProps.actions.resetStatusCreateRequestRepeatOrderCode();
		}
	}

	toggleNotifyRequestCode = () => {
		this.setState({ isNotifyRequestCode: !this.state.isNotifyRequestCode });
		if (this.state.typeOfCodeSelected) {
			this.setState({ currentTab: TAB_KEY.REPEAT_ORDER_CODE });
		} else this.setState({currentTab: TAB_KEY.REGISTER_CODE })
	};

	createProductCode = (value) => {
		const {
			createProductCode
		} = this.props.actions;
		createProductCode(value);
	};

	createRequestProductCode = (value) => {
		const {
			createRequestProductCode
		} = this.props.actions;
		createRequestProductCode(value);
	};

	onCreateRepeatOrderCode = (value) => {
		const {
			createRepeatOrderCode
		} = this.props.actions;
		createRepeatOrderCode({
			...value,
			asset: this.state.asset,
			type: this.state.typeOfCodeSelected
		});
	};

	onCreateRequestRepeatOrderCode = (value) => {
		const {
			createRequestRepeatOrderCode
		} = this.props.actions;
		createRequestRepeatOrderCode({
			...value,
			asset: this.state.asset,
			type: this.state.typeOfCodeSelected
		});
	};

	onCreateNewCodeClicked = () => {
		this.setState({typeOfCodeSelected: ""});
		this.toggleChooseAsset();
	};

	onCreateRepeatCodeClicked = () => {
		this.setState({ typeOfCodeSelected: constants.TYPE_REPEAT_ORDER_CODE.RETAIL });
		this.props.actions.getPriceAndFeeRepeatOrderCode({type: constants.TYPE_REPEAT_ORDER_CODE.RETAIL });
		this.toggleChooseAsset();
	};

	onCreate12MonthsRepeatCodeClicked = () => {
		this.setState({ typeOfCodeSelected: constants.TYPE_REPEAT_ORDER_CODE.WHOLESALE });
		this.props.actions.getPriceAndFeeRepeatOrderCode({type: constants.TYPE_REPEAT_ORDER_CODE.WHOLESALE });
		this.toggleChooseAsset();
	};

	onAssetClicked = asset => {
		this.setState({ asset });
		this.toggleChooseAsset();
		switch (this.state.typeOfCodeSelected) {
			case constants.TYPE_REPEAT_ORDER_CODE.RETAIL:
				asset === 'AGT' ? this.toggleCreateRepeatOrderCode() : this.toggleRequestRepeatOrderCode();
				this.calculatePriceAndFee(asset);
				break;
			case constants.TYPE_REPEAT_ORDER_CODE.WHOLESALE:
				asset === 'AGT' ? this.toggleCreate12MonthsRepeatOrderCode() : this.toggleRequest12MonthsRepeatOrderCode();
				this.calculatePriceAndFee(asset);
				break;
			default:
				asset === 'AGT' ? this.toggleCreateCode() : this.toggleRequestCode();
		}
	};

	calculatePriceAndFee = (asset) => {
		const { infoBuying } = this.props;
		const { typeOfCodeSelected } = this.state;
		let detailBuying = [];
		let infoByAsset = infoBuying[typeOfCodeSelected].filter(item => item.coin_type === asset);
		infoByAsset.forEach(item => {
			const totalFee = new BigNumber(item.shipping_fee,).times(item.shipping_type).toString();
			const amount = new BigNumber(item.fee).plus(totalFee).toString();
			detailBuying.push({
				price: item.fee,
				fee: item.shipping_fee,
				shippingType: item.shipping_type,
				amount
			})
		});
		this.setState({
			detailBuying
		})
	};

	toggleRequestCode = () => {
		this.setState({ isOpenRequestCode: !this.state.isOpenRequestCode });
		const {
			getListTitleByRegion
		} = this.props.actions;
		getListTitleByRegion();
	};

	toggleChooseAsset = () => {
		this.setState({ isOpenChooseAsset: !this.state.isOpenChooseAsset });
	};

	toggleCreateCode = () => {
		this.setState({ isOpenCreateCode: !this.state.isOpenCreateCode });
		const {
			userWallets,
			actions,
		} = this.props;
		actions.getListTitleByRegion();
		if (!userWallets) {
			actions.getAllUserWallet({ with_active: true });
		}
	};

	toggleCreateRepeatOrderCode = () => {
		this.setState({
			isOpenCreateRepeatOrderCode: !this.state.isOpenCreateRepeatOrderCode
		});
		const {
			userWallets,
			actions,
		} = this.props;
		if (!userWallets) {
			actions.getAllUserWallet({ with_active: true });
		}
	};

	toggleCreate12MonthsRepeatOrderCode = () => {
		this.setState({
			isOpenCreate12MonthsRepeatOrderCode: !this.state.isOpenCreate12MonthsRepeatOrderCode
		});
		const {
			userWallets,
			actions,
		} = this.props;
		if (!userWallets) {
			actions.getAllUserWallet({ with_active: true });
		}
	};

	toggleRequestRepeatOrderCode = () => {
		this.setState({
			isOpenRequestRepeatOrderCode: !this.state.isOpenRequestRepeatOrderCode
		});
	};

	toggleRequest12MonthsRepeatOrderCode = () => {
		this.setState({
			isOpenRequest12MonthsRepeatOrderCode: !this.state.isOpenRequest12MonthsRepeatOrderCode
		});
	};

	render() {
		const {
			currentTab,
			asset,
			isOpenCreateCode,
			isOpenChooseAsset,
			isNotifyRequestCode,
			isOpenRequestCode,
			isOpenCreateRepeatOrderCode,
			isOpenCreate12MonthsRepeatOrderCode,
			isOpenRequestRepeatOrderCode,
			isOpenRequest12MonthsRepeatOrderCode,
			detailBuying,
			typeOfCodeSelected
		} = this.state;
		const {
			userInfo,
			listTitle,
			isFetchingTitles,
			productCodeCreating,
			productCodeCreated,
			userWallets,
			productCodeEnabled,
			requestProductCodeCreating,
			requestProductCodeCreated,
			requestCode,
			infoBuying,
			repeatOrderCodeCreating,
			repeatOrderCodeCreated,
			repeatOrderCodeEnabled,
			requestRepeatOrderCodeCreated,
			requestRepeatOrderCodeCreating,
			requestRepeatOrderCode,
			isFetchingInfoBuying
		} = this.props;
		const { REGISTER_CODE, REPEAT_ORDER_CODE } = TAB_KEY;
		let contentJSX;
		if (currentTab === REPEAT_ORDER_CODE) {
			contentJSX = <RepeatOrderCodeTab/>;
		} else {
			contentJSX = <RegisterCodeTab/>;
		}
		
		return (
			<I18n ns="translations">
				{
					t => (
						<div className="row">
							<div className="col-lg-10 grid-margin">
								<div className="card">
									<ul className="nav nav-tabs tab-solid tab-solid-danger" role="tablist">
										{ userInfo && !isRetailUser(userInfo.userCode) && productCodeEnabled &&
											<Tab
												tabName={REGISTER_CODE}
												currentTab={currentTab}
												onTabClick={this.onTabClick}
												text={t('purchased_key')}
											/>
										}
										{
											repeatOrderCodeEnabled &&
											<Tab
												tabName={REPEAT_ORDER_CODE}
												currentTab={currentTab}
												onTabClick={this.onTabClick}
												text={t('repeat_order_code')}
											/>
										}

									</ul>
									{
										contentJSX
									}
								</div>
							</div>
							<div className="col-lg-2 grid-margin">
								{
									userInfo && !isRetailUser(userInfo.userCode) && productCodeEnabled &&
									<button
										className='btn btn-send text-right'
										onClick={this.onCreateNewCodeClicked}
									>
										<i className="mdi mdi-plus-circle-outline"/>
										{t('create_new_code')}
									</button>
								}
								{
									repeatOrderCodeEnabled &&
									<React.Fragment>
										<button
											className='btn btn-send text-right mt-2 d-flex align-items-center'
											onClick={this.onCreateRepeatCodeClicked}
										>
											<i className="mdi mdi-plus-circle-outline" />
											<div className="d-flex flex-column align-items-center">
												<span>{t('repeat_order')}</span>
												<span>{t('create_new_code')}</span>
											</div>
										</button>
										<button
											className='btn btn-send text-right mt-2 d-flex align-items-center'
											onClick={this.onCreate12MonthsRepeatCodeClicked}
										>
											<i className="mdi mdi-plus-circle-outline" />
											<div className="d-flex flex-column align-items-center">
												<span>{t('12_months_repeat_order')}</span>
												<span>{t('create_new_code')}</span>
											</div>
										</button>
									</React.Fragment>
								}
							</div>

							<ChooseAssetCreateCode
								isOpen={isOpenChooseAsset}
								toggle={this.toggleChooseAsset}
								onPressAGT={() => this.onAssetClicked('AGT')}
								onPressUSDT={() => this.onAssetClicked('USDT')}
								isFetching={ isFetchingInfoBuying}
							/>
							<CreateCodeModal
								isOpen={isOpenCreateCode}
								toggle={this.toggleCreateCode}
								listTitle={listTitle}
								userWallets={userWallets}
								isFetchingTitles={isFetchingTitles}
								onCreate={(value) => {
									this.createProductCode(value)
								}}
								asset={asset}
								productCodeCreating={productCodeCreating}
								productCodeCreated={productCodeCreated}
								onBack={() => {
									this.toggleChooseAsset();
									this.toggleCreateCode();
								}}
							/>
							<CreateRequestCodeModal
								isOpen={isOpenRequestCode}
								toggle={this.toggleRequestCode}
								listTitle={listTitle}
								isFetchingTitles={isFetchingTitles}
								onCreateRequest={(value) => {
									this.createRequestProductCode(value)
								}}
								asset={asset}
								requestProductCodeCreating={requestProductCodeCreating}
								requestProductCodeCreated={requestProductCodeCreated}
								onBack={() => {
									this.toggleChooseAsset();
									this.toggleRequestCode();
								}}
								onOpenNotify={this.toggleNotifyRequestCode}
							/>
							<CreateRepeatOrderCodeModal
								isOpen={isOpenCreateRepeatOrderCode}
								toggle={this.toggleCreateRepeatOrderCode}
								userWallets={userWallets}
								asset={asset}
								infoBuying={infoBuying}
								repeatOrderCodeCreated={repeatOrderCodeCreated}
								repeatOrderCodeCreating={repeatOrderCodeCreating}
								onCreate={this.onCreateRepeatOrderCode}
								onBack={() => {
									this.toggleChooseAsset();
									this.toggleCreateRepeatOrderCode();
								}}
							/>
							<RequestRepeatOrderCodeModal
								isOpen={isOpenRequestRepeatOrderCode}
								toggle={this.toggleRequestRepeatOrderCode}
								onCreateRequest={(value) => {
									this.onCreateRequestRepeatOrderCode(value)
								}}
								asset={asset}
								infoBuying={infoBuying}
								detailBuying={detailBuying}
								requestRepeatOrderCodeCreating={requestRepeatOrderCodeCreating}
								requestRepeatOrderCodeCreated={requestRepeatOrderCodeCreated}
								onBack={() => {
									this.toggleChooseAsset();
									this.toggleRequestRepeatOrderCode();
								}}
								onOpenNotify={this.toggleNotifyRequestCode}
							/>
							<Create12MonthsRepeatOrderCodeModal
								isOpen={isOpenCreate12MonthsRepeatOrderCode}
								toggle={this.toggleCreate12MonthsRepeatOrderCode}
								userWallets={userWallets}
								asset={asset}
								detailBuying={detailBuying}
								onCreate={this.onCreateRepeatOrderCode}
								repeatOrderCodeCreating={repeatOrderCodeCreating}
								repeatOrderCodeCreated={repeatOrderCodeCreated}
								onBack={() => {
									this.toggleChooseAsset();
									this.toggleCreate12MonthsRepeatOrderCode();
								}}
							/>
							<Request12MonthsRepeatOrderCodeModal
								isOpen={isOpenRequest12MonthsRepeatOrderCode}
								toggle={this.toggleRequest12MonthsRepeatOrderCode}
								onCreateRequest={(value) => {
									this.onCreateRequestRepeatOrderCode(value)
								}}
								asset={asset}
								detailBuying={detailBuying}
								requestRepeatOrderCodeCreating={requestRepeatOrderCodeCreating}
								requestRepeatOrderCodeCreated={requestRepeatOrderCodeCreated}
								onBack={() => {
									this.toggleChooseAsset();
									this.toggleRequest12MonthsRepeatOrderCode();
								}}
								onOpenNotify={this.toggleNotifyRequestCode}
							/>
							<NotifyRequestCodeInfoModal
								isOpen={isNotifyRequestCode}
								asset={asset}
								requestCode={!!typeOfCodeSelected ? requestRepeatOrderCode : requestCode}
								onClose={this.toggleNotifyRequestCode}
								toggle={this.toggleNotifyRequestCode}
							/>
						</div>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		userInfo: store.user.userInfo,
		productCodes: store.productCode.productCodes,
		listTitle: store.productCode.listTitle,
		isFetchingTitles: store.productCode.isFetchingTitles,
		productCodeCreating: store.productCode.productCodeCreating,
		productCodeCreated: store.productCode.productCodeCreated,
		totalProductCode: store.productCode.totalProductCode,
		userWallets: store.wallet.userWallets,
		productCodeEnabled: store.system.productCodeEnabled,
		requestProductCodeCreating: store.productCode.requestProductCodeCreating,
		requestProductCodeCreated: store.productCode.requestProductCodeCreated,
		requestCode: store.productCode.requestCode,
		infoBuying: store.repeatOrderCode.infoBuying,
		repeatOrderCodeCreating: store.repeatOrderCode.repeatOrderCodeCreating,
		repeatOrderCodeCreated: store.repeatOrderCode.repeatOrderCodeCreated,
		requestRepeatOrderCodeCreated: store.repeatOrderCode.requestRepeatOrderCodeCreated,
		requestRepeatOrderCodeCreating: store.repeatOrderCode.requestRepeatOrderCodeCreating,
		requestRepeatOrderCode: store.repeatOrderCode.requestRepeatOrderCode,
		isFetchingInfoBuying: store.repeatOrderCode.isFetchingInfoBuying,
		repeatOrderCodeEnabled: store.system.repeatOrderCodeEnabled,
	}
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				getUserProductCodes,
				getListTitleByRegion,
				getAllUserWallet,
				createProductCode,
				resetStatusCreateProductCode,
				createRequestProductCode,
				resetStatusCreateRequestProductCode,
				getUserRequestProductCodes,
				getPriceAndFeeRepeatOrderCode,
				createRepeatOrderCode,
				resetStatusCreateRepeatOrderCode,
				createRequestRepeatOrderCode,
				resetStatusCreateRequestRepeatOrderCode
			},
			dispatch
		)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductKeyStore);