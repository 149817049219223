import React, {Component} from 'react';
import { Field, reduxForm } from 'redux-form';
import Select from 'react-select';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { I18n } from 'react-i18next';
import './privateKeyInput.css';

class PrivateKeyInput extends Component {
	state = {
		address: "",
		privateKey: ""
	};

	renderField = ({ input, type, fieldValue, meta: { touched, error } }) => (
		<div className="form-group">
			<div className="input-group">
				<input
					{...input}
					type={type}
					className="form-control transfer-input margin-top-15px"
					value={fieldValue}
					onChange={e => this.setState({ [input.name]: e.target.value.trim() }, () => {
						this.props.onChangeField(input.name, this.state[input.name]);
					})}
				/>
				{touched && error && <p className="text-danger">{`* ${error}`}</p>}
			</div>
		</div>
	);

	render() {
		return (
			<I18n ns="translations">
				{
					t => (
						<div  className="margin-top-1rem">
							<div className="change-password-attention">
								<p>{t('attention_')}</p>
								<p>{t('main_wallet_private_key_attention')}</p>
							</div>
							<Select
								placeholder={ t('select_wallet') }
								options={ this.props.userWallets.map(wallet => ({ label: wallet.address, value: wallet.address })) }
								onChange={ (option) => {
									this.setState({
										address: option.value
									}, () => {
										this.props.onChangeField('address', this.state['address']);
									})
								} }
							/>
							<Field
								name='privateKey'
								type="text"
								component={this.renderField}
								fieldValue={this.state.privateKey}
							/>
						</div>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		userWallets: store.wallet.userWallets
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
			},
			dispatch
		)
	};
};

PrivateKeyInput.defaultProps = {
	onChangeField: () => {}
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(reduxForm({
	form: 'privateKeyInput',
})(PrivateKeyInput));
