import { ACTIONS } from '../01-actions/asset';
import { AUTH_ACTIONS } from '../01-actions';
import { UTIL_ACTIONS } from '../01-actions/utils';

const initialState = {
	status: null,
	assets: [],
	assetsFiltered: [],
	selectedAsset: null,
	trustLineCreated: false,
	trustLineCreating: false,
	error: null,
	isFetching: false,
	assetsSearch: [],
	assetInfo: {}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.GET_ALL_ASSETS_PROGRESS:
			return {
				...state,
				status: action.type,
				isFetching: true
			};
		case ACTIONS.CREATE_TRUST_LINE_PROGRESS:
			return {
				...state,
				status: action.type,
				trustLineCreating: true,
			};
		case ACTIONS.GET_ALL_ASSETS_SUCCESS:
			return {
				...state,
				status: action.type,
				assets: action.data.assets,
				assetsFiltered: action.data.assets,
				isFetching: false
			};
		case ACTIONS.CREATE_TRUST_LINE_SUCCESS:
			return {
				...state,
				status: action.type,
				trustLineCreated: true,
				trustLineCreating: false,
			};
		case ACTIONS.GET_ALL_ASSETS_FAILED:
		case ACTIONS.CREATE_TRUST_LINE_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				trustLineCreating: false,
				isFetching: false
			};
		case ACTIONS.FILTER_ASSETS:
			return {
				...state,
				status: action.type,
				assetsFiltered: action.data.assetsFiltered,
			};
		case ACTIONS.SELECT_ASSET:
			return {
				...state,
				status: action.type,
				selectedAsset: action.data.asset,
			};
		case ACTIONS.RESET_TRUST_STATUS:
			return {
				...state,
				status: action.type,
				trustLineCreated: false,
			};
		case ACTIONS.RESET_FILTER_ASSET_RESULT_STATUS:
			return {
				...state,
				status: action.type,
				assetsFiltered: state.assets,
				selectedAsset: null,
			};
		case AUTH_ACTIONS.LOGOUT:
			return initialState;
		case UTIL_ACTIONS.RESET_ERROR:
			return {
				...state,
				error: null,
			};
		case ACTIONS.SEARCH_ASSET_FROM_TRIAM_PROGRESS:
		case ACTIONS.REMOVE_ASSET_PROGRESS:
		case ACTIONS.IMPORT_ASSET_FROM_TRIAM_PROGRESS:
			return {
				...state,
				status: action.type,
				isFetching: true
			};
		case ACTIONS.REMOVE_ASSET_SUCCESS:
			return {
				...state,
				status: action.type,
				isFetching: false
			};
		case ACTIONS.SEARCH_ASSET_FROM_TRIAM_FAILED:
		case ACTIONS.REMOVE_ASSET_FAILED:
		case ACTIONS.IMPORT_ASSET_FROM_TRIAM_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				isFetching: false,
			};
		case ACTIONS.SEARCH_ASSET_FROM_TRIAM_SUCCESS:
			return  {
				...state,
				status: action.type,
				assetsSearch: action.data.assets,
				isFetching: false
			};
		case ACTIONS.IMPORT_ASSET_FROM_TRIAM_SUCCESS:
			return  {
				...state,
				status: action.type,
				assetInfo: action.data.asset,
				isFetching: false
			};
		case ACTIONS.CLEAR_ASSET_SEARCH:
			return {
				...state,
				status: action.type,
				assetsSearch: initialState.assetsSearch
			};
		default:
			return state;
	}
}