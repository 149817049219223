
import { ACTIONS } from '../01-actions/repeatOrderCode';
import { AUTH_ACTIONS } from '../01-actions';
import { UTIL_ACTIONS } from '../01-actions/utils';

const initialState = {
    status: null,
    error: null,
    listRepeatOrderCodes: [],
    totalRepeatOrderCodes: 0,
    isFetching: false,
    repeatOrderCodes: [],
    totalUserRepeatOrderCodes: 0,
    isFetchingRepeatOrderCode: false,
    requestRepeatOrderCodes: [],
    totalRequestRepeatOrderCodes:0,
    isFetchingRequestRepeatOrderCode: false,
    repeatOrderCode: null,
    repeatOrderCodeCreated: false,
    repeatOrderCodeCreating: false,
    requestRepeatOrderCode: null,
    requestRepeatOrderCodeCreated: false,
    requestRepeatOrderCodeCreating: false,
    infoBuying: null,
    isFetchingInfoBuying: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.GET_LIST_REPEAT_ORDER_CODES_INFO_PROGRESS:
            return {
                ...state,
                status: action.type,
                isFetching: true,
            };
        case ACTIONS.GET_LIST_REPEAT_ORDER_CODES_INFO_SUCCESS:
            return {
                ...state,
                status: action.type,
                listRepeatOrderCodes: action.data.listCodes,
                totalRepeatOrderCodes: action.data.totalRepeatOrderCodes ? action.data.totalRepeatOrderCodes : state.totalRepeatOrderCodes,
                isFetching: false,
            };
        case ACTIONS.GET_LIST_REPEAT_ORDER_CODES_INFO_FAILED:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
                isFetching: false,
            };

        case ACTIONS.GET_USER_REPEAT_ORDER_CODES_PROGRESS:
            return {
                ...state,
                status: action.type,
                isFetchingRepeatOrderCode: true,
            };
        case ACTIONS.GET_USER_REPEAT_ORDER_CODES_FAILED:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
                isFetchingRepeatOrderCode: false,
            };
        case ACTIONS.GET_USER_REPEAT_ORDER_CODES_SUCCESS:
            return {
                ...state,
                repeatOrderCodes: action.data.repeatOrderCodes,
                totalUserRepeatOrderCodes: action.data.totalRepeatOrderCodes,
                isFetchingRepeatOrderCode: false,
            };

        case ACTIONS.GET_USER_REQUEST_REPEAT_ORDER_CODES_PROGRESS:
            return {
                ...state,
                isFetchingRequestRepeatOrderCode: true,
                status: action.type,
            };
        case ACTIONS.GET_USER_REQUEST_REPEAT_ORDER_CODES_SUCCESS:
            return {
                ...state,
                isFetchingRequestRepeatOrderCode: false,
                requestRepeatOrderCodes: action.data.requestCodes,
                totalRequestRepeatOrderCodes: action.data.totalRequests
            };
        case ACTIONS.GET_USER_REQUEST_REPEAT_ORDER_CODES_FAILED:
            return {
                ...state,
                status: action.type,
                isFetchingRequestRepeatOrderCode: false,
                error: action.data.error
            };

        case ACTIONS.CREATE_REPEAT_ORDER_CODE_PROGRESS:
            return {
                ...state,
                status: action.type,
                repeatOrderCodeCreating: true,
            };
        case ACTIONS.CREATE_REPEAT_ORDER_CODE_FAILED:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
                repeatOrderCodeCreating: false,
            };
        case ACTIONS.CREATE_REPEAT_ORDER_CODE_SUCCESS:
            return {
                ...state,
                repeatOrderCode: action.data.code,
                repeatOrderCodes: [action.data.code, ...state.repeatOrderCodes],
                repeatOrderCodeCreated: true,
                repeatOrderCodeCreating: false,
            };

        case ACTIONS.CREATE_REQUEST_REPEAT_ORDER_CODE_PROGRESS:
            return {
                ...state,
                status: action.type,
                requestRepeatOrderCodeCreating: true,
            };
        case ACTIONS.CREATE_REQUEST_REPEAT_ORDER_CODE_FAILED:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
                requestRepeatOrderCodeCreating: false,
            };
        case ACTIONS.CREATE_REQUEST_REPEAT_ORDER_CODE_SUCCESS:
            return {
                ...state,
                requestRepeatOrderCode: action.data.requestRepeatOrderCode,
                requestRepeatOrderCodes: [action.data.requestRepeatOrderCode, ...state.requestRepeatOrderCodes],
                requestRepeatOrderCodeCreated: true,
                requestRepeatOrderCodeCreating: false,
            };
        case ACTIONS.GET_PRICE_AND_FEE_REPEAT_ORDER_CODE_PROGRESS:
            return {
                ...state,
                status: action.type,
                isFetchingInfoBuying: true
            };
        case ACTIONS.GET_PRICE_AND_FEE_REPEAT_ORDER_CODE_FAILED:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
                isFetchingInfoBuying: false
            };
        case ACTIONS.GET_PRICE_AND_FEE_REPEAT_ORDER_CODE_SUCCESS:
            return {
                ...state,
                infoBuying: action.data.infoBuying,
                isFetchingInfoBuying: false
            };
        case ACTIONS.RESET_STATUS_CREATE_REPEAT_ORDER_CODE:
            return {
                ...state,
                repeatOrderCodeCreated: false,
            };
        case ACTIONS.RESET_STATUS_CREATE_REQUEST_REPEAT_ORDER_CODE:
            return {
                ...state,
                requestRepeatOrderCodeCreated: false,
            };

        case ACTIONS.CANCEL_REQUEST_REPEAT_ORDER_CODE_PROGRESS:
            return {
                ...state,
                status: action.type,
                isCancellingRequest: true,
                isCancelledRequest: false,
            };
        case ACTIONS.CANCEL_REQUEST_REPEAT_ORDER_CODE_SUCCESS:
            return {
                ...state,
                status: action.type,
                isCancellingRequest: false,
                isCancelledRequest: true,
                requestRepeatOrderCodes: state.requestRepeatOrderCodes.filter(item => item._id !== action.data.requestCodeId)
            };
        case ACTIONS.CANCEL_REQUEST_REPEAT_ORDER_CODE_FAILED:
            return {
                ...state,
                status: action.type,
                isCancellingRequest: false,
                isCancelledRequest: false,
                error: action.data.error
            };
        case ACTIONS.NOTIFY_NEW_REPEAT_ORDER_CODE_CREATED:
            return {
                ...state,
                status: action.type,
                requestRepeatOrderCodes: state.requestRepeatOrderCodes.filter(item => item._id !== action.data.requestCodeId),
                repeatOrderCodes: [ action.data.code, ...state.repeatOrderCodes],
                totalUserRepeatOrderCodes: state.totalUserRepeatOrderCodes + 1,
            };
        case AUTH_ACTIONS.LOGOUT:
            return initialState;
        case UTIL_ACTIONS.RESET_ERROR:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}