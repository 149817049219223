import React, {Component} from 'react';
import { I18n } from 'react-i18next';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import AssetLogo from "../../../../03-components/AssetLogo/assetLogo";
import {getEnv} from "../../../../env";

export default class DetailAssetModal extends Component {

    render() {
        const { asset, onDelete, onCancel } = this.props;

        return (
            <I18n ns="translations">
                {
                    t => (
                        <Modal isOpen={this.props.isOpen} className="modal-lg">
                            <ModalHeader>
                               <span>{t('detail_asset')}</span>
                            </ModalHeader>
                            <ModalBody style={{maxHeight: '400px', overflowY: 'auto'}}>
                                {
                                    asset ? (asset.asset_type && asset.asset_type === 'native') ?
                                        (
                                            <div className="table-responsive">
                                                <table className="table balance-table">
                                                    <tbody>
                                                    <tr>
                                                        <td className="py-1">
                                                            <AssetLogo src={asset.logo} assetName={asset.name}/>
                                                        </td>
                                                        <td>
                                                            <b className="font-18">{asset.name || "RIA"}</b>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="py-1">
                                                            <span>{t('decimalPlace_')}</span>
                                                        </td>
                                                        <td>
                                                            <span>{asset.decimalPlace}</span>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        ):
                                        (
                                            <div className="table-responsive">
                                                <table className="table balance-table">
                                                    <tbody>
                                                    <tr>
                                                        <td className="py-1">
                                                            <AssetLogo src={asset.logo} assetName={asset.name}/>
                                                        </td>
                                                        <td>
                                                            <b className="font-18">{asset.name || "RIA"}</b>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="py-1">
                                                            <span>{t('capital_')}</span>
                                                        </td>
                                                        <td>
                                                            <span>{asset.capital}</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="py-1">
                                                            <span>{t('ratio_')}</span>
                                                        </td>
                                                        <td>
                                                            <span>{asset.ratio}</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="py-1">
                                                            <span>{t('decimalPlace_')}</span>
                                                        </td>
                                                        <td>
                                                            <span>{asset.decimalPlace}</span>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        ): null
                                }
                            </ModalBody>
                            <ModalFooter>
                                <div>
                                    {
                                        asset && asset.userInfo && asset.userInfo.email !== getEnv('TRIAM_ADMIN_EMAIL') &&
                                        <Button
                                            color="danger"
                                            onClick={onDelete}
                                        >{t('remove_')}</Button>

                                    }
                                    <Button className={'margin-left-5px'} onClick={onCancel}>{t('cancel')}</Button>
                                </div>
                            </ModalFooter>
                        </Modal>
                    )
                }
            </I18n>
        );
    }
}