import React, {Component} from 'react';

import {
	getAllContact,
	addContact,
	resetAddContactStatus,
	editContact,
	resetEditContactStatus,
	resetDeleteContactStatus,
} from '../../../01-actions/addressBook';
import ModalContact from './Modal/modalAddressBook';
import ConfirmDelete from './ComfirmDelete/confirmDelete';
import './addressBook.css';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { pushNotify } from "../../../03-components/Notification";
import { I18n } from 'react-i18next';
import i18n from 'i18next';
import Loading from '../../../03-components/Loading';

class AddressBook extends Component {
	state = {
		isOpenAdd: false,
		isOpenEdit: false,
		isOpenDelete: false,
		name: '',
		address: '',
		contactId: '',
	};

	componentDidMount() {
		this.props.actions.getAllContact();
	}
	
	componentWillReceiveProps(nextProps) {
		if (nextProps.contactAdded) {
			pushNotify('success', i18n.t('ADDRESS_ADDED_SUCCESSFULLY'));
			nextProps.actions.getAllContact();
			nextProps.actions.resetAddContactStatus();
		}
		if (nextProps.contactEdited) {
			pushNotify('success', i18n.t('ADDRESS_EDITED_SUCCESSFULLY'));
			nextProps.actions.getAllContact();
			nextProps.actions.resetEditContactStatus();
		}
		if (nextProps.contactDeleted) {
			pushNotify('success', i18n.t('ADDRESS_DELETED_SUCCESSFULLY'));
			nextProps.actions.getAllContact();
			nextProps.actions.resetDeleteContactStatus();
		}
	}

	toggleAddContact = () => {
		this.setState({
			name: '',
			address: '',
			isOpenAdd: !this.state.isOpenAdd
		});
	};

	toggleEditContact = (contactId, name, address) => {
		this.setState({
			contactId,
			name,
			address,
			isOpenEdit: !this.state.isOpenEdit
		});
	};

	toggleDeleteContact = contactId => {
		this.setState({
			contactId,
			isOpenDelete: !this.state.isOpenDelete
		});
	};

	closeAddContact = () => {
		this.setState({ isOpenAdd: !this.state.isOpenAdd });
	};

	closeEditContact = () => {
		this.setState({ isOpenEdit: !this.state.isOpenEdit });
	};

	closeDeleteContact = () => {
		this.setState({ isOpenDelete: !this.state.isOpenDelete });
	};
	
	render() {
		const { contacts, isFetching } = this.props;
		const { contactId, name, address } = this.state;
		
		return (
			<I18n ns="translations">
				{
					t => (
						<div className="row">
							<div className="col-lg-12 grid-margin">
								<div className="card">
									<h4 className="card-title">{t('address_book')}</h4>
									<div className="table-responsive">
										{
											isFetching ? (<Loading parent1Class="text-center"/>)
												:
												(<table className="table table-hover">
													<thead>
													<tr>
														<th>
															{t('name')}
														</th>
														<th>
															{t('address')}
														</th>
														<th className="text-right">
															{t('actions')}
														</th>
													</tr>
													</thead>
													<tbody>
													{
														contacts && contacts.map((contact, index) => (
															<tr key={index}>
																<td>
																	{contact.name}
																</td>
																<td>
																	{contact.address}
																</td>
																<td className="contact-action text-right">
																	<button className="btn btn-inverse-primary"
																			onClick={() => this.toggleEditContact(contact._id, contact.name, contact.address)}>
																		<i className="mdi mdi-pen"/>
																	</button>
																	<button className="btn btn-inverse-danger"
																			onClick={() => this.toggleDeleteContact(contact._id)}>
																		<i className="mdi mdi-delete"/>
																	</button>
																</td>
															</tr>
														))
													}
													</tbody>
												</table>)
										}
										<ModalContact
											isOpen={this.state.isOpenAdd}
											toggle={this.closeAddContact}
											title={t('add_address_book')}
											btnText={t('add')}
											action={this.props.actions.addContact}
										/>
										<ModalContact
											isOpen={this.state.isOpenEdit}
											toggle={this.closeEditContact}
											title={t('edit_address_book')}
											btnText={t('save')}
											action={this.props.actions.editContact}
											oldName={name}
											oldAddress={address}
											contactId={contactId}
										/>
										<ConfirmDelete
											isOpen={this.state.isOpenDelete}
											toggle={this.closeDeleteContact}
											contactId={contactId}
										/>
									</div>
									<div className="text-center">
										<button className="btn btn-primary" onClick={this.toggleAddContact}>
											<i className="mdi mdi-plus-circle-outline"/>
											{t('add_address_book')}
										</button>
									</div>
								</div>
							</div>
						</div>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		contacts: store.addressBook.contacts,
		isFetching: store.addressBook.isFetching,
		contactAdded: store.addressBook.contactAdded,
		contactEdited: store.addressBook.contactEdited,
		contactDeleted: store.addressBook.contactDeleted,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				getAllContact,
				addContact,
				resetAddContactStatus,
				editContact,
				resetEditContactStatus,
				resetDeleteContactStatus,
			},
			dispatch
		)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AddressBook);