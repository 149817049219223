import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { I18n } from 'react-i18next';
import './index.css';
import EZPayTransferItem from "./ezpayTransferItem";
import {getHistoryTopUp, pagingTopUpHistory} from "../../../../01-actions/topUp";
import Paginate from 'react-paginate';
import Loading from "../../../../03-components/Loading";

class EzpayTransfer extends Component {
	state = {
		loadedPage: 0,
	};

	componentDidMount() {
		const { actions } = this.props;
		actions.getHistoryTopUp({})
	}

	onPageChange = data => {
		const { actions, topUpHistories } = this.props;
		if (this.state.loadedPage < data.selected + 1) {
			actions.getHistoryTopUp({ numPage: data.selected + 1 });
			this.setState({ loadedPage: data.selected + 1 });
		}
		else {
			actions.pagingTopUpHistory(topUpHistories, data.selected);
		}
	};
	
	render() {
		const { isFetchingTopUpHistory, topUpHistoryPageCount, displayTopUpHistory } = this.props;
		return (
			<I18n ns="translations">
				{
					t => (
						<div className="col-lg-12">
							<h4 className="card-title">{t('ezpay_transfer')}</h4>
							<div className="table-responsive">
								<table className="table table-hover">
									<tbody>
									{
										isFetchingTopUpHistory ?
											(<Loading isTableRow />)
											:
										Array.isArray(displayTopUpHistory)
										&& displayTopUpHistory.length > 0 ? displayTopUpHistory.map((history, index) => (
												<EZPayTransferItem data={history} key={index}/>
											))
											:
											(<tr className="text-center">
												<td>
													<div>{t('no_data_to_show')}</div>
												</td>
											</tr>)
									}
									</tbody>
								</table>
								{
									displayTopUpHistory && topUpHistoryPageCount > 0 ?
										(<div className="paginate-wrapper text-center margin-top-15px">
											<Paginate
												pageCount={topUpHistoryPageCount}
												pageRangeDisplayed={3}
												marginPagesDisplayed={3}
												previousLabel="<"
												nextLabel=">"
												breakLabel="..."
												onPageChange={this.onPageChange}
											/>
										</div>)
										:
										null
								}
							</div>
						</div>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		topUpHistories: store.topUp.topUpHistories,
		displayTopUpHistory: store.topUp.displayTopUpHistory,
		topUpHistoryPageCount: store.topUp.topUpHistoryPageCount,
		isFetchingTopUpHistory: store.topUp.isFetchingTopUpHistory,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions: bindActionCreators(
			{
				getHistoryTopUp,
				pagingTopUpHistory
			},
			dispatch
		)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EzpayTransfer);