import React, { Component } from 'react';

// actions
import {
    getAmountBalance
} from '../../05-utils/commonUtils';

import './selectUserTitle.scss';
import Loading from "../Loading";
import {I18n} from "react-i18next";

class SelectUserTitle extends Component {
    render() {
        const {
            listTitle,
            handleSelectTitle,
            isFetchingTitles,
            userTitle,
            asset
        } = this.props;
        let listTitleValid = listTitle.filter(item => (asset === 'AGT' && item.coin_amount) || (asset === 'USDT' && item.USDT) );
        if (asset === 'AGT')
            listTitleValid = listTitleValid.sort((item, nextItem) => (Number(item.coin_amount) > Number(nextItem.coin_amount)) ? 1 : -1);
        else listTitleValid = listTitleValid.sort((item, nextItem) => (Number(item.USDT) > Number(nextItem.USDT)) ? 1 : -1);
        return (
            <I18n ns="translations">
                {
                    t => (
                        <div className='purchase-product-key'>
                            {
                                isFetchingTitles ? (<Loading parent1Class="text-center"/>)
                                :
                                <React.Fragment>
                                    <h4 className='heading-key'>{t('purchase_a_product_key')}</h4>
                                    <div className='list-key'>
                                        {listTitleValid.map((item, index) =>
                                            <div className='item-key' key={index}>
                                                <input id={index + '-myRadio-select-title'} onChange={() => handleSelectTitle(item)} type="radio" name="gender" value={item.name} checked={userTitle && userTitle.id === item.id}/>
                                                <p>{item.name}</p> <p>{`${asset === 'AGT' ? getAmountBalance(item.coin_amount, 3) : getAmountBalance(item.USDT, 6)} ${asset}`}</p>
                                            </div>
                                        )}
                                    </div>
                                </React.Fragment>
                            }

                        </div>
                    )
                }
            </I18n>
        )
    }
}

export default SelectUserTitle;