import React, {Component} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { I18n } from 'react-i18next';

import { setGa, verifyGa, resetGaVerifyStatus } from "../../../../../01-actions/user";
import validate from './validate';
import SubmitButton from '../../../../../03-components/ModalSubmitButton';

class SetGa extends Component {
	state = {
		password: '',
		code: '',
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps.gaVerified) {
			this.refreshInputAndToggle();
			this.props.actions.resetGaVerifyStatus();
		}
	}

	renderField = ({ input, label, type, fieldValue, disabled, isTfaCode, meta: { touched, error } }) => (
		<div className="form-group">
			<label>{label}</label>
			<div className="input-group">
				<input
					{...input}
					type={type}
					className={`form-control ${isTfaCode ? 'input-tfa' : 'transfer-input'}`}
					value={fieldValue}
					disabled={disabled}
					onChange={e => this.setState({ [input.name]: e.target.value })}
				/>
				{touched && error && <p className="text-danger">{`* ${error}`}</p>}
			</div>
		</div>
	);
	
	setGA = () => {
		const { password } = this.state;
		this.props.actions.setGa({ password });
	};
	
	verifyGA = () => {
		const { password, code } = this.state;
		this.props.actions.verifyGa({ password, code });
	};

	refreshInputAndToggle = () => {
		this.setState({ code: '', password: '' });
		this.props.toggle();
	};
	
	render() {
		const {
			isOpen,
			invalid,
			gaSetting,
			qrData,
			gaSecret,
			gaSet,
			gaVerifying
		} = this.props;
		const { password, code } = this.state;

		return (
			<I18n ns="translations">
				{
					t => (
						<Modal isOpen={isOpen} className="modal-lg">
							<ModalHeader toggle={this.refreshInputAndToggle}>{t('two_factor_authentication_reset')}</ModalHeader>
							<ModalBody>
								<div className="change-password-attention">
									<p>{t('attention_')}</p>
									{
										gaSet ? (<p>{t('verify_ga_intro')}</p>) : (<p>{t('set_ga_intro')}</p>)
									}
								</div>
								{
									gaSet ?
										(<div>
											<Field
												name="gaSecret"
												type="text"
												component={this.renderField}
												label={t('ga_secret')}
												fieldValue={gaSecret}
												disabled
											/>
											<div className="text-center">
												<div>{t('scan_qr_code_intro')}</div>
												<img src={qrData} className="margin-bottom-15px" alt=""/>
												<div>{t('enter_verify_ga_intro')}</div>
											</div>
											<Field
												name="code"
												type="text"
												component={this.renderField}
												fieldValue={code}
												isTfaCode
											/>
										</div>)
										:
										(<form className="margin-top-1rem">
											<Field
												name="password"
												type="password"
												component={this.renderField}
												label={t('password')}
												fieldValue={password}
											/>
										</form>)
								}
								
							</ModalBody>
							<ModalFooter>
								{
									gaSet ?
										(<SubmitButton
											className="btn btn-primary"
											onClick={this.verifyGA}
											isSubmitting={gaVerifying}
											text={t('verify')}
										/>)
										:
										(<SubmitButton
											className="btn btn-primary"
											onClick={this.setGA}
											isSubmitting={gaSetting}
											disabled={invalid}
											text={t('setup')}
										/>)
								}
							</ModalFooter>
						</Modal>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		gaSet: store.user.gaSet,
		gaSetting: store.user.gaSetting,
		qrData: store.user.qrData,
		gaSecret: store.user.gaSecret,
		gaVerifying: store.user.gaVerifying,
		gaVerified: store.user.gaVerified,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				setGa,
				verifyGa,
				resetGaVerifyStatus,
			},
			dispatch
		)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(reduxForm({
	form: 'setGa',
	validate,
})(SetGa));
