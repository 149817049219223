import I18n from 'i18next';

const validate = values => {
	const errors = {};
	const emailRegex = /\S+@\S+\.\S+/;
	if (!values.email) {
		errors.email = I18n.t('EMAIL_REQUIRED');
	}
	if (!emailRegex.test(values.email)) {
		errors.email = I18n.t('EMAIL_INVALID');
	}
	return errors;
};

export default validate;