import React, { Component } from 'react';
import { Switch, Route, Redirect, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import { logout } from '../../01-actions/auth';
import { getUserInfo } from '../../01-actions/user';
import { getAuthStatus } from '../../01-actions/auth';
import { connectSocket } from '../../01-actions/socket';
import { getTimestampBackground } from '../../01-actions/system';
import { isSuperAdmin } from "../../05-utils/commonUtils";
import { bindActionCreators } from "redux";
import UserManagement from './UserManagement/userManagement';
import AssetManagement from './AssetManagement/assetManagement';
import UserProfile from './UserProfile/userProfile';
import ResetUser from './ResetUser/resetUser';
import Summary from './Summary/summary';
import FirstWalletStore from './FirstWalletStore/firstWalletStore';
import DatabaseBackup from './DatabaseBackup/databaseBackup';
import News from './News/news';
import CodeManagement from './ProductCodeManagement/codeManagement';
import { I18n } from 'react-i18next';
import Header from '../../03-components/Header';
import Footer from '../../03-components/Footer';
import MenuItem from '../../03-components/MenuItem';

class AdminPage extends Component {
	state = {
		isOpenMenu: false,
		toggleActive: false,
	};

	componentDidMount() {
		const { userInfo, actions } = this.props;
		const { getUserInfo, getAuthStatus } = actions;
		if (!userInfo) {
			getUserInfo();
		}
		getAuthStatus();
	}

	componentWillReceiveProps(nextProps) {
		if(!this.props.userInfo && nextProps.userInfo){
			this.props.actions.connectSocket(nextProps.userInfo._id);
		}
	}

	isNavActive = key => window.location.href.endsWith(key) ? 'active' : '';

	logout = () => {
		const { logout, getTimestampBackground } = this.props.actions;
		logout();
		getTimestampBackground();
	}

	toggleMenu = () => {
		this.setState({ isOpenMenu: !this.state.isOpenMenu });
	};
	
	closeMenu = () => {
		this.setState({ isOpenMenu: false });
	};

	render() {
		const { isOpenMenu } = this.state;
		const { userInfo, token, getAuthStatusDone, socket, socketID, notifies, actions } = this.props;

		return (
			<I18n ns="translations">
				{
					(t, {i18n}) => (
						<div className="container-scroller">
							{
								getAuthStatusDone && !token ? <Redirect to="/login"/> : null
							}
							<Header
								userInfo={userInfo}
								logout={this.logout}
								toggleMenu={this.toggleMenu}
								socket={socket}
								notifies={notifies}
								actions={actions}
								socketID={socketID}
							/>
							<div className="container-fluid page-body-wrapper">
								<nav className={`sidebar sidebar-offcanvas ${isOpenMenu ? 'active' : null}`} id="sidebar">
									{
										userInfo && isSuperAdmin(userInfo) ?
											(<div className="text-center admin-profile-wrapper">
												{/*<div className="margin-bottom-15px">*/}
													{/*<i className="fa fa-user-tie text-white"/>*/}
												{/*</div>*/}
												<NavLink to="/wallet" className="btn btn-primary" role="button">
													<i className="fa fa-user-alt"/>
													<span>{t('user_mode')}</span>
												</NavLink>
											</div>)
											:
											null
									}
									<ul className="nav">
										<MenuItem
											activeClass={this.isNavActive('/admin')}
											linkTo='/admin'
											iconClass='fa-desktop icon-color'
											text={t('summary')}
											closeMenu={this.closeMenu}
										/>
										<MenuItem
											activeClass={this.isNavActive('/admin/user-management')}
											linkTo='/admin/user-management'
											iconClass='fa-users icon-color'
											text={t('user_management')}
											closeMenu={this.closeMenu}
										/>
										<MenuItem
											activeClass={this.isNavActive('/admin/reset-user')}
											linkTo='/admin/reset-user'
											iconClass='fa-users-cog icon-color'
											text={t('reset_user')}
											closeMenu={this.closeMenu}
										/>
										<MenuItem
											activeClass={this.isNavActive('/admin/first-wallet-store')}
											linkTo='/admin/first-wallet-store'
											iconClass='fa-box-open icon-color'
											text={t('first_wallet_store')}
											closeMenu={this.closeMenu}
										/>
										<MenuItem
											activeClass={this.isNavActive('/admin/database-backup')}
											linkTo='/admin/database-backup'
											iconClass='fa-database icon-color'
											text={t('database_backup')}
											closeMenu={this.closeMenu}
										/>
										<MenuItem
											activeClass={this.isNavActive('/admin/news')}
											linkTo='/admin/news'
											iconClass='fa-newspaper icon-color'
											text={t('news')}
											closeMenu={this.closeMenu}
										/>
										<MenuItem
											activeClass={this.isNavActive('/admin/asset-management')}
											linkTo='/admin/asset-management'
											iconClass='fa-coins icon-color'
											text={t('asset_management')}
											closeMenu={this.closeMenu}
										/>
										<MenuItem
											activeClass={this.isNavActive('/admin/product-code')}
											linkTo='/admin/product-code'
											srcImage='/images/icon/key_product.svg'
											text={t('product_code_management')}
											iconClass='icon-style'
											closeMenu={this.closeMenu}
										/>
										{/* <MenuItem
											activeClass={this.isNavActive('/admin/mail-box')}
											linkTo='/admin/mail-box'
											iconClass='fa-envelope'
											text={t('mail_box')}
											closeMenu={this.closeMenu}
										/> */}
										<li className="nav-item nav-logout">
											<button className="btn btn-link nav-link" onClick={this.logout}>
												<i className="menu-icon fa fa-sign-out-alt"/>
												<span className="menu-title">{t('logout')}</span>
											</button>
										</li>
									</ul>
								</nav>
								<div className="main-panel">
									<div className="content-wrapper">
										<Switch>
											<Route exact path="/admin" component={Summary}/>
											<Route path="/admin/user-management" component={UserManagement}/>
											<Route path="/admin/user/:armId" component={UserProfile}/>
											<Route path="/admin/reset-user" component={ResetUser}/>
											<Route path="/admin/first-wallet-store" component={FirstWalletStore}/>
											<Route path="/admin/database-backup" component={DatabaseBackup}/>
											<Route path="/admin/news" component={News}/>
											<Route path="/admin/asset-management" component={AssetManagement}/>
											<Route path="/admin/product-code" component={CodeManagement}/>
											<Redirect to="/error/404"/>
										</Switch>
										<Footer/>
									</div>
								</div>
							</div>
						</div>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		userInfo: store.user.userInfo,
		token: store.auth.token,
		getAuthStatusDone: store.auth.getAuthStatusDone,
		socket: store.socket.socket,
		socketID: store.socket.socketID,
		notifies: store.socket.notifies,
	}
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				logout,
				getUserInfo,
				getAuthStatus,
				connectSocket,
				getTimestampBackground,
			},
			dispatch
		)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);