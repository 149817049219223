import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import SubmitButton from '../../03-components/ModalSubmitButton';
import validate from "./validate";
import './login.css';
import { I18n } from 'react-i18next';
import TFAModal from '../Wallet/TfaCode/tfaCode';

class Form extends Component {
    state = {
        email: '',
        password: '',
        isOpenTFA: false,
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.isOpenTFAModal !== this.props.isOpenTFAModal) {
            this.setState({ isOpenTFA: nextProps.isOpenTFAModal });
        }
    }

    onReceiveTFACode = code => {
        const { email, password } = this.state;
        this.props.onSubmit({ email, password, code });
    };

    toggleTFAModal = () => {
        this.setState({ isOpenTFA: !this.state.isOpenTFA });
    };

    handleSubmit = () => {
        const email = this.emailInput.value;
        const password = this.passwordInput.value;
        this.props.onSubmit({ email, password });
    }

    renderField = ({ input, label, type, fieldValue, fieldRef, id, meta: { touched, error } }) => (
        <div className="form-group">
            <label className="text-color font-text font-size-18" >{label}</label>
            <div className="input-group">
                <input
                    id={id}
                    {...input}
                    type={type}
                    className="form-control login-input"
                    value={fieldValue}
                    onChange={e => this.setState({ [input.name]: e.target.value.trim() })}
                    ref={fieldRef}
                />
            </div>
            {touched && error && <p className="text-danger">{`* ${error}`}</p>}
        </div>
    );

    render() {
        const { userInfo, loggingIn } = this.props;
        let { email, password, isOpenTFA } = this.state;
        email = (this.emailInput && this.emailInput.value) || email;
        password = (this.passwordInput && this.passwordInput.value) || password;

        return (
            <I18n ns="translations">
                {
                    t => (
                        <React.Fragment>
                            <div>
                                <Field
                                    id="email"
                                    name="email"
                                    type="text"
                                    component={this.renderField}
                                    label={t('email_or_usercode')}
                                    fieldValue={email}
                                    fieldRef={ref => this.emailInput = ref}
                                />
                                <Field
                                    id="password"
                                    name="password"
                                    type="password"
                                    component={this.renderField}
                                    label={t('password')}
                                    fieldValue={password}
                                    fieldRef={ref => this.passwordInput = ref}
                                />
                            </div>
                            <div>
                                <div className="text-center submit-btn-wrapper">
                                    <SubmitButton
                                        className="btn btn-color"
                                        onClick={this.handleSubmit}
                                        isSubmitting={loggingIn && !userInfo}
                                        text={t('login')}
                                    />
                                </div>
                                {/* <div className="form-group text-center margin-top-15px">
                                    <Link to={'/forgot-password'} className="forgot-password text-color font-text"><span>{t('forgot_password')}</span></Link>
                                </div> */}
                            </div>
                            <TFAModal isOpen={isOpenTFA} toggle={this.toggleTFAModal} onSubmit={this.onReceiveTFACode}/>
                        </React.Fragment>
                    )
                }
            </I18n>
        );
    }
}

export default reduxForm({form: 'login',validate})(Form);
