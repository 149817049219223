import I18n from 'i18next';
import { constants } from "../../05-utils/commonData";

const validate = values => {
	const errors = {};
	if (!values.email) {
		errors.email = I18n.t('EMAIL_REQUIRED');
	}
	if (!constants.EMAIL_REGEX.test(values.email)
		&& !constants.CHINESE_FAKE_EMAIL_REGEX.test(values.email)
		&& !constants.CHINESE_USERCODE_REGEX.test(values.email)
		&& !constants.OUTSIDE_CHINESE_USERCODE_REGEX.test(values.email)) {
		errors.email = I18n.t('EMAIL_OR_USERCODE_INVALID');
	}
	if (!values.password) {
		errors.password = I18n.t('PASSWORD_REQUIRED');
	}
	return errors;
};

export default validate;