import React, {Component} from 'react';
import { I18n } from 'react-i18next';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import RenderHtml from 'react-render-html';

import {
	getAllNews,
	createNews,
	editNews,
	deleteNews,
	resetCreateNewsStatus,
	resetEditNewsStatus,
	resetDeleteNewsStatus,
} from "../../../01-actions/adminNews";
import { pushNotify } from "../../../03-components/Notification";
import ModalNews from './NewsModal/modalNews';
import ConfirmDelete from './DeleteConfirm/confirmDelete';
import './news.css';
import i18n from "i18next";

class News extends Component {
	state = {
		isOpenAdd: false,
		isOpenEdit: false,
		isOpenDelete: false,
		title: '',
		content: '',
		newsId: '',
	};
	
	componentDidMount() {
		this.props.actions.getAllNews();
	}
	
	componentWillReceiveProps(nextProps) {
		if (nextProps.newsCreated) {
			pushNotify('success', i18n.t('NEWS_CREATED_SUCCESSFULLY'));
			nextProps.actions.getAllNews();
			nextProps.actions.resetCreateNewsStatus();
		}
		if (nextProps.newsEdited) {
			pushNotify('success', i18n.t('NEWS_EDITED_SUCCESSFULLY'));
			nextProps.actions.getAllNews();
			nextProps.actions.resetEditNewsStatus();
		}
		if (nextProps.newsDeleted) {
			pushNotify('success', i18n.t('NEWS_DELETED_SUCCESSFULLY'));
			nextProps.actions.getAllNews();
			nextProps.actions.resetDeleteNewsStatus();
		}
	}

	toggleAddNews = () => {
		this.setState({
			title: '',
			content: '',
			isOpenAdd: !this.state.isOpenAdd
		});
	};

	toggleEditNews = (newsId, title, content) => {
		this.setState({
			newsId,
			title,
			content,
			isOpenEdit: !this.state.isOpenEdit
		});
	};

	toggleDeleteNews = newsId => {
		this.setState({
			newsId,
			isOpenDelete: !this.state.isOpenDelete
		});
	};

	closeAddNews = () => {
		this.setState({ isOpenAdd: !this.state.isOpenAdd });
	};

	closeEditNews = () => {
		this.setState({ isOpenEdit: !this.state.isOpenEdit });
	};

	closeDeleteNews = () => {
		this.setState({ isOpenDelete: !this.state.isOpenDelete });
	};
	
	render() {
		const { listNews } = this.props;
		const { title, content, newsId } = this.state;
		
		return (
			<I18n ns="translations">
				{
					t => (
						<div className="row">
							<div className="col-lg-12 grid-margin">
								<div className="card">
									<div className="create-news-wrapper">
										<h4 className="card-title">{t('news')}</h4>
										<div/>
										<button className="btn btn-primary" onClick={this.toggleAddNews}>
											<i className="fa fa-plus"/>
											{t('create_news')}
										</button>
									</div>
									<div className="table-responsive">
										<table className="table table-hover">
											<thead>
											<tr>
												<th>
													#
												</th>
												<th>
													{t('title')}
												</th>
												<th>
													{t('content')}
												</th>
												<th className="text-right">
													{t('actions')}
												</th>
											</tr>
											</thead>
											<tbody>
											{
												listNews &&
													listNews.map((news, index) => (
														<tr key={index}>
															<td>
																{news.index}
															</td>
															<td className="news-title">
																{news.title}
															</td>
															<td className="news-content">
																{RenderHtml(news.content)}
															</td>
															<td className="text-right">
																<button
																	className="btn btn-primary btn-sm margin-bottom-5px"
																	onClick={() => this.toggleEditNews(news._id, news.title, news.content)}
																>
																	<i className="fa fa-edit"/>
																	{t('edit')}
																</button>
																<br/>
																<button
																	className="btn btn-danger btn-sm"
																	onClick={() => this.toggleDeleteNews(news._id)}
																>
																	<i className="fa fa-trash"/>
																	{t('delete')}
																</button>
															</td>
														</tr>
													))
											}
											</tbody>
										</table>
									</div>
									<ModalNews
										isOpen={this.state.isOpenAdd}
										toggle={this.closeAddNews}
										modalTitle={t('create_news')}
										btnText={t('create')}
										action={this.props.actions.createNews}
									/>
									<ModalNews
										isOpen={this.state.isOpenEdit}
										toggle={this.closeEditNews}
										modalTitle={t('edit_news')}
										btnText={t('save')}
										action={this.props.actions.editNews}
										oldTitle={title}
										oldContent={content}
										newsId={newsId}
									/>
									<ConfirmDelete
										isOpen={this.state.isOpenDelete}
										toggle={this.closeDeleteNews}
										newsId={newsId}
									/>
								</div>
							</div>
						</div>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		listNews: store.adminNews.listNews,
		newsCreated: store.adminNews.newsCreated,
		newsEdited: store.adminNews.newsEdited,
		newsDeleted: store.adminNews.newsDeleted,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				getAllNews,
				createNews,
				editNews,
				deleteNews,
				resetCreateNewsStatus,
				resetEditNewsStatus,
				resetDeleteNewsStatus,
			},
			dispatch
		)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(News);