import { ACTIONS } from '../01-actions/transaction';
import { AUTH_ACTIONS } from '../01-actions';
import { UTIL_ACTIONS } from '../01-actions/utils';

const initialState = {
	status: null,
	transactionsHistory: null,
	limit: null,
	moneyTransferred: false,
	moneyTransferring: false,
	totalHistories: null,
	nextPagingToken: null,
	prevPagingToken: null,
	canGetMoreTransactionHistory: true,
	isFetching: false,
    isSigning: null,
	transactionsFail: null,
	displayTransactionsFail: null,
	transactionsFailPageCount: null,
	transactionsFailLimit: null,
	error: null,
	fetchingDetailTransaction: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.GET_HISTORY_TRANSACTION_PROGRESS:
			return {
				...state,
				status: action.type,
				isFetching: true,
			};
		case ACTIONS.TRANSFER_MONEY_PROGRESS:
			return {
				...state,
				status: action.type,
				moneyTransferring: true,
			};
		case ACTIONS.GET_HISTORY_TRANSACTION_SUCCESS:
			const { transactionsHistory } = action.data;
			transactionsHistory.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
			const lastHistory = transactionsHistory.length > 0 && transactionsHistory[transactionsHistory.length - 1];
			const firstHistory = transactionsHistory.length > 0 && transactionsHistory[0];
			return {
				...state,
				status: action.type,
				transactionsHistory,
				nextPagingToken: lastHistory && lastHistory.paging_token,
				prevPagingToken: firstHistory && firstHistory.paging_token,
				canGetMoreTransactionHistory: transactionsHistory.length !== 0,
				isFetching: false,
			};
		case ACTIONS.TRANSFER_MONEY_SUCCESS:
			return {
				...state,
				status: action.type,
				moneyTransferred: true,
				moneyTransferring: false,
			};
		case ACTIONS.GET_HISTORY_TRANSACTION_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				isFetching: false,
			};
		case ACTIONS.TRANSFER_MONEY_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				moneyTransferring: false,
			};
		case ACTIONS.RESET_TRANSFER_STATUS:
			return {
				...state,
				status: action.type,
				error: null,
				moneyTransferred: false,
			};
		case ACTIONS.PAGING_TRANSACTION_HISTORY:
			return {
				...state,
				status: action.type,
				displayTransactionsHistory: action.data.displayTransactionsHistory,
				limit: action.data.limit,
			};
        case ACTIONS.SIGN_SUBMIT_PROGRESS:
            return {
                ...state,
                status: action.type,
                isSigning: true,
            };
        case ACTIONS.SIGN_SUBMIT_FAILED:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
                isSigning: false
            };
		case ACTIONS.GET_TRANSACTION_FAIL_HISTORY_PROGRESS:
			return {
				...state,
				status: action.type,
				isFetching: true,
			};
		case ACTIONS.GET_TRANSACTION_FAIL_HISTORY_SUCCESS:
			return {
				...state,
				status: action.type,
				transactionsFail: state.transactionsFail ? state.transactionsFail.concat(action.data.transactionsFail) : action.data.transactionsFail,
				displayTransactionsFail: action.data.transactionsFail,
				transactionsFailPageCount: action.data.pageCount,
				transactionsFailLimit: action.data.limit,
				isFetching: false,
			};
		case ACTIONS.GET_TRANSACTION_FAIL_HISTORY_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				isFetching: false,
			};
		case ACTIONS.RESET_TRANSACTION_FAILED_PAGE:
			return {
				...state,
				status: action.type,
				transactionsFail: null,
				displayTransactionsFail: null,
				transactionsFailPageCount: null,
				isFetching: false,
			};
		case ACTIONS.PAGING_TRANSACTION_FAIL_HISTORY:
			return {
				...state,
				status: action.type,
				displayTransactionsFail: action.data.displayTransactionFail,
				transactionsFailLimit: action.data.limit,
			};
		case ACTIONS.RESET_HISTORY_PAGE_COUNT:
				return {
					...state,
					status: action.type,
					transactionsHistoryPageCount: null,
					transactionsHistory: null,
					displayTransactionsHistory: null,
					pagingToken: null,
					canGetMoreTransactionHistory: true,
					isFetching: false,
				};
		case AUTH_ACTIONS.LOGOUT:
			return initialState;
		case UTIL_ACTIONS.RESET_ERROR:
			return {
				...state,
				error: null,
			};
		default:
			return state;
	}
}