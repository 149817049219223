import { constants } from "../../../../05-utils/commonData";
import I18n from 'i18next';

const validate = values => {
	const errors = {};
	if (!values.name || values.name.trim() === "") {
		errors.name = I18n.t('NAME_REQUIRED');
	}
	if (!values.address) {
		errors.address = I18n.t('ADDRESS_REQUIRED');
	}
	else if (values.address.trim().length !== constants.WALLET_KEY_LENGTH) {
		errors.address = I18n.t('ADDRESS_INVALID');
	}
	return errors;
};

export default validate;