import React, {Component} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const pushNotify = (type, message) => {
	switch (type) {
		case 'success':
			toast.success(message, {
				position: toast.POSITION.TOP_RIGHT
			});
			break;
		case 'error':
			toast.error(message, {
				position: toast.POSITION.TOP_RIGHT
			});
			break;
		case 'warn':
			toast.warn(message, {
				position: toast.POSITION.TOP_RIGHT
			});
			break;
		case 'info':
			toast.info(message, {
				position: toast.POSITION.TOP_RIGHT
			});
			break;
		default:
			toast(message);
	}
};

class Notification extends Component {
	render() {
		return (
			<ToastContainer hideProgressBar autoClose={3000} closeOnClick={false} />
		);
	}
}

export default Notification;
export { pushNotify };