import React, {Component} from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { I18n } from 'react-i18next';
import './unblockManyWallet.css';
import { unblockWallet } from '../../../../../01-actions/wallet';

class UnblockManyWallet extends Component {
    state = {
        privateKey: ""
    };

    renderField = ({ input, label, type, value, walletID }) => (
        <div className="form-group">
            <label>{label}</label>
            <div className="input-group mb-3" style={{display: 'flex'}}>
                <input type="text"
                       className="form-control"
                       aria-describedby=""
                       onChange={e => this.setState({ privateKey: e.target.value.trim() })}
                />
                <div className="input-group-append">
                    <button
                        className="btn btn-unblock"
                        type="button"
                        onClick={() => {
                            this.props.actions.unblockWallet({
                                opPass: this.props.value.newOPPasswordReset.trim(),
                                privateKey: this.state.privateKey.trim(),
                                walletID: walletID,
                            });
                        }}
                    >
                        Unblock
                    </button>
                </div>
            </div>
        </div>
    );

    render() {
        const {
            userWallets
        } = this.props;
        const wallets = userWallets && userWallets.filter( wallet => wallet.address !== this.props.value.walletAddress);
        return (
            <I18n ns="translations">
                {
                    t => (
                        <div className="confirm-unblock-wallet-attention margin-top-1rem">
                            <div className="change-password-attention">
                                <p>{t('attention_')}</p>
                                <p>{t('unblock_wallet_attention')}</p>
                            </div>
                            <div className="margin-top-1rem">
                                {
                                    wallets && wallets.map((wallet, index) => (
                                        <Field
                                            key={index}
                                            name={wallet.address}
                                            walletID={wallet._id}
                                            type="text"
                                            component={this.renderField}
                                            label={wallet.address}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    )
                }
            </I18n>
        );
    }
}

const mapStateToProps = store => {
    return {
        userWallets: store.wallet.userWallets
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions : bindActionCreators(
            {
                unblockWallet
            },
            dispatch
        )
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(reduxForm({
    form: 'unblockManyWallet'
})(UnblockManyWallet));
