import { constants } from '../../../05-utils/commonData';
import I18n from 'i18next';

const validate = values => {
	const errors = {};
	if (!values.opPassword) {
		errors.opPassword = I18n.t('OP_PASSWORD_REQUIRED');
	}
	if (values.opPassword && values.opPassword.length < constants.MIN_USER_PASSWORD_LENGTH) {
		errors.opPassword = I18n.t('NEW_OP_PASSWORD_MIN_LENGTH');
	}

	if(values.opPassword && !new RegExp(/^(([A-Za-z0-9]|[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]|\s){6,200})$/gi).test(values.opPassword)){
		errors.opPassword = I18n.t('OP_PASS_ONLY_CONTAIN_LATIN_CHARACTER');
	}

	if(values.opPassword && !/[A-Z]/.test(values.opPassword)){
		errors.opPassword = I18n.t('OP_PASS_MUST_CONTAIN_UPPERCASE_CHARACTER');
	}

	if(values.opPassword && !/[a-z]/.test(values.opPassword)){
		errors.opPassword = I18n.t('OP_PASS_MUST_CONTAIN_LOWERCASE_CHARACTER');
	}
	if(values.opPassword && !/[0-9]/.test(values.opPassword)){
		errors.opPassword = I18n.t('OP_PASS_MUST_CONTAIN_DIGITS');
	}
	if(values.opPassword && !/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(values.opPassword)){
		errors.opPassword = I18n.t('OP_PASS_MUST_CONTAIN_SPECIAL_CHARACTER');
	}
	if (!values.confirmOPPassword) {
		errors.confirmOPPassword = I18n.t('CONFIRM_OP_PASSWORD_REQUIRED');
	}
	if (values.opPassword !== values.confirmOPPassword) {
		errors.confirmOPPassword = I18n.t('OP_PASSWORD_NOT_MATCH');
	}
	return errors;
};

export default validate;