import React, {Component} from "react";
import {formatDateTimeFull, getTransactionDescription} from "../../05-utils/commonUtils";
import { getEnv } from "../../env";

export default class TransactionDetail extends Component {
    render() {
        const {transaction, t} = this.props;
        switch(transaction.type) {
            case 'change_trust':
                return (
                    <React.Fragment>
                        <div className="charge-trust col-md-4">
                            <span className="fa fa-handshake-o"/>
                            <div><label>{t('charge_trust')}</label></div>
                        </div>
                        <div className="col-md-8 text-left info">
                            <div className="mb-2">
                                <b>{t('transaction_id')} : </b>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`${getEnv('REACT_APP_DASHBOARD_URI')}/blockscan-tx/${transaction.transaction_hash}`}
                                >
                                    {transaction.transaction_hash}
                                </a>
                            </div>
                            <div className='mb-2'><b>{t('trust_asset')} :</b> {transaction && transaction.asset_code}</div>
                            <div className='mb-2'><b>{t('issue_wallet')} :</b> {transaction && transaction.asset_issuer}</div>
                            <div><b>{t('time')} :</b> {formatDateTimeFull(transaction && transaction.created_at)}</div>
                        </div>
                    </React.Fragment>
                );
            case 'create_account':
                return (
                    <React.Fragment>
                        <div className="create-wallet col-md-4">
                            <span className="fa fa-user-circle"/>
                            <div><label>{t('create_wallet')}</label></div>
                        </div>
                        <div className="col-md-8 text-left info">
                            <div className="mb-2">
                                <b>{t('transaction_id')} : </b>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`${getEnv('REACT_APP_DASHBOARD_URI')}/blockscan-tx/${transaction.transaction_hash}`}
                                >
                                    {transaction.transaction_hash}
                                </a>
                            </div>
                            <div className='mb-2'><b>{t('funder_wallet')} :</b> {transaction && transaction.funder}</div>
                            <div className='mb-2'><b>{t('new_wallet')} :</b> {transaction && transaction.account}</div>
                            <div className='mb-2'><b>{t('amount')} :</b> {getTransactionDescription(transaction)}</div>
                            <div><b>{t('time')} :</b> {formatDateTimeFull(transaction && transaction.created_at)}</div>
                        </div>
                    </React.Fragment>
                );
            case 'payment':
                return (
                    <React.Fragment>
                        <div className="payment col-md-4">
                            <span className="fa fa-money"/>
                            <div><label>{t('payment')}</label></div>
                        </div>
                        <div className="col-md-8 text-left info">
                            <div className="mb-2">
                                <b>{t('transaction_id')} : </b>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`${getEnv('REACT_APP_DASHBOARD_URI')}/blockscan-tx/${transaction.transaction_hash}`}
                                >
                                    {transaction.transaction_hash}
                                </a>
                            </div>
                            <div className='mb-2'><b>{t('sender_wallet')} :</b> {transaction && transaction.from}</div>
                            <div className='mb-2'><b>{t('receiver_wallet')} :</b> {transaction && transaction.to}</div>
                            <div className='mb-2'><b>{t('amount')} :</b> {getTransactionDescription(transaction)}</div>
                            <div><b>{t('time')} :</b> {formatDateTimeFull(transaction && transaction.created_at)}</div>
                        </div>
                    </React.Fragment>
                );
            case 'account_merge':
                return (
                    <React.Fragment>
                        <div className="merge-wallet col-md-4">
                            <span className="fa fa-compress"/>
                            <div><label>{t('merge_wallet')}</label></div>
                        </div>
                        <div className="col-md-8 text-left info">
                            <div className="mb-2">
                                <b>{t('transaction_id')} : </b>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`${getEnv('REACT_APP_DASHBOARD_URI')}/blockscan-tx/${transaction.transaction_hash}`}
                                >
                                    {transaction.transaction_hash}
                                </a>
                            </div>
                            <div className='mb-2'><b>{t('source_wallet')} :</b> {transaction && transaction.account}</div>
                            <div className='mb-2'><b>{t('Destination_wallet')} :</b> {transaction && transaction.into}</div>
                            <div><b>{t('time')} :</b> {formatDateTimeFull(transaction && transaction.created_at)}</div>
                        </div>
                    </React.Fragment>
                )
            default:
                return (
                    <React.Fragment>
                        <div className="merge-wallet col-md-4">
                            <span className="fa fa-compress"/>
                            <div><label>{transaction.type}</label></div>
                        </div>
                        <div className="col-md-8 text-left info">
                            <div><b>{t('time')} :</b> {formatDateTimeFull(transaction && transaction.created_at)}</div>
                        </div>
                    </React.Fragment>
                )
        }
    }
}