import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// components
import SubmitButton from '../../../../03-components/ModalSubmitButton';
import {I18n} from "react-i18next";
import {formatDateTimeFull} from "../../../../05-utils/commonUtils";
import {constants } from "../../../../05-utils/commonData";

class NotifyProductCodeCreated extends Component {

    render() {
        const {
            isOpen,
            newProductCodeCreated,
            onClose,
            toggle,
            onView,
            type
        } = this.props;

        return (
            <I18n ns="translations">
                {
                    t => (
                        <Modal isOpen={isOpen} className="modal-lg">
                            <ModalHeader toggle={toggle}>
                                {t('notify')}
                            </ModalHeader>
                            <ModalBody>
                                <div className='p-2'>
                                    <div className='margin-bottom-10px'>
                                        <h6>{t('notify_created_product_code')}</h6>
                                    </div>
                                    <div className='wrapper-confirm-address'>
                                        <table>
                                            <tbody>
                                            <tr className='info-row'>
                                                <td className='info-title'>{t('product_code')}:</td>
                                                <td className='info-content'>{newProductCodeCreated.code}</td>
                                            </tr>
                                            <tr className='info-row'>
                                                <td className='info-title'>{t('created_at')} :</td>
                                                <td className='info-content'>{formatDateTimeFull(newProductCodeCreated.createdAt)}</td>
                                            </tr>
                                            {
                                                type === constants.TYPE_CODE.REPEAT_ORDER_CODE ?
                                                    newProductCodeCreated.type === constants.TYPE_REPEAT_ORDER_CODE.RETAIL ?
                                                        <React.Fragment>
                                                            <tr className='info-row'>
                                                                <td className='info-title'>{t('type')} :</td>
                                                                <td className='info-content'>{newProductCodeCreated.type}</td>
                                                            </tr>
                                                            <tr className='info-row'>
                                                                <td className='info-title'>{t('number_of_package')} :</td>
                                                                <td className='info-content'>{newProductCodeCreated.numberOfPackage}</td>
                                                            </tr>
                                                        </React.Fragment>
                                                        :<React.Fragment>
                                                            <tr className='info-row'>
                                                                <td className='info-title'>{t('type')} :</td>
                                                                <td className='info-content'>{newProductCodeCreated.type}</td>
                                                            </tr>
                                                            <tr className='info-row'>
                                                                <td className='info-title'>{t('number_of_shipping')} :</td>
                                                                <td className='info-content'>{newProductCodeCreated.numberOfShipping}</td>
                                                            </tr>
                                                        </React.Fragment>
                                                    :
                                                    <tr className='info-row'>
                                                        <td className='info-title'>{t('amount')}:</td>
                                                        <td className='info-content'>{`${newProductCodeCreated.amount} USDT`}</td>
                                                    </tr>
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <SubmitButton
                                    className="btn btn-primary"
                                    onClick={onClose}
                                    text={t('ok')}
                                />
                                <SubmitButton
                                    className="btn btn-success"
                                    onClick={onView}
                                    text={t('view')}
                                />
                            </ModalFooter>
                        </Modal>
                    )
                }
            </I18n>
        );
    }
}

export default NotifyProductCodeCreated;