import React, { Component } from 'react';
import {I18n} from "react-i18next";
import { Field, reduxForm } from 'redux-form';
import i18n from 'i18next';
import {sprintf} from "sprintf-js";
import { isNaturalNumber } from '../../05-utils/commonUtils';

class EnterNumberOfPackage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numberOfPackage: "",
        }
    }

    onKeyDown = e => {
        const DOT_KEY_CODE = 190;
        const COMMA_KEY_CODE = 188;
        if (e.keyCode === DOT_KEY_CODE || e.keyCode === COMMA_KEY_CODE) {
            e.preventDefault();
        }
    };

    onTextChange = e => {
        this.props.handleNumberOfPackageChange(Number(e.target.value));
        this.setState({ numberOfPackage: e.target.value });
        this.props.onEnterAmount({ event: e, numberOfPackage: e.target.value});
    };

    renderField = ({ input, label, type, fieldValue, placeholder, disabled }) => (
        <div className="form-group">
            <label>{label}</label>
            <div className="d-flex input-group">
                <input
                    {...input}
                    type={type}
                    className="form-control"
                    disabled={disabled}
                    value={!!fieldValue ? Number(fieldValue) : ""}
                    placeholder={placeholder}
                    onChange={this.onTextChange}
                    onKeyDown={this.onKeyDown}

                />
            </div>
            {Number(this.state.numberOfPackage) > Number(this.props.infoBuying && this.props.infoBuying.limit) && <p className="mt-2 text-danger">{`* ${sprintf(i18n.t('max_amount_package_error'), this.props.infoBuying.limit)}`}</p>}
            {this.state.numberOfPackage && !isNaturalNumber(this.state.numberOfPackage) && <p className="mt-2 text-danger">{`* ${sprintf(i18n.t('min_amount_package_error'), 0)}`}</p>}
        </div>
    );

    render() {
        const { numberOfPackage } = this.state;
        const { totalAmount, infoBuying, asset } = this.props;
        return (
            <I18n ns="translations">
                {
                    t => (
                        <div className='purchase-product-key'>
                            <h4 className='heading-key'>{t('purchase_a_repeat_order_code')}</h4>
                            <Field
                                name="amount"
                                type="number"
                                component={this.renderField}
                                label={t('number_of_package')}
                                fieldValue={numberOfPackage}
                                placeholder={t('enter_number_of_packages')}
                            />
                            {
                                isNaturalNumber(numberOfPackage) && Number(numberOfPackage) <= infoBuying.limit ?
                                <React.Fragment>
                                    <span>{`${t('total_amount')}: `}</span>
                                    <b className="text-success">{`${totalAmount} ${asset}`}</b>
                                </React.Fragment>
                                    : null
                            }
                        </div>
                    )
                }
            </I18n>
        )
    }
}

export default reduxForm({
    form: 'enterNumberOfPackage'
})(EnterNumberOfPackage);