import { ACTIONS } from '../01-actions/addressBook';
import { AUTH_ACTIONS } from '../01-actions';
import { UTIL_ACTIONS } from '../01-actions/utils';

const initialState = {
	status: null,
	contacts: null,
	isFetching: false,
	contactAdding: false,
	contactAdded: false,
	contactEditing: false,
	contactEdited: false,
	contactDeleting: false,
	contactDeleted: false,
	error: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.GET_ALL_CONTACTS_PROGRESS:
			return {
				...state,
				status: action.type,
				isFetching: true,
			};
		case ACTIONS.GET_ALL_CONTACTS_SUCCESS:
			return {
				...state,
				status: action.type,
				contacts: action.data.contacts,
				isFetching: false,
			};
		case ACTIONS.GET_ALL_CONTACTS_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				isFetching: false,
			};
		case ACTIONS.ADD_CONTACT_PROGRESS:
			return {
				...state,
				status: action.type,
				contactAdding: true,
			};
		case ACTIONS.ADD_CONTACT_SUCCESS:
			return {
				...state,
				status: action.type,
				contacts: action.data.contacts,
				contactAdding: false,
				contactAdded: true,
			};
		case ACTIONS.ADD_CONTACT_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				contactAdding: false,
			};
		case ACTIONS.RESET_ADD_CONTACT_STATUS:
			return {
				...state,
				status: action.type,
				contactAdding: false,
				contactAdded: false,
			};
		case ACTIONS.EDIT_CONTACT_PROGRESS:
			return {
				...state,
				status: action.type,
				contactEditing: true,
			};
		case ACTIONS.EDIT_CONTACT_SUCCESS:
			return {
				...state,
				status: action.type,
				contacts: action.data.contacts,
				contactEditing: false,
				contactEdited: true,
			};
		case ACTIONS.EDIT_CONTACT_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				contactEditing: false,
			};
		case ACTIONS.RESET_EDIT_CONTACT_STATUS:
			return {
				...state,
				status: action.type,
				contactEditing: false,
				contactEdited: false,
			};
		case ACTIONS.DELETE_CONTACT_PROGRESS:
			return {
				...state,
				status: action.type,
				contactDeleting: true,
			};
		case ACTIONS.DELETE_CONTACT_SUCCESS:
			return {
				...state,
				status: action.type,
				contacts: action.data.contacts,
				contactDeleting: false,
				contactDeleted: true,
			};
		case ACTIONS.DELETE_CONTACT_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				contactDeleting: false,
			};
		case ACTIONS.RESET_DELETE_CONTACT_STATUS:
			return {
				...state,
				status: action.type,
				contactDeleting: false,
				contactDeleted: false,
			};
		case AUTH_ACTIONS.LOGOUT:
			return initialState;
		case UTIL_ACTIONS.RESET_ERROR:
			return {
				...state,
				error: null,
			};
		default:
			return state;
	}
}