import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';

export default class MenuItem extends PureComponent {
    render() {
        const {
            activeClass,
            linkTo,
            closeMenu,
            srcImage,
            iconClass,
            text,
        } = this.props;
        return (
            <li className={`nav-item ${activeClass}`}>
                <NavLink onClick={closeMenu} className="nav-link" to={linkTo}>
                    {
                        srcImage ? <img className={iconClass} src={srcImage} alt="" />
                        : <i className={`menu-icon fa ${iconClass}`}/>
                    }
                    <span className="menu-title">{text}</span>
                </NavLink>
            </li>
        );
    }
}