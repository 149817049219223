import React, {Component} from 'react';
import { I18n } from 'react-i18next';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ACTIONS, searchAssetsFromTriam, importAssetFromTriam, getAllAsset, clearAssetSearch } from "../../../../01-actions/asset";
import Loading from '../../../../03-components/Loading';
import AssetLogo from "../../../../03-components/AssetLogo/assetLogo";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {pushNotify} from "../../../../03-components/Notification";
import i18n from "i18next";

class ImportAssetModal extends Component {
    constructor(props) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
    }

    state = {
        searchParam: '',
        isDoneRequest: false
    };

    handleClose() {
        this.props.onClose();
        this.props.actions.clearAssetSearch();
        this.setState({
            searchParam: '',
            isDoneRequest: false
        })
    }

    onKeySearchPress = e => {
        if (e.key === 'Enter') {
            if (this.state.searchParam !== '') {
                if ((this.state.searchParam).match(/^[a-zA-Z0-9]*$/)) {
                    this.props.actions.searchAssetsFromTriam({ assetName: this.state.searchParam });
                } else
                    pushNotify('warn', `${i18n.t('asset_name_not_allow_special_character')}`);
            } else
                pushNotify('warn', `${i18n.t('SEARCH_ASSET_FIELD_IS_REQUIRE')}`);
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.status !== ACTIONS.IMPORT_ASSET_FROM_TRIAM_SUCCESS && this.props.status === ACTIONS.IMPORT_ASSET_FROM_TRIAM_SUCCESS){
            this.props.actions.getAllAsset({});
        }
        if (prevProps.status !== ACTIONS.SEARCH_ASSET_FROM_TRIAM_SUCCESS && this.props.status === ACTIONS.SEARCH_ASSET_FROM_TRIAM_SUCCESS){
            this.setState({
                isDoneRequest: true
            })
        }
    }

    onSearchChange = e => {
        this.setState({ searchParam: e.target.value.trim(), isDoneRequest: false });
    };

    importAsset = (asset) => {
        this.props.actions.importAssetFromTriam({
            id: asset._id,
            asset_code: asset.name
        });
    };

    render() {
        const { assetsSearch, isFetching, assets} = this.props;

        // filter asset already import
        const listAssetsExist = assets && assets.map( asset => asset.name);

        return (
            <I18n ns="translations">
                {
                    t => (
                        <Modal isOpen={this.props.isOpen} className="modal-lg">
                            <ModalHeader>
                                <span>{t('import_asset')}</span>
                            </ModalHeader>
                            <ModalBody style={{'maxHeight': '400px', 'overflowY': 'auto'}}>
                                <div className="input-group margin-bottom-15px margin-top-15px">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t('search_asset')}
                                        onKeyPress={this.onKeySearchPress}
                                        onChange={this.onSearchChange}
                                        required
                                    />
                                </div>
                                {
                                    (this.state.isDoneRequest && assetsSearch.length) ?
                                    <div className="table-responsive">
                                        <table className="table balance-table">
                                            <tbody>
                                            {
                                                assetsSearch && assetsSearch.map((asset, index) => (
                                                    <tr key={index}>
                                                        <td className="py-1">
                                                            <AssetLogo src={asset.logo} assetName={asset.name}/>
                                                        </td>
                                                        <td>
                                                            <b className="font-18">{asset.name || "RIA"}</b>
                                                        </td>
                                                        <td className="text-right hide-small-screen">
                                                            {
                                                                listAssetsExist && !listAssetsExist.includes(asset.name) ?
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-info btn-fw"
                                                                        onClick={() => this.importAsset(asset)}
                                                                    > {t('import_')}
                                                                    </button>
                                                                    : null
                                                            }
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div> : null
                                }
                                {
                                    (this.state.isDoneRequest && !assetsSearch.length) &&
                                        <label className="text-danger">{t('no_asset_')}</label>
                                }
                                {
                                    isFetching && (<Loading parent1Class="text-center"/>)
                                }
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={this.handleClose}>{t('cancel')}</Button>
                            </ModalFooter>
                        </Modal>
                    )
                }
            </I18n>
        );
    }
}

const mapStateToProps = store => {
    return {
        status: store.asset.status,
        error: store.asset.error,
        assetsSearch: store.asset.assetsSearch,
        assetInfo: store.asset.assetInfo,
        isFetching: store.asset.isFetching,
        assets: store.asset.assets
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions : bindActionCreators(
            {
                searchAssetsFromTriam,
                importAssetFromTriam,
                getAllAsset,
                clearAssetSearch
            },
            dispatch
        )
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportAssetModal);