const ENV = {
	LCL: {
		REACT_APP_API_SERVER: 'http://localhost:4000',
		REACT_APP_DASHBOARD_URI: 'http://stg-dashboard.arm-system-holdings.com',
		URL_BACKGROUND: 'https://s3-ap-southeast-2.amazonaws.com/dev-triple-wallet/images/background-images/background',
		ENABLE_RESET_OP_PASS: true,
		ENABLE_PRODUCT_CODE_FOR_CHINA: true,
	},
	DEV: {
		REACT_APP_API_SERVER: 'https://dev-wallet-api.triple-tec.com',
		REACT_APP_DASHBOARD_URI: 'http://stg-dashboard.arm-system-holdings.com',
		URL_BACKGROUND: 'https://s3-ap-southeast-2.amazonaws.com/dev-triple-wallet/www-assets/images/background-images/background',
		ENABLE_RESET_OP_PASS: true,
		ENABLE_PRODUCT_CODE_FOR_CHINA: true,
	},
	STG: {
		REACT_APP_API_SERVER: 'https://stg-wallet-api.triple-tec.com',
		REACT_APP_DASHBOARD_URI: 'http://stg-dashboard.arm-system-holdings.com',
		URL_BACKGROUND: 'https://dev-greenbox-wallet.s3.amazonaws.com/www-assets/www-assets/images/background-images/background',
		ENABLE_RESET_OP_PASS: true,
		ENABLE_PRODUCT_CODE_FOR_CHINA: false,
	},
	PRO: {
		REACT_APP_API_SERVER: 'https://wallet-api.triple-tec.com',
		REACT_APP_DASHBOARD_URI: 'https://dashboard.triamnetwork.com',
		URL_BACKGROUND: 'https://prod-triple-wallet.s3.amazonaws.com/www-assets/images/background-images/background',
		ENABLE_RESET_OP_PASS: true,
		ENABLE_PRODUCT_CODE_FOR_CHINA: false,
	}
};

const config = ENV[process.env.REACT_APP_STAGE || "LCL"];

const getEnv = (name, defaultValue) => {
	return process.env[name] || config[name] || defaultValue;
};

export {getEnv};
