import React, { Component } from "react";
import { formatDateTimeFull, ezpayTransferStatus  } from "../../../../05-utils/commonUtils";
import { I18n } from 'react-i18next';
import { Collapse } from 'reactstrap';

export default class EZPayTransferItem extends Component {

    constructor(props){
        super(props);
        this.state = {
            isOpen: false
        };
    }

    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    render() {
        const { data } = this.props;
        const { isOpen } = this.state;
        if (!data) return null;
        let cardNumber = data.cardNumber
            ? data.cardNumber.substring(0,4) + "-****-****-" + data.cardNumber.substring(data.cardNumber.length -4 ,data.cardNumber.length)
            : null;

        return (
            <I18n ns="translations">
                {
                    t => (
                        <React.Fragment key={data._id}>
                            <tr className="hover-item-tb" onClick={this.toggle}>
                                <td>
                                    {formatDateTimeFull(data.createdAt)}
                                </td>
                                <td>
                                    {this.renderTopUpStatusType(data.resolveStatus, t)}
                                </td>
                                <td>
                                    <span className="text-right">
                                        {cardNumber }
                                    </span>
                                </td>
                                <td>
                                    <span className="text-right">
                                       {data.transactionID}
                                    </span>
                                </td>
                                <td>
                                    <span className="text-right">
                                        {`${data.totalTransferTCT} TCT`}
                                    </span>
                                </td>
                                <td>
                                    <i className={`fa fa-chevron-${isOpen ? "up" : "down"} text-primary`}/>
                                </td>
                            </tr>
                            <tr>
								<td colSpan={7}>
									<Collapse isOpen={isOpen}>
                                        <table  className="w-100">
                                            <tbody>
                                                <tr>
                                                   <td>
                                                        <span> {t('request_amount')} </span>
                                                   </td>
                                                    <td>
                                                         <span>{data.requestAmount || 0}</span>
                                                    </td>
                                                    <td className='w-50'>
                                                        <span> {t('TCT')} </span>
                                                    </td>
                                                </tr>
                                                { data.amountAGTNeed ?
                                                    <tr>
                                                        <td>
                                                            <span> {t('otc_amount')} </span>
                                                        </td>
                                                        <td>
                                                            <span>{data.amountAGTNeed || 0}</span>
                                                        </td>
                                                        <td>
                                                            <span> {t('AGT')} </span>
                                                        </td>
                                                    </tr>
                                                : null }
                                                { data.amountAGTNeed ?
                                                    <tr>
                                                        <td>
                                                            <span> {t('fee_otc')} </span>
                                                        </td>
                                                        <td>
                                                            <span>{data.totalOTCFee || 0}</span>
                                                        </td>
                                                        <td>
                                                            <span> {t('AGT')} </span>
                                                        </td>
                                                    </tr>
                                                : null }
                                                <tr>
                                                   <td>
                                                        <span> {t('total_topup_amount')} </span>
                                                    </td>
                                                    <td>
                                                        <span>{data.totalTransferTCT || 0}</span>
                                                    </td>
                                                    <td>
                                                        <span> {t('TCT')} </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                   <td>
                                                        <span> {t('top_up_fee')} </span>
                                                    </td>
                                                    <td>
                                                        <span>{data.transferFeeTopUp || 0}</span>
                                                    </td>
                                                    <td>
                                                        <span> {t('TCT')} </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
									</Collapse>
								</td>
							</tr>
                        </React.Fragment>
                    )
                }
            </I18n>
        );
    }

    renderTopUpStatusType = (status, t) => {
		switch (status) {
            case ezpayTransferStatus.PENDING:
                return <span className="badge badge-pill badge-warning text-small p-1 top-up-status">{t('pending')}</span>
            case ezpayTransferStatus.APPROVED:
                return <span className="badge badge-pill badge-success text-small p-1 top-up-status">{t('approved')}</span>
            case ezpayTransferStatus.SUCCEED:
                return <span className="badge badge-pill badge-info text-small p-1 top-up-status">{t('succeed_')}</span>
            case ezpayTransferStatus.REJECT:
                return <span className="badge badge-pill badge-danger text-small p-1 top-up-status">{t('reject')}</span>

			default:
                return <span className="badge badge-pill badge-warning text-small p-1 top-up-status">{t('pending')}</span>
		}
	};
}
