import I18n from 'i18next';
import { constants } from '../../../05-utils/commonData';
import { sprintf } from 'sprintf-js';

const validate = values => {
    const errors = {};
    if(!values.firstname) {
        errors.firstname = I18n.t('FIRST_NAME_REQUIRED')
    }
    if (values.firstname && values.firstname.length > constants.MAX_LENGTH_NAME_OF_USER) {
        errors.firstname = sprintf(
            I18n.t('NAME_MUST_LESS_THAN'),
            constants.MAX_LENGTH_NAME_OF_USER,
        )
    }

    if(!values.lastname) {
        errors.lastname = I18n.t('LAST_NAME_REQUIRED')
    }
    if (values.lastname && values.lastname.length > constants.MAX_LENGTH_NAME_OF_USER) {
        errors.lastname = sprintf(
            I18n.t('NAME_MUST_LESS_THAN'),
            constants.MAX_LENGTH_NAME_OF_USER,
        )
    }

    return errors;
}

export default validate;