import React, {Component} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import './style.css';

import Error403Page from "./403/index";
import Error404Page from "./404/index";


export default class ErrorPage extends Component {
	render() {
		return (
			<div className="pages-error">
				<Switch>
					<Route path="/error/403" component={Error403Page}/>
					<Route path="/error/404" component={Error404Page}/>
					<Redirect to="/error/404"/>
				</Switch>
			</div>
		);
	}
}