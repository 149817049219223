import React, {Component} from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { I18n } from 'react-i18next';
import Paginate from 'react-paginate';
import i18n from 'i18next';

import { pushNotify } from '../../../../03-components/Notification';
import './security.css';
import {
	getUserInfo,
	changeTFALoginRequire,
	resetChangeTFALoginRequireStatus,
	resetGaResetStatus,
	getLoginHistory,
	pagingLoginHistory,
	resetGaVerifyStatus,
} from "../../../../01-actions/user";
import { formatDateTimeFull } from '../../../../05-utils/commonUtils';
import ResetGaModal from './ResetGaModal/resetGaModal';
import SetGaModal from './SetGaModal/setGaModal';
import Loading from '../../../../03-components/Loading';

class Security extends Component {
	state= {
		loginTFARequire: false,
		isOpenResetGA: false,
		isOpenSetGA: false,
	};

	componentDidMount() {
		if (!this.props.userInfo)
			this.props.actions.getUserInfo();
		this.props.actions.getLoginHistory();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.TFARequireLoginChanged && nextProps.TFARequireLoginChanged !== this.props.TFARequireLoginChanged) {
			pushNotify('success', i18n.t('TFA_LOGIN_REQUIRE_CHANGE_SUCCESSFULLY'));
			nextProps.actions.getUserInfo();
			nextProps.actions.resetChangeTFALoginRequireStatus();
		}
		if (nextProps.loginHistory !== this.props.loginHistory) {
			nextProps.actions.pagingLoginHistory(nextProps.loginHistory);
		}
		if (nextProps.userInfo && nextProps.userInfo !== this.props.userInfo) {
			this.setState({ loginTFARequire: nextProps.userInfo.loginTFARequire });
		}
		if (nextProps.gaReset && nextProps.gaReset !== this.props.gaReset) {
			pushNotify('success', i18n.t('GA_RESET_SUCCESSFULLY'));
			this.setState({ isOpenResetGA: false });
			nextProps.actions.resetGaResetStatus();
			nextProps.actions.getUserInfo();
		}
		if (nextProps.gaVerified && nextProps.gaVerified !== this.props.gaVerified) {
			pushNotify('success', i18n.t('GA_VERIFIED_SUCCESSFULLY'));
			this.setState({ isOpenSetGA: false });
			nextProps.actions.resetGaVerifyStatus();
			nextProps.actions.getUserInfo();
		}
	}

	onChangeTFARequire = () => {
		this.setState({ loginTFARequire : !this.state.loginTFARequire }, () => {
			this.props.actions.changeTFALoginRequire({ loginTFARequire: this.state.loginTFARequire });
		});
	};

	onPageChange = data => {
		const { actions, loginHistory } = this.props;
		actions.pagingLoginHistory(loginHistory, data.selected);
	};

	toggleResetGA = () => {
		this.setState({ isOpenResetGA: !this.state.isOpenResetGA });
	};

	toggleSetGA = () => {
		this.setState({ isOpenSetGA: !this.state.isOpenSetGA });
	};

	render() {
		const {
			userInfo,
			loginHistory,
			displayLoginHistory,
			limit,
		} = this.props;
		return (
			<I18n ns="translations">
				{
					t => (
						<div>
							{
								userInfo ?
									(<div className="row">
										{
											userInfo.userClass.includes(999) && (
												<div className="col-sm-12 col-md-12">
													<h3><label>{t('tfa_verification')}</label></h3>
													<div className="tfa-wrapper">
														<div>{t('tfa_verification_intro')}</div>
														<div><b>{t('status')}</b></div>
														<div className="margin-bottom-15px">
															{
																userInfo.tfaEnabled ?
																	(<span className="tfa-status bg-success">
																		<i className="mdi mdi-checkbox-marked-circle" />{t('enabled_')}
																	</span>)
																	:
																	(<span className="tfa-status bg-danger">
																		<i className="mdi mdi-close-circle" />{t('disabled_')}
																	</span>)
															}
															<button className="btn btn-link">{t('why_should_i_keep_tfa_enabled')}</button>
														</div>
														<div><b>{t('preferred_tfa_method')}</b></div>
														<div className="margin-bottom-15px ga-wrapper">
															<div className="radio form-radio">
																<label className="form-check-label">
																	<input
																		type="radio"
																		className="form-check-input"
																		defaultChecked={userInfo.verifyGA}
																		onClick={e => e.preventDefault()}
																	/>
																	{t('google_authenticator')}
																	<i className="input-helper" />
																</label>
															</div>
															<div className="ga-verify">
																{
																	userInfo.verifyGA ?
																		(<span className="tfa-status bg-success">
																			<i className="mdi mdi-checkbox-marked-circle" />{t('verified_')}
																		</span>)
																		:
																		(<span className="tfa-status bg-danger">
																			<i className="mdi mdi-close-circle" />{t('not_verified_')}
																		</span>)
																}
															</div>
														</div>
														{
															userInfo.verifyGA ?
																(<div>
																	<div className="form-check form-check-flat margin-bottom-15px">
																		<label className="form-check-label">
																			<input
																				type="checkbox"
																				className="form-check-input"
																				checked={this.state.loginTFARequire}
																				onChange={this.onChangeTFARequire}
																			/>
																			{t('tfa_required_when_login')}
																			<i className="input-helper" />
																		</label>
																	</div>
																	<div className="form-check form-check-flat margin-bottom-15px">
																		<button className="btn btn-danger" onClick={this.toggleResetGA}>
																			<span><i className="mdi mdi-reload" />{t('reset_')}</span>
																		</button>
																	</div>
																</div>)
																:
																	(<div className="alert alert-danger">
																		<strong>{t('tfa_disabled_intro')}</strong>
																		<div className="text-center margin-top-15px">
																			<button className="btn btn-info" onClick={this.toggleSetGA}>
																				<span><i className="mdi mdi-settings" />{t('setup')}</span>
																			</button>
																		</div>
																	</div>)
														}
														<ResetGaModal
															isOpen={this.state.isOpenResetGA}
															toggle={this.toggleResetGA}
														/>
														<SetGaModal
															isOpen={this.state.isOpenSetGA}
															toggle={this.toggleSetGA}
														/>
													</div>
												</div>
											)
										}
										<div className="col-sm-12 col-md-12 margin-top-15px">
											<h3><label>{t('account_verification')}</label></h3>
											<div className="email-wrapper">
												<div className="margin-bottom-15px">{t('verify_account_intro')}</div>
												<div><b>{t('status')}</b></div>
												<div>
													{
														userInfo.verifyEmail ?
															(<span>
																<i className="text-success fa fa-check"/>
															</span>)
															:
															(<span>
																<i className="text-danger fa fa-times"/>
															</span>)
													}
													<span>{t('email_')}</span>
													<b>{userInfo.email}</b>
													{
														userInfo.verifyEmail ?
															(<span className="bg-success" aria-hidden="false">
																<i className="fa fa-check-circle"/>{t('verified_')}
															</span>)
															:
															(<span className="bg-danger">
																<i className="mdi mdi-close-circle"/>{t('not_verified_')}
															</span>)
													}
												</div>
											</div>
										</div>
									</div>)
									:
									(<Loading parent1Class="text-center"/>)
							}
							<div className="row login-history">
								<div className="col-sm-12 col-md-12">
									<h3><label>{t('recent_login_history')}</label></h3>
									<div className="table-responsive">
										<table className="table table-hover">
											<thead>
											<tr>
												<th>
													{t('date')}
												</th>
												<th>
													{t('ip_address')}
												</th>
												<th>
													{t('geo_location')}
												</th>
												<th>
													{t('status')}
												</th>
											</tr>
											</thead>
											<tbody>
											{
												displayLoginHistory ?
													displayLoginHistory.map((history, index) => (
														<tr key={index}>
															<td>
																{formatDateTimeFull(history.date)}
															</td>
															<td>
																{history.ipAddress}
															</td>
															<td>
																{history.geoLocation}
															</td>
															<td>
																{
																	history.status ?
																		(<i className="mdi mdi-checkbox-marked-circle-outline text-success"/>)
																		:
																		(<i className="mdi mdi-close-circle-outline text-danger"/>)
																}
															</td>
														</tr>
													))
													:
													(<Loading isTableRow colSpan={4}/>)
											}
											</tbody>
										</table>
										{
											displayLoginHistory && loginHistory.length > limit ?
												(<div className="paginate-wrapper text-center">
													<Paginate
														pageCount={loginHistory.length / limit}
														pageRangeDisplayed={3}
														marginPagesDisplayed={3}
														previousLabel="<"
														nextLabel=">"
														breakLabel="..."
														onPageChange={this.onPageChange}
														disabledClassName="paginate-disabled"
													/>
												</div>)
												:
												null
										}
									</div>
								</div>
							</div>
						</div>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		userInfo: store.user.userInfo,
		TFARequireLoginChanged: store.user.TFARequireLoginChanged,
		loginHistory: store.user.loginHistory,
		displayLoginHistory: store.user.displayLoginHistory,
		limit: store.user.limit,
		gaReset: store.user.gaReset,
		gaVerified: store.user.gaVerified,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				getUserInfo,
				changeTFALoginRequire,
				resetChangeTFALoginRequireStatus,
				resetGaResetStatus,
				getLoginHistory,
				pagingLoginHistory,
				resetGaVerifyStatus,
			},
			dispatch
		)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Security);
