import React, { Component } from 'react';
import { I18n } from "react-i18next";
import RadioInput from '../RadioInput/radioInput';
import { sprintf } from 'sprintf-js';
import { constants } from '../../05-utils/commonData';

class ChooseTheWayToShip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numberOfShipping: 0,
        }
    }

    onChangeWayToShip = numberOfShipping => {
        this.setState({ numberOfShipping });
        this.props.onChangeWayToShip(numberOfShipping);
    };

    render() {
        const { numberOfShipping } = this.state;
        const { detailBuying, asset} = this.props;
        const { ONCE_TIME, IN_12_TIMES } = constants.WAY_TO_SHIP;
        const buyOnceTime = detailBuying.find(item => item.shippingType === ONCE_TIME);
        const buyManyTimes = detailBuying.find(item => item.shippingType === IN_12_TIMES);
        return (
            <I18n ns="translations">
                {
                    t => (
                        <div className='purchase-product-key'>
                            <h4 className='heading-key'>{t('purchase_a_repeat_order_code')}</h4>
                            <div className='ml-5 mb-3'>
                                <RadioInput
                                    checked={numberOfShipping === ONCE_TIME}
                                    onChange={() => this.onChangeWayToShip(ONCE_TIME)}
                                    text={sprintf(t('delivery_at_the_same_time'), buyOnceTime.amount, asset)}
                                />
                                <RadioInput
                                    checked={numberOfShipping === IN_12_TIMES}
                                    onChange={() => this.onChangeWayToShip(IN_12_TIMES)}
                                    text={sprintf(t('monthly_delivery_for_12_months'), buyManyTimes.amount, asset, buyManyTimes.price, buyManyTimes.fee, buyManyTimes.shippingType)}
                                />
                            </div>
                        </div>
                    )
                }
            </I18n>
        )
    }
}

export default ChooseTheWayToShip;