import React, { Component } from 'react';
import { getUserFromLocalStorage } from '../../05-utils/commonUtils';
import { getMaintenanceModeStatus } from '../../01-actions/system';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoadingScreen from 'react-loading-screen';

class HomePage extends Component {
	componentDidMount() {
		if (this.props.isCheckedMaintenance && !this.props.maintenanceMode) {
			this.redirect();
		}
	}

	componentWillReceiveProps(nextProps) {
		if (!this.props.isCheckedMaintenance
			&& nextProps.isCheckedMaintenance
			&& !nextProps.maintenanceMode
		){
			this.redirect();
		}
	}

	redirect = () => {
		let token = getUserFromLocalStorage();
		if (token) this.props.history.push('/wallet');
		else this.props.history.push('/login');
	}

	render() {
		return (
			<LoadingScreen
				loading={true}
				bgColor='white'
				spinnerColor='green'
				textColor='#676767'
				logoSrc='/images/logo.png'
				text=''
			>
				<div>Triple Wallet</div>
			</LoadingScreen>
		);
	}
}

const mapStateToProps = store => {
	return {
		isCheckedMaintenance: store.system.isCheckedMaintenance,
		maintenanceMode: store.system.maintenanceMode,
		isFetchingMaintenanceModeStatus: store.system.isFetchingMaintenanceModeStatus,
		isChangingMaintenanceModeStatus: store.system.isChangingMaintenanceModeStatus,
	}
};

const mapDispatchToProps = dispatch => {
	return {
		actions: bindActionCreators(
			{
				getMaintenanceModeStatus,
			},
			dispatch
		)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);