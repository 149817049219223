import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';
import en from './06-assets/translations/en';
import ch from './06-assets/translations/ch';

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(reactI18nextModule)
	.init({
		fallbacks: true,
		fallbackLng: 'en',
		whitelist: [
			// 'ja',
			'en',
			'ch'
		],

		ns: ['translations'],
		defaultNS: 'translations',

		debug: false,

		react: {
			wait: true
		},

		resources: {
			en: {
				translations: en
			},
			// ja: {
			// 	translations: ja
			// },
			ch: {
				translations: ch
			}
		}
	});

export default i18n;
