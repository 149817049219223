export default {
    connect: (socket) => {

    },

    restoreDBSub: (socket, actions) => {
      socket.on('notification', function (message) {
			switch (message.type_i) {
				case 10: {
					let logsProgressElm;
					if (message.data.isFetching) {
						logsProgressElm = document.getElementsByClassName('logs-fetch-progress');
					}

					if (message.data.isRestoring) {
						logsProgressElm = document.getElementsByClassName('logs-restore-progress');
					}

					if (message.data.isDone) {
						logsProgressElm = document.getElementsByClassName('logs-done-progress');
						actions.restoreSuccessStatus();
					}

					if (logsProgressElm.length > 0) {
						logsProgressElm[0].innerHTML = message.data.status;
					} else {
						actions.restoreProgressStatus();
					}
					break;
				}
				default:
					console.log(message.data);
			}
		});
    }
}
