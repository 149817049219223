import { createSelector } from 'reselect';
import { errors, constants } from '../05-utils/commonData';

const getErrorAddressBook = state => state.addressBook.error;
const getErrorAdminDB = state => state.adminDB.error;
const getErrorAdminNews = state => state.adminNews.error;
const getErrorAdminUser = state => state.adminUser.error;
const getErrorAdminWallet = state => state.adminWallet.error;
const getErrorAsset = state => state.asset.error;
const getErrorAuth = state => state.auth.error;
const getErrorEmail = state => state.email.error;
const getErrorSocket = state => state.socket.error;
const getErrorSystem = state => state.system.error;
const getErrorTopUp = state => state.topUp.error;
const getErrorTransaction = state => state.transaction.error;
const getErrorUser = state => state.user.error;
const getErrorWallet = state => state.wallet.error;
const getErrorProductCode = state => state.productCode.error;
const getErrorRepeatOrderCode = state => state.repeatOrderCode.error;

const getAllErrors = createSelector(
    [
        getErrorAddressBook,
        getErrorAdminDB,
        getErrorAdminNews,
        getErrorAdminUser,
        getErrorAdminWallet,
        getErrorAsset,
        getErrorAuth,
        getErrorEmail,
        getErrorSocket,
        getErrorSystem,
        getErrorTopUp,
        getErrorTransaction,
        getErrorUser,
        getErrorWallet,
        getErrorProductCode,
        getErrorRepeatOrderCode
    ],
    (
        addressBookErr,
        adminDBErr,
        adminNewsErr,
        adminUserErr,
        adminWalletErr,
        assetErr,
        authErr,
        emailErr,
        socketErr,
        systemErr,
        topUpErr,
        transactionErr,
        userErr,
        walletErr,
        productCodeErr,
        repeatOrderCodeErr
    ) => {
        const errorsList = [
            addressBookErr,
            adminDBErr,
            adminNewsErr,
            adminUserErr,
            adminWalletErr,
            assetErr,
            authErr,
            emailErr,
            socketErr,
            systemErr,
            topUpErr,
            transactionErr,
            userErr,
            walletErr,
            productCodeErr,
            repeatOrderCodeErr
        ];
        return errorsList.find(error => error
            && error.errorCode !== errors.SERVER_IS_MAINTENANCE.errorCode
            && !constants.tokenErrors.includes(error.errorCode)
            );
    }
);

export {
    getAllErrors,
}