import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { I18n } from 'react-i18next';
import SubmitButton from '../ModalSubmitButton';

export default class ConfirmCancelRequestCode extends Component {
	render() {
		const {
			isOpen,
			toggle,
			onSubmit,
			isSubmitting,
		} = this.props;
		return (
			<I18n ns="translations">
				{
					t => (
						<Modal isOpen={isOpen} toggle={toggle} className="modal-lg confirm-reset-user">
							<ModalHeader toggle={toggle}>{t('important')}</ModalHeader>
							<ModalBody>
								<div className="text-center">
									<i className="fa fa-exclamation-circle text-danger"/>
									<p className="delete-contact-intro">{t('cancel_request_code_confirm')}</p>
								</div>
							</ModalBody>
							<ModalFooter>
								<button
									className="btn btn-light"
									onClick={this.props.toggle}
								>
									{t('cancel')}
								</button>
								<SubmitButton
									className="btn btn-danger"
									onClick={onSubmit}
									isSubmitting={isSubmitting}
									text={t('cancel_request')}
								/>
							</ModalFooter>
						</Modal>
					)
				}
			</I18n>
		);
	}
}
