import React, { Component } from 'react';
import { I18n } from 'react-i18next';
import { t } from 'i18next';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Paginate from 'react-paginate';
import Loading from '../../../../03-components/Loading';
import {constants} from "../../../../05-utils/commonData";
import {getListRepeatOrderCodesInfo} from "../../../../01-actions/repeatOrderCode";
import '../../index.css'
import {formatDateTimeFull} from "../../../../05-utils/commonUtils";

class RepeatOrderCodeManagement extends Component {
    state = {
        searchParam: '',
    };

    componentDidMount() {
        this.props.actions.getListRepeatOrderCodesInfo({})
    }

    onKeySearchPress = e => {
        if (e.key === 'Enter')
            this.props.actions.getListRepeatOrderCodesInfo({ searchParam: this.state.searchParam});
    };

    onSearchChange = e => {
        this.setState({ searchParam: e.target.value.trim() }, () => {
            if (this.state.searchParam === '')
                this.props.actions.getListRepeatOrderCodesInfo({});
        });
    };

    onPageChange = data => {
        this.props.actions.getListRepeatOrderCodesInfo({
            searchParam: this.state.searchParam,
            numPage: data.selected,
        });
    };

    renderType = (type) => {
        switch (type) {
            case constants.TYPE_REPEAT_ORDER_CODE.RETAIL:
                return <label className='color-danger'>{t('retail')}</label>;
            case constants.TYPE_REPEAT_ORDER_CODE.WHOLESALE:
                return <label className='color-success'>{t('wholesale')}</label>;
            default:
                return <label className='color-primary'>{type}</label>;
        }
    };
    renderStatus = (status) => {
        switch (status) {
            case constants.PRODUCT_CODE_STATUS.NEW:
                return <span className="badge badge-primary">{t('new')}</span>;
            case constants.PRODUCT_CODE_STATUS.USED:
                return <span className="badge badge-success">{t('used')}</span>;
            case constants.PRODUCT_CODE_STATUS.REJECTED:
                return <span className="badge badge-danger">{t('rejected')}</span>;
            default:
                return <span className="badge badge-primary">{status}</span>
        }
    };

    render() {
        const {
            listRepeatOrderCodes,
            totalRepeatOrderCodes,
            isFetchingRepeatOrderCode
        } = this.props;
        return (
            <I18n ns="translations">
                {
                    (t, {i18n}) => (
                        <div className="row">
                            <div className="col-lg-12 grid-margin">
                                <div className="card">
                                    <h4 className="card-title">{t('repeat_order_code_management')}</h4>
                                    <div className='row'>
                                        <div className="col-12 input-group margin-bottom-15px margin-top-15px">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={t('search_code')}
                                                onKeyPress={this.onKeySearchPress}
                                                onChange={this.onSearchChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-hover">
                                            <thead>
                                            <tr>
                                                <th>
                                                    #
                                                </th>
                                                <th>
                                                    {t('repeat_order_code')}
                                                </th>
                                                <th>
                                                    {t('user_code')}
                                                </th>
                                                <th>
                                                    {t('type')}
                                                </th>
                                                <th>
                                                    {t('unit_price')}
                                                </th>
                                                <th>
                                                    {t('fee')}
                                                </th>
                                                <th>
                                                    {t('number_of_package')}
                                                </th>
                                                <th>
                                                    {t('number_of_shipping')}
                                                </th>
                                                <th>
                                                    {t('status')}
                                                </th>
                                                <th>
                                                    {t('used_by')}
                                                </th>
                                                <th>
                                                    {t('created_at')}
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                isFetchingRepeatOrderCode ? <Loading parent1Class="text-center"/>
                                                :
                                                Array.isArray(listRepeatOrderCodes) && listRepeatOrderCodes.length > 0 ?
                                                    listRepeatOrderCodes.map((code, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                {index + 1}
                                                            </td>
                                                            <td>
                                                                {code.code}
                                                            </td>
                                                            <td>
                                                                {code.userCode}
                                                            </td>
                                                            <td className="text-primary">
                                                                {this.renderType(code.type)}
                                                            </td>
                                                            <td>
                                                                {code.unitPrice}
                                                            </td>
                                                            <td>
                                                                {code.fee}
                                                            </td>
                                                            <td>
                                                                {code.numberOfPackage}
                                                            </td>
                                                            <td>
                                                                {code.numberOfShipping}
                                                            </td>
                                                            <td className="text-primary">
                                                                {this.renderStatus(code.status)}
                                                            </td>
                                                            <td className="text-primary">
                                                                {code.usedBy}
                                                            </td>
                                                            <td>
                                                                {formatDateTimeFull(code && code.createdAt)}
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    (<tr className="text-center">
                                                        <td colSpan={12}>
                                                            <div>{t('no_data_to_show')}</div>
                                                        </td>
                                                    </tr>)
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        totalRepeatOrderCodes ? <div className="paginate-wrapper text-center margin-top-15px">
                                            <Paginate
                                                pageCount={totalRepeatOrderCodes / 50}
                                                pageRangeDisplayed={3}
                                                marginPagesDisplayed={3}
                                                previousLabel="<"
                                                nextLabel=">"
                                                breakLabel="..."
                                                onPageChange={this.onPageChange}
                                                disabledClassName="paginate-disabled"
                                            />
                                        </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
            </I18n>
        );
    }
}

const mapStateToProps = store => {
    return {
        status: store.productCode.status,
        listRepeatOrderCodes: store.productCode.listRepeatOrderCodes,
        totalRepeatOrderCodes: store.productCode.totalRepeatOrderCodes,
        isFetchingRepeatOrderCode: store.productCode.isFetchingRepeatOrderCode
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions : bindActionCreators(
            {
                getListRepeatOrderCodesInfo
            },
            dispatch
        )
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RepeatOrderCodeManagement);