import React, {Component} from 'react';
import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';
import {login, loginWithCode, getAuthStatus} from '../../01-actions/auth';
import './login.css';
import {bindActionCreators, compose} from "redux";
import {I18n} from 'react-i18next';
import {oauthPopup, isSuperAdmin} from "../../05-utils/commonUtils";
import {constants} from "../../05-utils/commonData";
import {isMaintenanceMode} from '../../07-selectors';
import LoginForm from './form';
import ChangeLanguageBar from '../../03-components/ChangeLanguageBar';
import AuthenBackgroundWrapper from '../Common/AuthenBackgroundWrapper/AuthenBackgroundWrapper';

class LoginPage extends Component {
    componentDidMount() {
        this.props.actions.getAuthStatus();
    }

    componentWillReceiveProps(nextProps) {
        const {isFetchingUserInfo, token, userInfo, isMaintenanceMode} = nextProps;

        // wait for lgogin success
        if (!token) return;
        // login success
        if (!isMaintenanceMode) {
            this.props.history.replace('/wallet');
            return;
        }
        //maintenance mode, wait for get user info, then check user is super admin
        if (this.props.isFetchingUserInfo && !isFetchingUserInfo) {
            if (userInfo && isSuperAdmin(userInfo)) {
                this.props.history.push('/wallet');
            } else {
                this.props.history.push('/maintenance');
            }
        }
    }

    renderField = ({input, label, type, fieldValue, meta: {touched, error}}) => (
        <div className="form-group">
            <label>{label}</label>
            <div className="input-group">
                <input
                    {...input}
                    type={type}
                    className="form-control login-input"
                    value={fieldValue}
                    onChange={e => this.setState({[input.name]: e.target.value.trim()})}
                />
            </div>
            {touched && error && <p className="text-danger">{`* ${error}`}</p>}
        </div>
    );

    openRobinDialog = () => {
        oauthPopup({
            width: 500,
            height: 550,
            path: constants.OAUTH_ROBIN,
            windowName: "windowName",
            callback: (code) => this.props.actions.loginWithCode({code})
        });
    };

    render() {
        const {userInfo, loggingIn, history, isOpenTFAModal, actions} = this.props;

        return (
            <I18n ns="translations">
                {
                    (t, {i18n}) => (
                        <div className="container-scroller">
                            <div className="container-fluid page-body-wrapper full-page-wrapper auth-page">
                                <ChangeLanguageBar i18n={i18n}/>
                                <AuthenBackgroundWrapper>
                                    <div className="login-form-wrapper">
                                        <div className="row w-100 login-middle">
                                            <div className="col-xs-12 col-md-8 col-lg-6 mx-auto">
                                                <div className="auth-custom-card row">
                                                    <div className="col-sm-12 auth-form">
                                                        <div className="text-center">
                                                            <div className="logo-wrapper">
                                                                <img src="/images/auth/logo.png" alt=""/>
                                                                <br/>
                                                                <h3 className="text-center margin-top-15px font-text text-color">{t('wallet_login')}</h3>
                                                            </div>
                                                        </div>
                                                        <LoginForm
                                                            userInfo={userInfo}
                                                            loggingIn={loggingIn}
                                                            onSubmit={actions.login}
                                                            history={history}
                                                            isOpenTFAModal={isOpenTFAModal}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="footer-text text-center">
										<span className="d-block text-center">
											<span className="text-white">Copyright © 2019 </span>
											<a href="/" target="_blank"
                                               rel="noopener noreferrer">Triple</a>
											<span className="text-white">. All rights reserved.</span>
										</span>
                                    </p>
                                </AuthenBackgroundWrapper>
                            </div>
                        </div>
                    )
                }
            </I18n>
        );
    }
}

const mapStateToProps = store => {
    return {
        loggingIn: store.auth.loggingIn,
        token: store.auth.token,
        isOpenTFAModal: store.auth.isOpenTFAModal,
        isMaintenanceMode: isMaintenanceMode(store),
        isFetchingUserInfo: store.user.isFetching,
        userInfo: store.user.userInfo,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(
            {
                login,
                getAuthStatus,
                loginWithCode,
            },
            dispatch
        )
    };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(LoginPage);
