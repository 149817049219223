import { ACTIONS } from '../01-actions/adminNews';
import { AUTH_ACTIONS } from '../01-actions';
import { UTIL_ACTIONS } from '../01-actions/utils';

const initialState = {
	status: null,
	listNews: null,
	newsCreating: false,
	newsCreated: false,
	newsEditing: false,
	newsEdited: false,
	newsDeleting: false,
	newsDeleted: false,
	error: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.GET_ALL_NEWS_PROGRESS:
			return {
				...state,
				status: action.type,
			};
		case ACTIONS.GET_ALL_NEWS_SUCCESS:
			return {
				...state,
				status: action.type,
				listNews: action.data.listNews,
			};
		case ACTIONS.GET_ALL_NEWS_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
			};
		case ACTIONS.CREATE_NEWS_PROGRESS:
			return {
				...state,
				status: action.type,
				newsCreating: true,
			};
		case ACTIONS.CREATE_NEWS_SUCCESS:
			return {
				...state,
				status: action.type,
				newsCreated: true,
				newsCreating: false,
			};
		case ACTIONS.CREATE_NEWS_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				newsCreating: false,
			};
		case ACTIONS.EDIT_NEWS_PROGRESS:
			return {
				...state,
				status: action.type,
				newsEditing: true,
			};
		case ACTIONS.EDIT_NEWS_SUCCESS:
			return {
				...state,
				status: action.type,
				newsEditing: false,
				newsEdited: true,
			};
		case ACTIONS.EDIT_NEWS_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				newsEditing: false,
			};
		case ACTIONS.DELETE_NEWS_PROGRESS:
			return {
				...state,
				status: action.type,
				newsDeleting: true,
			};
		case ACTIONS.DELETE_NEWS_SUCCESS:
			return {
				...state,
				status: action.type,
				newsDeleting: false,
				newsDeleted: true,
			};
		case ACTIONS.DELETE_NEWS_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				newsDeleting: false,
			};
		case ACTIONS.RESET_CREATE_NEWS_STATUS:
			return {
				...state,
				status: action.type,
				newsCreated: false,
			};
		case ACTIONS.RESET_EDIT_NEWS_STATUS:
			return {
				...state,
				status: action.type,
				newsEdited: false,
			};
		case ACTIONS.RESET_DELETE_NEWS_STATUS:
			return {
				...state,
				status: action.type,
				newsDeleted: false,
			};
		case AUTH_ACTIONS.LOGOUT:
			return initialState;
		case UTIL_ACTIONS.RESET_ERROR:
			return {
				...state,
				error: null,
			};
		default:
			return state;
	}
}