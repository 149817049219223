import React, { PureComponent } from 'react';
import Switch from "react-switch";
import Loading from '../Loading';

export default class SystemSetting extends PureComponent {
	render() {
		const {
			text,
			checked,
			onChange,
			isFetching,
			isChanging,
		} = this.props;
		return (
			<tr>
				<td>
					<b>{text}</b>
				</td>
				<td className="text-center">
				{
					isFetching ?
						(<Loading/>)
						:
						(<Switch
							onChange={onChange}
							checked={!!checked}
							disabled={isChanging}
						/>)
				}
				</td>
			</tr>
		);
	}
};