
import { ACTIONS } from '../01-actions/productCode';
import { AUTH_ACTIONS } from '../01-actions';
import { UTIL_ACTIONS } from '../01-actions/utils';

const initialState = {
    status: null,
    listCodes: null,
    totalCodes: null,
    error: null,
    isFetching: false,
    productCodes: [],
    totalProductCode: 0,
    listTitle: [],
    code: null,
    isFetchingTitles: false,
    productCodeCreating: false,
    productCodeCreated: false,
    isFetchingSummary: false,
    summary: {
        totalAvailable:  0,
        totalPurchased: 0,
        totalRejected: 0,
        time: null
    },
    requestCodes: [],
    totalRequests: null,
    requestCode: null,
    requestProductCodeCreating: false,
    requestProductCodeCreated: false,
    isFetchingProductCode: false,
    isFetchingRequestCode: false,
    isCancellingRequest: false,
    isCancelledRequest: false,
    listRepeatOrderCodes: [],
    isFetchingRepeatOrderCode: false,
    totalRepeatOrderCodes: 0,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.GET_LIST_PRODUCT_CODES_INFO_PROGRESS:
            return {
                ...state,
                status: action.type,
                isFetching: true,
            };
        case ACTIONS.GET_LIST_PRODUCT_CODES_INFO_SUCCESS:
            return {
                ...state,
                status: action.type,
                listCodes: action.data.listCodes,
                totalCodes: action.data.totalCodes ? action.data.totalCodes : state.totalCodes,
                isFetching: false,
            };
        case ACTIONS.GET_LIST_PRODUCT_CODES_INFO_FAILED:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
                isFetching: false,
            };
        case ACTIONS.ADMIN_REJECT_PRODUCT_CODE_PROGRESS:
            return {
                ...state,
                status: action.type,
            };
        case ACTIONS.ADMIN_REJECT_PRODUCT_CODE_SUCCESS:
            return {
                ...state,
                status: action.type
            };
        case ACTIONS.ADMIN_REJECT_PRODUCT_CODE_FAILED:
            return {
                ...state,
                status: action.type,
                error: action.data.error
            };
        case ACTIONS.GET_USER_PRODUCT_CODES_PROGRESS:
            return {
                ...state,
                status: action.type,
                isFetchingProductCode: true,
            };
        case ACTIONS.GET_USER_PRODUCT_CODES_FAILED:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
                isFetchingProductCode: false,
            };
        case ACTIONS.GET_USER_PRODUCT_CODES_SUCCESS:
            return {
                ...state,
                productCodes: action.data.productCodes,
                totalProductCode: action.data.totalProductCode,
                isFetchingProductCode: false,
            };
        case ACTIONS.GET_USER_REQUEST_PRODUCT_CODES_PROGRESS:
            return {
                ...state,
                isFetchingRequestCode: true,
                status: action.type,
            };
        case ACTIONS.GET_USER_REQUEST_PRODUCT_CODES_SUCCESS:
            return {
                ...state,
                isFetchingRequestCode: false,
                requestCodes: action.data.requestCodes,
                totalRequests: action.data.totalRequests
            };
        case ACTIONS.GET_USER_REQUEST_PRODUCT_CODES_FAILED:
            return {
                ...state,
                status: action.type,
                isFetchingRequestCode: false,
                error: action.data.error
            };
        case ACTIONS.GET_LIST_TITLE_BY_REGION_PROGRESS:
            return {
                ...state,
                status: action.type,
                isFetchingTitles: true
            };
        case ACTIONS.GET_LIST_TITLE_BY_REGION_FAILED:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
                isFetchingTitles: false
            };
        case ACTIONS.GET_LIST_TITLE_BY_REGION_SUCCESS:
            return {
                ...state,
                listTitle: action.data.listTitle,
                isFetchingTitles: false
            };
        case ACTIONS.CREATE_REQUEST_PRODUCT_CODE_PROGRESS:
            return {
                ...state,
                status: action.type,
                requestProductCodeCreating: true,
            };
        case ACTIONS.CREATE_REQUEST_PRODUCT_CODE_FAILED:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
                requestProductCodeCreating: false,
            };
        case ACTIONS.CREATE_REQUEST_PRODUCT_CODE_SUCCESS:
            return {
                ...state,
                requestCode: action.data.requestCode,
                requestCodes: [action.data.requestCode, ...state.requestCodes],
                requestProductCodeCreated: true,
                requestProductCodeCreating: false,
            };
        case ACTIONS.RESET_STATUS_CREATE_PRODUCT_CODE:
            return {
                ...state,
                productCodeCreated: false,
            };
        case ACTIONS.GET_SUMMARY_PRODUCT_CODE_PROGRESS:
            return {
                ...state,
                status: action.type,
                isFetchingSummary: true,
            };
        case ACTIONS.GET_SUMMARY_PRODUCT_CODE_FAILED:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
                isFetchingSummary: false,
            };
        case ACTIONS.GET_SUMMARY_PRODUCT_CODE_SUCCESS:
            return {
                ...state,
                summary: action.data.summary,
                isFetchingSummary: false,
            };
        case ACTIONS.CREATE_PRODUCT_CODE_PROGRESS:
            return {
                ...state,
                status: action.type,
                productCodeCreating: true,
            };
        case ACTIONS.CREATE_PRODUCT_CODE_FAILED:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
                productCodeCreating: false,
            };
        case ACTIONS.CREATE_PRODUCT_CODE_SUCCESS:
            return {
                ...state,
                code: action.data.code,
                productCodes: [action.data.code, ...state.productCodes],
                productCodeCreated: true,
                productCodeCreating: false,
            };
        case ACTIONS.RESET_STATUS_CREATE_REQUEST_PRODUCT_CODE:
            return {
                ...state,
                requestProductCodeCreated: false,
            }
        case ACTIONS.CANCEL_REQUEST_PRODUCT_CODE_PROGRESS:
            return {
                ...state,
                status: action.type,
                isCancellingRequest: true,
                isCancelledRequest: false,
            };
        case ACTIONS.CANCEL_REQUEST_PRODUCT_CODE_SUCCESS:
            return {
                ...state,
                status: action.type,
                isCancellingRequest: false,
                isCancelledRequest: true,
                requestCodes: state.requestCodes.filter(item => item._id !== action.data.requestCodeId)
            };
        case ACTIONS.CANCEL_REQUEST_PRODUCT_CODE_FAILED:
            return {
                ...state,
                status: action.type,
                isCancellingRequest: false,
                isCancelledRequest: false,
                error: action.data.error
            };
        case ACTIONS.GET_LIST_REPEAT_ORDER_CODES_INFO_PROGRESS:
            return {
                ...state,
                status: action.type,
                isFetchingRepeatOrderCode: true,
            };
        case ACTIONS.GET_LIST_REPEAT_ORDER_CODES_INFO_SUCCESS:
            return {
                ...state,
                status: action.type,
                listRepeatOrderCodes: action.data.listCodes,
                totalRepeatOrderCodes: action.data.totalRepeatOrderCodes ? action.data.totalRepeatOrderCodes : state.totalRepeatOrderCodes,
                isFetchingRepeatOrderCode: false,
            };
        case ACTIONS.GET_LIST_REPEAT_ORDER_CODES_INFO_FAILED:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
                isFetchingRepeatOrderCode: false,
            };
        case ACTIONS.NOTIFY_NEW_PRODUCT_CODE_CREATED:
            return {
                ...state,
                status: action.type,
                requestCodes: state.requestCodes.filter(item => item._id !== action.data.requestCodeId),
                productCodes: [action.data.code, ...state.productCodes],
                totalProductCode: state.totalProductCode + 1,
            };
        case AUTH_ACTIONS.LOGOUT:
            return initialState;
        case UTIL_ACTIONS.RESET_ERROR:
            return {
                ...state,
                error: null,
            };
        default:
            return state;
    }
}