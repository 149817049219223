import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { I18n } from 'react-i18next';

import SubmitButton from '../../../../03-components/ModalSubmitButton';
import { deleteContact } from "../../../../01-actions/addressBook";
import './confirmDelete.css';

class DeleteContact extends Component {
	deleteContact = () => {
		this.props.actions.deleteContact({ contactId: this.props.contactId });
		this.props.toggle();
	};
	
	render() {
		const {
			isOpen,
			toggle,
			contactDeleting,
		} = this.props;
		return (
			<I18n ns="translations">
				{
					t => (
						<Modal isOpen={isOpen} toggle={toggle} className="modal-lg modal-delete-contact">
							<ModalHeader toggle={toggle}>{t('warning')}</ModalHeader>
							<ModalBody>
								<div className="text-center">
									<i className="fa fa-exclamation-triangle text-warning"/>
									<p className="delete-contact-intro">{t('delete_address_book_confirm')}</p>
								</div>
							</ModalBody>
							<ModalFooter>
								<SubmitButton
									className="btn btn-light"
									onClick={this.props.toggle}
									text={t('cancel')}
								/>
								<SubmitButton
									className="btn btn-danger"
									onClick={this.deleteContact}
									isSubmitting={contactDeleting}
									text={t('delete')}
								/>
							</ModalFooter>
						</Modal>
					)
				}
			</I18n>
		);
	}
}

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				deleteContact,
			},
			dispatch
		)
	};
};

export default connect(
	null,
	mapDispatchToProps,
)(DeleteContact);
