import { ACTIONS } from '../01-actions/adminWallet';
import { AUTH_ACTIONS } from '../01-actions';
import { UTIL_ACTIONS } from '../01-actions/utils';

const initialState = {
	status: null,
	firstWallets: null,
	displayFirstWallets: null,
	limit: null,
	userWallets: null,
	isFetching: false,
	walletUnblocking: false,
	walletUnblocked: false,
	multiFirstWalletCreating: false,
	multiFirstWalletCreated: false,
	error: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ACTIONS.GET_ALL_FIRST_WALLET_NON_USER_PROGRESS:
			return {
				...state,
				status: action.type,
			};
		case ACTIONS.GET_ALL_FIRST_WALLET_NON_USER_SUCCESS:
			return {
				...state,
				status: action.type,
				firstWallets: action.data.firstWallets,
			};
		case ACTIONS.GET_ALL_FIRST_WALLET_NON_USER_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
			};
		case ACTIONS.ADMIN_GET_ALL_USER_WALLET_PROGRESS:
			return {
				...state,
				status: action.type,
				isFetching: true,
			};
		case ACTIONS.ADMIN_GET_ALL_USER_WALLET_SUCCESS:
			return {
				...state,
				status: action.type,
				isFetching: false,
				userWallets: action.data.userWallets,
			};
		case ACTIONS.ADMIN_GET_ALL_USER_WALLET_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				isFetching: false,
			};
		case ACTIONS.ADMIN_UNBLOCK_USER_WALLET_PROGRESS:
			return {
				...state,
				status: action.type,
				walletUnblocking: true,
			};
		case ACTIONS.ADMIN_UNBLOCK_USER_WALLET_SUCCESS:
			return {
				...state,
				status: action.type,
				walletUnblocking: false,
				walletUnblocked: true,
			};
		case ACTIONS.ADMIN_UNBLOCK_USER_WALLET_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				walletUnblocking: false,
			};
		case ACTIONS.ADMIN_SEND_USER_WALLET_INFORMATION_PROGRESS:
			return {
				...state,
				status: action.type
			};
		case ACTIONS.ADMIN_SEND_USER_WALLET_INFORMATION_SUCCESS:
			return {
				...state,
				status: action.type
			};
		case ACTIONS.ADMIN_SEND_USER_WALLET_INFORMATION_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error
			};
		case ACTIONS.CREATE_MULTI_FIRST_WALLET_PROGRESS:
			return {
				...state,
				status: action.type,
				multiFirstWalletCreating: true,
			};
		case ACTIONS.CREATE_MULTI_FIRST_WALLET_SUCCESS:
			return {
				...state,
				status: action.type,
				multiFirstWalletCreating: false,
				multiFirstWalletCreated: true,
			};
		case ACTIONS.CREATE_MULTI_FIRST_WALLET_FAILED:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				multiFirstWalletCreating: false,
			};
		case ACTIONS.RESET_CREATE_MULTI_FIRST_WALLET_STATUS:
			return {
				...state,
				status: action.type,
				error: action.data.error,
				multiFirstWalletCreated: false,
			};
		case ACTIONS.PAGING_FIRST_WALLET:
			return {
				...state,
				status: action.type,
				displayFirstWallets: action.data.displayFirstWallets,
				limit: action.data.limit,
			};
		case ACTIONS.RESET_UNBLOCK_USER_WALLET_STATUS:
			return {
				...state,
				status: action.type,
				walletUnblocked: false,
			};
		case ACTIONS.RESET_ERROR_ADMIN_WALLET_API_MESSAGE:
			return {
				...state,
				error: null,
			};
		case AUTH_ACTIONS.LOGOUT:
			return initialState;
		case UTIL_ACTIONS.RESET_ERROR:
			return {
				...state,
				error: null,
			};
		default:
			return state;
	}
}