import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { I18n } from 'react-i18next';
import {
	restoreDatabase,
	resetRestoreDatabaseStatus,
} from '../../../../../01-actions/adminDB';
import SubmitButton from '../../../../../03-components/ModalSubmitButton';
// import './ConfirmRestore.css';

class ConfirmRestore extends Component {
	restoreDatabase = () => {
		const { actions, backupFileName } = this.props;
		actions.restoreDatabase({ backupFileName });
	};

	render() {
		const {
			isOpen,
			toggle,
			databaseRestoring,
			backupFileName,
		} = this.props;

		return (
			<I18n ns="translations">
				{
					t => (
						<Modal isOpen={isOpen} toggle={toggle} className="modal-lg modal-delete-contact">
							<ModalHeader toggle={toggle}>{t('warning')}</ModalHeader>
							<ModalBody>
								<div style={!!databaseRestoring ? {display: 'none'} : {}} className="text-center">
									<i className="fa fa-exclamation-triangle text-warning"/>
									<p className="delete-contact-intro">{t('restore_database_intro')}</p>
								</div>
								<div className="card card-body bg-dark logs-monitor" style={!databaseRestoring ? {display: 'none'} : {} }>
									<pre className="text-light">
										<code className="title">Running restore database from file: ${backupFileName}</code> <br></br>
										<code className="logs-fetch-progress"></code> <br></br>
										<code className="logs-restore-progress"></code> <br></br>
										{!!databaseRestoring ? <code className="spinner">...</code> : null}
										<code className="logs-done-progress"></code> <br></br>
									</pre>
								</div>
							</ModalBody>
							<ModalFooter>
								<SubmitButton
									className="btn btn-light"
									onClick={this.props.toggle}
									text={t('cancel')}
								/>
								<SubmitButton
									className="btn btn-danger"
									onClick={this.restoreDatabase}
									isSubmitting={databaseRestoring}
									text={t('restore')}
								/>
							</ModalFooter>
						</Modal>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		databaseRestoring: store.adminDB.databaseRestoring,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				restoreDatabase,
				resetRestoreDatabaseStatus,
			},
			dispatch
		)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ConfirmRestore);
