import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// components
import SubmitButton from '../../../../03-components/ModalSubmitButton';

import {I18n} from "react-i18next";
import ChooseTheWayToShip from '../../../../03-components/ChooseTheWayToShip/chooseTheWayToShip';
import BigNumber from "bignumber.js";
import {constants} from "../../../../05-utils/commonData";

class Request12MonthsRepeatOrderCodeModal extends Component {
    state = {
        numberOfShipping: 0,
    };

    componentWillReceiveProps(nextProps) {
        if (!this.props.requestRepeatOrderCodeCreated && nextProps.requestRepeatOrderCodeCreated) {
            this.props.onOpenNotify();
            this.refreshInputAndToggle();
        }
    }

    request12MonthsRepeatOrderCode = () => {
        const {
            numberOfShipping
        } = this.state;
        this.props.onCreateRequest({
            numberOfShipping
        })
    };

    onChangeWayToShip = numberOfShipping => {
        this.setState({ numberOfShipping });
        const { detailBuying } = this.props;
        const infoBuying = detailBuying.find(item => item.shippingType === numberOfShipping);
        const amount = infoBuying.price;
        this.setState({
            amount
        })
    };

    refreshInputAndToggle = () => {
        this.setState({ numberOfShipping: 0 });
    };

    render() {
        const {
            isOpen,
            requestRepeatOrderCodeCreating,
            onBack,
            infoBuying,
            asset,
            detailBuying
        } = this.props;

        const {
            numberOfShipping
        } = this.state;
        return (
            <I18n ns="translations">
                {
                    t => (
                        <Modal isOpen={isOpen} className="modal-lg">
                            <ModalHeader toggle={() => {
                                this.refreshInputAndToggle();
                                this.props.toggle();
                            }}>
                                {t('create_new_code')}
                            </ModalHeader>
                            <ModalBody>
                                <ChooseTheWayToShip
                                    onChangeWayToShip={this.onChangeWayToShip}
                                    infoBuying={infoBuying}
                                    asset={asset}
                                    detailBuying={detailBuying}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <SubmitButton
                                    className="btn btn-info"
                                    text={t('back')}
                                    onClick={onBack}
                                />
                                <SubmitButton
                                    className="btn btn-primary"
                                    onClick={this.request12MonthsRepeatOrderCode}
                                    disabled={!numberOfShipping}
                                    text={t('generate')}
                                    isSubmitting={requestRepeatOrderCodeCreating}
                                />
                            </ModalFooter>
                        </Modal>
                    )
                }
            </I18n>
        );
    }
}

export default Request12MonthsRepeatOrderCodeModal;