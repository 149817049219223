import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-i18next';
import i18n from "i18next";
import { Collapse } from 'reactstrap';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { pushNotify } from '../../../../03-components/Notification';
import {
    getUserRequestProductCodes,
    cancelRequestCode,
} from '../../../../01-actions/productCode';
import ConfirmCancelRequestCode from '../../../../03-components/ConfirmCancelRequestCode/confirmCancelRequestCode';
import RequestCodeDetail from '../../../../03-components/RequestCodeDetail/requestCodeDetail';
import Loading from '../../../../03-components/Loading';
import CountDownItem from '../../../../03-components/CountDownItem/countDownItem';

class RequestRegisterCodeList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            requestCodes: props.requestCodes,
            requestOpenPosition: -1,
            selectedRequestIdToCancel: "",
            isOpenCancelRequest: false,
        }
    }


    componentDidMount() {
        const {
            getUserRequestProductCodes,
        } = this.props.actions;
        getUserRequestProductCodes({});
    }

    componentWillReceiveProps(nextProps) {
        const { requestCodes, isCancelledRequest } = nextProps;
        if (requestCodes !== this.state.requestCodes) {
            this.setState({ requestCodes });
        }
        if (!this.props.isCancelledRequest && isCancelledRequest) {
            pushNotify('success', i18n.t('CANCEL_REQUEST_CODE_SUCCESSFULLY'));
            this.toggleCancelRequest();
            this.setState({ selectedRequestIdToCancel: "" });
        }
    }

    onPageChange = data => this.props.actions.getUserProductCodes({
        numPage: data.selected,
    });

    onItemClick = position => {
        const { requestOpenPosition } = this.state;
        if (requestOpenPosition === position) {
            this.setState({ requestOpenPosition: -1 });
        }
        else {
            this.setState({ requestOpenPosition: position });
        }
    }

    onCancelRequest = () => {
        this.props.actions.cancelRequestCode({ id: this.state.selectedRequestIdToCancel });
    }

    toggleCancelRequest = () => {
        this.setState({ isOpenCancelRequest: !this.state.isOpenCancelRequest });
    }

    onPressCancel = id => {
        this.toggleCancelRequest();
        this.setState({ selectedRequestIdToCancel: id })
    }

    render() {
        const {
            isCancellingRequest,
            isFetchingRequestCode,
        } = this.props;
        const {
            requestCodes,
            requestOpenPosition,
            isOpenCancelRequest,
        } = this.state;

        return (
            <I18n ns="translations">
                {
                    t => (
                        <div className='col-lg-12'>
                            <h4 className='card-title'>{t('request_code')}</h4>
                            <div className='table-responsive list-purchased-key mt-3'>
                                {
                                    isFetchingRequestCode ?
                                        <Loading parent1Class="text-center" />
                                        :
                                        <table className='table table-hover wrapper-table-code'>
                                            <thead>
                                                <tr>
                                                    <th/>
                                                    <th>
                                                        {t('remaining_time')}
                                                    </th>
                                                    <th>
                                                        {t('address')}
                                                    </th>
                                                    <th>
                                                        {t('title_name')}
                                                    </th>
                                                    <th>
                                                        {t('amount')}
                                                    </th>
                                                    <th>
                                                        {t('actions')}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    !!requestCodes.length ? requestCodes.map((requestCode, index) =>
                                                            <React.Fragment key={index}>
                                                                <tr className="hover-item-tb item-purchased-key">
                                                                    <td className="history-transaction-icon">
                                                                        <button className="btn btn-link" onClick={() => this.onItemClick(index)}>
                                                                            <i className={`fa fa-chevron-${index === requestOpenPosition ? "up" : "down"}`} />
                                                                        </button>
                                                                    </td>
                                                                    <td className='wrapper-btn-copied'>
                                                                        <CountDownItem
                                                                            date={requestCode.expiredAt}
                                                                        />
                                                                    </td>
                                                                    <td className='text-primary'>
                                                                        {requestCode.address}
                                                                        <CopyToClipboard text={requestCode.address} onCopy={() => pushNotify('success', t('copied'))}>
                                                                            <button className="btn btn-link">
                                                                                <i className="far fa-copy" />
                                                                            </button>
                                                                        </CopyToClipboard>
                                                                    </td>
                                                                    <td>{requestCode.titleName}</td>
                                                                    <td><b className="text-success">{`${requestCode.amount} ${requestCode.asset}`}</b></td>
                                                                    <td className='text-primary'>
                                                                        <button
                                                                            className="btn btn-danger"
                                                                            onClick={() => this.onPressCancel(requestCode._id)}
                                                                        >
                                                                            {t('cancel')}
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={7}>
                                                                        <Collapse isOpen={index === requestOpenPosition}>
                                                                            <div className="transaction-detail align-items-center row">
                                                                                <RequestCodeDetail
                                                                                    asset={requestCode.asset}
                                                                                    amount={requestCode.amount}
                                                                                    address={requestCode.address}
                                                                                    createdAt={requestCode.createdAt}
                                                                                    expiredAt={requestCode.expiredAt}
                                                                                    t={t}
                                                                                />
                                                                            </div>
                                                                        </Collapse>
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        )
                                                : (<tr className="text-center">
                                                        <td colSpan={7}>
                                                            <div>{t('no_data_to_show')}</div>
                                                        </td>
                                                    </tr>)
                                                }
                                            </tbody>
                                        </table>
                                }
                                <ConfirmCancelRequestCode
                                    isOpen={isOpenCancelRequest}
                                    toggle={this.toggleCancelRequest}
                                    onSubmit={this.onCancelRequest}
                                    isSubmitting={isCancellingRequest}
                                />
                            </div>
                        </div>
                    )
                }
            </I18n>
        );
    }
}

const mapStateToProps = store => {
    return {
        requestCodes: store.productCode.requestCodes,
        isCancellingRequest: store.productCode.isCancellingRequest,
        isCancelledRequest: store.productCode.isCancelledRequest,
        isFetchingRequestCode: store.productCode.isFetchingRequestCode,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(
            {
                getUserRequestProductCodes,
                cancelRequestCode
            },
            dispatch
        )
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestRegisterCodeList);