import { callApi } from "../05-utils/commonUtils";
import { updateStatus } from "./utils";

const ACTIONS = {
	GET_ALL_ASSETS_PROGRESS: "GET_ALL_ASSETS_PROGRESS",
	GET_ALL_ASSETS_SUCCESS: "GET_ALL_ASSETS_SUCCESS",
	GET_ALL_ASSETS_FAILED: "GET_ALL_ASSETS_FAILED",
	CREATE_TRUST_LINE_PROGRESS: "CREATE_TRUST_LINE_PROGRESS",
	CREATE_TRUST_LINE_SUCCESS: "CREATE_TRUST_LINE_SUCCESS",
	CREATE_TRUST_LINE_FAILED: "CREATE_TRUST_LINE_FAILED",
	FILTER_ASSETS: "FILTER_ASSETS",
	SELECT_ASSET: "SELECT_ASSET",
	RESET_TRUST_STATUS: "RESET_TRUST_STATUS",
	RESET_FILTER_ASSET_RESULT_STATUS: "RESET_FILTER_ASSET_RESULT_STATUS",
	SEARCH_ASSET_FROM_TRIAM_PROGRESS: "SEARCH_ASSET_FROM_TRIAM_PROGRESS",
	SEARCH_ASSET_FROM_TRIAM_SUCCESS: "SEARCH_ASSET_FROM_TRIAM_SUCCESS",
	SEARCH_ASSET_FROM_TRIAM_FAILED: "SEARCH_ASSET_FROM_TRIAM_FAILED",
	IMPORT_ASSET_FROM_TRIAM_PROGRESS: "IMPORT_ASSET_FROM_TRIAM_PROGRESS",
	IMPORT_ASSET_FROM_TRIAM_SUCCESS: "IMPORT_ASSET_FROM_TRIAM_SUCCESS",
	IMPORT_ASSET_FROM_TRIAM_FAILED: "IMPORT_ASSET_FROM_TRIAM_FAILED",
	REMOVE_ASSET_PROGRESS: "REMOVE_ASSET_PROGRESS",
	REMOVE_ASSET_SUCCESS: "REMOVE_ASSET_SUCCESS",
	REMOVE_ASSET_FAILED: "REMOVE_ASSET_FAILED",
	CLEAR_ASSET_SEARCH: "CLEAR_ASSET_SEARCH"
};

const getAllAsset = (payload) => {
	const data = {
		name: "get_all_assets",
		params: {
			with_issuer_info: payload && payload.with_issuer_info
		},
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.GET_ALL_ASSETS_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.GET_ALL_ASSETS_SUCCESS, { assets: response.result }));
				else {
					dispatch(updateStatus(ACTIONS.GET_ALL_ASSETS_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("getAllAsset", err);
				dispatch(updateStatus(ACTIONS.GET_ALL_ASSETS_FAILED, {error: err}));
			});
	};
};

const createTrustLine = payload => {
	const data = {
		name: "create_trust_line",
		params: {
			address: payload.address,
			assetCode: payload.assetCode,
			opPass: payload.opPass,
			code: payload.code,
		},
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.CREATE_TRUST_LINE_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.CREATE_TRUST_LINE_SUCCESS, {}));
				else {
					dispatch(updateStatus(ACTIONS.CREATE_TRUST_LINE_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("createTrustLine", err);
				dispatch(updateStatus(ACTIONS.CREATE_TRUST_LINE_FAILED, {error: err}));
			});
	};
};

const filterAssets = (assets, keySearch) => {
	const assetsFiltered = keySearch === "" ? assets : assets.filter(asset => asset.name.includes(keySearch.toUpperCase()));
	return dispatch => dispatch(updateStatus(ACTIONS.FILTER_ASSETS, { assetsFiltered }));
};

const selectAsset = asset => {
	return dispatch => dispatch(updateStatus(ACTIONS.SELECT_ASSET, { asset }));
};

const resetTrustStatus = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_TRUST_STATUS, {}));

const resetFilterAssetResult = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_FILTER_ASSET_RESULT_STATUS, {}));

const searchAssetsFromTriam = (params) => {

	const data = {
		name: "search_asset_from_triam",
		params: { assetName: params.assetName },
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.SEARCH_ASSET_FROM_TRIAM_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.SEARCH_ASSET_FROM_TRIAM_SUCCESS, { assets: response.result }));
				else {
					dispatch(updateStatus(ACTIONS.SEARCH_ASSET_FROM_TRIAM_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("searchAssetsFromTriam", err);
				dispatch(updateStatus(ACTIONS.SEARCH_ASSET_FROM_TRIAM_FAILED, {error: err}));
			});
	};
};

const importAssetFromTriam = (params) => {
	const data = {
		name: "import_asset_from_triam",
		params: {
			id: params.id,
			asset_code: params.asset_code
		},
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.IMPORT_ASSET_FROM_TRIAM_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.IMPORT_ASSET_FROM_TRIAM_SUCCESS, { asset: response.result }));
				else {
					dispatch(updateStatus(ACTIONS.IMPORT_ASSET_FROM_TRIAM_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("importAssetFromTriam", err);
				dispatch(updateStatus(ACTIONS.IMPORT_ASSET_FROM_TRIAM_FAILED, {error: err}));
			});
	};
};

const removeAsset = (params) => {
	const data = {
		name: "remove_asset",
		params: {
			id: params.id
		},
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.REMOVE_ASSET_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.REMOVE_ASSET_SUCCESS));
				else {
					dispatch(updateStatus(ACTIONS.REMOVE_ASSET_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("removeAsset", err);
				dispatch(updateStatus(ACTIONS.REMOVE_ASSET_FAILED, {error: err}));
			});
	};
};

const clearAssetSearch = () => dispatch => dispatch(updateStatus(ACTIONS.CLEAR_ASSET_SEARCH, {}));

export {
	ACTIONS,
	getAllAsset,
	createTrustLine,
	filterAssets,
	selectAsset,
	resetTrustStatus,
	resetFilterAssetResult,
	searchAssetsFromTriam,
	importAssetFromTriam,
	removeAsset,
	clearAssetSearch
};