import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Steps from 'rc-steps';

// components
import SelectWallet from '../../../../03-components/SelectWallet/selectWallet';
import ConfirmAddress from '../../../../03-components/ConfirmAddress/confirmAddress';
import SubmitButton from '../../../../03-components/ModalSubmitButton';
import OPPassModal from "../../../../03-components/OPPassModal/OPPassModal";
import {I18n} from "react-i18next";
import ChooseTheWayToShip  from '../../../../03-components/ChooseTheWayToShip/chooseTheWayToShip';
import {constants} from "../../../../05-utils/commonData";
import BigNumber from "bignumber.js";

class Create12MonthsRepeatOrderCodeModal extends Component {
    state = {
        currentStep: 0,
        disabled: false,
        numberOfShipping: 0,
        walletAddress: '',
        isOpenOPPass: false,
        amount: 0,
    };

    steps = ['choose_the_way_to_ship', 'choose_wallet', 'finish'];

    componentWillReceiveProps(nextProps) {
        if (!this.props.repeatOrderCodeCreated  && nextProps.repeatOrderCodeCreated) {
            this.refreshInputAndToggle();
        }
    };

    toggleOPPass = () => this.setState({ isOpenOPPass: !this.state.isOpenOPPass });

    switchModal = () => {
        const {
            currentStep
        } = this.state;

        if (currentStep === 0) {
            this.setState({
                currentStep: 1
            })
        } else if (currentStep === 1) {
            this.setState({
                currentStep: 2
            })
        } else {
            this.setState({
                isOpenOPPass: true
            });
        }
    };

    onReceiveOPPass = opPass => {
        const {
            numberOfShipping,
            walletAddress
        } = this.state;
        this.props.onCreate({
            numberOfShipping,
            sourceAddress: walletAddress,
            opPass
        })
    };

    handleSelectAddress = (data) => {
        this.setState({
            walletAddress: data
        })
    };

    onChangeWayToShip = numberOfShipping => {
        this.setState({ numberOfShipping });
        const { detailBuying } = this.props;
        const infoBuying = detailBuying.find(item => item.shippingType === numberOfShipping);
        const amount = Number(infoBuying.amount);
        this.setState({
            amount
        })
    };

    refreshInputAndToggle = () => {
        this.setState({
            currentStep: 0,
            disabled: false,
            numberOfShipping: 0,
            walletAddress: '',
            isOpenOPPass: false,
            amount: 0
        });
    };

    onBack = () => {
        if (this.state.currentStep === 0)
            this.props.onBack();
        else
            this.setState({
                currentStep: this.state.currentStep -1
            })
    };

    render() {
        const {
            isOpen,
            userWallets,
            repeatOrderCodeCreating,
            infoBuying,
            asset,
            detailBuying
        } = this.props;

        const {
            currentStep,
            walletAddress,
            isOpenOPPass,
            numberOfShipping,
            amount
        } = this.state;
        return (
            <I18n ns="translations">
                {
                    t => (
                        <Modal isOpen={isOpen} className="modal-lg">
                            <ModalHeader toggle={() => {
                                this.refreshInputAndToggle();
                                this.props.toggle();
                            }}>
                                {t('create_new_code')}
                            </ModalHeader>
                            <ModalBody>
                                <Steps current={currentStep} labelPlacement="vertical" progressDot size="small">
                                    { this.steps && this.steps.map((step, index) => (
                                        <Steps.Step title={t(`${step}`)} key={index}/>
                                    ))}
                                </Steps>
                                {
                                    currentStep === 0 &&
                                    <ChooseTheWayToShip
                                        onChangeWayToShip={this.onChangeWayToShip}
                                        infoBuying={infoBuying}
                                        asset={asset}
                                        detailBuying={detailBuying}
                                    />
                                }
                                {
                                    currentStep === 1 &&
                                    <SelectWallet
                                        userWallets={userWallets}
                                        handleSelectAddress={this.handleSelectAddress}
                                        walletAddress={walletAddress}
                                        minAmountAccepted={amount}
                                    />
                                }
                                {
                                    currentStep === 2 &&
                                    <ConfirmAddress
                                        totalAmount={amount}
                                        walletSelected={walletAddress}
                                        numberOfShipping={numberOfShipping}
                                        type={constants.TYPE_REPEAT_ORDER_CODE.WHOLESALE}
                                    />
                                }

                                <OPPassModal
                                    isOpen={isOpenOPPass}
                                    toggle={this.toggleOPPass}
                                    onSubmit={this.onReceiveOPPass}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <SubmitButton
                                    className="btn btn-info"
                                    text={t('back')}
                                    onClick={this.onBack}
                                />
                                <SubmitButton
                                    className="btn btn-primary"
                                    onClick={this.switchModal}
                                    disabled={(currentStep === 1 && !walletAddress) || (currentStep === 0 && !numberOfShipping)}
                                    text={currentStep === 2 ? t('generate') : t('confirm')}
                                    isSubmitting={currentStep === 2 ? repeatOrderCodeCreating : false}
                                />
                            </ModalFooter>
                        </Modal>
                    )
                }
            </I18n>
        );
    }
}

export default Create12MonthsRepeatOrderCodeModal;