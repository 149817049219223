import { constants } from "../../../05-utils/commonData";
import I18n from 'i18next';

const validate = values => {
	const errors = {};
	if (!values.password) {
		errors.password = I18n.t('PASSWORD_REQUIRED');
	}
	if (!values.privateKey) {
		errors.privateKey = I18n.t('PRIVATE_KEY_REQUIRED');
	}
	else if (values.privateKey.length !== constants.WALLET_KEY_LENGTH) {
		errors.privateKey = I18n.t('PRIVATE_KEY_INVALID');
	}
	return errors;
};

export default validate;