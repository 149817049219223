import React, { Component } from 'react';
import { I18n } from 'react-i18next';
import { getListUserInfo } from "../../../01-actions/adminUser";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getUserName, isChineseUser } from "../../../05-utils/commonUtils";
import Paginate from 'react-paginate';
import Loading from '../../../03-components/Loading';

class UserManagement extends Component {
	state = {
		searchParam: '',
	};
	
	componentDidMount() {
		this.props.actions.getListUserInfo({});
	}

	onKeySearchPress = e => {
		if (e.key === 'Enter')
			this.props.actions.getListUserInfo({ searchParam: this.state.searchParam });
	};

	onSearchChange = e => {
		this.setState({ searchParam: e.target.value.trim() }, () => {
			if (this.state.searchParam === '')
				this.props.actions.getListUserInfo({});
		});
		
	};
	
	onPageChange = data => this.props.actions.getListUserInfo({
		searchParam: this.state.searchParam,
		numPage: data.selected,
	});
	
	render() {
		const { totalUser, users, isFetching } = this.props;
		
		return (
			<I18n ns="translations">
				{
					(t, {i18n}) => (
						<div className="row">
							<div className="col-lg-12 grid-margin">
								<div className="card">
									<h4 className="card-title">{t('user_management')}</h4>
									<div className="input-group margin-bottom-15px margin-top-15px">
										<input
											type="text"
											className="form-control"
											placeholder={t('search_user')}
											onKeyPress={this.onKeySearchPress}
											onChange={this.onSearchChange}
										/>
									</div>
									<div className="table-responsive">
										<table className="table table-hover">
											<thead>
											<tr>
												<th>
													#
												</th>
												<th>
													{t('email_or_usercode')}
												</th>
												<th>
													{t('name')}
												</th>
												{/*<th className="text-center">*/}
												{/*	{t('ga_verified')}*/}
												{/*</th>*/}
												<th className="text-right">
													{t('actions')}
												</th>
											</tr>
											</thead>
											<tbody>
											{
												users ?
													users.map((user, index) => (
														<tr key={index}>
															<td>
																{user.index}
															</td>
															<td className="text-primary">
																{isChineseUser(user.userCode) ? user.userCode : user.email}
															</td>
															<td>
																{getUserName(user)}
															</td>
															{/*<td className="text-center">*/}
															{/*	{*/}
															{/*		user.verifyGA ?*/}
															{/*			(<i className="text-success fa fa-check"/>)*/}
															{/*			:*/}
															{/*			(<i className="text-danger fa fa-times"/>)*/}
															{/*	}*/}
															{/*</td>*/}
															<td className="text-right">
																<a role="button" className="btn btn-info btn-sm" href={`/admin/user/${user.armId}`} target="_blank" rel="noopener noreferrer">
																	{t('profile')}
																</a>
															</td>
														</tr>
													))
													:
													null
											}
											</tbody>
										</table>
									</div>
									{
										users &&
											(<div className="paginate-wrapper text-center margin-top-15px">
												<Paginate
													pageCount={totalUser / 50}
													pageRangeDisplayed={3}
													marginPagesDisplayed={3}
													previousLabel="<"
													nextLabel=">"
													breakLabel="..."
													onPageChange={this.onPageChange}
													disabledClassName="paginate-disabled"
												/>
											</div>)
									}
									{
										isFetching && (<Loading parent1Class="text-center"/>)
									}
								</div>
							</div>
						</div>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		users: store.adminUser.users,
		totalUser: store.adminUser.totalUser,
		isFetching: store.adminUser.isFetching,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				getListUserInfo,
			},
			dispatch
		)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);