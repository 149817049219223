import { callApi} from "../05-utils/commonUtils";
import { updateStatus } from "./utils";
import { constants } from "../05-utils/commonData";

const ACTIONS = {
    GET_LIST_REPEAT_ORDER_CODES_INFO_PROGRESS: "GET_LIST_REPEAT_ORDER_CODES_INFO_PROGRESS",
    GET_LIST_REPEAT_ORDER_CODES_INFO_SUCCESS: "GET_LIST_REPEAT_ORDER_CODES_INFO_SUCCESS",
    GET_LIST_REPEAT_ORDER_CODES_INFO_FAILED: "GET_LIST_REPEAT_ORDER_CODES_INFO_FAILED",
    GET_USER_REPEAT_ORDER_CODES_PROGRESS: "GET_USER_REPEAT_ORDER_CODES_PROGRESS",
    GET_USER_REPEAT_ORDER_CODES_SUCCESS: "GET_USER_REPEAT_ORDER_CODES_SUCCESS",
    GET_USER_REPEAT_ORDER_CODES_FAILED: "GET_USER_REPEAT_ORDER_CODES_FAILED",
    GET_USER_REQUEST_REPEAT_ORDER_CODES_PROGRESS: "GET_USER_REQUEST_REPEAT_ORDER_CODES_PROGRESS",
    GET_USER_REQUEST_REPEAT_ORDER_CODES_SUCCESS: "GET_USER_REQUEST_REPEAT_ORDER_CODES_SUCCESS",
    GET_USER_REQUEST_REPEAT_ORDER_CODES_FAILED: "GET_USER_REQUEST_REPEAT_ORDER_CODES_FAILED",
    CREATE_REPEAT_ORDER_CODE_PROGRESS: "CREATE_REPEAT_ORDER_CODE_PROGRESS",
    CREATE_REPEAT_ORDER_CODE_SUCCESS: "CREATE_REPEAT_ORDER_CODE_SUCCESS",
    CREATE_REPEAT_ORDER_CODE_FAILED: "CREATE_REPEAT_ORDER_CODE_FAILED",
    CREATE_REQUEST_REPEAT_ORDER_CODE_PROGRESS: "CREATE_REQUEST_REPEAT_ORDER_CODE_PROGRESS",
    CREATE_REQUEST_REPEAT_ORDER_CODE_SUCCESS: "CREATE_REQUEST_REPEAT_ORDER_CODE_SUCCESS",
    CREATE_REQUEST_REPEAT_ORDER_CODE_FAILED: "CREATE_REQUEST_REPEAT_ORDER_CODE_FAILED",
    GET_PRICE_AND_FEE_REPEAT_ORDER_CODE_PROGRESS: "GET_PRICE_AND_FEE_REPEAT_ORDER_CODE_PROGRESS",
    GET_PRICE_AND_FEE_REPEAT_ORDER_CODE_SUCCESS: "GET_PRICE_AND_FEE_REPEAT_ORDER_CODE_SUCCESS",
    GET_PRICE_AND_FEE_REPEAT_ORDER_CODE_FAILED: "GET_PRICE_AND_FEE_REPEAT_ORDER_CODE_FAILED",
    RESET_STATUS_CREATE_REPEAT_ORDER_CODE: "RESET_STATUS_CREATE_REPEAT_ORDER_CODE",
    RESET_STATUS_CREATE_REQUEST_REPEAT_ORDER_CODE: "RESET_STATUS_CREATE_REQUEST_REPEAT_ORDER_CODE",
    CANCEL_REQUEST_REPEAT_ORDER_CODE_PROGRESS: "CANCEL_REQUEST_REPEAT_ORDER_CODE_PROGRESS",
    CANCEL_REQUEST_REPEAT_ORDER_CODE_SUCCESS: "CANCEL_REQUEST_REPEAT_ORDER_CODE_SUCCESS",
    CANCEL_REQUEST_REPEAT_ORDER_CODE_FAILED: "CANCEL_REQUEST_REPEAT_ORDER_CODE_FAILED",
    NOTIFY_NEW_REPEAT_ORDER_CODE_CREATED: "NOTIFY_NEW_REPEAT_ORDER_CODE_CREATED",
};

//Repeat order code
const getListRepeatOrderCodesInfo = payload => {
    const data = {
        name: "get_list_repeat_order_codes_info",
        params: {
            searchParam: payload.searchParam,
            numPage: payload.numPage,
        }
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.GET_LIST_REPEAT_ORDER_CODES_INFO_PROGRESS));
        callApi('rpc', data)
            .then(response => {
                if (response.success) {
                    dispatch(updateStatus(ACTIONS.GET_LIST_REPEAT_ORDER_CODES_INFO_SUCCESS, { listCodes: response.result, totalRepeatOrderCodes: response.totalCodes }));
                }
                else {
                    dispatch(updateStatus(ACTIONS.GET_LIST_REPEAT_ORDER_CODES_INFO_FAILED, { error: response.error }));
                }
            })
            .catch(err => {
                console.log("getListRepeatOrderCodesInfo", err);
                dispatch(updateStatus(ACTIONS.GET_LIST_REPEAT_ORDER_CODES_INFO_FAILED, {error: err}));
            });
    };
};

const getUserRepeatOrderCodes = payload => {
    const data = {
        name: "get_user_repeat_order_codes",
        params: {
            numPage: payload.numPage,
        }
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.GET_USER_REPEAT_ORDER_CODES_PROGRESS));
        callApi('rpc', data)
            .then(response => {
                if (response.success) {
                    dispatch(updateStatus(ACTIONS.GET_USER_REPEAT_ORDER_CODES_SUCCESS, { repeatOrderCodes: response.result, totalRepeatOrderCodes: response.totalCodes }));
                }
                else {
                    dispatch(updateStatus(ACTIONS.GET_USER_REPEAT_ORDER_CODES_FAILED, { error: response.error }));
                }
            })
            .catch(err => {
                console.log("getListRepeatOrderCodesInfo", err);
                dispatch(updateStatus(ACTIONS.GET_USER_REPEAT_ORDER_CODES_FAILED, {error: err}));
            });
    };
};

const getUserRequestRepeatOrderCodes = payload => {
    const data = {
        name: "get_user_request_repeat_order_codes",
        params: {
            numPage: payload.numPage
        }
    }

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.GET_USER_REQUEST_REPEAT_ORDER_CODES_PROGRESS));
        callApi('rpc', data)
            .then(response => {
                if (response.success) {
                    dispatch(updateStatus(ACTIONS.GET_USER_REQUEST_REPEAT_ORDER_CODES_SUCCESS, { requestCodes: response.result, totalRequests: response.totalRequests }));
                } else {
                    dispatch(updateStatus(ACTIONS.GET_USER_REQUEST_REPEAT_ORDER_CODES_FAILED, { error: response.error }));
                }
            })
            .catch(err => {
                dispatch(updateStatus(ACTIONS.GET_USER_REQUEST_REPEAT_ORDER_CODES_FAILED, { error: err }));
            })
    }
};

const createRepeatOrderCode = payload => {
    const data = {
        name: 'create_repeat_order_code',
        params: {...payload}
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.CREATE_REPEAT_ORDER_CODE_PROGRESS));
        callApi('rpc', data)
            .then(response => {
                if (response.success) {
                    dispatch(updateStatus(ACTIONS.CREATE_REPEAT_ORDER_CODE_SUCCESS, { code: response.result }));
                } else {
                    dispatch(updateStatus(ACTIONS.CREATE_REPEAT_ORDER_CODE_FAILED, { error: response.error }));
                }
            })
            .catch(err => {
                dispatch(updateStatus(ACTIONS.CREATE_REPEAT_ORDER_CODE_FAILED, { error: err }));
            })
    }
};

const createRequestRepeatOrderCode = payload => {
    const data = {
        name: 'create_request_repeat_order_code',
        params: {...payload}
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.CREATE_REQUEST_REPEAT_ORDER_CODE_PROGRESS));
        callApi('rpc', data)
            .then(response => {
                if (response.success) {
                    dispatch(updateStatus(ACTIONS.CREATE_REQUEST_REPEAT_ORDER_CODE_SUCCESS, { requestRepeatOrderCode: response.result }));
                } else {
                    dispatch(updateStatus(ACTIONS.CREATE_REQUEST_REPEAT_ORDER_CODE_FAILED, { error: response.error }));
                }
            })
            .catch(err => {
                dispatch(updateStatus(ACTIONS.CREATE_REQUEST_REPEAT_ORDER_CODE_FAILED, { error: err }));
            })
    }
};

const getPriceAndFeeRepeatOrderCode = payload => {
    const data = {
        name: 'get_price_and_fee_repeat_order_code',
        params: {}
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.GET_PRICE_AND_FEE_REPEAT_ORDER_CODE_PROGRESS));
        callApi('rpc', data)
            .then(response => {
                if (response.success) {
                    let infoBuying = {
                        [payload.type]: response.result[payload.type],
                    };
                    infoBuying = payload.type === constants.TYPE_REPEAT_ORDER_CODE.RETAIL ? { ...infoBuying, limit: response.result.limit } : infoBuying;
                    dispatch(updateStatus(ACTIONS.GET_PRICE_AND_FEE_REPEAT_ORDER_CODE_SUCCESS, { infoBuying }));
                } else {
                    dispatch(updateStatus(ACTIONS.GET_PRICE_AND_FEE_REPEAT_ORDER_CODE_FAILED, { error: response.error }));
                }
            })
            .catch(err => {
                dispatch(updateStatus(ACTIONS.GET_PRICE_AND_FEE_REPEAT_ORDER_CODE_FAILED, { error: err }));
            })
    }
};

const resetStatusCreateRepeatOrderCode = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_STATUS_CREATE_REPEAT_ORDER_CODE, {}));

const resetStatusCreateRequestRepeatOrderCode = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_STATUS_CREATE_REQUEST_REPEAT_ORDER_CODE, {}));

const cancelRequestRepeatOrderCode = payload => {
    const data = {
        name: "cancel_request_product_code",
        params: {
            ...payload
        }
    }

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.CANCEL_REQUEST_REPEAT_ORDER_CODE_PROGRESS));
        callApi('rpc', data)
            .then(response => {
                if (response.success) {
                    dispatch(updateStatus(ACTIONS.CANCEL_REQUEST_REPEAT_ORDER_CODE_SUCCESS, { requestCodeId: payload.id }));
                } else {
                    dispatch(updateStatus(ACTIONS.CANCEL_REQUEST_REPEAT_ORDER_CODE_FAILED, { error: response.error }));
                }
            })
            .catch(err => {
                dispatch(updateStatus(ACTIONS.CANCEL_REQUEST_REPEAT_ORDER_CODE_FAILED, { error: err }));
            })
    }
};

const notifyRepeatOrderCodeCreated = ({ code, requestCodeId} ) => dispatch => dispatch(updateStatus(ACTIONS.NOTIFY_NEW_REPEAT_ORDER_CODE_CREATED, { code, requestCodeId }));

export {
    ACTIONS,
    getListRepeatOrderCodesInfo,
    getUserRepeatOrderCodes,
    getUserRequestRepeatOrderCodes,
    createRepeatOrderCode,
    createRequestRepeatOrderCode,
    getPriceAndFeeRepeatOrderCode,
    resetStatusCreateRepeatOrderCode,
    resetStatusCreateRequestRepeatOrderCode,
    cancelRequestRepeatOrderCode,
    notifyRepeatOrderCodeCreated
};