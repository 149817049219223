import { callApi } from "../05-utils/commonUtils";
import { updateStatus } from "./utils";
import { constants } from "../05-utils/commonData";
import { getUserInfo } from "./user";

const ACTIONS = {
	LOGIN_PROGRESS: "LOGIN_PROGRESS",
	LOGIN_SUCCESS: "LOGIN_SUCCESS",
	LOGIN_FAILED: "LOGIN_FAILED",
	LOGIN_WITH_CODE_PROGRESS: "LOGIN_WITH_CODE_PROGRESS",
	LOGIN_WITH_CODE_SUCCESS: "LOGIN_WITH_CODE_SUCCESS",
	LOGIN_WITH_CODE_FAILED: "LOGIN_WITH_CODE_FAILED",
	LOGIN_TFA_REQUIRE: "LOGIN_TFA_REQUIRE",
	GET_AUTH_STATUS: "GET_AUTH_STATUS",
	LOGOUT: "LOGOUT",
};

const login = payload => {
	const data = {
		name: "sign_in",
		params: {
			email: payload.email,
			password: payload.password,
			code: payload.code,
		}
	};
	
	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.LOGIN_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success) {
					let loginMethod = constants.LOGIN_METHOD.EMAIL;
					if (constants.OUTSIDE_CHINESE_USERCODE_REGEX.test(payload.email) || constants.CHINESE_USERCODE_REGEX.test(payload.email)) {
						loginMethod = constants.LOGIN_METHOD.USER_CODE;
					}
					dispatch(updateStatus(ACTIONS.LOGIN_SUCCESS, { token: response.result.access_token, loginMethod }));
					dispatch(getUserInfo(response.result.access_token));
				}
				else {
					if (response.error.errorCode === constants.VERIFICATION_REQUIRED_ERROR){
						return dispatch(updateStatus(ACTIONS.LOGIN_TFA_REQUIRE, {}));
					}
					dispatch(updateStatus(ACTIONS.LOGIN_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("login", err);
				dispatch(updateStatus(ACTIONS.LOGIN_FAILED, { error: err }));
			});
	};
};

const loginWithCode = payload => {
	const data = {
		name: "sign_in_get_access_token",
		params: {
			code: payload.code,
		}
	};
	
	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.LOGIN_WITH_CODE_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.LOGIN_WITH_CODE_SUCCESS, { token: response.result.access_token }));
				else
					dispatch(updateStatus(ACTIONS.LOGIN_WITH_CODE_FAILED, { error: response.error }));
			})
			.catch(err => {
				console.log("loginWithCode", err);
				dispatch(updateStatus(ACTIONS.LOGIN_WITH_CODE_FAILED, { error: err }));
			});
	};
};

const getAuthStatus = () => dispatch => dispatch(updateStatus(ACTIONS.GET_AUTH_STATUS, {}));

const logout = () => {
	return {
		type: ACTIONS.LOGOUT
	}
};

export { ACTIONS, login, getAuthStatus, logout, loginWithCode };