import React, { Component } from 'react';
import QrCode from 'qrcode.react';
import Platform from 'react-platform-js';
import { I18n } from 'react-i18next';
import { constants } from '../../05-utils/commonData';
import './qrUrlScheme.css';

export default class QrUrlScheme extends Component {
    constructor(props) {
        super(props);

        const { pathname, search } = this.props.location;
        this.urlScheme = `${constants.URL_SCHEME_PREFIX}${pathname}${search}`.replace('/qr/', '');
        this.isMobile = Platform.OS === "Android" || Platform.OS === "iOS";
    }

    componentDidMount() {
        const { pathname, search } = this.props.location;
        if (pathname && search && this.isMobile) {
            window.location = this.urlScheme;
        }
    }

    onOpenAppClick = () => {
        window.location = this.urlScheme;
        if (Platform.OS === "iOS") {
            window.location = this.urlScheme;
            setTimeout(function () {
                if (!document.webkitHidden) {
                    window.location = 'http://itunes.apple.com/app/id935944952';
                }
            }, 25);
        } else if (Platform.OS === "Android") {
            window.location.replace(this.urlScheme);
            setTimeout(function () {
                if (!document.webkitHidden) {
                    window.location.replace('https://play.google.com/store/apps/details?id=com.facebook.katana');
                }
            }, 25);
        }
    }

    render() {
        const { pathname, search } = this.props.location;
        if (!pathname || !search) {
            return <div />;
        }

        return (
            <I18n ns="translations">
                {
                    (t, { i18n }) => (
                        <div className="container-scroller">
                            <div className="container-fluid page-body-wrapper full-page-wrapper auth-page">
                                <div className="content-wrapper theme-one">
                                    <div className="login-form-wrapper">
                                        <div className="row w-100 login-middle">
                                            <div className="col-lg-5 mx-auto qr-wrapper auth-custom-card">
                                                <div className="text-center">
                                                    <QrCode size={172} value={this.urlScheme} />
                                                    <br />
                                                    {
                                                        this.isMobile && (
                                                            <button
                                                                className="btn btn-success margin-top-1rem"
                                                                onClick={this.onOpenAppClick}
                                                            >
                                                                {
                                                                    Platform.OS === "iOS" ?
                                                                        (<i class="fab fa-apple" />)
                                                                        :
                                                                        (<i class="fab fa-android" />)
                                                                }
                                                                <span>{t('open_app')}</span>
                                                            </button>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </I18n>
        );
    }
}
