import React, {Component} from 'react';
import { I18n } from 'react-i18next';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

class ConfirmDeleteAssetModal extends Component {

    render() {
        const { onDelete, onCancel } = this.props;
        return (
            <I18n ns="translations">
                {
                    t => (
                        <Modal isOpen={this.props.isOpen} className="modal-lg">
                            <ModalHeader>
                                <span>{t(`confirm_`)}</span>
                            </ModalHeader>
                            <ModalBody style={{maxHeight: '400px', overflowY: 'auto'}}>
                                 <span>{t('are_you_sure_remove_this_asset?')}</span>
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                    color="primary"
                                    onClick={onDelete}
                                >{t('ok')}</Button>
                                <Button className={'margin-left-5px'}  onClick={onCancel}>{t('cancel')}</Button>
                            </ModalFooter>
                        </Modal>
                    )
                }
            </I18n>
        );
    }
}

export default ConfirmDeleteAssetModal;