import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getEnv } from '../../../env';
import { getTimestampBackground } from '../../../01-actions/system';

class AuthenBackgroundWrapper extends Component {
	componentDidMount() {
		const { timestampBackground, actions } = this.props;
		if (!timestampBackground) {
			actions.getTimestampBackground();
		};
	}

	render() {
		const { timestampBackground, children } = this.props;

		return (
			<div
				className="content-wrapper theme-one"
				style={{
					background: `linear-gradient(rgba(0,0,0,.2),rgba(0,0,0,.2)),url(${getEnv('URL_BACKGROUND')}?time=${timestampBackground})`,
					backgroundSize: 'cover'
				}}
			>
				{children}
			</div>
		);
	}
}

const mapStateToProps = store => {
	return {
		timestampBackground: store.system.timestampBackground,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions: bindActionCreators(
			{
				getTimestampBackground,
			},
			dispatch
		)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(AuthenBackgroundWrapper);
