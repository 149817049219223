import React, {Component} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { I18n } from 'react-i18next';

import {
	filterAssets,
	selectAsset,
	createTrustLine,
	resetFilterAssetResult,
} from '../../../../../01-actions/asset';
import { resetTfaCode } from '../../../../../01-actions/wallet';
import OPPassModal from '../../../../../03-components/OPPassModal/OPPassModal';
import AssetLogo from '../../../../../03-components/AssetLogo/assetLogo';
import TFAModal from '../../../TfaCode/tfaCode';
import SubmitButton from '../../../../../03-components/ModalSubmitButton';
import './trustCoin.css';

class TrustCoin extends Component {
	state = {
		isOpenTFA: false,
		isOpenOPPass: false,
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps.trustLineCreated !== this.props.trustLineCreated) {
			nextProps.toggle();
		}
	}

	onSelectToken = token => this.props.actions.selectAsset(token);

	isActive = asset => {
		const { selectedAsset } = this.props;
		if (selectedAsset && asset.asset_type === 'native' && selectedAsset.asset_type === 'native')
			return 'active';
		if (selectedAsset && asset.name === selectedAsset.name)
			return 'active';
		return '';
	};

	toggleTFAModal = () => this.setState({ isOpenTFA: !this.state.isOpenTFA });

	toggleOPPass = () => this.setState({ isOpenOPPass: !this.state.isOpenOPPass });

	searchToken = e => {
		this.props.actions.selectAsset();
		this.props.actions.filterAssets(this.props.assets, e.target.value.trim());
	};

	onCreateTrustLine = code => {
		this.setState({ isOpenTFA: false, isOpenOPPass: false });
		const { address, selectedAsset } = this.props;
		const { opPass } = this.state;
		this.props.actions.createTrustLine({
			address,
			assetCode: selectedAsset.name,
			opPass,
			code,
		});
	};

	refreshAssetsAndToggle = () => {
		this.props.actions.resetFilterAssetResult();
		this.props.toggle();
	};

	onReceiveOPPass = opPass => {
		const { tfaEnabled } = this.props.userInfo;
		this.setState({ opPass }, () => {
			if (tfaEnabled) {
				this.setState({ isOpenTFA: true });
			}
			else {
				this.onCreateTrustLine();
			}
		});
	};

	render() {
		const {
			isOpen,
			assetsFiltered,
			trustLineCreating,
			selectedAsset,
		} = this.props;
		const { isOpenTFA, isOpenOPPass } = this.state;
		return (
			<I18n ns="translations">
				{
					t => (
						<React.Fragment>
							<TFAModal
								isOpen={isOpenTFA}
								toggle={this.toggleTFAModal}
								onSubmit={this.onCreateTrustLine}
							/>
							<OPPassModal
								isOpen={isOpenOPPass}
								toggle={this.toggleOPPass}
								onSubmit={this.onReceiveOPPass}
							/>
                            <Modal className="modal-trust-coin" isOpen={isOpen}>
                                <ModalHeader toggle={this.refreshAssetsAndToggle}>{t('search_asset_by_name')}</ModalHeader>
                                <ModalBody>
                                    <div className="form-group search-token-wrapper">
                                        <label>{t('search_token')}</label>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={t('please_enter_keyword')}
                                                onChange={e => this.searchToken(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row list-token">
                                        {
                                            assetsFiltered.slice(0, 8).map((asset, index) => (
                                                <div className={`token-wrapper ${this.isActive(asset)}`}
                                                     onClick={() => this.onSelectToken(asset)} key={index}>
                                                    <AssetLogo className="margin-bottom-half-rem" src={asset.logo}
                                                               assetName={asset.name} inTrustCoin/>
                                                    <p><b>{asset.name || 'RIA'}</b></p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <SubmitButton
                                        className="btn btn-primary"
                                        onClick={this.toggleOPPass}
                                        isSubmitting={trustLineCreating}
                                        disabled={!selectedAsset || assetsFiltered.length === 0}
                                        text={t('OK')}
                                    />
                                </ModalFooter>
                            </Modal>
                       </React.Fragment>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		assets: store.asset.assets,
		assetsFiltered: store.asset.assetsFiltered,
		selectedAsset: store.asset.selectedAsset,
		tfaCode: store.wallet.tfaCode,
		trustLineCreated: store.asset.trustLineCreated,
		trustLineCreating: store.asset.trustLineCreating,
		userInfo: store.user.userInfo,
        status: store.asset.status
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				filterAssets,
				selectAsset,
				createTrustLine,
				resetTfaCode,
				resetFilterAssetResult,
			},
			dispatch
		)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(TrustCoin);