import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// components
import EnterNumberOfPackage from '../../../../03-components/EnterNumberOfPackage/enterNumberOfPackage';
import SubmitButton from '../../../../03-components/ModalSubmitButton';

// styles
import './requestRepeatOrderCodeModal.css';
import {I18n} from "react-i18next";
import {getAmountBalance} from "../../../../05-utils/commonUtils";
import BigNumber from "bignumber.js";

class RequestRepeatOrderCodeModal extends Component {
    state = {
        numberOfPackage: 0,
    };

    componentWillReceiveProps(nextProps) {
        if (!this.props.requestRepeatOrderCodeCreated && nextProps.requestRepeatOrderCodeCreated) {
            this.props.onOpenNotify();
            this.refreshInputAndToggle();
        }
    }

    onEnterAmount = ({ event, numberOfPackage  }) => {
        const { asset, infoBuying } = this.props;
        let price;
        let fee;
        price = infoBuying.retail && infoBuying.retail.find(item => numberOfPackage >= item.min && numberOfPackage <= item.max && item.coin_type === asset);
        if (!price) {
            event.preventDefault();
            return;
        }
        fee = price.shipping_fee;
        price = new BigNumber(price.fee).times(numberOfPackage).toString();
        let amount = new BigNumber(price.toString()).plus(fee).toString() || 0;
        this.setState({totalAmount: amount});
    };

    createRequestRepeatOrderCode = () => {
        const {
            numberOfPackage,
        } = this.state;

        this.props.onCreateRequest({
            numberOfPackage
        })
    };

    handleNumberOfPackageChange = numberOfPackage => {
        this.setState({ numberOfPackage });
    };

    refreshInputAndToggle = () => {
        this.setState({ numberOfPackage: 0 });
    };


    render() {
        const {
            isOpen,
            requestRepeatOrderCodeCreating,
            onBack,
            asset,
            infoBuying,
        } = this.props;

        const {
            numberOfPackage,
            totalAmount
        } = this.state;
        return (
            <I18n ns="translations">
                {
                    t => (
                        <Modal isOpen={isOpen} className="modal-lg">
                            <ModalHeader toggle={() => {
                                this.refreshInputAndToggle();
                                this.props.toggle();
                            }}>
                                {t('create_new_code')}
                            </ModalHeader>
                            <ModalBody>
                                <EnterNumberOfPackage
                                    handleNumberOfPackageChange={this.handleNumberOfPackageChange}
                                    asset={asset}
                                    infoBuying={infoBuying}
                                    totalAmount={totalAmount}
                                    onEnterAmount={this.onEnterAmount}
                                />
                            </ModalBody>
                            <ModalFooter>
                                <SubmitButton
                                    className="btn btn-info"
                                    text={t('back')}
                                    onClick={onBack}
                                />
                                <SubmitButton
                                    className="btn btn-primary"
                                    onClick={this.createRequestRepeatOrderCode}
                                    text={t('generate')}
                                    isSubmitting={requestRepeatOrderCodeCreating}
                                    disabled={ (Number(numberOfPackage) > Number(infoBuying && infoBuying.limit) || Number(numberOfPackage) <= 0)}
                                />
                            </ModalFooter>
                        </Modal>
                    )
                }
            </I18n>
        );
    }
}

export default RequestRepeatOrderCodeModal;