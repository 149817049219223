import React, { Component } from 'react';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import { I18n } from 'react-i18next'
import './chooseAssetCreateCode.css';
import Loading from "../../../../03-components/Loading";

export default class ChooseAssetCreateCode extends Component {
    refreshInputAndToggle = () => {
        this.props.toggle();
    };

    render() {
        const { isOpen, onPressUSDT, onPressAGT, isFetching } = this.props;
        return (
            <I18n ns="translations">
                {
                    t => (
                        <Modal isOpen={isOpen} className="modal-lg">
                            <ModalHeader toggle={this.refreshInputAndToggle}>{t('create_new_code')}</ModalHeader>
                            <ModalBody>
                                <span>{t('select_asset_for_create_product_code')}</span>
                                {
                                    isFetching ?
                                        <Loading parent1Class="text-center" />
                                        :
                                        <div className="row wrapper-system-top-up wrapper-confirm-address">
                                            <button className="btn btn-link col-lg-6 col-md-12" onClick={onPressUSDT}>
                                                <img className="logo-system-top-up" src="/images/product-key/tether-usdt.png" alt=""/>
                                            </button>
                                            <button className="btn btn-link col-lg-6 col-md-12"  onClick={onPressAGT}>
                                                <img className="logo-system-top-up" src="/images/product-key/agt.png" alt=""/>
                                            </button>
                                        </div>
                                }
                            </ModalBody>
                        </Modal>
                    )
                }
            </I18n>
        );
    }
}
