import I18n from 'i18next';

const validate = values => {
	const errors = {};
	if (!values.email) {
		errors.email = I18n.t('EMAIL_REQUIRED');
	}
	return errors;
};

export default validate;