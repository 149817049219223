import { callApi } from "../05-utils/commonUtils";
import { updateStatus } from "./utils";

const ACTIONS = {
	GET_ALL_BACK_UP_FILE_PROGRESS: "GET_ALL_BACK_UP_FILE_PROGRESS",
	GET_ALL_BACK_UP_FILE_SUCCESS: "GET_ALL_BACK_UP_FILE_SUCCESS",
	GET_ALL_BACK_UP_FILE_FAILED: "GET_ALL_BACK_UP_FILE_FAILED",
	BACKUP_MANUALLY_PROGRESS: "BACKUP_MANUALLY_PROGRESS",
	BACKUP_MANUALLY_SUCCESS: "BACKUP_MANUALLY_SUCCESS",
	BACKUP_MANUALLY_FAILED: "BACKUP_MANUALLY_FAILED",
	DELETE_BACK_UP_FILE_PROGRESS: "DELETE_BACK_UP_FILE_PROGRESS",
	DELETE_BACK_UP_FILE_SUCCESS: "DELETE_BACK_UP_FILE_SUCCESS",
	DELETE_BACK_UP_FILE_FAILED: "DELETE_BACK_UP_FILE_FAILED",
	RESTORE_DATABASE_PROGRESS: "RESTORE_DATABASE_PROGRESS",
	RESTORE_DATABASE_SUCCESS: "RESTORE_DATABASE_SUCCESS",
	RESTORE_DATABASE_FAILED: "RESTORE_DATABASE_FAILED",
	PAGING_BACK_UP_FILE: "PAGING_BACK_UP_FILE",
	RESET_DB_BACK_UP_STATUS: "RESET_DB_BACK_UP_STATUS",
	RESET_DELETE_BACK_UP_FILE_STATUS: "RESET_DELETE_BACK_UP_FILE_STATUS",
	RESET_RESTORE_DATABASE_STATUS: "RESET_RESTORE_DATABASE_STATUS",
};

const getAllBackUpFile = () => {
	const data = {
		name: "get_all_backup_files",
		params: {}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.GET_ALL_BACK_UP_FILE_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success) {
					const backUpFiles = response.result.map((file, index) => {
						return {...file, index: index + 1};
					});
					dispatch(updateStatus(ACTIONS.GET_ALL_BACK_UP_FILE_SUCCESS, { backUpFiles }));
				}
				else {
					dispatch(updateStatus(ACTIONS.GET_ALL_BACK_UP_FILE_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("getAllBackUpFile", err);
				dispatch(updateStatus(ACTIONS.GET_ALL_BACK_UP_FILE_FAILED, {error: err}));
			});
	};
};

const backupManually = () => {
	const data = {
		name: "backup_manually",
		params: {}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.BACKUP_MANUALLY_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success) {
					dispatch(updateStatus(ACTIONS.BACKUP_MANUALLY_SUCCESS, {}));
				}
				else {
					dispatch(updateStatus(ACTIONS.PAGING_BACK_UP_FILE, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("backupManually", err);
				dispatch(updateStatus(ACTIONS.PAGING_BACK_UP_FILE, {error: err}));
			});
	};
};

const deleteBackUpFile = payload => {
	const data = {
		name: "delete_backup_file",
		params: {
			backupFileName: payload.backupFileName,
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.DELETE_BACK_UP_FILE_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success) {
					dispatch(updateStatus(ACTIONS.DELETE_BACK_UP_FILE_SUCCESS, {}));
				}
				else {
					dispatch(updateStatus(ACTIONS.DELETE_BACK_UP_FILE_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("deleteBackUpFile", err);
				dispatch(updateStatus(ACTIONS.DELETE_BACK_UP_FILE_FAILED, {error: err}));
			});
	};
};

const restoreDatabase = payload => {
	const data = {
		name: "restore_database",
		params: {
			backupFileName: payload.backupFileName,
		}
	};

	return (dispatch) => {
		callApi('rpc', data)
			.then(response => {
				if (response.success && !response.isDone) {
					dispatch(updateStatus(ACTIONS.RESTORE_DATABASE_PROGRESS));
				}
				else {
					dispatch(updateStatus(ACTIONS.RESTORE_DATABASE_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("restoreDatabase", err);
				dispatch(updateStatus(ACTIONS.RESTORE_DATABASE_FAILED, {error: err}));
			});
	};
};

const pagingBackUpFiles = (backUpFiles, page = 0, limit = 10) => {
	const displayBackUpFiles = backUpFiles && backUpFiles.slice(page * limit, (page + 1) * limit);
	return dispatch => dispatch(updateStatus(ACTIONS.PAGING_BACK_UP_FILE, { displayBackUpFiles, limit }));
};

const resetDBBackUpStatus = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_DB_BACK_UP_STATUS, {}));

const resetDeleteBackUpFileStatus = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_DELETE_BACK_UP_FILE_STATUS, {}));

const resetRestoreDatabaseStatus = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_RESTORE_DATABASE_STATUS, {}));

const restoreProgressStatus = () => dispatch => dispatch(updateStatus(ACTIONS.RESTORE_DATABASE_PROGRESS, {}));

const restoreSuccessStatus = () => dispatch => dispatch(updateStatus(ACTIONS.RESTORE_DATABASE_SUCCESS, {}));

export {
	ACTIONS,
	pagingBackUpFiles,
	getAllBackUpFile,
	backupManually,
	resetDBBackUpStatus,
	deleteBackUpFile,
	resetDeleteBackUpFileStatus,
	restoreDatabase,
	resetRestoreDatabaseStatus,
	restoreSuccessStatus,
	restoreProgressStatus,
};