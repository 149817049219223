import { callApi } from "../05-utils/commonUtils";
import { updateStatus } from "./utils";

const ACTIONS = {
	GET_ALL_NEWS_PROGRESS: "GET_ALL_NEWS_PROGRESS",
	GET_ALL_NEWS_SUCCESS: "GET_ALL_NEWS_SUCCESS",
	GET_ALL_NEWS_FAILED: "GET_ALL_NEWS_FAILED",
	CREATE_NEWS_PROGRESS: "CREATE_NEWS_PROGRESS",
	CREATE_NEWS_SUCCESS: "CREATE_NEWS_SUCCESS",
	CREATE_NEWS_FAILED: "CREATE_NEWS_FAILED",
	EDIT_NEWS_PROGRESS: "EDIT_NEWS_PROGRESS",
	EDIT_NEWS_SUCCESS: "EDIT_NEWS_SUCCESS",
	EDIT_NEWS_FAILED: "EDIT_NEWS_FAILED",
	DELETE_NEWS_PROGRESS: "DELETE_NEWS_PROGRESS",
	DELETE_NEWS_SUCCESS: "DELETE_NEWS_SUCCESS",
	DELETE_NEWS_FAILED: "DELETE_NEWS_FAILED",
	RESET_CREATE_NEWS_STATUS: "RESET_CREATE_NEWS_STATUS",
	RESET_EDIT_NEWS_STATUS: "RESET_EDIT_NEWS_STATUS",
	RESET_DELETE_NEWS_STATUS: "RESET_DELETE_NEWS_STATUS",
};

const getAllNews = () => {
	const data = {
		name: "get_all_news",
		params: {},
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.GET_ALL_NEWS_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success) {
					const listNews = response.result.map((news, index) => {
						return {...news, index: index + 1};
					});
					dispatch(updateStatus(ACTIONS.GET_ALL_NEWS_SUCCESS, { listNews }));
				}
				else {
					dispatch(updateStatus(ACTIONS.GET_ALL_NEWS_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("getAllNews", err);
				dispatch(updateStatus(ACTIONS.GET_ALL_NEWS_FAILED, {error: err}));
			});
	};
};

const createNews = payload => {
	const data = {
		name: "post_news",
		params: {
			title: payload.title,
			content: payload.content,
		},
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.CREATE_NEWS_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.CREATE_NEWS_SUCCESS, {}));
				else {
					dispatch(updateStatus(ACTIONS.CREATE_NEWS_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("createNews", err);
				dispatch(updateStatus(ACTIONS.CREATE_NEWS_FAILED, {error: err}));
			});
	};
};

const editNews = payload => {
	const data = {
		name: "edit_news",
		params: {
			_id: payload.newsId,
			title: payload.title,
			content: payload.content,
		},
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.EDIT_NEWS_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.EDIT_NEWS_SUCCESS, {}));
				else {
					dispatch(updateStatus(ACTIONS.EDIT_NEWS_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("editNews", err);
				dispatch(updateStatus(ACTIONS.EDIT_NEWS_FAILED, {error: err}));
			});
	};
};

const deleteNews = payload => {
	const data = {
		name: "delete_news",
		params: {
			_id: payload.newsId,
		},
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.DELETE_NEWS_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.DELETE_NEWS_SUCCESS, {}));
				else {
					dispatch(updateStatus(ACTIONS.DELETE_NEWS_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("deleteNews", err);
				dispatch(updateStatus(ACTIONS.DELETE_NEWS_FAILED, {error: err}));
			});
	};
};

const resetCreateNewsStatus = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_CREATE_NEWS_STATUS, {}));

const resetEditNewsStatus = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_EDIT_NEWS_STATUS, {}));

const resetDeleteNewsStatus = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_DELETE_NEWS_STATUS, {}));

export {
	ACTIONS,
	getAllNews,
	createNews,
	editNews,
	deleteNews,
	resetCreateNewsStatus,
	resetEditNewsStatus,
	resetDeleteNewsStatus,
};