import React, {PureComponent} from 'react';
import Loading from '../Loading';

export default class Button extends PureComponent {
	render() {
		const { onClick, className, isSubmitting, text, disabled, hidden } = this.props;
		return (
			<button
				className={className}
				onClick={onClick}
				disabled={isSubmitting || disabled}
				hidden={hidden}
			>
				{
					isSubmitting ? <Loading loadingClass="text-white"/> : <span className="text-white font-text font-size-18">{text}</span>
				}
			</button>
		);
	}
};