import { callApi } from "../05-utils/commonUtils";
import { updateStatus } from "./utils";

const ACTIONS = {
	VERIFY_EMAIL_PROGRESS: "VERIFY_EMAIL_PROGRESS",
	VERIFY_EMAIL_SUCCESS: "VERIFY_EMAIL_SUCCESS",
	VERIFY_EMAIL_FAILED: "VERIFY_EMAIL_FAILED",
	RESEND_VERIFY_EMAIL_PROGRESS: "RESEND_VERIFY_EMAIL_PROGRESS",
	RESEND_VERIFY_EMAIL_SUCCESS: "RESEND_VERIFY_EMAIL_SUCCESS",
	RESEND_VERIFY_EMAIL_FAILED: "RESEND_VERIFY_EMAIL_FAILED",
};

const verifyEmail = payload => {
	const data = {
		name: "verify_email",
		params: {
			code: payload.code
		}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.VERIFY_EMAIL_PROGRESS));
		callApi('rpc',
			data,
			null,
			(response) => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.VERIFY_EMAIL_SUCCESS, {}));
				else {
					dispatch(updateStatus(ACTIONS.VERIFY_EMAIL_FAILED, { error: response.error }));
				}
			},
			(err) => {
				dispatch(updateStatus(ACTIONS.VERIFY_EMAIL_FAILED, {error: err}));
			}
		);
	};
};

const resendVerifyEmail = () => {
	const data = {
		name: "resend_verify_email",
		params: {}
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.RESEND_VERIFY_EMAIL_PROGRESS));
		callApi('rpc',
			data,
			null,
			(response) => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.RESEND_VERIFY_EMAIL_SUCCESS, {}));
				else {
					dispatch(updateStatus(ACTIONS.RESEND_VERIFY_EMAIL_FAILED, { error: response.error }));
				}
			},
			(err) => {
				dispatch(updateStatus(ACTIONS.RESEND_VERIFY_EMAIL_FAILED, {error: err}));
			}
		);
	};
};

export { ACTIONS, verifyEmail, resendVerifyEmail };