import React, { Component } from 'react';

class RadioInput extends Component {
    render() {
        const { checked, onChange, text } = this.props;

        return (
            <label className="form-check-label">
                <input
                    type="radio"
                    className="form-check-input"
                    checked={checked}
                    onChange={onChange}
                />
                {text}
            </label>
        )
    }
}

export default RadioInput;