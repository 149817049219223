import { callApi } from "../05-utils/commonUtils";
import { updateStatus } from "./utils";

const ACTIONS = {
	GET_ALL_CONTACTS_PROGRESS: "GET_ALL_CONTACTS_PROGRESS",
	GET_ALL_CONTACTS_SUCCESS: "GET_ALL_CONTACTS_SUCCESS",
	GET_ALL_CONTACTS_FAILED: "GET_ALL_CONTACTS_FAILED",
	ADD_CONTACT_PROGRESS: "ADD_CONTACT_PROGRESS",
	ADD_CONTACT_SUCCESS: "ADD_CONTACT_SUCCESS",
	ADD_CONTACT_FAILED: "ADD_CONTACT_FAILED",
	RESET_ADD_CONTACT_STATUS: "RESET_ADD_CONTACT_STATUS",
	EDIT_CONTACT_PROGRESS: "EDIT_CONTACT_PROGRESS",
	EDIT_CONTACT_SUCCESS: "EDIT_CONTACT_SUCCESS",
	EDIT_CONTACT_FAILED: "EDIT_CONTACT_FAILED",
	RESET_EDIT_CONTACT_STATUS: "RESET_EDIT_CONTACT_STATUS",
	DELETE_CONTACT_PROGRESS: "DELETE_CONTACT_PROGRESS",
	DELETE_CONTACT_SUCCESS: "DELETE_CONTACT_SUCCESS",
	DELETE_CONTACT_FAILED: "DELETE_CONTACT_FAILED",
	RESET_DELETE_CONTACT_STATUS: "RESET_DELETE_CONTACT_STATUS",
};

const getAllContact = () => {
	const data = {
		name: "get_all_user_contacts",
		params: {},
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.GET_ALL_CONTACTS_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.GET_ALL_CONTACTS_SUCCESS, { contacts: response.result }));
				else {
					dispatch(updateStatus(ACTIONS.GET_ALL_CONTACTS_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("getAllContact", err);
				dispatch(updateStatus(ACTIONS.GET_ALL_CONTACTS_FAILED, {error: err}));
			});
	};
};

const addContact = payload => {
	const data = {
		name: "create_user_contact",
		params: {
			name: payload.name,
			address: payload.address,
		},
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.ADD_CONTACT_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.ADD_CONTACT_SUCCESS, {}));
				else {
					dispatch(updateStatus(ACTIONS.ADD_CONTACT_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("addContact", err);
				dispatch(updateStatus(ACTIONS.ADD_CONTACT_FAILED, { error: err }));
			});
	};
};

const editContact = payload => {
	const data = {
		name: "edit_user_contact",
		params: {
			contactId: payload.contactId,
			name: payload.name,
			address: payload.address,
		},
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.EDIT_CONTACT_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.EDIT_CONTACT_SUCCESS, {}));
				else {
					dispatch(updateStatus(ACTIONS.EDIT_CONTACT_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("editContact", err);
				dispatch(updateStatus(ACTIONS.EDIT_CONTACT_FAILED, { error: err }));
			});
	};
};

const deleteContact = payload => {
	const data = {
		name: "remove_user_contact",
		params: {
			contactId: payload.contactId,
		},
	};

	return (dispatch) => {
		dispatch(updateStatus(ACTIONS.DELETE_CONTACT_PROGRESS));
		callApi('rpc', data)
			.then(response => {
				if (response.success)
					dispatch(updateStatus(ACTIONS.DELETE_CONTACT_SUCCESS, {}));
				else {
					dispatch(updateStatus(ACTIONS.DELETE_CONTACT_FAILED, { error: response.error }));
				}
			})
			.catch(err => {
				console.log("editContact", err);
				dispatch(updateStatus(ACTIONS.DELETE_CONTACT_FAILED, { error: err }));
			});
	};
};

const resetAddContactStatus = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_ADD_CONTACT_STATUS, {}));

const resetEditContactStatus = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_EDIT_CONTACT_STATUS, {}));

const resetDeleteContactStatus = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_DELETE_CONTACT_STATUS, {}));

export {
	ACTIONS,
	getAllContact,
	addContact,
	resetAddContactStatus,
	editContact,
	resetEditContactStatus,
	deleteContact,
	resetDeleteContactStatus,
};