import { callApi} from "../05-utils/commonUtils";
import { updateStatus } from "./utils";

const ACTIONS = {
    GET_LIST_PRODUCT_CODES_INFO_PROGRESS: "GET_LIST_PRODUCT_CODES_INFO_PROGRESS",
    GET_LIST_PRODUCT_CODES_INFO_SUCCESS: "GET_LIST_PRODUCT_CODES_INFO_SUCCESS",
    GET_LIST_PRODUCT_CODES_INFO_FAILED: "GET_LIST_PRODUCT_CODES_INFO_FAILED",
    ADMIN_REJECT_PRODUCT_CODE_PROGRESS: "ADMIN_REJECT_PRODUCT_CODE_PROGRESS",
    ADMIN_REJECT_PRODUCT_CODE_SUCCESS: "ADMIN_REJECT_PRODUCT_CODE_SUCCESS",
    ADMIN_REJECT_PRODUCT_CODE_FAILED: "ADMIN_REJECT_PRODUCT_CODE_FAILED",
    GET_USER_PRODUCT_CODES_PROGRESS: "GET_USER_PRODUCT_CODES_PROGRESS",
    GET_USER_PRODUCT_CODES_SUCCESS: "GET_USER_PRODUCT_CODES_SUCCESS",
    GET_USER_PRODUCT_CODES_FAILED: 'GET_USER_PRODUCT_CODES_FAILED',
    GET_USER_REQUEST_PRODUCT_CODES_PROGRESS: "GET_USER_REQUEST_PRODUCT_CODES_PROGRESS",
    GET_USER_REQUEST_PRODUCT_CODES_SUCCESS: "GET_USER_REQUEST_PRODUCT_CODES_SUCCESS",
    GET_USER_REQUEST_PRODUCT_CODES_FAILED: 'GET_USER_REQUEST_PRODUCT_CODES_FAILED',
    GET_LIST_TITLE_BY_REGION_PROGRESS: 'GET_LIST_TITLE_BY_REGION_PROGRESS',
    GET_LIST_TITLE_BY_REGION_SUCCESS: 'GET_LIST_TITLE_BY_REGION_SUCCESS',
    GET_LIST_TITLE_BY_REGION_FAILED: 'GET_LIST_TITLE_BY_REGION_FAILED',
    CREATE_PRODUCT_CODE_PROGRESS: 'CREATE_PRODUCT_CODE_PROGRESS',
    CREATE_PRODUCT_CODE_SUCCESS: 'CREATE_PRODUCT_CODE_SUCCESS',
    CREATE_PRODUCT_CODE_FAILED: 'CREATE_PRODUCT_CODE_FAILED',
    RESET_STATUS_CREATE_PRODUCT_CODE: 'RESET_STATUS_CREATE_PRODUCT_CODE',
    GET_SUMMARY_PRODUCT_CODE_PROGRESS: 'GET_SUMMARY_PRODUCT_CODE_PROGRESS',
    GET_SUMMARY_PRODUCT_CODE_SUCCESS: 'GET_SUMMARY_PRODUCT_CODE_SUCCESS',
    GET_SUMMARY_PRODUCT_CODE_FAILED: 'GET_SUMMARY_PRODUCT_CODE_FAILED',
    CREATE_REQUEST_PRODUCT_CODE_PROGRESS: 'CREATE_REQUEST_PRODUCT_CODE_PROGRESS',
    CREATE_REQUEST_PRODUCT_CODE_SUCCESS: 'CREATE_REQUEST_PRODUCT_CODE_SUCCESS',
    CREATE_REQUEST_PRODUCT_CODE_FAILED: 'CREATE_REQUEST_PRODUCT_CODE_FAILED',
    RESET_STATUS_CREATE_REQUEST_PRODUCT_CODE: 'RESET_STATUS_CREATE_REQUEST_PRODUCT_CODE',
    CANCEL_REQUEST_PRODUCT_CODE_PROGRESS: 'CANCEL_REQUEST_PRODUCT_CODE_PROGRESS',
    CANCEL_REQUEST_PRODUCT_CODE_SUCCESS: 'CANCEL_REQUEST_PRODUCT_CODE_SUCCESS',
    CANCEL_REQUEST_PRODUCT_CODE_FAILED: 'CANCEL_REQUEST_PRODUCT_CODE_FAILED',
    NOTIFY_NEW_PRODUCT_CODE_CREATED: 'NOTIFY_NEW_PRODUCT_CODE_CREATED',
    GET_LIST_REPEAT_ORDER_CODES_INFO_PROGRESS: "GET_LIST_REPEAT_ORDER_CODES_INFO_PROGRESS",
    GET_LIST_REPEAT_ORDER_CODES_INFO_SUCCESS: "GET_LIST_REPEAT_ORDER_CODES_INFO_SUCCESS",
    GET_LIST_REPEAT_ORDER_CODES_INFO_FAILED: "GET_LIST_REPEAT_ORDER_CODES_INFO_FAILED",
};

const getListProductCodesInfo = payload => {
    const data = {
        name: "get_list_product_codes_info",
        params: {
            searchParam: payload.searchParam,
            region: payload.region,
            numPage: payload.numPage,
        }
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.GET_LIST_PRODUCT_CODES_INFO_PROGRESS));
        callApi('rpc', data)
            .then(response => {
                if (response.success) {
                    dispatch(updateStatus(ACTIONS.GET_LIST_PRODUCT_CODES_INFO_SUCCESS, { listCodes: response.result, totalCodes: response.totalCodes }));
                }
                else {
                    dispatch(updateStatus(ACTIONS.GET_LIST_PRODUCT_CODES_INFO_FAILED, { error: response.error }));
                }
            })
            .catch(err => {
                console.log("getListProductCodesInfo", err);
                dispatch(updateStatus(ACTIONS.GET_LIST_PRODUCT_CODES_INFO_FAILED, {error: err}));
            });
    };
};

const rejectProductCode = payload => {
    const data = {
        name: "admin_reject_product_code",
        params: {
            productCode: payload.productCode,
            userCode: payload.userCode,
        }
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.ADMIN_REJECT_PRODUCT_CODE_PROGRESS));
        callApi('rpc', data)
            .then(response => {
                if (response.success) {
                    dispatch(updateStatus(ACTIONS.ADMIN_REJECT_PRODUCT_CODE_SUCCESS, {}));
                }
                else {
                    dispatch(updateStatus(ACTIONS.ADMIN_REJECT_PRODUCT_CODE_FAILED, { error: response.error }));
                }
            })
            .catch(err => {
                console.log("rejectProductCode", err);
                dispatch(updateStatus(ACTIONS.ADMIN_REJECT_PRODUCT_CODE_FAILED, {error: err}));
            });
    };
};

const getUserProductCodes = (payload) => {
    const data = {
        name: "get_user_product_codes",
        params: {
            numPage: payload.numPage
        }
    }

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.GET_USER_PRODUCT_CODES_PROGRESS));
        callApi('rpc', data)
            .then(response => {
                if (response.success) {
                    dispatch(updateStatus(ACTIONS.GET_USER_PRODUCT_CODES_SUCCESS, { productCodes: response.result, totalProductCode: response.totalCodes }));
                } else {
                    dispatch(updateStatus(ACTIONS.GET_USER_PRODUCT_CODES_FAILED, { error: response.error }));
                }
            })
            .catch(err => {
                dispatch(updateStatus(ACTIONS.GET_USER_PRODUCT_CODES_FAILED, { error: err }));
            })
    }
}

const getListTitleByRegion = (payload) => {
    const data = {
        name: 'get_list_titles_by_region',
        params: {}
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.GET_LIST_TITLE_BY_REGION_PROGRESS));
        callApi('rpc', data)
            .then(response => {
                if (response.success) {
                    dispatch(updateStatus(ACTIONS.GET_LIST_TITLE_BY_REGION_SUCCESS, { listTitle: response.result }));
                } else {
                    dispatch(updateStatus(ACTIONS.GET_LIST_TITLE_BY_REGION_FAILED, { error: response.error }));
                }
            })
            .catch(err => {
                dispatch(updateStatus(ACTIONS.GET_LIST_TITLE_BY_REGION_FAILED, { error: err }));
            })
    }
};

const createProductCode = (payload) => {
    const data = {
        name: 'create_product_code',
        params: {...payload}
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.CREATE_PRODUCT_CODE_PROGRESS));
        callApi('rpc', data)
            .then(response => {
                if (response.success) {
                    dispatch(updateStatus(ACTIONS.CREATE_PRODUCT_CODE_SUCCESS, { code: response.result }));
                } else {
                    dispatch(updateStatus(ACTIONS.CREATE_PRODUCT_CODE_FAILED, { error: response.error }));
                }
            })
            .catch(err => {
                dispatch(updateStatus(ACTIONS.CREATE_PRODUCT_CODE_FAILED, { error: err }));
            })
    }
}

const getSummaryProductCode = () => {
    const data = {
        name: 'get_summary_product_code',
        params: {}
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.GET_SUMMARY_PRODUCT_CODE_PROGRESS));
        callApi('rpc', data)
            .then(response => {
                if (response.success) {
                    dispatch(updateStatus(ACTIONS.GET_SUMMARY_PRODUCT_CODE_SUCCESS, { summary: response.result }));
                } else {
                    dispatch(updateStatus(ACTIONS.GET_SUMMARY_PRODUCT_CODE_FAILED, { error: response.error }));
                }
            })
            .catch(err => {
                dispatch(updateStatus(ACTIONS.GET_SUMMARY_PRODUCT_CODE_FAILED, { error: err }));
            })
    }
}

const getUserRequestProductCodes = payload => {
    const data = {
        name: "get_user_request_product_codes",
        params: {
            numPage: payload.numPage
        }
    }

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.GET_USER_REQUEST_PRODUCT_CODES_PROGRESS));
        callApi('rpc', data)
            .then(response => {
                if (response.success) {
                    dispatch(updateStatus(ACTIONS.GET_USER_REQUEST_PRODUCT_CODES_SUCCESS, { requestCodes: response.result, totalRequests: response.totalRequests }));
                } else {
                    dispatch(updateStatus(ACTIONS.GET_USER_REQUEST_PRODUCT_CODES_FAILED, { error: response.error }));
                }
            })
            .catch(err => {
                dispatch(updateStatus(ACTIONS.GET_USER_REQUEST_PRODUCT_CODES_FAILED, { error: err }));
            })
    }
}

const cancelRequestCode = payload => {
    const data = {
        name: "cancel_request_product_code",
        params: {
            id: payload.id
        }
    }

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.CANCEL_REQUEST_PRODUCT_CODE_PROGRESS));
        callApi('rpc', data)
            .then(response => {
                if (response.success) {
                    dispatch(updateStatus(ACTIONS.CANCEL_REQUEST_PRODUCT_CODE_SUCCESS, { requestCodeId: payload.id }));
                } else {
                    dispatch(updateStatus(ACTIONS.CANCEL_REQUEST_PRODUCT_CODE_FAILED, { error: response.error }));
                }
            })
            .catch(err => {
                dispatch(updateStatus(ACTIONS.CANCEL_REQUEST_PRODUCT_CODE_FAILED, { error: err }));
            })
    }
}

const resetStatusCreateProductCode = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_STATUS_CREATE_PRODUCT_CODE, {}));

const createRequestProductCode = (payload) => {
    const data = {
        name: 'create_request_product_code',
        params: {...payload}
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.CREATE_REQUEST_PRODUCT_CODE_PROGRESS));
        callApi('rpc', data)
            .then(response => {
                if (response.success) {
                    dispatch(updateStatus(ACTIONS.CREATE_REQUEST_PRODUCT_CODE_SUCCESS, { requestCode: response.result }));
                } else {
                    dispatch(updateStatus(ACTIONS.CREATE_REQUEST_PRODUCT_CODE_FAILED, { error: response.error }));
                }
            })
            .catch(err => {
                dispatch(updateStatus(ACTIONS.CREATE_REQUEST_PRODUCT_CODE_FAILED, { error: err }));
            })
    }
}

const resetStatusCreateRequestProductCode = () => dispatch => dispatch(updateStatus(ACTIONS.RESET_STATUS_CREATE_REQUEST_PRODUCT_CODE, {}));

const notifyProductCodeCreated = ({code, requestCodeId}) => dispatch => dispatch(updateStatus(ACTIONS.NOTIFY_NEW_PRODUCT_CODE_CREATED, { code, requestCodeId }));

export {
    ACTIONS,
    getListProductCodesInfo,
    rejectProductCode,
    getUserProductCodes,
    getListTitleByRegion,
    createProductCode,
    resetStatusCreateProductCode,
    getSummaryProductCode,
    getUserRequestProductCodes,
    createRequestProductCode,
    resetStatusCreateRequestProductCode,
    cancelRequestCode,
    notifyProductCodeCreated,
};