import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { I18n } from 'react-i18next';
import { adminResetUser, resetAdminResetUserStatus } from "../../../../01-actions/adminUser";
import './confirmResetUser.css';

import SubmitButton from '../../../../03-components/ModalSubmitButton';

class ConfirmReset extends Component {
	componentWillReceiveProps(nextProps) {
		if (nextProps.userReset) {
			nextProps.actions.resetAdminResetUserStatus();
			nextProps.toggle();
		}
	}
	
	resetUser = () => {
		const { actions, usersSelected } = this.props;
		actions.adminResetUser({ listUser: usersSelected });
	};

	render() {
		const {
			isOpen,
			toggle,
			userResetting,
		} = this.props;
		return (
			<I18n ns="translations">
				{
					t => (
						<Modal isOpen={isOpen} toggle={toggle} className="modal-lg confirm-reset-user">
							<ModalHeader toggle={toggle}>{t('important')}</ModalHeader>
							<ModalBody>
								<div className="text-center">
									<i className="fa fa-exclamation-circle text-danger"/>
									<p className="delete-contact-intro">{t('reset_user_confirm')}</p>
								</div>
							</ModalBody>
							<ModalFooter>
								<SubmitButton
									className="btn btn-light"
									onClick={this.props.toggle}
									text={t('cancel')}
								/>
								<SubmitButton
									className="btn btn-danger"
									onClick={this.resetUser}
									isSubmitting={userResetting}
									text={t('reset_user')}
								/>
							</ModalFooter>
						</Modal>
					)
				}
			</I18n>
		);
	}
}

const mapStateToProps = store => {
	return {
		userResetting: store.adminUser.userResetting,
		userReset: store.adminUser.userReset,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		actions : bindActionCreators(
			{
				adminResetUser,
				resetAdminResetUserStatus,
			},
			dispatch
		)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ConfirmReset);
