import I18n from 'i18next';

const validate = values => {
	const errors = {};
	if (!values.code) {
		errors.code = I18n.t('TFA_CODE_REQUIRED');
	}
	if (values.code && values.code.length < 6) {
		errors.code = I18n.t('TFA_CODE_MIN_LENGTH');
	}
	return errors;
};

export default validate;